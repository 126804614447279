import React from 'react';
import PropTypes from 'prop-types';
import * as en from 'intl/en';
import { FormattedMessage } from 'react-intl';
import { withTheme } from '@material-ui/core';

const getStyles = theme => ({
  wrapper: {
    color: theme.palette.textColor,
    marginTop: 5,
    marginBottom: 5,
  },
  fieldName: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

const DescriptionLine = props => {
  const {
    description,
    title,
    theme,
    children,
    fontSize,
  } = props;
  const styles = getStyles(theme);

  return (
    <div
      style={{
        fontSize,
        ...styles.wrapper,
      }}
    >
      <span style={{...styles.fieldName}}>
      {
        title.text ?
        <FormattedMessage
          id={title.text}
          defaultMessage={en[title.text]}
        />
        :
        title
      }:
      </span>
      &nbsp;
      {children}
      {
        description && description.text ?
        <FormattedMessage
          id={description.text}
          defaultMessage={en[description.text]}
        />
        :
        description
      }
    </div>
  );
};

DescriptionLine.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
  theme: PropTypes.object.isRequired,
  children: PropTypes.any,
  fontSize: PropTypes.number,
};

DescriptionLine.defaultProps = {
  title: '',
  description: '',
};

export default withTheme(DescriptionLine);
