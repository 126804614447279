import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Column } from 'react-virtualized';
import { TableCell } from '@material-ui/core';

import StationKeyDialogContainer from '../../StationKeyDialog/index';
import { showActionColumn } from '../../utils/helpers';
import { stationKey } from '../../utils/constants';

const StationKeyColumn = ({ styles, actionColumns }) => {
  const isEnabledStationKey = showActionColumn(actionColumns, stationKey);

  return (
    isEnabledStationKey ? (
      <Column
        headerRenderer={headerProps => (
          <TableCell
            component="div"
            variant="head"
            style={{ border: 'none' }}
          >
            <div style={{ width: 58, height: 50 }} />
          </TableCell>
        )}
        dataKey={stationKey}
        width={58}
        cellRenderer={cellProps => {
          const isEnabled = _.isEqual(cellProps.rowData.device.device_keys_state, 'Set');
          const isError = _.isEqual(cellProps.rowData.device.device_keys_state, 'Error');
          const isNotApplicable = _.isEqual(cellProps.rowData.device.device_keys_state, 'NotApplicable');

        return (
          <TableCell
            component="div"
            variant="body"
            style={{ border: 'none' }}
          >
            {
              !isNotApplicable &&
              <StationKeyDialogContainer
                index={cellProps.rowIndex}
                log={cellProps.rowData}
                styles={styles}
                isEnabled={isEnabled}
                isError={isError}
              />
            }
          </TableCell>
        );
      }}
        headerStyle={{ width: 58, margin: 0 }}
        style={{ margin: 0 }}
      />
    ) : null
  );
};

StationKeyColumn.propTypes = {
  styles: PropTypes.object.isRequired,
  actionColumns: PropTypes.array.isRequired,
};

export default StationKeyColumn;
