import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'redux-form';

import { translateLabel } from '../helpers';

const CheckboxInput = ({input, label, disabled, checked, style}) => (
  <FormControlLabel
    style={style}
    control={
    <MuiCheckbox
      color="default"
      role="checkbox"
      label={label}
      checked={!!input.value}
      onChange={input.onChange}
      aria-checked={input.value}
      disabled={disabled}
    />
  }
    label={label}
  />
);

CheckboxInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  style: PropTypes.object,
};

const Checkbox = ({ name, label, intl, validate, disabled, checked, style, checkboxChange }) => (
    <Field
      name={name}
      component={CheckboxInput}
      label={translateLabel(label, intl)}
      validate={validate}
      disabled={disabled}
      checked={checked}
      onChange={event => checkboxChange && checkboxChange(name, event.target.checked)}
      style={style}
    />
);

Checkbox.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.any,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  style: PropTypes.object,
  checkboxChange: PropTypes.func,
};

export default injectIntl(Checkbox);
