import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import * as en from 'intl/en';

import ConfirmationDialog from './ConfirmationDialog';
import { deleteUnassignedDevice } from '../../actions/devicesActions';

class ConfirmationDialogContainer extends Component {
  static propTypes = {
    _deleteUnassignedDevice: PropTypes.func.isRequired,
    inventoryId: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  confirmAction = () => {
    const { _deleteUnassignedDevice, inventoryId } = this.props;

    _deleteUnassignedDevice(inventoryId);
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen } = this.state;
    const {
      handleOpen,
      handleClose,
      confirmAction,
    } = this;

    return (
      <Fragment>
        <Button
          color="primary"
          onClick={handleOpen}
        >
          <FormattedMessage
            id="operations.details.deleteFromLot"
            defaultMessage={en.operations.details.deleteFromLot}
          />
        </Button>
        <ConfirmationDialog
          isOpen={isOpen}
          closeDialog={handleClose}
          confirmAction={confirmAction}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  _deleteUnassignedDevice: inventoryId => {
    dispatch(deleteUnassignedDevice(inventoryId));
  },
});

export default connect(null, mapDispatchToProps)(ConfirmationDialogContainer);
