import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';

import TransferInfo from '../presentational/TransferInfo';
import CellRenderer from './CellRenderer';
import HeaderRenderer from './HeaderRenderer';
import ValidTransferColumn from './ValidTransferColumn';
import BaseStationSwitch from './ActionsColumns/BaseStationSwitch';
import GraphColumn from './ActionsColumns/GraphColumn';
import DownloadLogColumn from './ActionsColumns/DownloadLogColumn';
import StationKeyColumn from './ActionsColumns/StationKeyColumn';
import Spinner from './Spinner';

const rebuildTooltip = _.debounce(() => ReactTooltip.rebuild(), 300);

const VirtualizedDeviceLogsTable = ({
  headerColumns,
  actionColumns,
  rows,
  onSort,
  sortDirection,
  activeField,
  styles,
  isLoading,
  logsToHighlight,
  toggleBaseStationSwitch,
  notSortable,
  ...rest
}) => {
  return (
    <div style={{ flex: '1 1 auto', minHeight: 505, minWidth: 1200 }}>
      <ReactTooltip
        id="valid_transfer"
        type="none"
        effect="solid"
      >
        <TransferInfo />
      </ReactTooltip>
      <AutoSizer>
        {({ height, width }) => (
          <Table
            onRowsRendered={rebuildTooltip}
            style={{
              ...styles.table,
            }}
            width={width}
            height={height}
            headerHeight={50}
            rowHeight={50}
            rowCount={rows.length}
            rowGetter={({ index }) => rows[index]}
            headerStyle={{
              marginLeft: 5,
            }}
            rowStyle={({ index }) => {
              const fileLink = _.get(rows[index], 'file_link');
              const shouldHighlightRow = _.includes(logsToHighlight, fileLink);

              return {
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                ...(shouldHighlightRow ? styles.newLog : {}),
              };
            }}
            overscanRowCount={10}
            noRowsRenderer={() => {
              return (
                <Spinner isLoading={isLoading} styles={styles} />
              );
            }}
          >
            {
              BaseStationSwitch({ styles, actionColumns, toggleBaseStationSwitch, ...rest })
            }
            {
              GraphColumn({ styles, actionColumns, ...rest })
            }
            {
              DownloadLogColumn({ styles, actionColumns, ...rest })
            }
            {
              StationKeyColumn({ styles, actionColumns, ...rest })
            }
            {
              headerColumns.map((headerColumn, index) => {
                if (!headerColumn.checked) return null;

                return (
                  <Column
                    headerRenderer={headerProps => HeaderRenderer({
                      ...headerProps,
                      ...headerColumn,
                      sortDirection,
                      onSort,
                      activeField,
                      notSortable,
                      isLoading,
                    })}
                    key={headerColumn.key}
                    dataKey={headerColumn.key}
                    cellRenderer={cellProps => CellRenderer({ ...cellProps, checked: headerColumn.checked })}
                    width={50}
                    flexGrow={1}
                    cellDataGetter={({ dataKey, rowData }) => { return _.get(rowData, dataKey); }}
                  />
                );
              })
            }
            {
              rows.length > 0 && ValidTransferColumn({ styles, headerColumns, actionColumns, ...rest })
            }
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};

VirtualizedDeviceLogsTable.propTypes = {
  headerColumns: PropTypes.array.isRequired,
  actionColumns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  sortDirection: PropTypes.number,
  activeField: PropTypes.string,
  styles: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  logsToHighlight: PropTypes.array.isRequired,
  toggleBaseStationSwitch: PropTypes.func.isRequired,
  notSortable: PropTypes.bool,
};

export default VirtualizedDeviceLogsTable;
