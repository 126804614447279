import _ from 'lodash';
import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_FIRMWARES,
  PUBLISH_FIRMWARE,
  DEFAULT_FIRMWARE,
  UPDATE_FIRMWARE,
  UPDATE_FIRMWARE_ARCHIVE,
} from '../actions/firmwareActions';
import {
  CLOSE_DEFAULT_FIRMWARE_DIALOG,
} from '../actions/uiActions';

import firmwareDevicesReducer from './firmwareDevicesReducer';

const updateById = (state, action) => ({ ...state, [action.result.id]: action.result });

export const byId = createReducer({}, {
  [`${LOAD_FIRMWARES}_SUCCESS`]: (state, action) => _.keyBy(action.result, f => f.id),
  [`${PUBLISH_FIRMWARE}_SUCCESS`]: updateById,
  [`${UPDATE_FIRMWARE}_SUCCESS`]: updateById,
  [`${UPDATE_FIRMWARE_ARCHIVE}_SUCCESS`]: updateById,
});

export const allIds = createReducer([], {
  [`${LOAD_FIRMWARES}_SUCCESS`]: (state, action) => _.map(action.result, f => f.id),
  [`${PUBLISH_FIRMWARE}_SUCCESS`]: (state, action) => ([...state, action.result.id]),
});

export const defaultFirmwares = createReducer({}, {
  [`${LOAD_FIRMWARES}_SUCCESS`]: (state, action) => {
    const defaults = { B: null, S: null };

    _.forEach(action.result, f => {
      if (f.is_default) {
        defaults[f.device_type] = f.id;
      }
    });

    return defaults;
  },
});

const defaultFirmwareActionInfoAsyncReducer = asyncReducerHelper(DEFAULT_FIRMWARE);

export const defaultFirmwareActionInfo = (state, action) => {
  if (action.type === CLOSE_DEFAULT_FIRMWARE_DIALOG) {
    return {};
  }

  return defaultFirmwareActionInfoAsyncReducer(state, action);
};

export const loadInfo = asyncReducerHelper(LOAD_FIRMWARES);

const firmwareReducer = combineReducers({
  loadInfo,
  defaultFirmwareActionInfo,
  byId,
  allIds,
  defaultFirmwares,
  devices: firmwareDevicesReducer,
});

export default firmwareReducer;
