import axios from 'axios';
import TokenCall from 'components/auth/services/token';
import { extractNameParts } from 'components/utils/helpers';
import Config from 'config';
import { getResponseData } from 'components/utils/asyncHelpers';

const { AUTH_API_ROOT } = Config;
const { CancelToken } = axios;

let cancelSource;

export const addUserSignUp = async function(user) {
  try {
    return await axios.post(`${AUTH_API_ROOT}/signup`, {
      ...user,
      password: user.password || 'password',
    });
  } catch (error) {
    throw error;
  }
};

export const addUser = user => {
  try {
    return getResponseData('post', `${AUTH_API_ROOT}/users/create`, { ...user });
  } catch (error) {
    throw error;
  }
};

export const updateUser = async function({ pk, user }) {
  const nameParts = extractNameParts(user.name);
  const updatedData = {
    ...user,
    first_name: nameParts[0],
    last_name: nameParts[1],
  };

  try {
    await TokenCall.put(`${AUTH_API_ROOT}/users/${pk}`, updatedData);
    return true;
  } catch (error) {
    throw error;
  }
};

export const loadUsers = ({ selectedPage = null, perPage = 10 }) => {
  cancelSource = CancelToken.source();
  const headers = {
    'X-Per-Page': perPage,
    'X-Page': selectedPage,
  };

  if (!selectedPage) {
    delete headers['X-Page'];
  }

  return getResponseData('get', `${AUTH_API_ROOT}/users`, {
    cancelToken: cancelSource.token,
    headers,
  });
};

export const fetchUserData = ({ userId }) =>
  getResponseData('get', `${AUTH_API_ROOT}/users/${userId}`);
