export default {
  serialNumber: 'Serial #',
  status: 'Status',
  mac: 'MAC',
  iccid: 'ICCID',
  imei: 'IMEI',
  sn: 'SN',
  action: 'Action',
  deleteFromLot: 'Delete',
  removeFromLot: 'Remove from lot',
  deviceType: 'Device Type',
  sku: 'SKU',
  creator: 'Creator',
  lotNumber: 'Lot Number',
  partNumber: 'Part Number',
  type: 'Type',
  rev: 'Rev',
  quantity: 'Quantity',
  dateOpen: 'Date Open',
  dateClose: 'Date Close',
  closeLotSuccess: 'Lot closed successfully',
  openLotSuccess: 'Open lot success',
  nrf: 'nRF Version',
  glyph: 'Glyph Version',
  psoc: 'PSoC Version',
  handedness: 'Handedness',
  size: 'Size',
  description: 'Description',
};
