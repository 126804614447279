import _ from 'lodash';
import * as en from 'intl/en';
import { intl } from 'IntlGlobalProvider';
import { NO_AVAILABLE_DATES } from '../common/Notifier/utils/constants';

const _prettyError = message => {
  let error = '';

  switch (message.toLowerCase()) {
    case 'network error':
      error = 'utils.errors.networkError';
      break;
    default:
      break;
  }

  if (intl && error) {
    return intl.formatMessage({
      id: error,
      defaultMessage: _.get(en, error),
    });
  } else if (error) {
    return _.get(en, error);
  } else {
    return message;
  }
};

export const cleanAWSMessage = (message = '', error) => {
  if (error === NO_AVAILABLE_DATES) {
    return error;
  }

  if (error && _.get(error, 'response.data.message')) {
    return _.get(error, 'response.data.message');
  }

  const parts = message.split(': ');

  if (parts.length < 2) {
    return _prettyError(message, intl);
  }

  return parts.pop();
};
