/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import '../css/LogoIconWithColoredText.css';

const LogoIconWithColoredText = props => (
    <SvgIcon
      fontSize="inherit"
      {...props}
      viewBox="0 0 113.855 66.189"
    >
      <defs>
        <linearGradient
          id="a"
          x1="0.001"
          y1="-2.474"
          x2="0.999"
          y2="-2.474"
          gradientUnits="objectBoundingBox"
        >
            <stop
              offset="0"
              stopColor="#23bbec"
            />
            <stop
              offset="1"
              stopColor="#1a99ce"
            />
        </linearGradient>
      </defs>
      <g transform="translate(-33.8 -104.6)">
        <g transform="translate(33.8 161.064)">
            <path
              className="logoIcon-text"
              d="M36.24,277.835a4.767,4.767,0,0,1-1.787-1.718,4.884,4.884,0,0,1-.653-2.44,4.661,4.661,0,0,1,2.44-4.158,5.5,5.5,0,0,1,4.536-.241,3.863,3.863,0,0,1,1.546,1.1l-.859.859a3.453,3.453,0,0,0-2.646-1.1,3.862,3.862,0,0,0-1.89.481,3.406,3.406,0,0,0-1.306,1.306,3.819,3.819,0,0,0,0,3.712,3.658,3.658,0,0,0,1.306,1.306,3.861,3.861,0,0,0,1.89.481,3.536,3.536,0,0,0,2.646-1.134l.859.859a4.116,4.116,0,0,1-1.546,1.1,5.464,5.464,0,0,1-2.028.378A8.566,8.566,0,0,1,36.24,277.835Z"
              transform="translate(-33.8 -268.9)"
            />
            <path
              className="logoIcon-text"
              d="M76.989,276.045H72.006l-1.031,2.337H69.6L73.827,269h1.34l4.261,9.382H78.02Zm-.481-1.065-2.028-4.605-1.993,4.605Z"
              transform="translate(-57.297 -268.966)"
            />
            <path
              className="logoIcon-text"
              d="M112.334,269.1h1.34v8.214h5.052v1.168H112.3l.034-9.382Z"
              transform="translate(-85.322 -269.031)"
            />
            <path
              className="logoIcon-text"
              d="M148.989,276.045h-4.983l-1.031,2.337H141.6L145.827,269h1.34l4.261,9.382H150.02Zm-.481-1.065-2.028-4.605-2.028,4.605Z"
              transform="translate(-104.553 -268.966)"
            />
            <path
              className="logoIcon-d"
              d="M184.14,277.835a4.767,4.767,0,0,1-1.787-1.718,4.884,4.884,0,0,1-.653-2.44,4.661,4.661,0,0,1,2.44-4.158,5.5,5.5,0,0,1,4.536-.241,3.863,3.863,0,0,1,1.546,1.1l-.859.859a3.453,3.453,0,0,0-2.646-1.1,3.862,3.862,0,0,0-1.89.481,3.406,3.406,0,0,0-1.306,1.306,3.819,3.819,0,0,0,0,3.712,3.658,3.658,0,0,0,1.306,1.306,3.861,3.861,0,0,0,1.89.481,3.536,3.536,0,0,0,2.646-1.134l.859.859a4.116,4.116,0,0,1-1.546,1.1,5.464,5.464,0,0,1-2.028.378A8.251,8.251,0,0,1,184.14,277.835Z"
              transform="translate(-130.872 -268.9)"
            />
            <path
              className="logoIcon-d"
              d="M222.3,269.1h1.34v8.214h5.086v1.168H222.3Z"
              transform="translate(-157.52 -269.031)"
            />
            <path
              className="logoIcon-d"
              d="M255.24,277.835a4.767,4.767,0,0,1-1.787-1.718,4.885,4.885,0,0,1,0-4.88,4.766,4.766,0,0,1,1.787-1.718,5.537,5.537,0,0,1,5.086,0,4.766,4.766,0,0,1,1.787,1.718,4.884,4.884,0,0,1,.653,2.44,4.556,4.556,0,0,1-.653,2.44,4.767,4.767,0,0,1-1.787,1.718,5.537,5.537,0,0,1-5.086,0Zm4.4-1.065a3.406,3.406,0,0,0,1.306-1.306,3.82,3.82,0,0,0,0-3.712,3.657,3.657,0,0,0-1.306-1.306,3.819,3.819,0,0,0-3.711,0,3.4,3.4,0,0,0-1.306,1.306,3.82,3.82,0,0,0,0,3.712,3.658,3.658,0,0,0,1.306,1.306,3.73,3.73,0,0,0,1.856.481A3.4,3.4,0,0,0,259.639,276.77Z"
              transform="translate(-177.538 -268.9)"
            />
            <path
              className="logoIcon-d"
              d="M298.731,277.52a4.311,4.311,0,0,1-1.031-3.093V269.1h1.34v5.258c0,1.993.893,3.024,2.646,3.024a2.442,2.442,0,0,0,1.959-.756,3.252,3.252,0,0,0,.687-2.268V269.1h1.306v5.327a4.311,4.311,0,0,1-1.031,3.093,3.867,3.867,0,0,1-2.921,1.065A4.089,4.089,0,0,1,298.731,277.52Z"
              transform="translate(-207.007 -269.031)"
            />
            <path
              className="logoIcon-d"
              d="M338.8,269.1h3.952a5.989,5.989,0,0,1,2.646.584,4.151,4.151,0,0,1,1.787,1.65,4.789,4.789,0,0,1,.653,2.44,4.474,4.474,0,0,1-.653,2.44,4.151,4.151,0,0,1-1.787,1.65,5.883,5.883,0,0,1-2.646.584H338.8Zm3.849,8.214a4.156,4.156,0,0,0,1.993-.447,3.343,3.343,0,0,0,1.34-1.237,3.547,3.547,0,0,0,.481-1.856,3.637,3.637,0,0,0-.481-1.856,3.342,3.342,0,0,0-1.34-1.237,4.156,4.156,0,0,0-1.993-.447h-2.543v7.079Z"
              transform="translate(-233.983 -269.031)"
            />
        </g>
        <g transform="translate(58.131 104.6)">
            <path
              className="logoIcon-b"
              d="M228.46,222.7v7.836l-10.86.069v-7.836Z"
              transform="translate(-178.766 -182.113)"
            />
            <path
              className="logoIcon-c"
              d="M193.836,168.618a3.916,3.916,0,0,1-3.918,3.918,6.083,6.083,0,1,0,0,12.166h.722v7.836h-.687a13.918,13.918,0,1,1-.034-27.837A3.916,3.916,0,0,1,193.836,168.618Z"
              transform="translate(-151.462 -144.046)"
            />
            <path
              className="logoIcon-d"
              d="M173.3,137.935c0,8.454-6.186,15.087-14.056,15.087H153.95v-7.836h5.292c4.055,0,6.22-3.746,6.22-7.251a7.365,7.365,0,0,0-6.633-7.286,3.883,3.883,0,0,1-3.471-3.505,16.466,16.466,0,0,0-32.132-3.059,3.924,3.924,0,0,1-3.093,2.681,9.48,9.48,0,0,0-7.7,9.141c0,4.468,3.162,9.279,8.248,9.279h3.059a3.918,3.918,0,0,1,0,7.836h-3.059c-8.867,0-16.083-7.664-16.083-17.114a17.234,17.234,0,0,1,11.925-16.29,24.326,24.326,0,0,1,46.188,3.883A15.164,15.164,0,0,1,173.3,137.935Z"
              transform="translate(-104.6 -104.6)"
            />
        </g>
      </g>
    </SvgIcon>
);

export default LogoIconWithColoredText;
