import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

let INTL;

const IntlGlobalProvider = (props, context) => {
  INTL = context.intl;
  return props.children;
};

IntlGlobalProvider.contextTypes = {
  intl: intlShape.isRequired,
  children: PropTypes.any,
};

class IntlTranslator {
  instance;

  // Singleton
  constructor() {
    if (!this.instance) {
      this.instance = this;
    }
    return this.instance;
  }

  formatMessage(message, values) {
    return INTL.formatMessage(message, values);
  }
}

export const intl = new IntlTranslator();
export default IntlGlobalProvider;
