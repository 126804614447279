import React, { Component } from 'react';
import PropTypes from 'prop-types';

import getStyles from './Alert.styles';

class Alert extends Component {
  render() {
    const { type, text, id, style } = this.props;
    const styles = getStyles();

    return (
            <div
              id={id}
              className="alert"
              style={{ ...styles, ...styles[type], ...style }}
            >
                { text }
            </div>
    );
  }
}

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired,
  id: PropTypes.string,
  style: PropTypes.object,
};

export default Alert;
