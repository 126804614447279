export default {
  min: 'Min',
  max: 'Max',
  available: 'Available',
  toShip: 'To Ship',
  pending: 'Pending',
  inTransit: 'In Transit',
  notes: 'Notes',
  done: 'Done',
  name: 'Name',
  orderVerification: 'Order Verification',
  requested: 'Requested',
  approved: 'Approved',
  balance: 'Balance',
  packed: 'Packed',
};
