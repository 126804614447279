import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import { FETCH_ORDER_DEVICE_COUNT } from '../actions/shipmentsActions';

const getBySiteId = (state, action) => {
  return {
    ...state,
    [action.result.siteId]: action.result,
  };
};

const bySiteId = createReducer({}, {
  [`${FETCH_ORDER_DEVICE_COUNT}_SUCCESS`]: getBySiteId,
});

const deviceCountReducer = combineReducers({
  bySiteId,
  async: asyncReducerHelper(FETCH_ORDER_DEVICE_COUNT),
});

export default deviceCountReducer;
