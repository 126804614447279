import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createPatient } from '../../actions/patientsActions';
import PatientForm from './PatientForm';

export class PatientFormContainer extends Component {
  onSubmit = values => new Promise((resolve, reject) => {
    this.props.createPatient({
      ...values,
      clinics: [],
      date_of_birth: values.date_of_birth.format('YYYY-MM-DD'),
    }, { resolve, reject });
  });

  render() {
    return (
      <PatientForm onSubmit={this.onSubmit} initialValues={{ shipping_address: { country: 'US' } }} />
    );
  }
}

PatientFormContainer.propTypes = {
  createPatient: PropTypes.func.isRequired,
};

const mapDispathToProps = { createPatient };

export default connect(null, mapDispathToProps)(PatientFormContainer);
