/* eslint react/no-did-mount-set-state: 0, react/no-did-update-set-state: 0, complexity: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage, FormattedDate } from 'react-intl';
import moment from 'moment';
import Highstock from 'highcharts/highstock';
import ReactHighcharts from 'react-highcharts';
import xrange from 'highcharts/modules/xrange';
import map from 'highcharts/modules/map';
import _ from 'lodash';
import {
  Button,
  Switch,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import * as en from 'intl/en';
import getGlobalStyles from 'components/styles/global.styles';

import { chartConfig } from './utils/configurations';
import { getGraphConfig, addData } from './utils/helper';
import getStyles from './Graph.styles';
import GraphHeader from './GraphHeader';
import {
  loadPatientData,
  loadErrorsData,
  loadImuData,
} from './actions/graphActions';
import { loadAvailableDates } from './services/graphService';
import AvailableDates from './availableDates/AvailableDates';
import { tagResetColors } from './utils/dataModels';

const Charts = ReactHighcharts.withHighcharts(Highstock);

Highstock.setOptions({
  global: {
    useUTC: false,
  },
});

xrange(Highstock);
map(Highstock);

class Graph extends Component {
  constructor(props) {
    super(props);

    this.globalStyles = getGlobalStyles();
    this.state = {
      config: chartConfig,
      groupedDates: {},
    };
    this.loadMore = this.loadMore.bind(this);
    this.toggleSeries = this.toggleSeries.bind(this);
  }

  async componentWillMount() {
    const availableDates = this.props.deviceLog
      ? await loadAvailableDates(this.props.deviceLog.device_log_id)
      : [];

    let groupedDates = {};

    availableDates.sort();

    if (availableDates.length > 0) {
      groupedDates = _.groupBy(availableDates, date => {
        return moment(date).startOf('month').format('MMMM');
      });
    }

    this.pageNumber = 1;
    this.isGraphRendered = false;
    this.setState({
      isLoading: false,
      allDataLoaded: false,
      isLoadingMoreData: false,
      groupedDates,
      fromDate: availableDates[0],
    });
    this.loadGraphData(availableDates[0]);
  }

  componentWillReceiveProps(nextProps) {
    const {
      imuData,
      errorsData,
      patientData,
      isLoadingErrors,
      isLoadingPatient,
      isLoadingImu,
    } = nextProps.deviceLogGraph;
    const { isLoadingMoreData } = this.state;

    if (
      (
        !isLoadingErrors
        && !isLoadingPatient
        && !isLoadingImu
      )
      && nextProps.deviceLog
      && (!this.isGraphRendered || isLoadingMoreData)
    ) {
      let graphConfig = null;

      this.setState({
        isLoading: false,
        allDataLoaded: this.isAllDataLoaded(nextProps),
      });

      if (isLoadingMoreData) {
        addData(patientData.data, errorsData.data, imuData.data, this.props.intl, this.chart.getChart());
        this.setState({
          isLoadingMoreData: false,
        });
      } else {
        const series = this.chart ? this.chart.getChart().series : [];

        graphConfig = getGraphConfig(patientData.data, errorsData.data, imuData.data, this.props.intl, series);
        if (graphConfig) {
          this.isGraphRendered = true;
          this.setState({
            config: graphConfig,
          });
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isLoading, allDataLoaded } = this.state;
    const { isLoading: nextIsLoading, allDataLoaded: nextAllDataLoaded } = nextState;

    if ((isLoading === nextIsLoading) && (allDataLoaded === nextAllDataLoaded)) {
      return false;
    }

    return true;
  }

  changeDate = fromDate => {
    this.setState({
      fromDate,
    });
    this.isGraphRendered = false;
    this.pageNumber = 1;
    this.loadGraphData(fromDate);
  }

  loadGraphData = fromDate => {
    const {
      _loadErrorsData,
      _loadImuData,
      _loadPatientData,
      isModalOpen,
      deviceLog,
    } = this.props;
    const { pageNumber } = this;
    const logId = deviceLog ? deviceLog.device_log_id : null;

    if (isModalOpen && logId) {
      const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

      if (!fromDate) {
        return;
      }
      fromDate = moment(fromDate).format(dateFormat);
      const toDate = moment(fromDate).add(1, 'days').format(dateFormat);

      _loadErrorsData(logId, pageNumber, fromDate, toDate);
      _loadImuData(logId, pageNumber, fromDate, toDate);
      _loadPatientData(logId, pageNumber, fromDate, toDate);
      this.setState({
        isLoading: true,
      });
    }
  }

  isAllDataLoaded = nextProps => {
    const {
      imuData,
      errorsData,
      patientData,
    } = nextProps.deviceLogGraph;

    return (imuData.pages || []).length <= this.pageNumber
      && (errorsData.pages || []).length <= this.pageNumber
      && (patientData.pages || []).length <= this.pageNumber;
  }

  loadMore() {
    this.pageNumber++;
    this.loadGraphData(this.state.fromDate);
    this.setState({
      isLoadingMoreData: true,
    });
  }

  assignChart = node => {
    this.chart = node;
  }

  toggleSeries = (evt, isInputChecked) => {
    this.chart.chart.series.forEach((series, index) => {
      series.setVisible(isInputChecked, false);
    });
    this.chart.chart.redraw();
  }

  render() {
    const {
      config,
      allDataLoaded,
      isLoading,
      groupedDates,
      fromDate,
    } = this.state;
    const { intl, deviceLog } = this.props;

    this.styles = getStyles(allDataLoaded);

    if (!allDataLoaded) {
      return null;
    }

    return (
      <div
        style={this.styles.graphWrapper}
      >
        <div
          style={this.styles.headerContainer}
        >
          {!_.isEmpty(deviceLog) &&
            <GraphHeader
              deviceLog={deviceLog}
            />}
        </div>
        <div
          style={this.styles.graphContainer}
        >
          {
            fromDate &&
            <div
              style={this.styles.dateHeader}
            >
              <div
                style={{
                  ...this.styles.paletteContainer,
                }}
              >
                {tagResetColors.map((background, index) => {
                  return (
                    <div
                      key={background}
                      style={{
                        ...this.styles.colorBox,
                        background,
                      }}
                    />
                  );
                })}
              </div>
              <span
                style={this.styles.dateText}
              >
                <FormattedMessage
                  id="graph.common.showingResults"
                  defaultMessage={en.graph.common.showingResults}
                />
                &nbsp;
                <span
                  style={this.styles.selectedDate}
                >
                  <FormattedDate
                    value={fromDate}
                    month="short"
                    day="2-digit"
                    year="numeric"
                  />
                </span>
              </span>
              <FormControlLabel
                label={intl.formatMessage({
                  id: 'graph.common.offOn',
                  defaultMessage: en.graph.common.offOn,
                })}
                style={this.styles.graphSwitchLabel}
                control={
                  <Switch
                    onChange={this.toggleSeries}
                    style={{
                      ...this.styles.onOff,
                    }}
                    classes={{
                      switchBase: this.props.classes.switchBase,
                      checked: this.props.classes.checked,
                      track: this.props.classes.track,
                    }}
                  />
                }
              />
            </div>
          }
          <div
            style={this.styles.chartContainer}
          >
            <Charts
              ref={this.assignChart}
              className="graph"
              key={config.id}
              isPureConfig
              config={config}
              domProps={{
                style: this.styles.chart,
              }}
            />
          </div>
          <div style={this.styles.graphControls}>
            <Button
              id="btn--load-more"
              children={intl.formatMessage({
                id: 'graph.common.loadMore',
                defaultMessage: en.graph.common.loadMore,
              })}
              style={{ ...this.globalStyles.primaryButton, ...this.styles.button }}
              onClick={this.loadMore}
              disabled={isLoading || allDataLoaded}
            />
            <AvailableDates
              groupedDates={groupedDates}
              selectedDate={fromDate}
              changeDate={this.changeDate}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

Graph.propTypes = {
  intl: intlShape,
  classes: PropTypes.object.isRequired,
  deviceLogGraph: PropTypes.object,
  deviceLog: PropTypes.object,
  isModalOpen: PropTypes.bool.isRequired,
  _loadErrorsData: PropTypes.func.isRequired,
  _loadImuData: PropTypes.func.isRequired,
  _loadPatientData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  deviceLogGraph: state.deviceLogGraph,
});

const mapDispatchToProps = dispatch => ({
  _loadPatientData: (logId, pageNumber, fromDate, toDate) => {
    dispatch(loadPatientData(logId, pageNumber, fromDate, toDate));
  },
  _loadErrorsData: (logId, pageNumber, fromDate, toDate) => {
    dispatch(loadErrorsData(logId, pageNumber, fromDate, toDate));
  },
  _loadImuData: (logId, pageNumber, fromDate, toDate) => {
    dispatch(loadImuData(logId, pageNumber, fromDate, toDate));
  },
});

const enhance = compose(
  injectIntl,
  withStyles(getGlobalStyles),
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(Graph);
