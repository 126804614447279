import React from 'react';
import PropTypes from 'prop-types';
import { Radio as MuiRadio, FormControlLabel } from '@material-ui/core';
import { injectIntl, intlShape } from 'react-intl';

import { translateLabel } from '../helpers';

const Radio = ({ value, color, label, labelPlacement, intl, checked, ...props }) => {
  return (
    <FormControlLabel
      checked={checked}
      value={value}
      control={<MuiRadio color={color} role="radio" aria-checked={checked} />}
      label={translateLabel(label, intl)}
      labelPlacement={labelPlacement}
      {...props}
    />
  );
};

Radio.propTypes = {
  intl: intlShape.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
};

Radio.defaultProps = {
  color: 'default',
  labelPlacement: 'end',
};

export default injectIntl(Radio);
