import React from 'react';
import { reduxForm, FormSection } from 'redux-form';

import { required, email } from 'components/common/forms/validation';
import Form from 'components/common/forms/Form';
import { CREATE_PATIENT } from 'components/common/forms/names';
import { TextField, SelectField, Datepicker } from 'components/common/forms/elements';

import { handedness, bandSizes, tremorPostures, countries } from '../../utils/data';

const successMessage = { message: 'patients.form.addPatientSuccess' };

const PatientForm = ({ ...rest }) => (
  <Form
    id={CREATE_PATIENT}
    successMessage={successMessage}
    submitBtnTxt="patients.form.addPatient"
    showCancel
    {...rest}
  >
    <TextField name="first_name" label="patients.form.firstName" validate={required} />
    <TextField name="last_name" label="patients.form.lastName" validate={required} />
    <Datepicker name="date_of_birth" label="patients.form.dob" validate={required} />
    <TextField name="email" label="patients.form.email" validate={[required, email]} />
    <TextField name="account_number" label="patients.form.accountNumber" validate={required} />
    <TextField name="physician" label="patients.form.prescribingPhysician" validate={required} />
    <SelectField
      name="handedness"
      label="patients.form.handedness"
      items={handedness}
      itemValue="value"
      validate={required}
    />
    <SelectField
      name="wrist_band_size"
      label="patients.form.bandSize"
      items={bandSizes}
      itemValue="value"
      validate={required}
    />
    <SelectField
      name="tremor_posture"
      label="patients.form.tremorPosture"
      items={tremorPostures}
      itemValue="value"
      itemText="text"
      validate={required}
    />
    <FormSection name="shipping_address">
      <TextField name="address_line_1" label="patients.form.shipping.address" validate={required} />
      <TextField name="address_line_2" label="patients.form.shipping.ext" />
      <TextField name="city" label="patients.form.shipping.city" validate={required} />
      <TextField name="state" label="patients.form.shipping.state" validate={required} />
      <TextField name="zip" label="patients.form.shipping.zip" validate={required} />
      <SelectField
        name="country"
        label="patients.form.shipping.country"
        items={countries}
        itemValue="value"
        itemText="text"
        validate={required}
      />
    </FormSection>
    <TextField name="phone" label="patients.form.phoneNumber" />
  </Form>
);

export default reduxForm({
  form: CREATE_PATIENT,
})(PatientForm);
