import {
  LOAD_ERRORS_DATA,
  LOAD_PATIENT_DATA,
  LOAD_IMU_DATA,
} from '../actions/graphActions';
import initialState from './initialState';

function graphReducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case `${LOAD_ERRORS_DATA}`:
      return {
        ...state,
        isLoadingErrors: true,
        error: null,
      };
    case `${LOAD_ERRORS_DATA}_SUCCESS`:
      return {
        ...state,
        isLoadingErrors: false,
        errorsData: payload,
      };
    case `${LOAD_ERRORS_DATA}_FAILURE`:
      return {
        ...state,
        isLoadingErrors: false,
        error,
        errorsData: {},
      };
    case `${LOAD_PATIENT_DATA}`:
      return {
        ...state,
        isLoadingPatient: true,
        error: null,
      };
    case `${LOAD_PATIENT_DATA}_SUCCESS`:
      return {
        ...state,
        isLoadingPatient: false,
        patientData: payload,
      };
    case `${LOAD_PATIENT_DATA}_FAILURE`:
      return {
        ...state,
        isLoadingPatient: false,
        error,
        patientData: {},
      };
    case `${LOAD_IMU_DATA}`:
      return {
        ...state,
        isLoadingImu: true,
        error: null,
      };
    case `${LOAD_IMU_DATA}_SUCCESS`:
      return {
        ...state,
        isLoadingImu: false,
        imuData: payload,
      };
    case `${LOAD_IMU_DATA}_FAILURE`:
      return {
        ...state,
        isLoadingImu: false,
        error,
        imuData: {},
      };
    default:
      return state;
  }
}

export default graphReducer;
