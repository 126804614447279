import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  injectIntl,
  intlShape,
  FormattedMessage,
} from 'react-intl';
import {
  withStyles,
  IconButton,
  Icon,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import _ from 'lodash';
import * as en from 'intl/en';
import getGlobalStyles from 'components/styles/newGlobal.styles';
import MouseDown from 'components/common/base/MouseDown';
import Session from 'components/utils/session';

import getStyles from './ControlColumns.styles';

class ControlColumns extends Component {
  constructor(props) {
    super(props);

    const actionColumnsByRole = Session.actionColumnsRoleId(Session.JWTDecoded.role_id);

    this.state = {
      showDialog: false,
      tableFields: Session.logTableColumns,
      actionFields: actionColumnsByRole,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        showDialog: false,
      });
    }
  }

  toggleDialog = () => {
    this.setState({
      showDialog: !this.state.showDialog,
    });
    this.props.updateColumns(this.state.tableFields);
    this.props.updateActionColumns(this.state.actionFields);
  };

  updateFieldKey = (fields, key) => {
    for (const field of fields) {
      if (field.key === key) {
        field.checked = !field.checked;
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          fields,
        });
        break;
      }
    }
  }

  handleChange = event => {
    const key = event.target.value;
    const { tableFields, actionFields } = this.state;
    const { updateColumns, updateActionColumns } = this.props;

    this.updateFieldKey(tableFields, key);
    this.updateFieldKey(actionFields, key);

    updateColumns(tableFields);
    updateActionColumns(actionFields);
  }

  renderFormControl = (column, index, intl) => (
    <FormControlLabel
      key={column.key}
      control={
        <Checkbox
          checked={column.checked}
          value={column.key}
          color="default"
          role="checkbox"
          aria-checked={column.checked}
          data-index={index}
          onChange={this.handleChange}
        />
      }
      label={
        intl.formatMessage({
          id: column.title,
          defaultMessage: _.get(en, column.title),
        })
      }
    />
  );

  render() {
    const {
      classes,
      intl,
    } = this.props;
    const {
      showDialog,
      tableFields,
      actionFields,
    } = this.state;

    return (
      <MouseDown handler={this.handleClickOutside}>
        <div ref={node => { this.wrapperRef = node; }}>
          <IconButton onClick={this.toggleDialog} disableRipple>
            <Icon className={classes.iconColor}>
              add_circle
            </Icon>
          </IconButton>
          <div
            className={classes.dialog}
            style={{
              display: showDialog ? 'block' : 'none',
            }}
          >
            <h2 className={classes.title}>
              <FormattedMessage
                id="files.filter.showOrHide"
                defaultMessage={en.files.filter.showOrHide}
              />
            </h2>
            <div>
              <div className={classes.content}>
                <FormGroup>
                  {
                    tableFields.map((column, index) =>
                      this.renderFormControl(column, index, intl))
                  }
                  {
                    actionFields.map((column, index) =>
                      this.renderFormControl(column, index, intl))
                  }
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </MouseDown>
    );
  }
}

ControlColumns.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  updateColumns: PropTypes.func.isRequired,
  updateActionColumns: PropTypes.func.isRequired,
};

const enhance = compose(
  injectIntl,
  withStyles(theme => ({
    ...getStyles(theme),
    ...getGlobalStyles(theme),
  })),
);

export default enhance(ControlColumns);
