export const LOAD_PATIENTS_LIST = 'LOAD_PATIENTS_LIST';
export const LOAD_PATIENT_DETAILS = 'LOAD_PATIENT_DETAILS';
export const LOAD_PATIENT_COMPLIANCES = 'LOAD_PATIENT_COMPLIANCES';
export const LOAD_COMPLIANCE_DETAILS = 'LOAD_COMPLIANCE_DETAILS';
export const REQUEST_REPORT = 'REQUEST_REPORT';

export function loadPatientsList(selectedPage, perPage, sortField, sortType, criteria) {
  return {
    type: LOAD_PATIENTS_LIST,
    selectedPage,
    perPage,
    sortField,
    sortType,
    criteria,
  };
}

export function loadPatientDetails(patientId) {
  return {
    type: LOAD_PATIENT_DETAILS,
    patientId,
  };
}

export const loadPatientCompliances = patientIds => {
  return {
    type: LOAD_PATIENT_COMPLIANCES,
    patientIds,
  };
};

export const loadComplianceDetails = patientId => {
  return {
    type: LOAD_COMPLIANCE_DETAILS,
    patientId,
  };
};

export const CREATE_PATIENT = 'CREATE_PATIENT';
export const createPatient = (data, promise) => {
  return {
    type: CREATE_PATIENT,
    data,
    promise,
  };
};

export const requestReport = (data, patient_id, promise, notification) => {
  return {
    type: REQUEST_REPORT,
    data,
    patient_id,
    promise,
    notification,
  };
};
