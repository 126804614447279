import form from './en/form';
import table from './en/table';
import common from './en/common';
import password from './en/password';

export default {
  form,
  table,
  common,
  password,
};
