import { combineReducers } from 'redux';

import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_DEVICES,
  ASSIGN_DEVICE,
  UNASSIGN_DEVICE,
  LOAD_PREFIX,
} from '../actions/devicesActions';
import { deviceStatus } from '../utils/constants';

const assignDevice = (state, action) => {
  return state.map(device => {
    const { deviceInventoryId } = action.payload;
    const { prefix, brighttree_id } = action.payload.data;

    const subjectId = prefix.concat(brighttree_id).trim();

    if (device.device_inventory_id === deviceInventoryId) {
      return {
        ...device,
        status: deviceStatus.Assigned,
        device_details: {
          ...device.device_details,
          subject_id: subjectId,
        },
      };
    }
    return device;
  });
};

const unassignDevice = (state, action) => {
  return state.map(device => {
    const { deviceInventoryId } = action.payload;

    if (device.device_inventory_id === deviceInventoryId) {
      return {
        ...device,
        status: deviceStatus.Unassigned,
        device_details: {
          ...device.device_details,
          subject_id: null,
        },
      };
    }
    return device;
  });
};

const list = createReducer([], {
  [`${LOAD_DEVICES}`]: () => ([]),
  [`${LOAD_DEVICES}_SUCCESS`]: (state, action) => action.result.data,
  [`${ASSIGN_DEVICE}_SUCCESS`]: assignDevice,
  [`${UNASSIGN_DEVICE}_SUCCESS`]: unassignDevice,
});

const pages = createReducer([], {
  [`${LOAD_DEVICES}_SUCCESS`]: (state, action) => (action.result.pages),
});

const prefix = createReducer([], {
  [`${LOAD_PREFIX}`]: () => ([]),
  [`${LOAD_PREFIX}_SUCCESS`]: (state, action) => action.result,
});

const devicesReducer = combineReducers({
  loadAsyncInfo: asyncReducerHelper(LOAD_DEVICES),
  list,
  pages,
  prefix,
});

export default devicesReducer;
