import { call, put } from 'redux-saga/effects';
import {
  FORGOT_PASSWORD_FORM,
  RESET_PASSWORD_FORM,
  FORCE_CHANGE_PASSWORD_FORM,
} from 'components/common/forms/names';
import { formSagaRequestWithPayload } from 'components/utils/reduxHelpers';
import AuthService from '../services/auth';
import {
  FORCE_CHANGE_PASSWORD,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions/authActions';

export function* forceChangePassword(action) {
  const { notification, promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    FORCE_CHANGE_PASSWORD_FORM,
    FORCE_CHANGE_PASSWORD,
    AuthService.forceChangePassword,
    promise,
    rest,
    notification,
  );
}

export function* requestVerify(action) {
  try {
    const result = yield call(AuthService.verify, action.data);

    yield put({ type: VERIFY_SUCCESS, result });
  } catch (error) {
    yield put({ type: VERIFY_FAILURE, error });
  }
}

export function* requestForgotPassword(action) {
  const { notification, promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    FORGOT_PASSWORD_FORM,
    FORGOT_PASSWORD,
    AuthService.forgotPassword,
    promise,
    rest,
    notification,
  );
}

export function* requestResetPassword(action) {
  const { notification, promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    RESET_PASSWORD_FORM,
    RESET_PASSWORD,
    AuthService.resetPassword,
    promise,
    rest,
    notification,
  );
}
