export const ADD_DEVICE_TYPE = 'ADD_DEVICE_TYPE';
export const ADD_SKU = 'ADD_SKU';
export const ADD_USER = 'ADD_USER';
export const ADD_CLINIC = 'ADD_CLINIC';
export const CREATE_LOT = 'CREATE_LOT';
export const CREATE_STUDY = 'CREATE_STUDY';
export const MANAGE_SITE = 'MANAGE_SITE';
export const REJECT_ORDER = 'REJECT_ORDER';
export const PACKAGE_DETAILS = 'PACKAGE_DETAILS';
export const BUY_PICKUP = 'BUY_PICKUP';
export const HAND_DELIVERY = 'HAND_DELIVERY';
export const BUY_DELIVERY = 'BUY_DELIVERY';
export const PUBLISH_DFU = 'PUBLISH_DFU';
export const DEVICE_FIRMWARE = 'DEVICE_FIRMWARE';
export const BASESTATION_FIRMWARE_QUOTA_FORM = 'BASESTATION_FIRMWARE_QUOTA_FORM';
export const STIM_FIRMWARE_QUOTA_FORM = 'STIM_FIRMWARE_QUOTA_FORM';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CREATE_PATIENT = 'CREATE_PATIENT';
export const LOGIN_FORM = 'LOGIN_FORM';
export const FORGOT_PASSWORD_FORM = 'FORGOT_PASSWORD_FORM';
export const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM';
export const FORCE_CHANGE_PASSWORD_FORM = 'FORCE_CHANGE_PASSWORD_FORM';
export const ASSIGN_DEVICE_FORM = 'ASSIGN_DEVICE_FORM';
export const UNASSIGN_DEVICE_FORM = 'UNASSIGN_DEVICE_FORM';
export const REQUEST_REPORT_FORM = 'REQUEST_REPORT_FORM';
