/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CreateIcon = props => (
    <SvgIcon
      {...props}
      viewBox="0 0 23.857 25.207"
    >
      <g
        id="edit-74"
        transform="translate(-1.5 -1.293)"
      >
        <line
          id="Line_338"
          data-name="Line 338"
          className="create-icon-1"
          x2="22.857"
          transform="translate(2 26)"
        />
        <line
          id="Line_339"
          data-name="Line 339"
          className="create-icon-2"
          x2="4.571"
          y2="4.571"
          transform="translate(14.571 5.429)"
        />
        <path
          id="Path_312"
          data-name="Path 312"
          className="create-icon-1"
          d="M8.714,20.286,3,21.429l1.143-5.714L17.857,2l4.571,4.571Z"
          transform="translate(0.143 0)"
        />
      </g>
    </SvgIcon>
);

export default CreateIcon;
