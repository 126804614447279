import { getDeviceProperty } from 'components/inventory/selectors';

const _injectDeviceProps = (state, arr) => (
  arr.map(item => ({
    ...item,
    deviceName: getDeviceProperty(state, item.device_type_id, 'device_commercial_name'),
    skuNumber: getDeviceProperty(state, item.device_type_id, 'sku_number'),
  }))
);

export const getLots = state => _injectDeviceProps(state, state.operations.lots.list);

export const isProcessingLotDetails = state => state.operations.lotDetails.async.load.isProcessing
|| state.operations.unassignedDevices.async.devices.isProcessing
|| state.operations.lotDetails.async.closeLot.isProcessing
|| state.operations.lotDetails.async.openLot.isProcessing;
