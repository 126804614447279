import { combineReducers } from 'redux';
import _ from 'lodash';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import { LOAD_PATIENT_COMPLIANCES } from '../actions/patientsActions';

export const DEFAULT_STATE = {};

const byPatientId = createReducer(DEFAULT_STATE, {
  [`${LOAD_PATIENT_COMPLIANCES}`]: () => (DEFAULT_STATE),
  [`${LOAD_PATIENT_COMPLIANCES}_SUCCESS`]: (state, action) => _.keyBy(action.result, c => c.patient_id),
});

const patientComplianceReducer = combineReducers({
  async: asyncReducerHelper(LOAD_PATIENT_COMPLIANCES),
  byPatientId,
});

export default patientComplianceReducer;
