export default theme => ({
  container: {
    display: 'block',
    marginTop: 20,
    paddingTop: 10,
  },
  inputControl: {
    display: 'block',
  },
  fileInputLabel: {
    display: 'inline-block',
    height: 36,
    lineHeight: '36px',
    width: 'calc(100% - 160px)',
    border: `1px solid ${theme.palette.borderColor1}`,
    textAlign: 'center',
  },
  fileInputButton: {
    display: 'inline-block',
    float: 'right',
    width: 150,
  },
});
