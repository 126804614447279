
import { call, put } from 'redux-saga/effects';
import * as NotesService from '../services/notesService';
import {
  LOAD_NOTES,
  ADD_NOTE,
} from '../actions/notesActions';

export function* loadNotes(action) {
  try {
    const result = yield call(
      NotesService.loadNotes,
      action.objectType,
      action.objectId,
    );

    yield put({ type: `${LOAD_NOTES}_SUCCESS`, result });
  } catch (error) {
    yield put({ type: `${LOAD_NOTES}_FAILURE`, error });
  }
}

export function* addNote(action) {
  try {
    const result = yield call(
      NotesService.addNote,
      action.objectType,
      action.objectId,
      action.note,
    );

    yield put({ type: `${ADD_NOTE}_SUCCESS`, result, extras: action.extras });
  } catch (error) {
    yield put({ type: `${ADD_NOTE}_FAILURE`, error });
  }
}
