/* eslint complexity: 0, array-callback-return: 0, no-loop-func: 0 */
import moment from 'moment';
import * as en from 'intl/en';
import _ from 'lodash';
import {
  chartConfig,
  tremorRatingConfig,
  tremorFrequencyConfig,
  stimSessionsConfig,
  settingsChangeConfig,
  flagConfig,
  stimAmpConfig,
  scatterColors,
  errorConfig,
  plotLines,
  errorYAxis,
  mainYAxis,
  imuConfig,
  seriesNames,
  batteryAxis,
  batteryLevelConfig,
  impedanceAcConfig,
  impedanceBcConfig,
  impedanceYAxis,
} from './configurations';
import {
  patientEvents,
  frequencyFlag,
  stimSessionModel,
  buttonClickModel,
  tremorRatingModel,
  userSettingsModel,
  stimAmpModel,
  imuModel,
  errorModel,
  batteryLevelModel,
  impedanceModel,
} from './dataModels';

let intl;

const convertTimestamp = (timestamp, ms = 0) => (
  moment(timestamp).valueOf() + ms
);
const formatTimestamp = timestamp => (
  `${intl.formatDate(timestamp)}
   ${intl.formatTime(timestamp, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })}`
);
const _isImpdncPlaceholder = value => {
  return value === 0 || value === 65535 || !value;
};

const graphLabels = () => {
  return {
    stimSessions: intl.formatMessage({
      id: 'graph.labels.stimSessions',
      defaultMessage: en.graph.labels.stimSessions,
    }),
    tremorRating: intl.formatMessage({
      id: 'graph.labels.tremorRating',
      defaultMessage: en.graph.labels.tremorRating,
    }),
    tremorFrequency: intl.formatMessage({
      id: 'graph.labels.tremorFrequency',
      defaultMessage: en.graph.labels.tremorFrequency,
    }),
    settingsChange: intl.formatMessage({
      id: 'graph.labels.settingsChange',
      defaultMessage: en.graph.labels.settingsChange,
    }),
    maxCurrent: intl.formatMessage({
      id: 'graph.labels.maxCurrent',
      defaultMessage: en.graph.labels.maxCurrent,
    }),
    imu: intl.formatMessage({
      id: 'graph.labels.imu',
      defaultMessage: en.graph.labels.imu,
    }),
    buttonClicks: intl.formatMessage({
      id: 'graph.labels.buttonClicks',
      defaultMessage: en.graph.labels.buttonClicks,
    }),
    batteryYAxis: intl.formatMessage({
      id: 'graph.labels.batteryYAxis',
      defaultMessage: en.graph.labels.batteryYAxis,
    }),
    mainYAxis: intl.formatMessage({
      id: 'graph.labels.mainYAxis',
      defaultMessage: en.graph.labels.mainYAxis,
    }),
    impedanceYAxis: intl.formatMessage({
      id: 'graph.labels.impedanceYAxis',
      defaultMessage: en.graph.labels.impedanceYAxis,
    }),
    impedanceAc: intl.formatMessage({
      id: 'graph.labels.impedanceAc',
      defaultMessage: en.graph.labels.impedanceAc,
    }),
    impedanceBc: intl.formatMessage({
      id: 'graph.labels.impedanceBc',
      defaultMessage: en.graph.labels.impedanceBc,
    }),
  };
};

const processImuData = (imuData = []) => {
  const arr = [];

  imuData.sort((a, b) => {
    return convertTimestamp(a.timestamp_utc, a.milliseconds) - convertTimestamp(b.timestamp_utc, b.milliseconds);
  });

  for (const imu of imuData) {
    const timestamp = convertTimestamp(imu.timestamp_utc, imu.milliseconds);
    const timestampUtc = formatTimestamp(timestamp);

    arr.push(imuModel(timestamp, timestampUtc, imu.tag_reset));
  }

  return arr;
};

const processErrorsData = (errorsData = [], existingErrorCodes = []) => {
  const errors = {};
  const errorData = [...errorsData];
  const batteryLevels = [];

  // needed for pagination, to know the initial list of errors
  existingErrorCodes.map(errorDesc => {
    errors[errorDesc] = errors[errorDesc] || {
      severity: 1,
      data: [],
    };
    return;
  });
  errorData.sort((a, b) => {
    return convertTimestamp(a.timestamp_utc, a.milliseconds) - convertTimestamp(b.timestamp_utc, b.milliseconds);
  });

  for (const error of errorData) {
    const errorKey = `${error.description}:${error.description_desc}`;

    errors[errorKey] = errors[errorKey] || {
      data: [],
    };
    errors[errorKey].severity = error.severity;
    const arr = errors[errorKey].data;
    const index = Object.keys(errors).indexOf(errorKey);
    const timestamp = convertTimestamp(error.timestamp_utc, error.milliseconds);
    const timestampUtc = formatTimestamp(timestamp);

    arr.push(errorModel(timestamp, timestampUtc, errorKey, index, error.tag_reset));
    errors[errorKey].data = arr;

    if (error.notes.includes('=')) {
      const batteryValues = error.notes.split('=');
      const data = batteryLevelModel(timestamp, timestampUtc, batteryValues[0], batteryValues[1], error.tag_reset);

      if (data) {
        batteryLevels.push(data);
      }
    }
  }

  return {
    errors,
    batteryLevels,
  };
};

let btnClck = [];
let btnClckAvg = [];
let impAc = [];
let impAcAvg = [];
let impBc = [];
let impBcAvg = [];

export const getBtnClk = () => (btnClck);
export const getAvgBtnClk = () => (btnClckAvg);
export const getImpAC = () => (impAc);
export const getAvgImpAC = () => (impAcAvg);
export const getImpBC = () => (impBc);
export const getAvgImpBC = () => (impBcAvg);

const processPatientData = (patientData = [], previousValues = {}) => {
  const tremorFrequency = [];
  const tremorRating = [];
  const lsStimSessions = [];
  const lsUserSettings = [];
  const lsStimAmp = [];
  const lsButtonClicks = [];
  const lsButtonClicksAverage = [];
  const lsImpedanceAc = [];
  const lsImpedanceAcAverage = [];
  const lsImpedanceBc = [];
  const lsImpedanceBcAverage = [];
  const stimAmpZones = [];

  patientData = [...patientData];
  patientData.sort((a, b) => {
    return convertTimestamp(a.timestamp_utc, a.milliseconds) - convertTimestamp(b.timestamp_utc, b.milliseconds);
  });

  for (let i = 0; i < patientData.length; i++) {
    const log = patientData[i];
    const timestamp = convertTimestamp(log.timestamp_utc, log.milliseconds);
    const timestampUtc = formatTimestamp(timestamp);

    if (
      log.patient_event_desc === patientEvents.STIM_INCR_AMPL ||
      log.patient_event_desc === patientEvents.STIM_DECR_AMPL
    ) {
      lsButtonClicks.push(buttonClickModel(timestamp, timestampUtc, log));

      const btnClickIndex = lsButtonClicks.length - 1;
      const previousLog = btnClickIndex >= 1 ? lsButtonClicks[btnClickIndex - 1] : {};
      const previousTimestamp = previousLog.x;

      if (btnClickIndex === 0) {
        lsButtonClicksAverage.push(buttonClickModel(timestamp, timestampUtc, log));
      } else if (log.patient_event_desc !== previousLog.key.value) {
        // add button click if different from previous one
        lsButtonClicksAverage.push(buttonClickModel(timestamp, timestampUtc, log));
      } else if ((timestamp - previousTimestamp) >= 120000) {
        // add log if time difference between logs 2 minutes or more
        lsButtonClicksAverage.push(buttonClickModel(timestamp, timestampUtc, log));
      }
    } else if (log.patient_event_desc === patientEvents.TREMOR_RATING) {
      tremorRating.push(tremorRatingModel(timestamp, timestampUtc, log));
    } else if (
      log.patient_event_desc === patientEvents.MEASUREMENT ||
      log.patient_event_desc === patientEvents.CALIBRATION
    ) {
      tremorFrequency.push(frequencyFlag(
        log.tremor_frequency, log.patient_event_desc,
        timestamp, timestampUtc, log.tag_reset,
      ));
    } else if (
      log.patient_event_desc === patientEvents.STIM_SESSION_START ||
      log.patient_event_desc === patientEvents.STIM_SESSION_END
    ) {
      lsStimSessions.push(stimSessionModel(timestamp, timestampUtc, log));
    } else if (log.patient_event_desc === patientEvents.CHANGE_USER_SETTINGS) {
      lsUserSettings.push(userSettingsModel(timestamp, timestampUtc, log.tag_reset));
    }

    if (!_isImpdncPlaceholder(log.impedance_ac)) {
      lsImpedanceAc.push(impedanceModel(timestamp, timestampUtc, log.impedance_ac));
    }
    if (!_isImpdncPlaceholder(log.impedance_bc)) {
      lsImpedanceBc.push(impedanceModel(timestamp, timestampUtc, log.impedance_bc, true));
    }

    if (i === 0) {
      if (!_isImpdncPlaceholder(log.impedance_ac)) {
        lsImpedanceAcAverage.push(impedanceModel(timestamp, timestampUtc, log.impedance_ac));
      }
      if (!_isImpdncPlaceholder(log.impedance_bc)) {
        lsImpedanceBcAverage.push(impedanceModel(timestamp, timestampUtc, log.impedance_bc, true));
      }

      if (previousValues.lastStimAmp) {
        lsStimAmp.push(stimAmpModel(timestamp, timestampUtc, { stim_amp: previousValues.lastStimAmp.y }));
      }
      lsStimAmp.push(stimAmpModel(timestamp, timestampUtc, log));
    } else {
      // to achieve the step effect
      lsStimAmp.push(stimAmpModel(timestamp, timestampUtc, { stim_amp: patientData[i - 1].stim_amp }));
      lsStimAmp.push(stimAmpModel(timestamp, timestampUtc, log));

      if (!_.last(lsImpedanceAcAverage) || (timestamp - _.last(lsImpedanceAcAverage).x) >= 120000) {
        // add log if time difference between logs 2 minutes or more
        // or if it's the first log to be added
        if (!_isImpdncPlaceholder(log.impedance_ac)) {
          lsImpedanceAcAverage.push(impedanceModel(timestamp, timestampUtc, log.impedance_ac));
        }
        if (!_isImpdncPlaceholder(log.impedance_bc)) {
          lsImpedanceBcAverage.push(impedanceModel(timestamp, timestampUtc, log.impedance_bc, true));
        }
      }
    }
  }

  btnClck = [...btnClck, ...lsButtonClicks];
  btnClckAvg = [...btnClckAvg, ...lsButtonClicksAverage];

  impAc = [...impAc, ...lsImpedanceAc];
  impAcAvg = [...impAcAvg, ...lsImpedanceAcAverage];
  impBc = [...impBc, ...lsImpedanceBc];
  impBcAvg = [...impBcAvg, ...lsImpedanceBcAverage];

  return {
    tremorFrequency,
    tremorRating,
    lsStimSessions,
    lsUserSettings,
    lsStimAmp,
    lsButtonClicks,
    lsButtonClicksAverage,
    lsImpedanceAc,
    lsImpedanceAcAverage,
    lsImpedanceBc,
    lsImpedanceBcAverage,
    stimAmpZones,
  };
};

export const processData = (patientData, errorsData, imuData, _intl, existingErrorCodes, previousValues) => {
  intl = _intl;

  const translations = graphLabels();
  const patientProcessedData = processPatientData(patientData, previousValues);
  const imuProcessedData = processImuData(imuData);
  const errorsProcessedData = processErrorsData(errorsData, existingErrorCodes);

  return {
    patientProcessedData,
    imuProcessedData,
    errorsProcessedData,
    translations,
  };
};

export const getGraphConfig = (patientData, errorsData, imuData, _intl, series) => {
  if (!patientData && !errorsData && !imuData) {
    return null;
  }

  btnClck = [];
  btnClckAvg = [];
  impAc = [];
  impAcAvg = [];
  impBc = [];
  impBcAvg = [];
  const data = processData(patientData, errorsData, imuData, _intl);
  const {
    patientProcessedData,
    imuProcessedData,
    errorsProcessedData,
    translations,
  } = data;
  const {
    tremorFrequency,
    tremorRating,
    lsStimSessions,
    lsUserSettings,
    lsStimAmp,
    lsButtonClicksAverage,
    lsImpedanceAcAverage,
    lsImpedanceBcAverage,
    stimAmpZones,
  } = patientProcessedData;
  const {
    errors,
    batteryLevels,
  } = errorsProcessedData;

  const errorPlotLines = [];
  const lsErrorConfigs = [];

  let index = 0;

  for (const error_description in errors) {
    if (error_description) {
      index++;
      // plotLines.label.text = error_description;
      errorPlotLines.push({
        ...plotLines,
        value: index,
        label: {
          text: error_description,
          style: {
            color: 'transparent',
          },
        },
      });

      lsErrorConfigs.push({
        ...errorConfig,
        name: error_description,
        color: scatterColors[errors[error_description].severity],
        data: errors[error_description].data,
      });
    }
  }

  tremorRatingConfig.data = tremorRating;
  tremorRatingConfig.name = translations.tremorRating;
  tremorFrequencyConfig.data = tremorFrequency;
  tremorFrequencyConfig.name = translations.tremorFrequency;
  stimSessionsConfig.data = lsStimSessions;
  stimSessionsConfig.name = translations.stimSessions;
  settingsChangeConfig.data = lsUserSettings;
  settingsChangeConfig.name = translations.settingsChange;
  flagConfig.data = lsButtonClicksAverage;
  flagConfig.name = translations.buttonClicks;
  stimAmpConfig.data = lsStimAmp;
  stimAmpConfig.zoneAxis = 'x';
  stimAmpConfig.zones = stimAmpZones;
  stimAmpConfig.name = translations.maxCurrent;
  batteryLevelConfig.data = batteryLevels;
  batteryLevelConfig.name = translations.batteryYAxis;
  impedanceAcConfig.name = translations.impedanceAc;
  impedanceAcConfig.data = lsImpedanceAcAverage;
  impedanceBcConfig.name = translations.impedanceBc;
  impedanceBcConfig.data = lsImpedanceBcAverage;

  const newConfig = {
    ...chartConfig,
    series: [],
    yAxis: [
      {
        ...mainYAxis,
        title: {
          text: translations.mainYAxis,
        },
      },
      {
        ...errorYAxis,
        plotLines: errorPlotLines,
      },
      {
        ...batteryAxis,
        title: {
          text: translations.batteryYAxis,
        },
      },
      {
        ...impedanceYAxis,
        title: {
          text: translations.impedanceYAxis,
        },
      },
    ],
  };

  imuConfig.data = imuProcessedData;

  stimSessionsConfig.visible = true;
  flagConfig.visible = true;
  stimAmpConfig.visible = true;

  tremorRatingConfig.visible = false;
  tremorFrequencyConfig.visible = false;
  settingsChangeConfig.visible = false;
  imuConfig.visible = false;
  batteryLevelConfig.visible = false;
  impedanceAcConfig.visible = false;
  impedanceBcConfig.visible = false;

  newConfig.series.push(stimSessionsConfig);
  newConfig.series.push(flagConfig);
  newConfig.series.push(stimAmpConfig);
  newConfig.series.push(tremorRatingConfig);
  newConfig.series.push(tremorFrequencyConfig);
  newConfig.series.push(settingsChangeConfig);
  newConfig.series.push(imuConfig);
  newConfig.series.push(batteryLevelConfig);
  newConfig.series.push(impedanceAcConfig);
  newConfig.series.push(impedanceBcConfig);

  for (let i = 0; i < newConfig.series.length; i++) {
    if (i >= series.length) {
      break;
    }
    newConfig.series[i].visible = series[i].visible;
  }

  newConfig.series = newConfig.series.concat(lsErrorConfigs);

  return newConfig;
};

export const addData = (patientData, errorsData, imuData, _intl, chart) => {
  if (!patientData && !errorsData && !imuData) {
    return null;
  }

  const lsSeries = chart.series;
  const existingErrorCodes = [];

  let lastStimAmp;

  lsSeries.forEach(item => {
    if (item.userOptions.id === seriesNames.ERRORS) {
      existingErrorCodes.push(item.name);
    }
    if (item.userOptions.id === seriesNames.STIM_AMP) {
      const lastIndex = item.data.length - 1;

      lastStimAmp = lastIndex > -1 ? item.data[lastIndex] : null;
    }
  });

  const data = processData(
    patientData, errorsData, imuData, _intl, existingErrorCodes,
    {
      lastStimAmp,
    },
  );
  const {
    patientProcessedData,
    imuProcessedData,
    errorsProcessedData,
  } = data;
  const {
    tremorFrequency,
    tremorRating,
    lsStimSessions,
    lsUserSettings,
    lsStimAmp,
    lsButtonClicks,
    lsButtonClicksAverage,
    lsImpedanceAc,
    lsImpedanceAcAverage,
    lsImpedanceBc,
    lsImpedanceBcAverage,
  } = patientProcessedData;
  const {
    errors,
    batteryLevels,
  } = errorsProcessedData;
  const lsExistingErrorSeries = [];
  const xAxis = chart.xAxis[0];
  /*
  const ex = xAxis.getExtremes();
  const xInterval = ex.max - ex.min;
  let xPoint = 0;
  */
  const extremes = xAxis.getExtremes();
  const range = (extremes.max - extremes.min) / 1000;

  for (let i = 0; i < lsSeries.length; i++) {
    const series = lsSeries[i];

    let seriesData = [];

    if (series.userOptions.id === seriesNames.TREMOR_RATING) {
      seriesData = tremorRating;
    } else if (series.userOptions.id === seriesNames.TREMOR_FREQUENCY) {
      seriesData = tremorFrequency;
    } else if (series.userOptions.id === seriesNames.SETTINGS_CHANGE) {
      seriesData = lsUserSettings;
    } else if (series.userOptions.id === seriesNames.STIM_SESSIONS) {
      seriesData = lsStimSessions;
    } else if (series.userOptions.id === seriesNames.BUTTON_CLICK) {
      if (range < 120) {
        seriesData = lsButtonClicks;
      } else {
        seriesData = lsButtonClicksAverage;
      }
    } else if (series.userOptions.id === seriesNames.IMU) {
      seriesData = imuProcessedData;
    } else if (series.userOptions.id === seriesNames.STIM_AMP) {
      seriesData = lsStimAmp;
    } else if (series.userOptions.id === seriesNames.ERRORS) {
      if (errors[series.name]) {
        seriesData = errors[series.name].data;
      }
      lsExistingErrorSeries.push(series);
    } else if (series.userOptions.id === seriesNames.BATTERY_LEVEL) {
      seriesData = batteryLevels;
    } else if (series.userOptions.id === seriesNames.IMPEDANCE_AC) {
      if (range < 120) {
        seriesData = lsImpedanceAc;
      } else {
        seriesData = lsImpedanceAcAverage;
      }
      seriesData = lsImpedanceAc;
    } else if (series.userOptions.id === seriesNames.IMPEDANCE_BC) {
      if (range < 120) {
        seriesData = lsImpedanceBc;
      } else {
        seriesData = lsImpedanceBcAverage;
      }
    }

    for (const dataPoint of seriesData) {
      // xPoint = dataPoint.x > xPoint ? dataPoint.x : xPoint;
      series.addPoint(dataPoint, false);
    }
  }
  const lsErrorNames = Object.keys(errors);
  const newErrorSeries = lsErrorNames.filter(item => existingErrorCodes.indexOf(item) === -1);

  let index = lsErrorNames.length - newErrorSeries.length;

  for (const error_description of newErrorSeries) {
    index++;
    chart.yAxis[1].addPlotLine({
      ...plotLines,
      value: index,
      label: {
        text: error_description,
      },
    });

    chart.addSeries({
      ...errorConfig,
      name: error_description,
      color: scatterColors[errors[error_description].severity],
      data: errors[error_description].data,
    });

    /*
    errorsProcessedData[error_description].data.forEach(dataPoint => {
      xPoint = dataPoint.x > xPoint ? dataPoint.x : xPoint;
    });
    */
  }

  // needed to avoid unwanted zoom in after redraw (the chart would jump to x=0, y=0 without this)

  // zooms to the most right point in chart
  // xPoint = ex.max > xPoint ? ex.max : xPoint;

  // zooms to the most right point in this iteration
  // xAxis.setExtremes(xPoint - xInterval, xPoint);

  chart.redraw();

  // disables zoom
  xAxis.setExtremes(null, null);

  // chart.showResetZoom();
};
