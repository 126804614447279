/* eslint complexity: 0 */
import {
  LOAD_NOTES,
  ADD_NOTE,
  CLEAR_NOTES_ERRORS,
} from '../actions/notesActions';
import initialState from './initialState';

function notesReducer(state = initialState, action) {
  switch (action.type) {
    case `${LOAD_NOTES}`:
      return {
        ...state,
        isLoadingNotes: true,
        notes: [],
      };
    case `${LOAD_NOTES}_SUCCESS`:
      return {
        ...state,
        isLoadingNotes: false,
        notes: action.result.reverse(),
      };
    case `${LOAD_NOTES}_FAILURE`:
      return {
        ...state,
        isLoadingNotes: false,
        loadingNotesError: action.error,
      };
    case `${ADD_NOTE}`:
      return {
        ...state,
        isAddingNote: true,
        addingNoteSuccess: false,
        addingNoteError: null,
      };
    case `${ADD_NOTE}_SUCCESS`:
      return {
        ...state,
        isAddingNote: false,
        notes: [
          {
            ...action.result,
            is_valid: true,
          },
          ...state.notes,
        ],
        addingNoteSuccess: true,
      };
    case `${ADD_NOTE}_FAILURE`:
      return {
        ...state,
        isAddingNote: false,
        addingNoteError: action.error,
        addingNoteSuccess: false,
      };
    case `${CLEAR_NOTES_ERRORS}`:
      return {
        ...state,
        addingNoteError: null,
      };
    default:
      return state;
  }
}

export default notesReducer;
