import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { CSVLink } from 'react-csv';

import SearchBar from 'components/common/search/SearchBar';
import { intlWord } from 'components/utils/helpers';

import { Button, withTheme } from '@material-ui/core';
import listWrapper from 'components/common/base/ListWrapper';
import PerPage from 'components/common/pagination/PerPage';
import PageSelector from 'components/common/pagination/PageSelector';
import * as en from 'intl/en';

import CalaTable from 'components/common/calaTable/CalaTable';
import getGlobalStyles from 'components/styles/newGlobal.styles';
import { getResponseData } from 'components/utils/asyncHelpers';
import Config from 'config';

import getStyles from './OperationsList.styles';
import { loadLots } from '../actions/operationsActions';
import { getLots } from '../reducers/operationsSelectors';

const {
  INVENTORY_API_ROOT,
} = Config;

class OperationsList extends Component {
  constructor(props) {
    super(props);

    this.styles = getStyles(this.props.theme);
    this.globalStyles = getGlobalStyles(this.props.theme);

    this.searchFields = [
      { text: intlWord(this.props.intl, 'operations.list.lotNumber'), key: 'lot_name' },
      { text: intlWord(this.props.intl, 'operations.list.createDate'), key: 'created_date' },
      { text: intlWord(this.props.intl, 'operations.list.closeDate'), key: 'close_date' },
      { text: intlWord(this.props.intl, 'operations.list.creator'), key: 'created_by' },
      { text: intlWord(this.props.intl, 'operations.list.serialNumber'), key: 'serial_number' },
      { text: intlWord(this.props.intl, 'operations.list.productFamily'), key: 'product_family' },
    ];

    this.columns = [
      { title: 'operations.list.lotNumber', key: 'lot_name' },
      { title: 'operations.list.createDate', key: 'created_date' },
      { title: 'operations.list.closeDate', key: 'close_date' },
      { title: 'operations.list.creator', key: 'created_by' },
      { title: 'operations.list.productFamily', key: 'product_family' },
      { title: 'operations.list.deviceCount', key: 'device_count', notSortable: true },
      { title: 'operations.list.deviceType', key: 'device_type_id', notSortable: true },
      { title: 'operations.list.sku', key: 'skuNumber', notSortable: true },
    ];

    this.dataFields = [
      {
        type: 'link',
        path: `/operations/details/{0}`,
        params: ['lot_id'],
        key: 'lot_id',
        textKey: 'lot_name',
      },
      {
        type: 'date',
        key: 'created_date',
      },
      {
        type: 'date',
        key: 'close_date',
      },
      {
        key: 'created_by',
      },
      {
        key: 'product_family',
      },
      {
        key: 'device_count',
      },
      {
        key: 'deviceName',
      },
      {
        key: 'skuNumber',
      },
    ];

    this.headers = [
      { label: 'lot_name', key: 'lot_name' },
      { label: 'lot_id', key: 'lot_id' },
      { label: 'device_type_id', key: 'device_type_id' },
      { label: 'created_by', key: 'created_by' },
      { label: 'created_date', key: 'created_date' },
      { label: 'status', key: 'status' },
      { label: 'close_date', key: 'close_date' },
      { label: 'device_count', key: 'device_count' },
    ];

    this.state = {
      data: [],
    };
    this.csvLinkEl = React.createRef();
  }

  componentDidMount() {
    if (!this.props.hasOperationsDetails) {
      this.dataFields.splice(0, 1, { type: 'custom', item: '' });
    }

    this.props.loadLots();
    this.props.setPagination({ perPage: 25, selectedPage: 1 });
    this.props.setSort({ key: 'created_date', direction: -1 });
    this.props.setUpdateListFunction(this.sortList);
  }

  // eslint-disable-next-line react/sort-comp
  getLotsList = () => {
    const headers = {
      'X-Per-Page': 100000,
      'X-Page': 1,
      'X-SortField': 'created_date',
      'X-SortType': 'desc',
    };
    return getResponseData('get', `${INVENTORY_API_ROOT}/v1/lots`, {headers});
  }

  downloadReport = async() => {
    const data = await this.getLotsList();
    this.setState({ data: data.data }, () => {
      setTimeout(() => {
        this.csvLinkEl.current.link.click();
      });
    });
  }

  sortList = (sort, pagination, criteria) => {
    this.props.loadLots(
      pagination.selectedPage,
      pagination.perPage,
      sort.key,
      sort.direction,
      criteria,
    );
  }

  render() {
    const {
      pagination,
      sort,
      perPageChange,
      selectedPageChange,
      searchChange,
      sortChange,
      lots,
      operations,
    } = this.props;
    const numberOfPages = operations.lots.data.pages.length;

    const { data } = this.state;
    return (
      <div
        style={this.styles.container}
      >
        <SearchBar
          onSearch={searchChange}
          fields={this.searchFields}
        />
        <div
          style={this.styles.paginationContainer}
        >
          <PerPage
            perPage={pagination.perPage}
            selectedPage={pagination.selectedPage}
            perPageChange={perPageChange}
            numberOfPages={numberOfPages}
            numberOfRecords={operations.lots.data.total}
            style={this.styles.perPage}
          />

        <Button
          type="button"
          style={{
                  ...this.globalStyles.primaryButton,
                  ...this.styles.button,
                  padding: '10px 20px',
                  textDecoration: 'none',
                }}
          value="Export to CSV (Async)"
          onClick={this.downloadReport}
        >
        <FormattedMessage
          id="OperationsListPage.exportTable"
          defaultMessage={en.OperationsListPage.exportTable}
        />
        </Button>
          <CSVLink
            filename="operations_lot_management.csv"
            data={data}
            ref={this.csvLinkEl}
            headers={this.headers}
          />

          <PageSelector
            selectedPage={pagination.selectedPage}
            selectedPageChange={selectedPageChange}
            numberOfPages={numberOfPages}
            position="topRight"
          />
        </div>
        <div
          style={this.styles.tableWrapper}
        >
          <CalaTable
            rowKey="lot_id"
            id="table--operations"
            data={lots}
            dataFields={this.dataFields}
            columns={this.columns}
            activeField={sort.key}
            onSort={sortChange}
            sortDirection={sort.direction}
            showNotes={this.showNotes}
            isLoading={operations.lots.async.lots.isProcessing}
            notSortable={lots.length === 0}
            theme={this.props.theme}
          />
          {lots.length > 0 &&
            <PageSelector
              selectedPage={pagination.selectedPage}
              selectedPageChange={selectedPageChange}
              numberOfPages={numberOfPages}
            />
          }
        </div>
      </div>
    );
  }
}

OperationsList.propTypes = {
  theme: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  operations: PropTypes.object.isRequired,
  loadLots: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  sortChange: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  selectedPageChange: PropTypes.func.isRequired,
  perPageChange: PropTypes.func.isRequired,
  setUpdateListFunction: PropTypes.func.isRequired,
  searchChange: PropTypes.func.isRequired,
  hasOperationsDetails: PropTypes.bool.isRequired,
  lots: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  operations: state.operations,
  lots: getLots(state),
});

const mapDispatchToProps = { loadLots };

const enhance = compose(
  injectIntl,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
  listWrapper({ displayName: 'OperationsList' }),
);

export default enhance(OperationsList);
