import { ADD_CLINIC as ADD_CLINIC_FORM } from 'components/common/forms/names';
import { sagaRequestWithPayload, formSagaRequestWithPayload } from 'components/utils/reduxHelpers';
import {
  LOAD_CLINICS,
  LOAD_CLINICS_WITHOUT_LOGO,
  ADD_CLINIC,
  UPDATE_CLINIC,
} from 'components/clinics/actions/clinicsActions';
import * as ClinicsService from '../services/clinicsService';

export function* loadClinics(action) {
  yield* sagaRequestWithPayload(LOAD_CLINICS, ClinicsService.loadClinics, action);
}

export function* loadClinicsWithoutLogo(action) {
  yield* sagaRequestWithPayload(LOAD_CLINICS_WITHOUT_LOGO, ClinicsService.loadClinicsWithoutLogo, action);
}

export function* addClinic(action) {
  const { promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    ADD_CLINIC_FORM,
    ADD_CLINIC,
    ClinicsService.addClinic,
    promise,
    rest,
  );
}

export function* updateClinic(action) {
  const { promise, clinic } = action;
  const { id } = clinic;

  yield* formSagaRequestWithPayload(
    ADD_CLINIC_FORM,
    UPDATE_CLINIC,
    ClinicsService.updateClinic,
    promise,
    { id, clinic },
  );
}
