import list from './en/list';
import details from './en/details';
import compliance from './en/compliance';
import form from './en/form';

export default {
  list,
  details,
  compliance,
  form,
};
