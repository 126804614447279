import { combineReducers } from 'redux';
import { asyncReducerHelper } from 'components/utils/reduxHelpers';
import { LOAD_PROFILE, SAVE_PROFILE } from '../actions/profileActions';

export const loadInfo = asyncReducerHelper(LOAD_PROFILE);
export const saveInfo = asyncReducerHelper(SAVE_PROFILE);

const asyncReducer = combineReducers({
  loadInfo,
  saveInfo,
});

export default asyncReducer;
