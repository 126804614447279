import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

const QuarantineActions = ({
  quarantineItem,
  downloadFile,
  style,
  ...rest
}) => (
  <Fragment>
    <IconButton
      className="material-icons"
      id={`button__download__${quarantineItem.id}`}
      data-quarantinelogid={quarantineItem.id}
      data-filename={quarantineItem.file_link}
      onClick={downloadFile}
      style={style.downloadButton}
      disableRipple
    >
      file_download
    </IconButton>
  </Fragment>
);

QuarantineActions.propTypes = {
  style: PropTypes.shape({
    downloadButton: PropTypes.shape({ color: PropTypes.string }),
  }).isRequired,
  quarantineItem: PropTypes.shape({
    id: PropTypes.number,
    serial_number: PropTypes.string,
    device_id: PropTypes.number,
    mac: PropTypes.string,
    request_date: PropTypes.string,
    request_type: PropTypes.string,
    file_link: PropTypes.string,
  }).isRequired,
  downloadFile: PropTypes.func.isRequired,
};

export default QuarantineActions;
