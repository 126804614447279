import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import PageWrapper from 'components/common/PageWrapper';
import PageHeader from 'components/common/PageHeader';
import OperationsIcon from 'components/styles/icons/OperationsIcon';
import DeviceAssignment from 'components/operations/ui/DeviceAssignment';
import PrefixLegend from 'components/operations/ui/DeviceAssignment/PrefixLegend';
import { loadPrefix } from 'components/operations/actions/devicesActions';
import { getPrefixList, getPrefixDescriptions } from 'components/operations/selectors';

import NavigationLinks from '../ui/NavigationLinks';
import styles from './DeviceAssignment.styles';

class DeviceAssignmentPage extends Component {
  constructor(props) {
    super(props);

    this.style = styles();
  }

  componentDidMount() {
    const { _loadPrefix } = this.props;

    _loadPrefix();
  }

  render() {
    const { classes, prefixDescriptions, prefixList } = this.props;

    return (
      <PageWrapper>
        <PageHeader title="DeviceAssignment.title" Icon={OperationsIcon} />
          <NavigationLinks style={this.style.navLink} />
          {
            prefixDescriptions.Comercial &&
            <PrefixLegend
              prefixDescriptions={prefixDescriptions}
              prefixLegendStyle={this.style.prefixLegend}
            />
          }
        <div className={classes.content}>
          <DeviceAssignment prefixList={prefixList} />
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = state => ({
  prefixList: getPrefixList(state),
  prefixDescriptions: getPrefixDescriptions(state),
});

const mapDispatchToProps = dispatch => ({
  _loadPrefix: () => {
    dispatch(loadPrefix());
  },
});

DeviceAssignmentPage.propTypes = {
  classes: PropTypes.object.isRequired,
  prefixList: PropTypes.array.isRequired,
  prefixDescriptions: PropTypes.shape({
    Comercial: PropTypes.string,
    Test: PropTypes.string,
  }).isRequired,
  _loadPrefix: PropTypes.func.isRequired,
};

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(DeviceAssignmentPage);
