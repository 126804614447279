import { combineReducers } from 'redux';

import clinicsListReducer from './clinicsListReducer';
import newClinicReducer from './newClinicReducer';

const clinicsReducer = combineReducers({
  list: clinicsListReducer,
  newItem: newClinicReducer,
});

export default clinicsReducer;
