import _ from 'lodash';

export const ROLES = {
  SERVICE: 0,
  ADMIN: 1,
  CLINICAL_COORDINATOR: 2,
  CLINICAL_OPERATION: 3,
  REGULATORY: 4,
  OPERATIONS: 5,
  CLINICAL_SCIENCES: 6,
  PATIENT: 7,
  OUS_CALA: 8,
  PRODUCTION: 9,
  ENGINEER: 10,
};

export const ROLES_BY_ID = _.invert(ROLES);

const SUB_ROLES = {
  [ROLES.ADMIN]: [
    ROLES.ADMIN,
    ROLES.CLINICAL_OPERATION,
    ROLES.REGULATORY,
    ROLES.OPERATIONS,
    ROLES.CLINICAL_SCIENCES,
    ROLES.PATIENT,
    ROLES.OUS_CALA,
    ROLES.PRODUCTION,
    ROLES.ENGINEER,
  ],
  [ROLES.CLINICAL_OPERATION]: [],
  [ROLES.REGULATORY]: [ROLES.PATIENT],
  [ROLES.OPERATIONS]: [ROLES.PATIENT],
  [ROLES.CLINICAL_SCIENCES]: [],
  [ROLES.PATIENT]: [],
  [ROLES.OUS_CALA]: [],
  [ROLES.ENGINEER]: [],
};

export const ROLE_NAMES = {
  [ROLES.ADMIN]: 'Admin',
  [ROLES.CLINICAL_COORDINATOR]: 'Clinical Coordinator',
  [ROLES.CLINICAL_OPERATION]: 'Clinical Operation',
  [ROLES.REGULATORY]: 'Quality',
  [ROLES.OPERATIONS]: 'Operations',
  [ROLES.CLINICAL_SCIENCES]: 'Clinical Sciences',
  [ROLES.PATIENT]: 'Patient',
  [ROLES.OUS_CALA]: 'Developer',
  [ROLES.PRODUCTION]: 'Production',
  [ROLES.ENGINEER]: 'Engineer',
};

export const getSubRoles = role => {
  if (!role) {
    return [];
  }

  return _.map(SUB_ROLES[role], subRole => {
    return {
      value: subRole,
      text: ROLE_NAMES[subRole],
    };
  });
};

export const isCalaUser = roleId => {
  const calaRoles = [
    ROLES.ADMIN,
    ROLES.OUS_CALA,
    ROLES.CLINICAL_OPERATION,
    ROLES.REGULATORY,
    ROLES.OPERATIONS,
    ROLES.PRODUCTION,
    ROLES.ENGINEER,
  ];

  return _.includes(calaRoles, roleId);
};

export const isClinicUser = roleId => (false); // site view currently disabled

export const restrictedAccess = roleId => {
  const calaRoles = [
    ROLES.PATIENT,
    ROLES.CLINICAL_COORDINATOR,
  ];

  return _.includes(calaRoles, roleId);
};

export const isClinicalSciences = roleId => roleId === ROLES.CLINICAL_SCIENCES;

export const isAllowedRole = (roleId, allowedRoles) => _.includes(allowedRoles, roleId);
