export default {
  addPatient: 'Add Patient',
  addPatientSuccess: 'Patient created',
  firstName: 'First name',
  lastName: 'Last name',
  dob: 'Date of birth',
  prescribingPhysician: 'Prescribing physician',
  handedness: 'Handedness',
  bandSize: 'Band size',
  tremorPosture: 'Tremor posture',
  phoneNumber: 'Phone number',
  left: 'Left',
  right: 'Right',
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  shipping: {
    address: 'Shipping Address',
    city: 'City',
    country: 'Country',
    ext: 'Shipping Address 2',
    state: 'State/Province/Region',
    zip: 'ZIP',
  },
  accountNumber: 'Account #',
  email: 'Email',
  successMessage: 'Request Successful',
  errorMessage: 'Request Failed',
};
