import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import OperationsPage from './OperationsPage';
import OperationsDetailsPage from './OperationsDetailsPage';
import DeviceAssignmentPage from './DeviceAssignmentPage';

class OperationsRoute extends Component {
  render() {
    /**
     * Component properties
     * @name props
     * @memberOf OperationsRoute
     * @instance
     * @type {Object}
     * @property {Object} match Route information
     * @property {string} match.url Current URL
     */
    const { match } = this.props;

    return (
        <Switch>
          <Route
            exact
            path={match.url}
            component={OperationsPage}
          />
          <Route
            exact
            path={`${match.url}/details/:lotId`}
            component={OperationsDetailsPage}
          />
          <Route
            exact
            path={`${match.url}/assign`}
            component={DeviceAssignmentPage}
          />
          <Redirect
            from="*"
            to="/"
          />
        </Switch>
    );
  }
}

/**
 * Component properties validation
 * @memberOf OperationsRoute
 * @type {Object}
 * @static
 */
OperationsRoute.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

export default OperationsRoute;
