export default {
  lotNumber: 'Lot #',
  createDate: 'Created Date',
  closeDate: 'Close Date',
  creator: 'Creator',
  deviceCount: 'Device Count',
  deviceType: 'Device Type',
  sku: 'TLA #',
  serialNumber: 'Serial number',
  mac: 'MAC',
  subjectId: 'Subject ID',
  status: 'Status',
  prefixLegend: 'Prefix Legend:',
  comercial: 'Commercial Devices: ',
  testing: 'For Testing/R&D: ',
  BT: 'BT-XXXXX',
  RD: 'RD-XXX',
  noLots: 'No Lots Found',
  noDevices: 'No Devices Found',
  modelType: 'Model',
  productFamily: 'Product Family',
};
