import theme from './default.theme';

const getGlobalStyles = () => ({
  page: {
    fontSize: theme.size.body,
    height: '100%',
    overflow: 'hidden',
    content: {
      overflowY: 'auto',
    },
    container: {
      width: '100%',
      color: theme.palette.textColor,
      fontSize: theme.size.body,
    },
  },
  flexPage: {
    display: 'flex',
    width: '100%',
    height: '100%',
    fontSize: theme.size.body,
    backgroundColor: theme.palette.blankColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  coloredPage: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.blankColor,
  },
  whitePage: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.blankColor,
  },
  pageHeader: {
    display: 'flex',
    padding: theme.padding.pageSmall,
    alignItems: 'center',
    height: theme.height.pageHeader,
    borderBottom: `1px solid ${theme.palette.borderColor1}`,
  },
  pageTitle: {
    fontSize: theme.size.header,
    color: theme.palette.textColor,
    letterSpacing: theme.spacing(0) * 2,
    fontWeight: 300,
  },
  pageIcon: {
    width: '2em',
    height: '2em',
    fill: theme.palette.textColor,
    marginRight: theme.spacing(1) * 4,
  },
  pageTagline: {
    fontSize: theme.size.body,
    color: theme.palette.defaultTextColor,
    letterSpacing: theme.spacing(0),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    fontWeight: 200,
  },
  table: {
    fontSize: theme.size.body,
  },
  tableHeaderRow: {
    backgroundColor: theme.palette.thickBackgroundColor,
    color: theme.palette.secondaryTextColor,
    fontSize: theme.size.body,
  },
  tableRow: {
    color: theme.palette.textColor,
    borderBottom: 'none',
    ODD: {
      backgroundColor: theme.palette.secondaryBackgroundColor,
    },
  },
  tableRowColumn: {
    fontSize: theme.size.body,
  },
  primaryButtonLabel: {
    fontSize: theme.size.body,
    textTransform: 'uppercase',
    letterSpacing: theme.spacing(0),

  },
  primaryButton: {
    width: '100%',
    padding: '0.8em',
    height: 'auto',
    cursor: 'pointer',
    backgroundColor: theme.palette.backgroundColor,
    color: theme.palette.secondaryTextColor,
    borderRadius: 50,
    label: {
      fontSize: theme.size.body,
      textTransform: 'uppercase',
      letterSpacing: theme.spacing(0),
    },
  },
  secondaryButton: {
    width: '100%',
    padding: '0.5em',
    height: 'auto',
    backgroundColor: theme.palette.secondaryButtonColor,
    color: theme.palette.secondaryTextColor,
    borderRadius: 5,
    label: {
      fontSize: theme.size.body,
      textTransform: 'none',
      fontWeight: theme.font.thin,
      marginLeft: '0.5em',
    },
  },
  linkButton: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: theme.palette.backgroundColor,
    label: {
      fontSize: theme.size.body,
      textTransform: 'uppercase',
      letterSpacing: theme.spacing(0),
      fontWeight: theme.font.thin,
    },
  },
  primaryLink: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    color: theme.palette.backgroundColor,
    fontSize: theme.size.body,
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: theme.spacing(0),
    fontWeight: theme.font.normal,
  },
  primaryInput: {
    backgroundColor: theme.palette.blankColor,
    fontSize: theme.size.body,
    paddingLeft: theme.padding.textInput,
    paddingRight: theme.padding.textInput,
    color: theme.palette.defaultTextColor,
    fontWeight: theme.font.thin,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.borderColor2,
  },
  primaryInputLabel: {
    display: 'block',
    color: theme.palette.textColor,
    fontSize: theme.size.body,
    marginBottom: theme.spacing(7),
    textTransform: 'uppercase',
    fontWeight: theme.font.bold,
    letterSpacing: theme.spacing(0),
  },
  plainListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: theme.size.body,
    paddingTop: theme.padding.textInput,
    paddingBottom: theme.padding.textInput,
    borderBottom: `1px solid ${theme.palette.borderColor2}`,
    content: {
      flexGrow: 1,
      flexShrink: 1,
      color: theme.palette.backgroundColor,
      fontWeight: theme.font.bold,
      text: {
        marginBottom: theme.spacing(8),
      },
      tagline: {
        color: theme.palette.defaultTextColor,
        fontWeight: theme.font.thin,
      },
    },
    actions: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
      button: {
        width: 32,
        height: 32,
        padding: 0,
        icon: {
          color: theme.palette.backgroundColor,
          width: 24,
          height: 24,
        },
      },
    },
  },
  switchBase: { // graph switch style
    '&$checked': {
      color: theme.palette.backgroundColor,
      '& + $track': {
        backgroundColor: theme.palette.backgroundColor,
        opacity: 1,
        border: 'none',
      },
    },
  },
  checked: {},
  track: {},
});

export default getGlobalStyles;
