import common from './en/common';
import deviceTypes from './en/deviceTypes';
import sku from './en/sku';
import form from './en/form';

export default {
  common,
  deviceTypes,
  sku,
  form,
};
