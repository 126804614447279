/* eslint complexity: 0 */
import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAILURE,
  FORCE_CHANGE_PASSWORD,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  LOGOUT_SUCCESS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions/authActions';
import initialState from './initialState';

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        error: null,
      };
    case LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        error: null,
      };
    case LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    case FORCE_CHANGE_PASSWORD:
      return {
        ...state,
        isForwardingForceChangePassword: true,
        forceChangePasswordError: null,
      };
    case `${FORCE_CHANGE_PASSWORD}_SUCCESS`:
      return {
        ...state,
        isForwardingForceChangePassword: false,
        forceChangePasswordError: null,
      };
    case `${FORCE_CHANGE_PASSWORD}_FAILURE`:
      return {
        ...state,
        isForwardingForceChangePassword: false,
        forceChangePasswordError: action.error,
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isVerified: false,
        verifyError: null,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        isForwardingForgotPassword: true,
        forgotPasswordError: null,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        isResettingPassword: true,
        resetPasswordError: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        error: null,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isVerified: true,
        verifyError: null,
      };
    case `${FORGOT_PASSWORD}_SUCCESS`:
      return {
        ...state,
        isForwardingForgotPassword: false,
        forgotPasswordError: null,
      };
    case `${RESET_PASSWORD}_SUCCESS`:
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: action.error,
      };
    case VERIFY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isVerified: false,
        verifyError: action.error,
      };
    case `${FORGOT_PASSWORD}_FAILURE`:
      return {
        ...state,
        isForwardingForgotPassword: false,
        forgotPasswordError: action.error,
      };
    case `${RESET_PASSWORD}_FAILURE`:
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: action.error,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default authReducer;
