import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Icon, TextField, withStyles } from '@material-ui/core';
import * as en from 'intl/en';
import _ from 'lodash';
import getGlobalStyles from 'components/styles/global.styles';
import getStyles from './PageSelector.styles';

/**
 * @description One part of the Pagination component which consists of two parts. The PageSelector component is responsible for loading new data when a new page has been selected
 * @class
 * @return { Component } React Component
 */
class PageSelector extends Component {
  constructor(props) {
    super(props);

    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();
  }

  _previousPage = () => {
    const { selectedPage } = this.props;

    if (selectedPage === 1) {
      return;
    }
    const newPage = selectedPage - 1;

    this.props.selectedPageChange(newPage);
  }

  _nextPage = () => {
    const { selectedPage } = this.props;
    const newPage = selectedPage + 1;

    if (newPage > this.props.numberOfPages) {
      return;
    }

    this.props.selectedPageChange(newPage);
  }

  /**
   * Function which handles page change
   * @param {Object} ev Event
   * @param {number} selectedPage Number of selected page
   * @return {void}
   */
  _handlePageChange = (ev, selectedPage) => {
    const { selectedPageChange, numberOfPages } = this.props;
    const newPage = parseInt(ev.target.value, 0);

    if (Number.isNaN(newPage)) {
      selectedPageChange('');
    } else if (newPage > numberOfPages) {
      selectedPageChange(numberOfPages);
    } else {
      selectedPageChange(newPage);
    }
  }

  render() {
    const {
      selectedPage,
      numberOfPages,
      intl,
      hide,
      position,
      classes,
    } = this.props;

    return (
      <div
        id="pagination_wrapper"
        style={{...this.globalStyles.pageTagline, display: hide ? 'none' : 'flex', ...this.styles[position]}}
      >
        <div
          id="pagination_right"
          style={this.styles.rightContainer}
        >
          <div style={this.styles.pageSelector}>
            <Icon
              className="btn--previous-page"
              onClick={this._previousPage}
              style={this.styles.iconButton}
              classes={{
                root: classes.arrowIcon,
              }}
            >
              keyboard_arrow_left
            </Icon>
              <div style={this.styles.selectedPageContainer}>
                <TextField
                  id="input--page-number"
                  name="page-number"
                  onChange={this._handlePageChange}
                  InputProps={{ disableUnderline: true, classes: { input: classes.inputStyle } }}
                  value={selectedPage}
                  onBlur={this._selectPage}
                  style={this.styles.pageInput}
                  required
                />
                {
                  intl.formatMessage({
                    id: 'files.pagination.of',
                    defaultMessage: _.get(en, 'files.pagination.of'),
                  }, {
                    numberOfPages,
                  })
                }
              </div>
            <Icon
              className="btn--next-page"
              onClick={this._nextPage}
              style={this.styles.iconButton}
              classes={{
                root: classes.arrowIcon,
              }}
            >
              keyboard_arrow_right
            </Icon>
          </div>
        </div>
      </div>
    );
  }
}

PageSelector.propTypes = {
  intl: intlShape,
  numberOfPages: PropTypes.number,
  selectedPage: PropTypes.number,
  hide: PropTypes.bool,
  selectedPageChange: PropTypes.func.isRequired,
  position: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

PageSelector.defaultProps = {
  numberOfPages: 1,
  hide: false,
  selectedPage: 1,
  position: 'bottomCenter',
};

const enhance = compose(injectIntl, withStyles(getStyles));

export default enhance(PageSelector);
