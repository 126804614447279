/* eslint max-len: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import '../css/inventoryIcon.css';

const InventoryIcon = props => (
    <SvgIcon
      {...props}
      viewBox="0 0 22.414 24"
      fontSize="inherit"
    >
      <path
        id="Path_107"
        data-name="Path 107"
        className="inventory-line"
        style={{
          stroke: props.htmlColor,
        }}
        d="M13.243,22.414l5.171-5.171a2,2,0,0,0,0-2.829L9,5H1v8l9.414,9.414A2,2,0,0,0,13.243,22.414Z"
      />
      <path
        id="Path_108"
        data-name="Path 108"
        className="inventory-line"
        style={{
          stroke: props.htmlColor,
        }}
        d="M21,11,11,1H3"
      />
      <circle
        id="Ellipse_72"
        data-name="Ellipse 72"
        className="inventory-dot"
        style={{
          stroke: props.htmlColor,
        }}
        cx="2"
        cy="2"
        r="2"
        transform="translate(4 8)"
      />
    </SvgIcon>
);

InventoryIcon.propTypes = {
  htmlColor: PropTypes.string,
};

export default InventoryIcon;
