/* eslint max-len: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/core';

const NoteIcon = props => {
  const { isFilled, theme, ...otherProps } = props;

  if (isFilled) {
    return (
      <SvgIcon
        style={{
          fill: theme.palette.backgroundColor,
        }}
        viewBox="0 0 20 20"
        fontSize="inherit"
        {...otherProps}
      >
        <path
          d="M4,4V24H18.952L24,18.952V4ZM8.762,9.238H19.714v.952H8.762Zm0,2.857H19.714v.952H8.762Zm0,2.857H19.714V15.9H8.762Zm10,7.9v-4.1h4.1Z"
          transform="translate(-4 -4)"
        />
      </SvgIcon>
    );
  } else {
    return (
      <SvgIcon
        {...otherProps}
        viewBox="0 0 20 20"
        fontSize="inherit"
      >
        <path
          d="M4,4V24H18.959L24,18.959V4Zm.952.952h18.1V18.286H18.286v4.762H4.952Zm3.81,4.286v.952H19.714V9.238Zm0,2.857v.952H19.714V12.1Zm0,2.857V15.9H19.714v-.952Zm10.476,4.286h3.136l-3.136,3.136Z"
          transform="translate(-4 -4)"
        />
      </SvgIcon>
    );
  }
};

NoteIcon.propTypes = {
  isFilled: PropTypes.bool,
};

export default withTheme(NoteIcon);
