export const LOAD_CLINICS = 'LOAD_CLINICS';
export const LOAD_CLINICS_WITHOUT_LOGO = 'LOAD_CLINICS_WITHOUT_LOGO';
export const ADD_CLINIC = 'ADD_CLINIC';
export const UPDATE_CLINIC = 'UPDATE_CLINIC';
export const CLEAR_CLINIC_ERRORS = 'CLEAR_CLINIC_ERRORS';
export const RESET_NEW_ITEM = 'RESET_NEW_ITEM';

export function loadClinics(notification) {
  return {
    type: LOAD_CLINICS,
    notification,
  };
}

export function loadClinicsWithoutLogo(notification) {
  return {
    type: LOAD_CLINICS_WITHOUT_LOGO,
    notification,
  };
}

export function addClinic(clinic, promise) {
  return { type: ADD_CLINIC, clinic, promise };
}

export function updateClinic(clinic, promise) {
  return { type: UPDATE_CLINIC, clinic, promise };
}

export function clearClinicErrors() {
  return { type: CLEAR_CLINIC_ERRORS };
}

export function resetNewItem() {
  return {
    type: RESET_NEW_ITEM,
  };
}
