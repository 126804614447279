import { combineReducers } from 'redux';

import studiesReducer from './studiesReducer';
import latestNotesReducer from './latestNotesReducer';

const clinicalStudiesReducer = combineReducers({
  studies: studiesReducer,
  latestNotes: latestNotesReducer,
});

export default clinicalStudiesReducer;
