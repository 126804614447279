import { createSelector } from 'reselect';

export const getFirmwaresById = state => state.operations.firmware.byId;
export const getFirmwaresIds = state => state.operations.firmware.allIds;
export const getDefaultFirmwaresIds = state => state.operations.firmware.defaultFirmwares;

export const getFirmwaresLoadInfo = state => state.operations.firmware.loadInfo;
export const getDefaultFirmwareActionInfo = state => state.operations.firmware.defaultFirmwareActionInfo;

export const getFirmwaresList = createSelector(
  [getFirmwaresById, getFirmwaresIds],
  (byId, allIds) => allIds.map(fId => byId[fId]),
);

export const getDefaultFirmwares = createSelector(
  [getFirmwaresById, getDefaultFirmwaresIds],
  (byId, defaults) => ({
    B: byId[defaults.B],
    S: byId[defaults.S],
  }),
);

export const getFirmwareDevicesLoadInfo = state => state.operations.firmware.devices.loadInfo;

export const getFirmwareDevicesById = state => state.operations.firmware.devices.byId;

export const getFirmwareDevicesIdsByFirmware = (state, props) =>
  state.operations.firmware.devices.byFirmwareId[props.firmwareId];

export const makeGetFirmwareDevicesList = () => {
  return createSelector(
    [getFirmwareDevicesById, getFirmwareDevicesIdsByFirmware],
    (byId, ids) => (ids ? ids.map(id => byId[id]) : []),
  );
};

export const makeGetFirmwareConfirmationCounts = () => {
  return createSelector(
    makeGetFirmwareDevicesList(),
    devices => devices.reduce(
      (acc, device) => {
        if (device.success_date && device.download_date) {
          acc.confirmedCount++;
        } else if (!device.success_date && device.download_date) {
          acc.notConfirmedCount++;
        } else if (!device.success_date && !device.download_date) {
          acc.noResponseCount++;
        }
        return acc;
      },
      { confirmedCount: 0, notConfirmedCount: 0, noResponseCount: 0 },
    ),
  );
};
