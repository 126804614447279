import { combineReducers } from 'redux';
import _ from 'lodash';
import { createReducer } from 'components/utils/reduxHelpers';
import { LOAD_USERS } from '../actions/usersActions';

export const BY_ID_DEFAULT_STATE = {};

export const byId = createReducer(BY_ID_DEFAULT_STATE, {
  [`${LOAD_USERS}`]: () => ({}),
  [`${LOAD_USERS}_SUCCESS`]: (state, action) => _.keyBy(action.result.data, u => u.pk),
});

const dataReducer = combineReducers({
  byId,
});

export default dataReducer;
