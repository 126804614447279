import moment from 'moment';
import Config from 'config';
import { getResponseData } from 'components/utils/asyncHelpers';

const {
  CLINICAL_STUDY_API_ROOT,
  CLINIC_API_ROOT,
} = Config;

export const loadStudies = () =>
  getResponseData('get', `${CLINICAL_STUDY_API_ROOT}/studies`);

export const loadSites = () =>
  getResponseData('get', `${CLINIC_API_ROOT}/`);

export const addSite = (site, studyId) =>
  getResponseData('post', `${CLINICAL_STUDY_API_ROOT}/studies/${studyId}/sites`, site);

export const updateSite = ({ site, studyId }) => {
  const date = moment.utc(site.activation_date).format();

  return getResponseData('put', `${CLINICAL_STUDY_API_ROOT}/studies/${studyId}/sites/${site.site_study_id}`, {
    ...site,
    activation_date: date,
  });
};

export const createStudy = ({ study }) =>
  getResponseData('post', `${CLINICAL_STUDY_API_ROOT}/studies`, study);

export const updateStudy = ({ study }) =>
  getResponseData('put', `${CLINICAL_STUDY_API_ROOT}/studies/${study.clinical_study_id}`, study);
