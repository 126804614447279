import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import * as en from 'intl/en';

import getGlobalStyles from 'components/styles/global.styles';
import { mediaStyle } from 'components/utils/styles';
import { FormattedMessage } from 'react-intl';
import PageWrapper from 'components/common/PageWrapper';

import HomeIcon from 'components/styles/icons/HomeIcon';
import DeviceLogsTable from 'components/deviceLogs/DeviceLogsTable';
import getStyles from './DeviceLogsPage.styles';

class DeviceLogsPage extends Component {
  constructor(props) {
    super(props);
    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();
  }

  render() {
    const { width } = this.props;

    return (
      <PageWrapper>
        <div style={this.globalStyles.pageHeader}>
          <HomeIcon style={this.globalStyles.pageIcon} />
          <h1 style={{ ...this.globalStyles.pageTitle, ...mediaStyle(this.styles.title, width) }}>
            <FormattedMessage
              id="DeviceLogsPage.title"
              defaultMessage={en.DeviceLogsPage.title}
            />
          </h1>
        </div>
        <div style={mediaStyle(this.styles.content, width)}>
          <DeviceLogsTable />
        </div>
      </PageWrapper>
    );
  }
}

DeviceLogsPage.propTypes = {
  width: PropTypes.string,
};

export default withWidth()(DeviceLogsPage);
