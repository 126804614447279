import {
  getBtnClk,
  getAvgBtnClk,
  getImpAC,
  getAvgImpAC,
  getImpBC,
  getAvgImpBC,
} from './helper';

export const seriesNames = {
  TREMOR_RATING: 'TREMOR_RATING',
  TREMOR_FREQUENCY: 'TREMOR_FREQUENCY',
  STIM_SESSIONS: 'STIM_SESSIONS',
  SETTINGS_CHANGE: 'SETTINGS_CHANGE',
  BUTTON_CLICK: 'BUTTON_CLICK',
  IMU: 'IMU',
  STIM_AMP: 'STIM_AMP',
  ERRORS: 'ERRORS',
  BATTERY_LEVEL: 'BATTERY_LEVEL',
  IMPEDANCE_AC: 'IMPEDANCE_AC',
  IMPEDANCE_BC: 'IMPEDANCE_BC',
};
export const scatterColors = {
  0: 'black',
  1: '#49b3e5',
  2: '#70d5d6',
  3: '#68c659',
  4: '#ffc000',
  5: '#f3804a',
  6: '#ee3755',
};
export const plotLines = {
  color: 'transparent',
  dashStyle: 'LongDash',
  width: 1,
  value: 1,
  label: {
    align: 'left',
    style: {
      fontStyle: 'italic',
      color: 'transparent',
    },
    text: '',
    x: 10,
  },
  zIndex: 1,
};
export const graphColors = {
  lightgray: '#989898',
  calaGreen: 'rgb(113, 186, 204)',
  gray: 'rgba(204,204,204,.25)',
  blue: '#00ADF3',
};
const scrollbar = {
  barBackgroundColor: graphColors.calaGreen,
  barBorderRadius: 7,
  barBorderWidth: 0,
  buttonBackgroundColor: 'transparent',
  buttonArrowColor: 'transparent',
  buttonBorderWidth: 0,
  buttonBorderRadius: 7,
  trackBackgroundColor: graphColors.gray,
  trackBorderWidth: 0,
  trackBorderRadius: 8,
  trackBorderColor: graphColors.gray,
  rifleColor: 'transparent',
  height: 10,
  enabled: true,
  showFull: false,
};

export const stimSessionsConfig = {
  id: seriesNames.STIM_SESSIONS,
  type: 'scatter',
  data: [],
  turboThreshold: 0,
  marker: {
    lineWidth: 2,
    lineColor: graphColors.blue,
    symbol: 'diamond',
    radius: 10,
  },
  dataLabels: {
    overflow: 'none',
    crop: false,
    color: 'white',
    enabled: true,
    x: 0,
    y: 0,
    align: 'center',
    verticalAlign: 'middle',
    style: {
      textOutline: null,
    },
  },
  name: 'Stim sessions',
  visible: true,
};

export const tremorRatingConfig = {
  id: seriesNames.TREMOR_RATING,
  type: 'scatter',
  data: [],
  turboThreshold: 0,
  marker: {
    symbol: 'circle',
    lineWidth: 1,
    lineColor: graphColors.lightgray,
    fillColor: 'white',
    radius: 10,
  },
  dataLabels: {
    overflow: 'none',
    crop: false,
    color: graphColors.lightgray,
    enabled: true,
    x: 0,
    y: 0,
    align: 'center',
    verticalAlign: 'middle',
    style: {
      textOutline: null,
    },
  },
  name: 'Tremor score',
  visible: false,
};
export const settingsChangeConfig = {
  id: seriesNames.SETTINGS_CHANGE,
  type: 'scatter',
  data: [],
  turboThreshold: 0,
  marker: {
    symbol: 'circle',
    lineWidth: 2,
    lineColor: graphColors.lightgray,
    fillColor: graphColors.lightgray,
    radius: 10,
  },
  dataLabels: {
    overflow: 'none',
    crop: false,
    color: 'white',
    enabled: true,
    x: 0,
    y: 0,
    align: 'center',
    verticalAlign: 'middle',
    style: {
      textOutline: null,
    },
  },
  name: 'Settings change',
  visible: false,
};
export const batteryLevelConfig = {
  id: seriesNames.BATTERY_LEVEL,
  yAxis: 2,
  type: 'scatter',
  data: [],
  turboThreshold: 0,
  marker: {
    symbol: `url(/icons/icon_battery_legend.png)`,
  },
  connectEnds: true,
  name: 'Battery level',
  visible: false,
};

export const flagConfig = {
  id: seriesNames.BUTTON_CLICK,
  useHTML: true,
  dataLabels: {
    useHTML: true,
  },
  type: 'flags',
  name: 'arrows',
  onSeries: seriesNames.STIM_AMP,
  color: 'transparent',
  fillColor: 'transparent',
  shape: 'circlepin',
  data: [],
  turboThreshold: 0,
  visible: true,
};

export const tremorFrequencyConfig = {
  id: seriesNames.TREMOR_FREQUENCY,
  useHTML: true,
  dataLabels: {
    useHTML: true,
  },
  type: 'flags',
  shape: 'squarepin',
  name: 'Tremor Frequency',
  color: graphColors.calaGreen,
  fillColor: graphColors.calaGreen,
  lineWidth: 3,
  data: [],
  turboThreshold: 0,
  visible: false,
};

export const imuConfig = {
  id: seriesNames.IMU,
  dataLabels: {
    color: 'gray',
    enabled: true,
    format: 'IMU',
  },
  type: 'xrange',
  borderRadius: 1,
  borderColor: 'gray',
  name: 'IMU',
  colors: ['transparent'],
  colorByPoint: true,
  data: [],
  turboThreshold: 0,
  visible: false,
};

export const mainYAxis = {
  min: 0,
  title: {
    text: 'mA',
  },
};
export const errorYAxis = {
  gridLineWidth: 0,
  min: 0,
  max: 10,
  title: {
    text: '',
  },
  labels: false,
  maxPadding: 0.2,
  plotLines: [],
};
export const batteryAxis = {
  min: 0,
  max: 100,
  title: {
    text: 'Batter level (%)',
  },
  opposite: true,
};

export const impedanceYAxis = {
  min: 0,
  title: {
    text: 'kiloohm',
  },
};

export const chartConfig = {
  id: 1,
  chart: {
    type: 'line',
    zoomType: 'x',
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  credits: {
    enabled: true,
    position: {
      align: 'right',
      x: -10,
      verticalAlign: 'bottom',
      y: -5,
    },
    href: 'https://www.calahealth.com/',
    text: '',
  },
  xAxis: {
    type: 'datetime',
    gridLineWidth: 1,
    events: {
      // adding full list of button clicks if zoomed in
      setExtremes: data => {
        const range = (data.max - data.min) / 1000;

        if (range < 120 && range > 0) {
          const newBtn = getBtnClk();
          const newAc = getImpAC();
          const newBc = getImpBC();

          if (newBtn.length !== data.target.chart.series[4].data.length) {
            data.target.chart.series[4].setData(newBtn);
          }
          if (newAc.length !== data.target.chart.series[8].data.length) {
            data.target.chart.series[8].setData(newAc);
          }
          if (newBc.length !== data.target.chart.series[9].data.length) {
            data.target.chart.series[9].setData(newBc);
          }
        } else {
          const newBtn = getAvgBtnClk();
          const newAc = getAvgImpAC();
          const newBc = getAvgImpBC();

          if (newBtn.length !== data.target.chart.series[4].data.length) {
            data.target.chart.series[4].setData(newBtn);
          }
          if (newAc.length !== data.target.chart.series[8].data.length) {
            data.target.chart.series[8].setData(newAc);
          }
          if (newBc.length !== data.target.chart.series[9].data.length) {
            data.target.chart.series[9].setData(newBc);
          }
        }
      },
    },
    // plotBands: [],
  },

  yAxis: [],
  mapNavigation: {
    enabled: true,
    enableButtons: false,
  },
  tooltip: {
    useHTML: true,
    formatter() {
      return `${this.point.key.date}<br>${this.point.key.text}`;
    },
    valueDecimals: 2,
    crosshairs: [{
      width: 1,
      color: 'Gray',
    }, {
      width: 1,
      color: 'gray',
    }],
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0.5,
    },
    scatter: {
      marker: {
        radius: 7,
        symbol: 'circle',
      },
    },
  },
  series: [],
  scrollbar,
};

export const stimAmpConfig = {
  id: seriesNames.STIM_AMP,
  color: 'darkgray',
  yAxis: 0,
  name: 'Max current',
  data: [],
  turboThreshold: 0,
  visible: true,
};
export const impedanceAcConfig = {
  id: seriesNames.IMPEDANCE_AC,
  yAxis: 3,
  type: 'scatter',
  name: 'Impedance AC',
  data: [],
  turboThreshold: 0,
  marker: {
    symbol: `url(/icons/graph/ImpedanceAC.png)`,
  },
  visible: false,
};
export const impedanceBcConfig = {
  id: seriesNames.IMPEDANCE_BC,
  yAxis: 3,
  type: 'scatter',
  name: 'Impedance BC',
  data: [],
  turboThreshold: 0,
  marker: {
    symbol: `url(/icons/graph/ImpedanceBC.png)`,
  },
  visible: false,
};
export const errorConfig = {
  id: seriesNames.ERRORS,
  yAxis: 1,
  type: 'scatter',
  name: '',
  color: scatterColors['1'],
  data: [],
  turboThreshold: 0,
  visible: false,
  events: {
    hide() {
      this.yAxis.options.plotLines.map((plotLine, index) => {
        const plotLineName = this.chart.yAxis[1].options.plotLines[index].label.text;

        if (plotLineName === this.name) {
          this.chart.yAxis[1].options.plotLines[index].label.style = {
            color: 'transparent',
          };
          this.chart.yAxis[1].options.plotLines[index].color = 'transparent';
        }
        return true;
      });
      this.chart.yAxis[1].update();
    },
    show() {
      this.yAxis.options.plotLines.map((plotLine, index) => {
        const plotLineName = this.chart.yAxis[1].options.plotLines[index].label.text;

        if (plotLineName === this.name) {
          this.chart.yAxis[1].options.plotLines[index].label.style = {
            color: 'black',
          };
          this.chart.yAxis[1].options.plotLines[index].color = 'lightgray';
        }
        return true;
      });
      this.chart.yAxis[1].update();
    },
  },
};

