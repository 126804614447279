const getStyles = allDataLoaded => ({
  graphWrapper: {
    maxHeight: '84vh',
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    border: '#C3C3C3 1px solid',
    margin: '20px 50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  graphContainer: {
    backgroundColor: 'white',
    padding: '48px',
  },
  chart: {
    minWidth: '1628px',
    width: '95%',
  },
  refreshIndicator: {
    display: 'block',
    marginLeft: '50%',
    marginTop: '20%',
  },
  button: {
    marginLeft: '45%',
    width: 'auto',
    display: allDataLoaded ? 'none' : 'block',
  },
  graphControls: {
    marginLeft: '50px',
    marginBottom: '20px',
  },
  dateHeader: {
    textAlign: 'center',
    margin: '0 50px 15px 50px',
  },
  paletteContainer: {
    display: 'inline-block',
    float: 'left',
  },
  dateText: {
    border: 'solid 1px #C5C5C5',
    borderRadius: '20px',
    padding: '5px 20px',
  },
  selectedDate: {
    fontWeight: 'bold',
    color: '#A7A7A7',
  },
  colorBox: {
    minHeight: '15px',
    minWidth: '15px',
    display: 'inline-block',
    marginRight: '5px',
  },
  onOff: {
    display: 'inline-block',
    float: 'left',
    width: 'auto',
  },
  graphSwitchLabel: {
    marginLeft: 0,
  },
});

export default getStyles;
