import React from 'react';
import { reduxForm } from 'redux-form';

import Form from 'components/common/forms/Form';
import { required } from 'components/common/forms/validation';
import { ADD_SKU } from 'components/common/forms/names';
import { TextField, SelectField } from 'components/common/forms/elements';

import { addSku } from '../../actions/inventoryActions';

const deviceTypes = [
  {text: 'STIM', value: 'STIM'},
  {text: 'BASESTATION', value: 'BASESTATION'},
  {text: 'BAND', value: 'BAND'},
];

const productFamily = [
  {
    text: 'kIQ', value: 'kIQ',
  },
  {text: 'Trio', value: 'Trio'},
];

const submit = (values, dispatch) => new Promise((resolve, reject) => {
  dispatch(addSku(values, { resolve, reject }));
});

const AddSkuForm = props => {
  const successMessage = {
    message: 'inventory.sku.addSkuSuccess',
  };
  const errorMessage = {
    message: 'inventory.sku.addSkuError',
  };

  return (
    <Form
      id={ADD_SKU}
      onSubmit={submit}
      successMessage={successMessage}
      errorMessage={errorMessage}
      {...props}
    >
      <TextField
        name="sku"
        label="inventory.sku.skuName"
        validate={required}
      />
      <TextField
        name="description"
        label="inventory.sku.description"
        validate={required}
      />
      <SelectField
        name="product_family"
        label="inventory.sku.productFamily"
        items={productFamily}
        itemValue="value"
        itemText="text"
        validate={required}
      />
      <SelectField
        name="type"
        label="inventory.sku.deviceType"
        items={deviceTypes}
        itemValue="value"
        itemText="text"
        validate={required}
      />
    </Form>
  );
};

export default reduxForm({
  form: ADD_SKU,
  enableReinitialize: true,
})(AddSkuForm);
