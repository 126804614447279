import _ from 'lodash';
import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_UNASSIGNED_DEVICES,
  OPERATIONS_TOGGLE_DEVICE,
  OPERATIONS_TOGGLE_ALL_DEVICES,
  DELETE_UNASSIGNED_DEVICE,
} from '../actions/devicesActions';

const getList = (state, action) => {
  return action.result.map(device => device.device_inventory_id);
};

const toggleDevice = (state, action) => {
  if (action.payload.checked) {
    return [
      ...state,
      action.payload.deviceInventoryId,
    ];
  } else {
    return _.filter(state, deviceInventoryId => deviceInventoryId !== action.payload.deviceInventoryId);
  }
};

const removeDevice = (state, action) => {
  return _.filter(state, deviceInventoryId => deviceInventoryId !== action.payload.inventoryId);
};

const list = createReducer([], {
  [`${LOAD_UNASSIGNED_DEVICES}_SUCCESS`]: getList,
  [`${OPERATIONS_TOGGLE_DEVICE}_SUCCESS`]: toggleDevice,
  [`${DELETE_UNASSIGNED_DEVICE}_SUCCESS`]: removeDevice,
});

const toggleAllChecked = (state, action) => {
  return !state;
};

const allChecked = createReducer(true, {
  [`${LOAD_UNASSIGNED_DEVICES}_SUCCESS`]: () => true,
  [`${OPERATIONS_TOGGLE_ALL_DEVICES}`]: toggleAllChecked,
});

const asyncReducer = combineReducers({
  toggle: asyncReducerHelper(OPERATIONS_TOGGLE_DEVICE),
});

const checkedDevicesReducer = combineReducers({
  async: asyncReducer,
  list,
  allChecked,
});

export default checkedDevicesReducer;
