import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTheme } from '@material-ui/core';
import DescriptionLine from 'components/common/DescriptionLine';
import SimpleTable from 'components/common/simpleTable/SimpleTable';
import CalaDialog from 'components/common/calaDialog/CalaDialog';
import Fetch from 'components/common/Fetch';

import DoubleCheckIcon from 'components/styles/icons/compliance/DoubleCheckIcon';
import ComplianceTableRow from './ComplianceTableRow';
import { getComplianceDetails } from '../../selectors';
import getStyles from './Compliance.styles';

const ComplianceDialog = props => {
  const {
    isOpen,
    subjectId,
    closeDialog,
    compliance,
    theme,
    fetch,
    asyncInfo,
  } = props;
  const headers = [
    { key: 1 },
    { key: 2, text: 'patients.compliance.firstSession' },
    { key: 3, text: 'patients.compliance.secondSession' },
    { key: 4 },
  ];
  const styles = getStyles(theme);

  return (
    <CalaDialog
      isModalOpen={isOpen}
      closeDialog={closeDialog}
      id="modal--compliance_details"
      contentId="compliance_details__content"
      title="patients.compliance.weeklyCompliance"
      icon={<DoubleCheckIcon />}
    >
      <DescriptionLine
        fontSize={12}
        title={{ text: 'patients.list.subjectId' }}
        description={subjectId}
      />
      <Fetch fetch={fetch} asyncInfo={asyncInfo} skipFetchOnMount>
        <SimpleTable headers={headers} customStyles={styles.sessionTable} >
          {
            compliance.daily_compliance_history.map(dailyCompliance => {
              return <ComplianceTableRow key={dailyCompliance.date} data={dailyCompliance} />;
            })
          }
        </SimpleTable>
      </Fetch>
    </CalaDialog>
  );
};

ComplianceDialog.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  subjectId: PropTypes.string,
  closeDialog: PropTypes.func.isRequired,
  compliance: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  asyncInfo: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  compliance: getComplianceDetails(state),
});

const enhance = compose(
  withTheme,
  connect(mapStateToProps),
);

export default enhance(ComplianceDialog);
