import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PrimaryButton from 'components/common/buttons/PrimaryButton';
import { closeLot } from 'components/operations/actions/operationsActions';
import permissions from 'components/operations/utils/permissions';
import { isAllowedRole } from 'components/users/utils/userRoles';
import Session from 'components/utils/session';

import ClosedLotActions from './ClosedLotActions';

const LotActions = props => {
  const {
    isLotClosed,
    styles,
    checkedDevices,
    lotDetails,
    _closeLot,
  } = props;
  const roleId = Session.JWTDecoded.role_id;
  const isAllowedRoleCloseLot = isAllowedRole(roleId, permissions.closeLot);
  const isAllowedRolePrintLot = isAllowedRole(roleId, permissions.printLot);

  let element = null;

  if (!isLotClosed) {
    element = (
      isAllowedRoleCloseLot &&
        <PrimaryButton
          onClick={() => _closeLot(lotDetails.data.lot_id, checkedDevices.list)}
          message="OperationsDetailsPage.closeLot"
          customStyles={styles.button}
        />
    );
  } else {
    element = (
      <ClosedLotActions styles={styles} isAllowedRolePrintLot={isAllowedRolePrintLot} />);
  }

  return (
    <span style={styles.actionWrapper}>
      {element}
    </span>
  );
};

LotActions.propTypes = {
  isLotClosed: PropTypes.bool.isRequired,
  styles: PropTypes.object.isRequired,
  _closeLot: PropTypes.func.isRequired,
  lotDetails: PropTypes.object.isRequired,
  checkedDevices: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  lotDetails: state.operations.lotDetails,
  checkedDevices: state.operations.checkedDevices,
});

const mapDispatchToProps = dispatch => ({
  _closeLot: (lotId, devices) => {
    dispatch(closeLot(lotId, devices));
  },

});

export default connect(mapStateToProps, mapDispatchToProps)(LotActions);
