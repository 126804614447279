export const shared = {
  password: 'Password',
  changePassword: 'Change Password',
  emailAddress: 'Email Address',
  logout: 'Logout',
  shippingAddress: 'Shipping Address',
  number: 'Number',
  name: 'Name',
  address: 'Address',
  done: 'Done',
  cancel: 'Cancel',
  unknown: 'Unknown',
  ok: 'OK',
  message: 'Message',
  assign: 'Assign',
  unassign: 'Unassign',
  other: 'Other',
  googleSignIn: 'Sign In With Google',
  oauth: 'Logging in by Google Account',
};

export { default as common } from 'components/common/intl/en';
export { default as forms } from 'components/common/forms/intl/en';
export { default as files } from 'components/deviceLogs/intl/en';
export { default as Sidebar } from 'containers/common/intl/en/Sidebar';
export { default as Topbar } from 'containers/common/intl/en/Topbar';
export { default as search } from 'components/common/search/intl/en';

export { default as user } from 'components/users/intl/en';
export { default as UserPage } from 'containers/core/users/intl/en/UserPage';

export { default as graph } from 'components/graph/intl/en';
export { default as clinic } from 'components/clinics/intl/en';
export { default as ClinicPage } from 'containers/core/clinics/intl/en/ClinicPage';

export { default as profile } from 'components/profile/intl/en';
export { default as ProfilePage } from 'containers/core/profile/intl/en/ProfilePage';

export { default as DeviceLogsPage } from 'containers/core/deviceLogs/intl/en/DeviceLogsPage';
export { default as utils } from 'components/utils/intl/en';

export { default as ClinicalStudyPage } from 'containers/core/clinicalStudy/intl/en/ClinicalStudyPage';
export { default as clinicalStudy } from 'components/clinicalStudy/intl/en';

export { default as PatientListPage } from 'containers/core/patients/intl/en/PatientListPage';
export { default as PatientDetailsPage } from 'containers/core/patients/intl/en/PatientDetailsPage';
export { default as CreatePatientPage } from 'containers/core/patients/intl/en/CreatePatientPage';
export { default as patients } from 'components/patients/intl/en';
export { default as InventoryPage } from 'containers/core/inventory/intl/en/InventoryPage';
export { default as inventory } from 'components/inventory/intl/en';

export { default as OperationsPage } from 'containers/core/operations/intl/en/OperationsPage';
export { default as OperationsListPage } from 'containers/core/operations/intl/en/OperationsListPage';
export { default as OperationsDetailsPage } from 'containers/core/operations/intl/en/OperationsDetailsPage';
export { default as OperationsDFU } from 'containers/core/operations/intl/en/OperationsDFU';
export { default as DeviceAssignment } from 'containers/core/operations/intl/en/DeviceAssignment';

export { default as operations } from 'components/operations/intl/en';

export { default as ShipmentsPage } from 'containers/core/shipments/intl/en/ShipmentsPage';
export { default as shipments } from 'components/shipments/intl/en';

export { default as QuarantinePage } from 'containers/core/quarantine/intl/en/QuarantinePage';
export { default as quarantine } from 'components/quarantine/intl/en';

export { default as auth } from 'components/auth/intl/en';

export { default as notifier } from 'components/common/Notifier/intl/en';
