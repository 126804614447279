import TokenCall from 'components/auth/services/token';
import Config from 'config';

const { DEVICE_API_ROOT } = Config;

export const downloadQuarantine = async id => {
  try {
    const response = await TokenCall.get(`${DEVICE_API_ROOT}/v0/quarantine/${id}/download`);

    return response.data;
  } catch (error) {
    return error;
  }
};
