const getStyles = () => ({
  table: {
    display: 'table',
    margin: '20px 0',
  },
  heading: {
    display: 'table-row',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#737373',
    textTransform: 'uppercase',
  },
  row: {
    display: 'table-row',
  },
  cell: {
    paddingTop: '10px',
    display: 'table-cell',
    paddingLeft: '35px',
    paddingRight: '35px',
    color: '#737373',
    textAlign: 'center',
  },
  cellBorder: {
    borderRight: 'solid #C3C3C3 1px',
  },
});

export default getStyles;
