import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import Message from 'components/common/Message';

const PrefixLegend = ({ prefixLegendStyle, prefixDescriptions, ...rest }) => (
  <div style={prefixLegendStyle.container}>
    <div style={prefixLegendStyle.title}>
      <strong>
        <Message id="operations.list.prefixLegend" />
      </strong>
    </div>
    <div style={prefixLegendStyle.message}>
    <Tooltip title={prefixDescriptions.Comercial}>
      <div style={prefixLegendStyle.prefixInfo}>
        <div style={prefixLegendStyle.content}>
          <Message id="operations.list.comercial" />
        </div>
        <div style={prefixLegendStyle.text}>
          <Message id="operations.list.BT" />
        </div>
      </div>
    </Tooltip><br />
    <Tooltip title={prefixDescriptions.Test}>
      <div style={prefixLegendStyle.prefixInfo}>
        <div style={prefixLegendStyle.content}>
          <Message id="operations.list.testing" />
        </div>
        <div style={prefixLegendStyle.text}>
          <Message id="operations.list.RD" />
        </div>
      </div>
    </Tooltip>
    </div>
  </div>
);

PrefixLegend.propTypes = {
  prefixLegendStyle: PropTypes.object.isRequired,
  prefixDescriptions: PropTypes.object.isRequired,
};

export default PrefixLegend;
