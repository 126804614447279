
import _ from 'lodash';
import { store } from 'App';
import { getUserDetailsList } from 'components/users/selectors';
import { fetchMultipleUsersData } from 'components/users/actions/usersActions';
import TokenCall from 'components/auth/services/token';

export const getResponseData = async(method, url, body) => {
  const response = await TokenCall[method](url, body);

  return response.data;
};

export const fetchMissingUsers = (ids = []) => {
  const byId = getUserDetailsList(store.getState());
  const deduped = [...new Set(ids)];
  const missingUsers = [];

  _.forEach(deduped, id => {
    if (id && !byId[id]) {
      missingUsers.push(id);
    }
  });

  if (missingUsers.length === 0) return;

  store.dispatch(fetchMultipleUsersData(missingUsers));
};
