/* eslint max-len: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';

class TremorIcon extends Component {
  render() {
    const { filled, ...rest } = this.props;

    return (
      <SvgIcon
        style={{
          fill: filled ? this.props.theme.palette.backgroundColor : 'none',
          stroke: this.props.theme.palette.backgroundColor,
          strokeLinecap: 'square',
          strokeMiterlimit: 10,
          strokeWidth: 2,
        }}
        {...rest}
        viewBox="0 0 9.884 30"
      >
        <path
          className="tremor-icon"
          d="M6.977,28.5H1.995A1.532,1.532,0,0,1,.5,27.005V5.084a1.391,1.391,0,0,1,.3-.9L3.29,1.1a1.4,1.4,0,0,1,2.292,0L8.073,4.187a1.607,1.607,0,0,1,.3.9V27.005A1.386,1.386,0,0,1,6.977,28.5Z"
          transform="translate(0.5 0.5)"
        />
      </SvgIcon>
    );
  }
}

TremorIcon.propTypes = {
  filled: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      backgroundColor: PropTypes.string,
    }),
  }).isRequired,
};

export default withTheme(TremorIcon);
