import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Checkbox,
  withTheme,
} from '@material-ui/core';

import getStyles from './OperationsDetailsTable.styles';
import LotTableBody from './LotTableBody';
import ConfirmationDialogContainer from '../../ConfirmationDialog';

const OpenLotTableBody = props => {
  const {
    data,
    theme,
    checkedDevices,
    checkboxChange,
    hasDeleteButton,
  } = props;
  const styles = getStyles(theme);

  return (
    <Fragment>
      {
        data.map(row => {
          const isChecked = checkedDevices.indexOf(row.device_inventory_id) >= 0;

          return (
            <TableRow
              key={row.device_inventory_id}
              style={{
                ...(isChecked ? {} : styles.uncheckedRow),
              }}
            >
              <TableCell
                style={styles.actionColumn}
              >
                <Checkbox
                  checked={isChecked}
                  value={row.device_inventory_id.toString()}
                  color="default"
                  role="checkbox"
                  aria-checked={isChecked}
                  onChange={checkboxChange}
                />
              </TableCell>
              <LotTableBody row={row} skuNumber={row.sku_number} />
              {
                hasDeleteButton &&
                <TableCell
                  style={styles.lastColumn}
                >
                  <ConfirmationDialogContainer inventoryId={row.device_inventory_id} />
                </TableCell>
              }
            </TableRow>
          );
        })
      }
    </Fragment>
  );
};

OpenLotTableBody.propTypes = {
  hasDeleteButton: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  checkedDevices: PropTypes.array.isRequired,
  checkboxChange: PropTypes.func.isRequired,
};

export default withTheme(OpenLotTableBody);
