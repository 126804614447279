import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { TableRow, TableCell } from '@material-ui/core';
import * as en from 'intl/en';

import QuarantineActions from './QuarantineActions';

const QuarantineTableBody = ({
  quarantineItem,
  downloadFile,
  style,
  ...rest
}) => (
  <TableRow>
    <TableCell>
      <QuarantineActions
        quarantineItem={quarantineItem}
        downloadFile={downloadFile}
        style={style}
      />
    </TableCell>
    <TableCell>
      {quarantineItem.mac}
    </TableCell>
    <TableCell>
      {quarantineItem.device_id}
    </TableCell>
    <TableCell>
      {quarantineItem.serial_number}
    </TableCell>
    <TableCell>
      <FormattedDate
        value={moment.utc(quarantineItem.request_date).local()}
        day="numeric"
        month="numeric"
        year="numeric"
        hour="numeric"
        minute="numeric"
      />
    </TableCell>
    <TableCell>
      <FormattedMessage
        id={`quarantine.list.${quarantineItem.request_type}`}
        defaultMessage={en.quarantine.list[quarantineItem.request_type]}
      />
    </TableCell>
  </TableRow>
);

QuarantineTableBody.propTypes = {
  style: PropTypes.shape({
    graphButton: PropTypes.shape({ color: PropTypes.string }),
    downloadButton: PropTypes.shape({ color: PropTypes.string }),
  }).isRequired,
  quarantineItem: PropTypes.shape({
    id: PropTypes.number,
    serial_number: PropTypes.string,
    device_id: PropTypes.number,
    mac: PropTypes.string,
    request_date: PropTypes.string,
    request_type: PropTypes.string,
    file_link: PropTypes.string,
  }).isRequired,
  downloadFile: PropTypes.func.isRequired,
};

export default QuarantineTableBody;
