export const LOAD_QUARANTINE_LIST = 'LOAD_QUARANTINE_LIST';
export const LOAD_QUARANTINE_LIST_SUCCESS = 'LOAD_QUARANTINE_LIST_SUCCESS';
export const LOAD_QUARANTINE_LIST_FAILURE = 'LOAD_QUARANTINE_LIST_FAILURE';
export const LOAD_QUARANTINE_ITEM = 'LOAD_QUARANTINE_ITEM';
export const LOAD_QUARANTINE_ITEM_SUCCESS = 'LOAD_QUARANTINE_ITEM_SUCCESS';
export const LOAD_QUARANTINE_ITEM_FAILURE = 'LOAD_QUARANTINE_ITEM_FAILURE';
export const CANCEL_PENDING_REQUEST = 'CANCEL_PENDING_REQUEST';

export function loadQuarantineList(selectedPage, perPage, sortField, sortType, criteria) {
  const headers = {
    'X-Per-Page': perPage,
    'X-Page': selectedPage,
    'X-SortField': sortField,
    'X-SortType': sortType === 1 ? 'asc' : 'desc',
  };

  if (criteria && criteria.terms && criteria.terms !== '') {
    headers['X-SearchTerms'] = criteria.terms;
  }

  if (criteria && (criteria.dateRange || {}).startDate) {
    headers['X-From'] = criteria.dateRange.startDate;
    headers['X-To'] = criteria.dateRange.endDate;
  }

  return {
    type: LOAD_QUARANTINE_LIST,
    headers,
  };
}

export const quarantineListLoaded = list => ({
  type: LOAD_QUARANTINE_LIST_SUCCESS,
  payload: { list },
});

export function loadQuarantineItem(quarantine_log_id) {
  return {
    type: LOAD_QUARANTINE_ITEM,
    quarantine_log_id,
  };
}

export const quarantineItemLoaded = item => ({
  type: LOAD_QUARANTINE_ITEM_SUCCESS,
  payload: { item },
});

export const cancelPendingRequest = () => ({
  type: CANCEL_PENDING_REQUEST,
});
