import { createReducer } from 'components/utils/reduxHelpers';
import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from '../actions';

export const DEFAULT_STATE = [];

const enqueueSnackbar = (state, action) => {
  return [
    ...state,
    {
      ...action.notification,
    },
  ];
};

const removeSnackbar = (state, action) => {
  return state.filter(notification => notification.key !== action.key);
};

const notificationsReducer = createReducer(DEFAULT_STATE, {
  [`${ENQUEUE_SNACKBAR}`]: enqueueSnackbar,
  [`${REMOVE_SNACKBAR}`]: removeSnackbar,
});

export default notificationsReducer;
