/* eslint  global-require: 0 */

import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';

axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': process.env.ALLOWED_ORIGIN || '*',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
};

if (process.env.NODE_ENV === 'development') {
  const a11y = require('react-a11y').default;

  a11y(React, ReactDOM, {
    rules: {
      'img-uses-alt': 1,
      'no-hash-ref': 1,
      'aria-unsupported-elements': 1,
      'img-redundant-alt': 1,
      'aria-role': 1,
    },
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
