import dialog from './en/dialog';
import table from './en/table';
import pagination from './en/pagination';
import filter from './en/filter';
import notes from './en/notes';

export default {
  dialog,
  table,
  pagination,
  filter,
  notes,
};
