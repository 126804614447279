import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/common/Alert';
import { cleanAWSMessage } from 'components/utils/errors';
import { injectIntl, intlShape } from 'react-intl';
import * as en from 'intl/en';
import AccessControl from 'components/common/base/AccessControl';

import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

const styles = {
  actionWrapper: {
    marginTop: 20,
    marginBottom: 20,
  },
};

class Form extends React.Component {
  cancel = () => {
    const { reset, cancel, initialize } = this.props;

    initialize(null);
    reset();
    if (cancel) {
      cancel();
    }
  }

  render() {
    const {
      error,
      handleSubmit,
      submitSucceeded,
      children,
      id,
      onSubmit,
      submitting,
      successMessage,
      errorMessage,
      anyTouched,
      intl,
      showCancel,
      submitBtnTxt,
      cancelBtnTxt,
      submitBtnStyle,
      hideSubmitBtn,
      roles,
      extraAccessData,
      accessCheck,
      pristine,
    } = this.props;
    const successTrnslt = intl.formatMessage({
      id: successMessage.message,
      defaultMessage: en[successMessage.message],
    }, successMessage.values);
    const errorTrnslt = errorMessage
      ? intl.formatMessage({
        id: errorMessage.message,
        defaultMessage: en[errorMessage.message],
      }, errorMessage.values)
      : null;

    return (
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        {!submitting && !anyTouched && submitSucceeded &&
          <Alert
            id="form--success"
            text={successTrnslt}
            type="success"
          />
        }
        {!submitting && error &&
          <Alert
            id="form--alert"
            text={cleanAWSMessage(error.message, error, intl) || errorTrnslt}
            type="danger"
          />
        }
        {children}
        <AccessControl
          roles={roles}
          extraAccessData={extraAccessData}
          accessCheck={accessCheck}
        >
          <div style={styles.actionWrapper}>
            {
              !hideSubmitBtn &&
              <PrimaryButton
                id={`btn--${id}-submit`}
                type="submit"
                disabled={pristine || submitting}
                customStyles={submitBtnStyle}
                message={submitBtnTxt}
              />
            }
            {
              showCancel &&
              <SecondaryButton
                id={`btn--${id}-cancel`}
                type="button"
                disabled={submitting}
                message={cancelBtnTxt}
                onClick={this.cancel}
              />
            }
          </div>
        </AccessControl>
      </form>
    );
  }
}

Form.propTypes = {
  intl: intlShape.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
  submitSucceeded: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  successMessage: PropTypes.object.isRequired,
  errorMessage: PropTypes.object,
  anyTouched: PropTypes.bool.isRequired,
  showCancel: PropTypes.any,
  reset: PropTypes.func.isRequired,
  submitBtnTxt: PropTypes.string,
  cancelBtnTxt: PropTypes.string,
  initialize: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  submitBtnStyle: PropTypes.object,
  hideSubmitBtn: PropTypes.bool,
  roles: PropTypes.array,
  extraAccessData: PropTypes.object,
  accessCheck: PropTypes.func,
  pristine: PropTypes.bool.isRequired,
};

Form.defaultProps = {
  submitBtnTxt: 'forms.common.done',
  cancelBtnTxt: 'forms.common.cancel',
};

export default injectIntl(Form);
