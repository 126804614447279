import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTheme } from '@material-ui/core/styles';
import {
  FormattedDate,
  FormattedMessage,
} from 'react-intl';
import * as en from 'intl/en';
import moment from 'moment';
import newGlobalStyles from 'components/styles/newGlobal.styles';
import getStyles from './Details.styles';

const defaultDateFormat = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
};

/**
 * Component which renders list of object fields
 * @param {Object} props Props passed from parent component
 * @returns {React.Component} Return component which contains data with applied styling
 */
const Details = props => {
  const { theme, data, items } = props;
  const globalStyles = newGlobalStyles(theme);
  const styles = getStyles(theme);

  return (
    <div
      style={{
        ...globalStyles.plainListItem.content.tagline,
        ...styles.wrapper,
      }}
    >
      {
        items.map((item, index) => {
          const lastElement = index + 1 === items.length
          ? null
          : <span>| &nbsp;</span>;

          let element = null;

          if (!data[item.key]) {
            element = '-';
          } else if (item.type === 'date') {
            const dateFormat = item.dateFormat || defaultDateFormat;

            element = (
              <FormattedDate
                value={moment.utc(data[item.key]).local()}
                {...dateFormat}
              />
            );
          } else {
            element = data[item.key];
          }

          return (
            <span
              key={item.key}
              style={{...styles.itemContainer}}
            >
              <span style={{...styles.fieldName}}>
                  <FormattedMessage
                    id={item.text}
                    defaultMessage={en[item.text]}
                  />:
              </span>
              &nbsp;
              {element}
              &nbsp;
              {lastElement}
            </span>
          );
        })
      }
    </div>
  );
};

Details.propTypes = {
  theme: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};

const enhance = compose(withTheme);

export default enhance(Details);
