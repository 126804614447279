export const required = value => (value || typeof value === 'number'
  ? undefined : { message: 'forms.validation.required' });

export const maxLength = max => value =>
  (value && value.length > max ? { message: 'forms.validation.maxLength', values: { max }} : undefined);

export const minLength = min => value =>
  (value && value.length < min ? { message: 'forms.validation.minLength', values: { min }} : undefined);

export const minLength2 = minLength(2);

export const number = value =>
  (value && Number.isNaN(Number(value)) ? { message: 'forms.validation.mustBeANumber'} : undefined);

export const minValue = min => value =>
  (value && value < min ? { message: 'forms.validation.minValue', values: { min }} : undefined);

export const minValue0 = minValue(0);

export const email = value =>
  (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? { message: 'forms.validation.invalidEmail' }
    : undefined);

export const tooYoung = value =>
  (value && value < 13
    ? { message: 'forms.validation.tooYoung' }
    : undefined);

export const alphaNumeric = value =>
  (value && /[^a-zA-Z0-9 ]/i.test(value)
    ? { message: 'forms.validation.alphanumeric' }
    : undefined);

export const phoneNumber = value =>
  (value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? { message: 'forms.validation.invalidPhone' }
    : undefined);

export const passwordMinLength = value =>
  (value && value.length < 8
    ? { message: 'forms.validation.passwordMinLength' }
    : undefined);

export const lowercaseCharacter = value =>
  (value && /[a-z]+/.test(value)
    ? undefined
    : { message: 'forms.validation.lowercaseCharacter' });

export const uppercaseCharacter = value =>
  (value && /[A-Z]+/.test(value)
    ? undefined
    : { message: 'forms.validation.uppercaseCharacter' });

export const numberCharacter = value =>
  (value && /[0-9]+/.test(value)
    ? undefined
    : { message: 'forms.validation.numberCharacter' });

export const specialCharacter = value =>
  (value && /[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/.test(value)
    ? undefined
    : { message: 'forms.validation.specialCharacter' });
