import React from 'react';
import PropTypes from 'prop-types';
import * as en from 'intl/en';
import { FormattedMessage } from 'react-intl';
import { Button, withStyles } from '@material-ui/core';
import getGlobalStyles from 'components/styles/newGlobal.styles';

const PrimaryButton = props => {
  const {
    customStyles,
    onClick,
    classes,
    message,
    fullWidth,
    ...rest
  } = props;

  return (
    <Button
      className={classes.primaryButton}
      fullWidth={fullWidth}
      style={{
        ...customStyles,
      }}
      onClick={onClick}
      {...rest}
    >
      <FormattedMessage
        id={message}
        defaultMessage={en[message]}
      />
    </Button>
  );
};

PrimaryButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  customStyles: PropTypes.object,
  message: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

PrimaryButton.defaultProps = {
  customStyles: {},
  fullWidth: true,
};

export default withStyles(getGlobalStyles)(PrimaryButton);
