import { call, put } from 'redux-saga/effects';
import { sagaRequestWithPayload } from 'components/utils/reduxHelpers';
import * as DeviceLogsService from '../services/deviceLogsService';
import {
  LOAD_DEVICE_LOGS,
  TOGGLE_BASE_STATION,
  DELETE_STATION_KEY,
} from '../actions/deviceLogsActions';

export function* loadDeviceLogs(action) {
  try {
    let result;

    if (action) {
      result = yield call(
        DeviceLogsService.loadDeviceLogs, action.selectedPage, action.perPage,
        action.sortField, action.sortType, action.criteria,
      );
    } else {
      result = yield call(DeviceLogsService.loadDeviceLogs);
    }

    yield put({ type: `${LOAD_DEVICE_LOGS}_SUCCESS`, result, payload: action });
  } catch (error) {
    yield put({ type: `${LOAD_DEVICE_LOGS}_FAILURE`, error });
  }
}

export function* toggleBaseStation(action) {
  yield* sagaRequestWithPayload(TOGGLE_BASE_STATION, DeviceLogsService.toggleBaseStation, action);
}

export function* deleteStationKey(action) {
  yield* sagaRequestWithPayload(DELETE_STATION_KEY, DeviceLogsService.deleteStationKey, action);
}
