import { combineReducers } from 'redux';

import complianceDetailsReducer from './complianceDetailsReducer';
import patientComplianceReducer from './patientComplianceReducer';
import patientDetailsReducer from './patientDetailsReducer';
import patientListReducer from './patientListReducer';

const patientsReducer = combineReducers({
  complianceDetails: complianceDetailsReducer,
  patientCompliances: patientComplianceReducer,
  patientDetails: patientDetailsReducer,
  patientList: patientListReducer,
});

export default patientsReducer;
