import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { withStyles, withTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import * as en from 'intl/en';
import Details from '../Details';

import styles from './PageHeader.styles';

const PageHeader = ({
  title, detailProps, classes, Icon, buttonTitle, onButtonClick, theme,
}) => (
    <div className={classes.pageHeader}>
      <Icon classes={{ root: classes.pageIcon }} htmlColor={theme.palette.textColor} />
      <div>
        <h1 className={classes.pageTitle}>
          <FormattedMessage id={title} defaultMessage={en[title]} />
        </h1>
        {detailProps &&
          <div className={classes.pageDetails}>
            <Details {...detailProps} />
          </div>
        }
      </div>
      {onButtonClick &&
        <Button className={classes.button} onClick={onButtonClick}>
          <FormattedMessage id={buttonTitle} defaultMessage={en[buttonTitle]} />
        </Button>
      }
    </div>
);

PageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  detailProps: PropTypes.object,
  onButtonClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  Icon: PropTypes.func.isRequired,
};

export default compose(
  withTheme,
  withStyles(styles),
)(PageHeader);
