export const LOAD_NOTES = 'LOAD_NOTES';
export const ADD_NOTE = 'ADD_NOTE';
export const CLEAR_NOTES_ERRORS = 'CLEAR_NOTES_ERRORS';
export const OPEN_NOTES = 'OPEN_NOTES';
export const CLOSE_NOTES = 'CLOSE_NOTES';

export function loadNotes(objectType, objectId) {
  return {
    type: LOAD_NOTES,
    objectType,
    objectId,
  };
}

export function addNote(objectType, objectId, note, extras) {
  return {
    type: ADD_NOTE,
    objectType,
    objectId,
    note,
    extras,
  };
}

export function clearNotesErrors() {
  return {
    type: CLEAR_NOTES_ERRORS,
  };
}

export function openNotes({ objectType, objectId, extras = null }) {
  return { type: OPEN_NOTES, payload: { objectType, objectId: objectId.toString(), extras }};
}

export function closeNotes() {
  return { type: CLOSE_NOTES };
}
