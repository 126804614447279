export const LOAD_PROFILE = 'LOAD_PROFILE';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const CLEAR_PROFILE_ERRORS = 'CLEAR_PROFILE_ERRORS';

export function loadProfile() {
  return {
    type: LOAD_PROFILE,
  };
}

export function saveProfile(data, promise) {
  return {
    type: SAVE_PROFILE,
    data,
    promise,
  };
}

export function clearProfileErrors() {
  return {
    type: CLEAR_PROFILE_ERRORS,
  };
}
