import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Alert from 'components/common/Alert';
import { cleanAWSMessage } from 'components/utils/errors';
import * as en from 'intl/en';

const AlertSelector = props => {
  const {
    error,
    success,
    successMessage,
    info,
    infoMessage,
    intl,
  } = props;

  let params = null;

  if (error) {
    params = {
      id: 'alert-error',
      type: 'danger',
      text: cleanAWSMessage(
        error.message,
        error,
        intl,
      ),
    };
  } else if (info) {
    params = {
      id: 'alert-info',
      type: 'primary',
      text: intl.formatMessage({
        id: infoMessage,
        defaultMessage: en[infoMessage],
      }),
    };
  } else if (success) {
    params = {
      id: 'alert-success',
      type: 'success',
      text: intl.formatMessage({
        id: successMessage,
        defaultMessage: en[successMessage],
      }),
    };
  }

  if (!params) {
    return null;
  }

  return (
    <Alert
      id={params.id}
      type={params.type}
      text={params.text}
    />
  );
};

AlertSelector.propTypes = {
  intl: intlShape.isRequired,
  error: PropTypes.object,
  success: PropTypes.bool,
  successMessage: PropTypes.string,
  info: PropTypes.bool,
  infoMessage: PropTypes.string,
};

export default injectIntl(AlertSelector);
