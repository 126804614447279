/* eslint max-len: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';

class SessionIcon extends Component {
  render() {
    const { filled, ...rest } = this.props;

    return (
      <SvgIcon
        style={{
          fill: filled ? this.props.theme.palette.backgroundColor : 'none',
          stroke: this.props.theme.palette.backgroundColor,
          strokeMiterlimit: 10,
          strokeWidth: 2,
        }}
        {...rest}
        viewBox="0 0 37.639 29"
      >
        <path
          d="M35.6.5H8.2A1.6,1.6,0,0,0,6.8,1.6L.5,26.6A1.544,1.544,0,0,0,2,28.5H35.6A1.538,1.538,0,0,0,37.1,27V2A1.538,1.538,0,0,0,35.6.5Z"
          transform="translate(0.039)"
        />
      </SvgIcon>
    );
  }
}

SessionIcon.propTypes = {
  filled: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      backgroundColor: PropTypes.string,
    }),
  }).isRequired,
};

export default withTheme(SessionIcon);
