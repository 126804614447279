import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  TableRow,
  withTheme,
} from '@material-ui/core';

import LotTableBody from './LotTableBody';

const ClosedLotTableBody = props => {
  const {
    data,
    deviceTypeId,
    getDeviceProp,
  } = props;

  const deviceCommercialName = getDeviceProp(deviceTypeId, 'device_commercial_name');
  const skuNumber = getDeviceProp(deviceTypeId, 'sku_number');

  return (
    <Fragment>
      {
        data.map(row => {
          return (
            <TableRow
              key={row.serial_number}
            >
              <LotTableBody
                row={row}
                deviceCommercialName={deviceCommercialName}
                skuNumber={skuNumber}
              />
            </TableRow>
          );
        })
      }
    </Fragment>
  );
};

ClosedLotTableBody.propTypes = {
  data: PropTypes.array.isRequired,
  deviceTypeId: PropTypes.number.isRequired,
  getDeviceProp: PropTypes.func.isRequired,
};

const enhance = compose(withTheme);

export default enhance(ClosedLotTableBody);
