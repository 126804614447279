import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';

import getStyles from './Keyword.styles';

class Keyword extends Component {
  render() {
    const { children, onCancel } = this.props;
    const styles = getStyles();

    return (
      <div style={styles.button}>
        <span style={styles.keyword}>{children}</span>
        <Icon
          className="material-icons"
          style={styles.icon}
          onClick={onCancel}
        >
          close
        </Icon>
      </div>
    );
  }
}

Keyword.propTypes = {
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Keyword;
