import theme from 'components/styles/default.theme';

const getStyles = () => ({
  pickerModal: {
    fontSize: '16px',
    color: 'rgba(102, 102, 102, 0.6)',
    padding: '0px 24px 24px',
    boxSizing: 'border-box',
    overflowY: 'hidden',
    borderTop: 'medium none',
    borderBottom: 'medium none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '712px',
    width: '768px',
    maxWidth: '768px',
  },
  exportButton: {
    width: '100%',
    padding: '0.2em',
    height: 'auto',
    cursor: 'pointer',
    backgroundColor: theme.palette.backgroundColor,
    color: theme.palette.secondaryTextColor,
    borderRadius: 50,
    marginTop: '20px',
    lineHeight: '36px',
  },
});

export default getStyles;
