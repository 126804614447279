import list from './en/list';
import details from './en/details';
import common from './en/common';
import pickup from './en/pickup';
import parcel from './en/parcel';

export default {
  list,
  details,
  common,
  pickup,
  parcel,
};
