export const LOAD_DEVICE_LOGS = 'LOAD_DEVICE_LOGS';
export const TOGGLE_BASE_STATION = 'TOGGLE_BASE_STATION';
export const DELETE_STATION_KEY = 'DELETE_STATION_KEY';

export function loadDeviceLogs(selectedPage, perPage, sortField, sortType, criteria) {
  return {
    type: LOAD_DEVICE_LOGS,
    selectedPage,
    perPage,
    sortField,
    sortType,
    criteria,
  };
}

export const toggleBaseStation = (deviceId, isEnabled) => {
  return {
    type: TOGGLE_BASE_STATION,
    deviceId,
    isEnabled,
  };
};

export const deleteStationKey = deviceId => {
  return {
    type: DELETE_STATION_KEY,
    deviceId,
  };
};
