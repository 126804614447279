import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_DEVICE_LOGS,
  TOGGLE_BASE_STATION,
  DELETE_STATION_KEY,
} from '../actions/deviceLogsActions';

const updateBaseStation = (state, action) => {
  return state.map(log => {
    if (log.device.device_id === action.payload.deviceId) {
      return {
        ...log,
        device: {
          ...log.device,
          device_config: {
            enable_basestation: action.payload.isEnabled,
          },
        },
      };
    }

    return log;
  });
};

const removeStationKey = (state, action) => {
  return state.map(log => {
    if (log.device.device_id === action.payload.deviceId) {
      return {
        ...log,
        device: {
          ...log.device,
          device_keys_state: null,
        },
      };
    }

    return log;
  });
};

const data = createReducer([], {
  [LOAD_DEVICE_LOGS]: () => ([]),
  [`${LOAD_DEVICE_LOGS}_SUCCESS`]: (state, action) => (action.result.data),
  [`${TOGGLE_BASE_STATION}_SUCCESS`]: updateBaseStation,
  [`${DELETE_STATION_KEY}_SUCCESS`]: removeStationKey,
});

const pages = createReducer([], {
  [`${LOAD_DEVICE_LOGS}_SUCCESS`]: (state, action) => (action.result.pages),
});

const total = createReducer(0, {
  [`${LOAD_DEVICE_LOGS}_SUCCESS`]: (state, action) => (action.result.total),
});

const asyncReducer = combineReducers({
  data: asyncReducerHelper(LOAD_DEVICE_LOGS),
  toggle: asyncReducerHelper(TOGGLE_BASE_STATION),
});

const asyncOverride = (state = {}, action) => {
  if (action.type === LOAD_DEVICE_LOGS) {
    return {
      data: { isProcessing: true },
      toggle: {},
    };
  }

  return asyncReducer(state, action);
};

const logsReducer = combineReducers({
  async: asyncOverride,
  data,
  pages,
  total,
});

export default logsReducer;
