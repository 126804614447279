export const LOAD_LOTS = 'LOAD_LOTS';
export const LOAD_LOT_DETAILS = 'LOAD_LOT_DETAILS';
export const CREATE_LOT = 'CREATE_LOT';
export const CLOSE_LOT = 'CLOSE_LOT';
export const OPEN_LOT = 'OPEN_LOT';

export const loadLots = (selectedPage, perPage, sortField, sortType, criteria) => {
  return {
    type: LOAD_LOTS,
    selectedPage,
    perPage,
    sortField,
    sortType,
    criteria,
  };
};

export const loadLotDetails = lotId => {
  return {
    type: LOAD_LOT_DETAILS,
    lotId,
  };
};

export const createLot = (lot, promise) => {
  return {
    type: CREATE_LOT,
    lot,
    promise,
  };
};

export const closeLot = (lotId, devices) => {
  return {
    type: CLOSE_LOT,
    lotId,
    devices,
  };
};

export const openLot = lotId => {
  return {
    type: OPEN_LOT,
    lotId,
  };
};
