import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import getGlobalStyles from 'components/styles/global.styles';
import * as en from 'intl/en';

import getStyles from './AddSku.styles';
import AddSkuForm from './AddSkuForm';

class AddSku extends Component {
  constructor(props) {
    super(props);

    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();
  }

  render() {
    const {
      isModalOpen,
      closeAddSku,
    } = this.props;

    return (
      <Dialog
        id="modal--add-sku"
        open={isModalOpen}
        onClose={closeAddSku}
        PaperProps={{ style: this.styles.modalBody }}
        style={this.styles.modal}
      >
        <div
          style={{
            ...this.globalStyles.pageHeader,
            ...this.styles.header,
          }}
        >
          <Icon
            className="material-icons"
            style={{
              ...this.globalStyles.pageIcon,
              ...this.styles.pageIcon,
            }}
          >
            add_circle_outline
          </Icon>
          <h1 style={{ ...this.globalStyles.pageTitle }}>
            <FormattedMessage
              id="inventory.sku.title"
              defaultMessage={en.inventory.sku.title}
            />
          </h1>
          <Icon
            className="material-icons"
            style={{ ...this.styles.closeButton, ...this.styles.closeIcon }}
            onClick={closeAddSku}
          >
            close
          </Icon>
        </div>
        <div
          id="add-sku__content"
          style={this.styles.content}
        >
          <AddSkuForm closeDialog={closeAddSku} />
        </div>
      </Dialog>
    );
  }
}

AddSku.propTypes = {
  isModalOpen: PropTypes.bool,
  closeAddSku: PropTypes.func.isRequired,
};

export default AddSku;
