import { combineReducers } from 'redux';
import _ from 'lodash';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_CLINICS,
  LOAD_CLINICS_WITHOUT_LOGO,
  ADD_CLINIC,
  UPDATE_CLINIC,
} from '../actions/clinicsActions';

export const BY_ID_DEFAULT_STATE = {};

const addOrUpdate = (state, action) => {
  return {
    ...state,
    [action.result.id]: action.result,
  };
};

const clinicsByKeyReducer = (acc, clinic) => ({
  ...acc,
  [clinic.id]: {
    ...clinic,
    logo: _.get(acc, 'id.logo') || clinic.logo,
  },
});

export const byId = createReducer(BY_ID_DEFAULT_STATE, {
  [`${LOAD_CLINICS}_SUCCESS`]: (state, action) => action.result.reduce(clinicsByKeyReducer, state),
  [`${LOAD_CLINICS_WITHOUT_LOGO}_SUCCESS`]: (state, action) => action.result.reduce(clinicsByKeyReducer, state),
  [`${ADD_CLINIC}_SUCCESS`]: addOrUpdate,
  [`${UPDATE_CLINIC}_SUCCESS`]: addOrUpdate,
});

export const loadInfo = asyncReducerHelper(LOAD_CLINICS);
export const loadInfoWithoutClinicLogo = asyncReducerHelper(LOAD_CLINICS_WITHOUT_LOGO);
export const createInfo = asyncReducerHelper(ADD_CLINIC);
export const updateInfo = asyncReducerHelper(UPDATE_CLINIC);

const clinicsListReducer = combineReducers({
  byId,
  loadInfo,
  loadInfoWithoutClinicLogo,
  createInfo,
  updateInfo,
});

export default clinicsListReducer;
