import {
  OPEN_NOTES,
  CLOSE_NOTES,
} from '../actions/notesActions';

export const DEFAULT_STATE = {
  isOpen: false,
  objectType: null,
  objectId: null,
  extras: null,
};

export function notesDialogReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case OPEN_NOTES:
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case CLOSE_NOTES:
      return {
        ...DEFAULT_STATE,
      };
    default:
      return state;
  }
}

export default notesDialogReducer;
