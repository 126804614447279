import { combineReducers } from 'redux';

import deviceCountReducer from './deviceCountReducer';
import orderInfoReducer from './orderInfoReducer';
import ordersReducer from './ordersReducer';
import ratesReducer from './ratesReducer';
import shipmentOrderReducer from './shipmentOrderReducer';

const shipmentsReducer = combineReducers({
  deviceCount: deviceCountReducer,
  orderInformation: orderInfoReducer,
  orders: ordersReducer,
  rates: ratesReducer,
  shipmentOrder: shipmentOrderReducer,
});

export default shipmentsReducer;
