import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTheme, withWidth } from '@material-ui/core';

import { mediaStyle } from 'components/utils/styles';
import BackIcon from 'components/styles/icons/BackIcon';
import PageWrapper from 'components/common/PageWrapper';
import PageHeader from 'components/common/PageHeader';
import PatientForm from 'components/patients/ui/PatientForm';

import getStyles from './CreatePatientPage.styles';

class CreatePatientPage extends React.Component {
  constructor(props, context) {
    super(props);

    this.styles = getStyles(props.theme);
  }

  render() {
    const { width } = this.props;

    return (
      <PageWrapper>
        <PageHeader title="CreatePatientPage.title" Icon={() => <BackIcon link="/patients" />} />
        <div style={mediaStyle(this.styles.content, width)}>
          <div style={this.styles.content.left}>
            <PatientForm />
          </div>
        </div>
      </PageWrapper>
    );
  }
}

CreatePatientPage.propTypes = {
  theme: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

const enhance = compose(
  withWidth(),
  withTheme,
);

export default enhance(CreatePatientPage);
