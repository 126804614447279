import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';

import { UNASSIGN_DEVICE_FORM } from 'components/common/forms/names';
import { TextField, Datepicker } from 'components/common/forms/elements';
import { required } from 'components/common/forms/validation';
import Form from 'components/common/forms/Form';

import { unassignDevice } from '../../../actions/devicesActions';

const successMessage = { message: 'operations.form.unassignDeviceSuccess' };
const errorMessage = { message: 'operations.form.unassignDeviceError' };

const UnassignForm = props => {
  const submit = values => new Promise((resolve, reject) => {
    const { _unassignDevice, deviceInventoryId } = props;

    _unassignDevice(values, deviceInventoryId, { resolve, reject });
  });

  return (
    <Form
      id={UNASSIGN_DEVICE_FORM}
      successMessage={successMessage}
      errorMessage={errorMessage}
      onSubmit={submit}
      {...props}
      pristine={false}
    >
      <TextField
        name="serial_number"
        label="operations.form.serialNumber"
        grayBackground
        disabled
        validate={required}
      />
      <TextField
        name="brighttree_id"
        label="operations.form.subjectId"
        grayBackground
        disabled
        validate={required}
      />
      <Datepicker
        name="unassign_date"
        label="operations.form.unassignDate"
        validate={required}
      />
    </Form>
  );
};

UnassignForm.propTypes = {
  _unassignDevice: PropTypes.func.isRequired,
  deviceInventoryId: PropTypes.number.isRequired,
};

const mapDispatchToProps = dispatch => ({
  _unassignDevice: (data, deviceInventoryId, promise) => {
    dispatch(unassignDevice(data, deviceInventoryId, promise));
  },
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: UNASSIGN_DEVICE_FORM,
  }),
);

export default enhance(UnassignForm);
