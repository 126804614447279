import axios from 'axios';
import moment from 'moment';

const setToken = function(token) {
  localStorage.setItem('token', token);
};

const getToken = function() {
  return localStorage.getItem('token') || null;
};

const setEmail = function(email) {
  localStorage.setItem('email', email);
};

const getEmail = function() {
  return localStorage.getItem('email') || null;
};

const setJWTDecoded = function(JWTDecoded) {
  localStorage.setItem('JWTDecoded', JWTDecoded);
};

const getJWTDecoded = function() {
  return JSON.parse(localStorage.getItem('JWTDecoded') || null);
};

const setSidebar = function(collapsed) {
  localStorage.setItem('sidebar', collapsed);
};

const getSidebar = function() {
  return localStorage.getItem('sidebar');
};

let defaultActionColumns = [
  { title: 'files.table.baseStation', key: 'base_station_sync_column', checked: true },
  { title: 'files.table.graphColumn', key: 'graph_column', checked: true },
  { title: 'files.table.downloadLogColumn', key: 'download_log_column', checked: true },
  { title: 'files.table.stationKeyColumn', key: 'station_key_column', checked: true },
];

const getActionColumnsRoleId = roleId => {
  const filterActionColumns = (begin, end) => {
    defaultActionColumns = [ // needed for testing purposes.
      { title: 'files.table.baseStation', key: 'base_station_sync_column', checked: true },
      { title: 'files.table.graphColumn', key: 'graph_column', checked: true },
      { title: 'files.table.downloadLogColumn', key: 'download_log_column', checked: true },
      { title: 'files.table.stationKeyColumn', key: 'station_key_column', checked: true },
    ];

    defaultActionColumns = defaultActionColumns.slice(begin, end);

    return JSON.parse(localStorage.getItem('actionColumns') || null) || defaultActionColumns;
  };

  switch (roleId) {
    case 2: { // clinical_coordinator
      return filterActionColumns(1, 3);
    }

    case 3: { // clinical_operation
      return filterActionColumns(1, 3);
    }

    case 4: { // regulatory
      return filterActionColumns(1, 3);
    }

    case 5: { // operations
      return filterActionColumns(1, 3);
    }

    case 6: { // clinical_sciences
      return filterActionColumns(1, 3);
    }

    case 8: { // UOS_cala(developer)
      return filterActionColumns(1, 3);
    }

    case 9: { // production
      return filterActionColumns(1, 3);
    }

    case 10: { // engineer
      return filterActionColumns(1, 3);
    }

    default: {
      return filterActionColumns(0, 4);
    }
  }
};

const setActionColumns = values => {
  localStorage.setItem('actionColumns', JSON.stringify(values));
};

const getActionColumns = () => (
  JSON.parse(localStorage.getItem('actionColumns') || null) || defaultActionColumns
);

const removeActionColumns = () => localStorage.removeItem('actionColumns');

const defaultLogTableColumns = [
  { title: 'files.table.deviceLogId', key: 'device_log_id', checked: true },
  { title: 'files.table.mac', key: 'device.mac', checked: true },
  { title: 'files.table.deviceId', key: 'device.device_id' },
  { title: 'files.table.subjectId', key: 'subject_id', checked: true },
  { title: 'files.table.serialNumber', key: 'device.serial_number' },
  { title: 'files.table.uploadDate', key: 'created_at', checked: true },
  { title: 'files.table.receivedTime', key: 'upload_date' },
  { title: 'files.table.firmware', key: 'firmware_version' },
  { title: 'files.table.logType', key: 'request_type' },
  { title: 'files.table.iccid', key: 'device.modem_iccid' },
  { title: 'files.table.imei', key: 'device.modem_imei' },
  { title: 'files.table.tla', key: 'device.sku' },
];

const setLogTableColumns = values => {
  localStorage.setItem('logTableColumns', JSON.stringify(values));
};

const getLogTableColumns = () => (
  JSON.parse(localStorage.getItem('logTableColumns') || null) || defaultLogTableColumns
);

const removeLogTableColumns = () => localStorage.removeItem('logTableColumns');

const purge = function() {
  localStorage.removeItem('token');
  localStorage.removeItem('email');
  localStorage.removeItem('sidebar');
  localStorage.removeItem('logTableColumns');
  localStorage.removeItem('actionColumns');
};

const isExpired = function(expiryMoment) {
  const now = moment().unix();

  return expiryMoment < now;
};

export default {
  get token() {
    return getToken();
  },
  set token(tokens) {
    setToken(tokens);
  },
  get email() {
    return getEmail();
  },
  set email(email) {
    setEmail(email);
  },
  get JWTDecoded() {
    return getJWTDecoded();
  },
  set JWTDecoded(JWTDecoded) {
    setJWTDecoded(JWTDecoded);
  },
  get sidebar_collapsed() {
    return getSidebar() === 'true';
  },
  set sidebar_collapsed(collapsed) {
    setSidebar(collapsed);
  },
  get expired() {
    const expiryMoment = this.token.ExpiresAt;

    return isExpired(expiryMoment);
  },
  get Tokenized() {
    const { token } = (this || {});

    return axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  purge,
  set actionColumns(values) {
    setActionColumns(values);
  },
  get actionColumns() {
    return getActionColumns();
  },
  defaultActionColumns,
  removeActionColumns,
  actionColumnsRoleId(roleId) {
    return getActionColumnsRoleId(roleId);
  },
  set logTableColumns(values) {
    setLogTableColumns(values);
  },
  get logTableColumns() {
    return getLogTableColumns();
  },
  defaultLogTableColumns,
  removeLogTableColumns,
};
