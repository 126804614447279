import theme from 'components/styles/default.theme';

const getStyles = () => ({
  bottomCenter: {
    position: 'absolute',
    bottom: -50,
    left: '45%',
    justifyContent: 'center',
    margin: 0,
  },
  topRight: {
    display: 'inline-flex',
    justifyContent: 'center',
    margin: 0,
  },
  iconButton: {
    background: theme.palette.backgroundColor,
    borderRadius: '20px',
    height: '30px',
    width: '30px',
    margin: '0 10px',
  },
  arrowIcon: {
    paddingTop: '3px',
    paddingLeft: '3px',
    color: 'white',
  },
  pageInput: {
    border: `solid 1px ${theme.palette.borderColor1}`,
    maxWidth: '40px',
    maxHeight: '30px',
    borderRadius: '30px',
    textAlign: 'center',
    margin: '0 10px',
  },
  inputStyle: {
    textAlign: 'center',
    margin: '0 auto',
  },
  selectedPageContainer: {
    display: 'inline-flex',
    marginRight: '10px',
    alignItems: 'center',
  },
  pageSelector: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  rightContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  leftContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: 'auto',
  },
});

export default getStyles;
