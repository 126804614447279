import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  map,
  switchMap,
  takeUntil,
  catchError,
} from 'rxjs/operators';
import Config from 'config';

import TokenCall from 'components/auth/services/token';
import {
  LOAD_QUARANTINE_LIST,
  LOAD_QUARANTINE_LIST_FAILURE,
  LOAD_QUARANTINE_ITEM,
  LOAD_QUARANTINE_ITEM_FAILURE,
  CANCEL_PENDING_REQUEST,
  quarantineListLoaded,
  quarantineItemLoaded,
} from '../actions/quarantineActions';

const { DEVICE_API_ROOT } = Config;

export const quarantineListEpic = action$ =>
  action$.pipe(
    ofType(LOAD_QUARANTINE_LIST),
    switchMap(action =>
      from(TokenCall.get(`${DEVICE_API_ROOT}/v0/quarantine`, { headers: action.headers }))
        .pipe(takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST))))),

    map(response => quarantineListLoaded(response.data)),
    catchError(error => of({
      type: LOAD_QUARANTINE_LIST_FAILURE,
      payload: error,
      error: true,
    })),
  );

export const quarantineItemEpic = action$ =>
  action$.pipe(
    ofType(LOAD_QUARANTINE_ITEM),
    switchMap(action =>
      from(TokenCall.get(`${DEVICE_API_ROOT}/v0/quarantine/${action.quarantine_log_id}`))
        .pipe(takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST))))),

    map(response => quarantineItemLoaded(response.data)),
    catchError(error => of({
      type: LOAD_QUARANTINE_ITEM_FAILURE,
      payload: error,
      error: true,
    })),
  );
