export const LOAD_DEVICE_TYPES = 'LOAD_DEVICE_TYPES';
export const ADD_DEVICE_TYPE = 'ADD_DEVICE_TYPE';
export const CLEAR_INVENTORY_ERRORS = 'CLEAR_INVENTORY_ERRORS';
export const UPDATE_DEVICE_TYPE = 'UPDATE_DEVICE_TYPE';
export const FETCH_DEVICE_STOCK = 'FETCH_DEVICE_STOCK';
export const FETCH_MULTIPLE_DEVICE_STOCK = 'FETCH_MULTIPLE_DEVICE_STOCK';
export const FETCH_SKU_LIST = 'FETCH_SKU_LIST';
export const ADD_SKU = 'ADD_SKU';

export function loadDeviceTypes(notification) {
  return {
    type: LOAD_DEVICE_TYPES,
    notification,
  };
}

export function addDeviceType(deviceType, promise) {
  return {
    type: ADD_DEVICE_TYPE,
    deviceType,
    promise,
  };
}

export function updateDeviceType(deviceType, promise) {
  return {
    type: UPDATE_DEVICE_TYPE,
    deviceType,
    promise,
  };
}

export function clearErrors() {
  return {
    type: CLEAR_INVENTORY_ERRORS,
  };
}

export function fetchDeviceStock(siteId, deviceTypeIds) {
  return {
    type: FETCH_DEVICE_STOCK,
    siteId,
    deviceTypeIds,
  };
}

export function fetchMultipleDeviceStock(sites) {
  return {
    type: FETCH_MULTIPLE_DEVICE_STOCK,
    sites,
  };
}

export function fetchSkuList(notification) {
  return {
    type: FETCH_SKU_LIST,
    notification,
  };
}

export function addSku(sku, promise) {
  return {
    type: ADD_SKU,
    sku,
    promise,
  };
}
