/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CheckIcon = props => (
    <SvgIcon
      {...props}
      viewBox="0 0 14.005 10.685"
    >
      <path
        d="M11.278,19.6,7.957,16.279,6.83,17.406l4.448,4.448L20.835,12.3,19.708,11.17Z"
        transform="translate(-6.83 -11.17)"
      />
    </SvgIcon>
);

export default CheckIcon;
