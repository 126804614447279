import React from 'react';
import * as en from 'intl/en';
import { FormattedMessage } from 'react-intl';
import getStyles from '../DeviceLogsTable.styles';

const TransferInfo = props => {
  const styles = getStyles();

  return (
    <div
      style={{
        ...styles.validTransfer.description,
      }}
    >
      <div
        style={{
          ...styles.validTransfer.description.title,
        }}
      >
        <FormattedMessage
          id="files.table.note"
          defaultMessage={en.files.table.note}
        />
      </div>
      <div
        style={{
          ...styles.validTransfer.description.textWrapper,
        }}
      >
        <div
          style={{
            ...styles.validTransfer.mark,
            ...styles.validTransfer.mark.isValid,
          }}
        />
        <div
          style={{
            ...styles.validTransfer.description.textWrapper.content,
          }}
        >
          <FormattedMessage
            id="files.table.passedUpload"
            defaultMessage={en.files.table.passedUpload}
          />
        </div>
      </div>
      <div
        style={{
          ...styles.validTransfer.description.textWrapper,
        }}
      >
        <div
          style={{
            ...styles.validTransfer.mark,
            ...styles.validTransfer.mark.isInvalid,
          }}
        />
        <div
          style={{
            ...styles.validTransfer.description.textWrapper.content,
          }}
        >
          <FormattedMessage
            id="files.table.failedUpload"
            defaultMessage={en.files.table.failedUpload}
          />
        </div>
      </div>
      <div
        style={{
          ...styles.validTransfer.description.textWrapper,
        }}
      >
        <div
          style={{
            ...styles.validTransfer.mark,
          }}
        />
        <div
          style={{
            ...styles.validTransfer.description.textWrapper.content,
          }}
        >
          <FormattedMessage
            id="files.table.undefinedUpload"
            defaultMessage={en.files.table.undefinedUpload}
          />
        </div>
      </div>
    </div>
  );
};

export default TransferInfo;
