export default {
  deviceId: 'DEVICE ID',
  firmware: 'FIRMWARE',
  uploadDate: 'UPLOAD DATE',
  receivedTime: 'RECEIVED TIME',
  downloadFiles: 'DOWNLOAD FILES',
  mac: 'MAC',
  deviceLogId: 'DEVICE LOG ID',
  serialNumber: 'SERIAL #',
  patientId: 'PATIENT ID',
  subjectId: 'SUBJECT ID',
  baseStation: 'BASE STATION SYNC',
  noteColumn: 'NOTE BUTTON',
  graphColumn: 'GRAPH BUTTON',
  downloadLogColumn: 'DOWNLOAD BUTTON',
  stationKeyColumn: 'STATION KEY BUTTON',
  newLogs: 'New data logs uploaded',
  note: 'Note',
  passedUpload: 'Indicates passed upload',
  failedUpload: 'Indicates failed upload',
  undefinedUpload: 'No dot indicates upload not validated',
  imei: 'IMEI',
  iccid: 'ICCID',
  tla: 'TLA',
  baseStationSync: 'Base station sync is enabled',
  phoneSync: 'Phone sync is enabled',
  noLogs: 'No logs',
  logType: 'Log type',
};
