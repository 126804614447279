const getStyles = theme => ({
  header: {
    height: 'auto',
    maxHeight: '50px',
    minWidth: 500,
  },
  closeButton: {
    marginLeft: 'auto',
    color: 'white',
    background: '#C2C2C2',
    borderRadius: '50px',
    cursor: 'pointer',
  },
  content: {
    margin: '25px 35px',
  },
  containerDivider: {
    borderBottom: `1px solid ${theme.palette.borderColor1}`,
    borderTop: `1px solid ${theme.palette.borderColor1}`,
    background: theme.palette.secondaryBackgroundColor,
    color: theme.palette.textColor,
    padding: '10px 30px',
  },
  title: {
    marginLeft: 20,
  },
});

export default getStyles;
