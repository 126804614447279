import {
  LOAD_VERSION,
  LOAD_VERSION_SUCCESS,
  LOAD_VERSION_FAILURE,
} from 'components/version/actions/version';
import initialState from './initialState';

function versionReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_VERSION:
      return Object.assign({}, state, {
        ...initialState,
        isLoading: true,
        isLoaded: false,
        loadError: null,
      });
    case LOAD_VERSION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isLoaded: true,
        services: {
          ...action.result,
        },
      });
    case LOAD_VERSION_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isLoaded: true,
        loadError: action.error,
      });
    default:
      return state;
  }
}

export default versionReducer;
