import { ROLES } from 'components/users/utils/userRoles';

export default {
  createUser: [ROLES.ADMIN, ROLES.OUS_CALA, ROLES.CLINICAL_OPERATION, ROLES.OPERATIONS],
  showUserManagement: [
    ROLES.ADMIN,
    ROLES.CLINICAL_OPERATION,
    ROLES.REGULATORY,
    ROLES.CLINICAL_SCIENCES,
    ROLES.OUS_CALA,
  ],
};
