import { combineReducers } from 'redux';
import { createReducer } from 'components/utils/reduxHelpers';

import {
  OPEN_DEVICE_FIRMWARE_DIALOG,
  CLOSE_DEVICE_FIRMWARE_DIALOG,
  OPEN_DEFAULT_FIRMWARE_DIALOG,
  CLOSE_DEFAULT_FIRMWARE_DIALOG,
} from '../actions/uiActions';

import {
  UNASSIGN_FIRMWARE,
  DEFAULT_FIRMWARE,
} from '../actions/firmwareActions';

export const DEVICE_FIRMWARE_DIALOG_DEFAULT_STATE = {
  isOpen: false,
  action: 'add',
  firmware: { id: 0, device_type: 'B' },
  serialNumber: null,
};

export const deviceFirmwareDialog = createReducer(DEVICE_FIRMWARE_DIALOG_DEFAULT_STATE, {
  [OPEN_DEVICE_FIRMWARE_DIALOG]: (state, action) => ({ ...action.payload, isOpen: true }),
  [CLOSE_DEVICE_FIRMWARE_DIALOG]: () => ({ ...DEVICE_FIRMWARE_DIALOG_DEFAULT_STATE }),
  [`${UNASSIGN_FIRMWARE}_SUCCESS`]: () => ({ ...DEVICE_FIRMWARE_DIALOG_DEFAULT_STATE }),
});

export const DEFAULT_FIRMWARE_DIALOG_DEFAULT_STATE = {
  isOpen: false,
  firmware: { id: 0, device_type: 'B' },
};

export const defaultFirmwareDialog = createReducer(DEFAULT_FIRMWARE_DIALOG_DEFAULT_STATE, {
  [OPEN_DEFAULT_FIRMWARE_DIALOG]: (state, action) => ({ ...action.payload, isOpen: true }),
  [CLOSE_DEFAULT_FIRMWARE_DIALOG]: () => ({ ...DEFAULT_FIRMWARE_DIALOG_DEFAULT_STATE }),
  [`${DEFAULT_FIRMWARE}_SUCCESS`]: () => ({ ...DEFAULT_FIRMWARE_DIALOG_DEFAULT_STATE }),
});

const uiReducer = combineReducers({
  deviceFirmwareDialog,
  defaultFirmwareDialog,
});

export default uiReducer;
