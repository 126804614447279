import { ROLES } from 'components/users/utils/userRoles';

export default {
  downloadLog: [
    ROLES.ADMIN,
    ROLES.OUS_CALA,
    ROLES.CLINICAL_OPERATION,
    ROLES.OPERATIONS,
    ROLES.CLINICAL_SCIENCES,
    ROLES.REGULATORY,
    ROLES.PRODUCTION,
    ROLES.ENGINEER,
  ],
  createNote: [
    ROLES.ADMIN,
    ROLES.CLINICAL_COORDINATOR,
    ROLES.CLINICAL_OPERATION,
    ROLES.REGULATORY,
    ROLES.OPERATIONS,
    ROLES.CLINICAL_SCIENCES,
    ROLES.OUS_CALA,
  ],
  baseStation: [
    ROLES.ADMIN,
  ],
};
