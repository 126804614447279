export default {
  studyAcronym: 'Study Acronym',
  protocolNumber: 'Protocol Number',
  statusOfStudy: 'Status of Study',
  numberOfPatients: 'Number of Patients',
  numberOfSites: 'Number of Sites',
  devicesToBeUsed: 'Devices to be Used',
  createStudy: 'Create Study',
  siteNumber: 'Site Number',
  principalInvestigator: 'Principal Investigator',
  contactPerson: 'Main Shipping Contact Person',
  saveStudy: 'Save Study',
  addSite: 'Add a Site',
  manageSiteSuccess: 'Site updated',
  manageSiteError: 'Manage Site Error',
  updateStudySuccess: 'Update Study Success',
  updateStudyError: 'Update Study Error',
  createStudySuccess: 'Create Study Success',
  createStudyError: 'Create Study Error',
  min: 'Min',
  max: 'Max',
  activationDate: 'Activation Date',
  missingDevices: 'Device missing. Please add a device to the study',
  nameAlreadyTaken: 'Study already exists. Please change study name',
  shipping: {
    address: 'Shipping Address',
    city: 'City',
    country: 'Country',
    ext: 'Shipping Address 2',
    state: 'State',
    zip: 'ZIP',
  },
  phoneNumber: 'Phone number',
};
