import { combineReducers } from 'redux';

import deviceTypeReducer from './deviceTypeReducer';
import deviceStockReducer from './deviceStockReducer';
import skuReducer from './skuReducer';

const inventoryReducer = combineReducers({
  deviceType: deviceTypeReducer,
  deviceStock: deviceStockReducer,
  skus: skuReducer,
});

export default inventoryReducer;
