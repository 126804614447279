import theme from 'components/styles/default.theme';

const getStyles = () => ({
  container: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 1200,
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    header: {
      borderBottom: 'none',
    },
    headerRow: {
      borderBottom: '1px solid #A0A0A0',
    },
  },
  graphButton: {
    color: theme.palette.backgroundColor,
  },
  downloadButton: {
    color: theme.palette.backgroundColor,
  },
  noteButton: {
    color: theme.palette.backgroundColor,
  },
  key: {
    color: theme.palette.backgroundColor,
  },
  keyDialogConfirmBtn: {
    borderRadius: '20px',
    backgroundColor: theme.palette.backgroundColor,
  },
  keyDialogCancelBtn: {
    borderRadius: '20px',
  },
  dialog: {
    height: '100%',
    width: '90%',
    maxWidth: 'none',
  },
  tableCell: {
    textAlign: 'center',
  },
  newDeviceLogs: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 50px',
    marginBottom: '20px',
    background: '#E8F7FC',
    color: '#0F92B9',
    fontSize: '15px',
    letterSpacing: '1px',
    cursor: 'pointer',
    textShadow: '0.3px 0.3px',
  },
  checkIcon: {
    marginRight: '15px',
  },
  newLog: {
    background: '#EFFAFD',
  },
  validTransfer: {
    description: {
      margin: 30,
      background: '#FCE8A7',
      border: `1px solid #DDC881`,
      color: '#A69249',
      padding: 20,
      fontSize: theme.size.body,
      fontWeight: theme.font.bold,
      borderRadius: 5,
      title: {
        textAlign: 'center',
        textTransform: 'uppercase',
      },
      textWrapper: {
        marginTop: 10,
        textAlign: 'left',
        display: 'flex',
        content: {
          display: 'inline-block',
          marginLeft: 10,
        },
      },
    },
    column: {
      height: 50,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 27,
      paddingRight: 0,
    },
    mark: {
      borderRadius: '50%',
      width: 15,
      height: 15,
      display: 'inline-block',
      isValid: {
        background: '#49b3e5',
      },
      isInvalid: {
        background: '#ee3755',
      },
    },
  },
  tableWrapper: {
    position: 'relative',
    width: '100%',
  },
  tableScroller: {
    overflowX: 'auto',
    overflowY: 'visible',
  },
  paginationContainer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  switchWrapper: {
    display: 'inline-flex',
    width: '62px',
  },
  spinner: {
    center: {
      left: '50%',
      top: 50,
      position: 'relative',
    },
    progress: {
      color: theme.palette.backgroundColor,
    },
    textHolder: {
      color: theme.palette.textColor,
    },
  },
});

export default getStyles;
