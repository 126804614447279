import React from 'react';
import PropTypes from 'prop-types';
import { Column } from 'react-virtualized';
import { IconButton, TableCell } from '@material-ui/core';

import { showActionColumn } from '../../utils/helpers';
import { graphKey } from '../../utils/constants';

const GraphColumn = ({ styles, showDialog, actionColumns }) => {
  const isEnabled = showActionColumn(actionColumns, graphKey);

  return (
    isEnabled ? (
      <Column
        headerRenderer={headerProps => (
          <TableCell
            component="div"
            variant="head"
            style={{ border: 'none' }}
          >
            <div style={{ width: 58, height: 50 }} />
          </TableCell>
        )}
        dataKey={graphKey}
        width={58}
        cellRenderer={cellProps => {
        return (
          <TableCell
            component="div"
            variant="body"
            style={{ border: 'none' }}
          >
            <IconButton
              id={`button__graph__${cellProps.rowIndex}`}
              className="material-icons"
              data-logid={cellProps.rowData.device_log_id}
              onClick={showDialog(cellProps.rowData)}
              disableRipple
            >
              visibility
            </IconButton>
          </TableCell>
        );
      }}
        headerStyle={{ width: 58, margin: 0 }}
        style={{ margin: 0 }}
      />
    ) : null
  );
};

GraphColumn.propTypes = {
  styles: PropTypes.object.isRequired,
  showDialog: PropTypes.func.isRequired,
  actionColumns: PropTypes.array.isRequired,
};

export default GraphColumn;
