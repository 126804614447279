/* eslint max-len:0 */
export default {
  resetPassword: 'Reset your password',
  enterInformation: 'Enter your information below.',
  forgotPassword: 'Forgot your password?',
  enterEmail: 'Enter your email address below and we will send you a secure link to reset our password.',
  sentEmail: 'We just sent an email to',
  newPasswordSet: 'Your new password has been set.',
  redirectingIn: 'Redirecting in',
  checkSpam:
    'Click the secure link we sent you to reset your password. If you didn\'t receive an email, check your Spam folder.',
};
