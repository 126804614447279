import { flattenObject } from 'components/utils/helpers';
import * as en from 'intl/en';
import * as es from 'intl/es';

const messages = {
  en: flattenObject(en),
  es: flattenObject(es),
};

export const fetchMessages = locale => messages[locale];

export const getUserLocale = () =>
// TODO: we need to decide where to store this information
  'en';

