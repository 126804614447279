export default {
  custom: 'Custom Size',
  FedExEnvelope: 'Envelop',
  FedExSmallBox: 'Small Box',
  FedExMediumBox: 'Medium Box',
  FedExLargeBox: 'Large Box',
  FedExExtraLargeBox: 'Extra Large Box',
  FedExPak: 'Pak',
  FedExTube: 'Tube',
  FedEx10kgBox: '10kg Box',
  FedEx25kgBox: '25kg Box',
};
