import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  injectIntl,
  intlShape,
  FormattedMessage,
} from 'react-intl';
import { withStyles } from '@material-ui/core';
import NewUploadIcon from 'components/styles/icons/NewUploadIcon';
import Alert from 'components/common/Alert';
import GraphModal from 'components/graph/GraphModal';
import {
  enterOrSpacePressed,
  intlWord,
} from 'components/utils/helpers';
import wsEvents from 'config/wsEvents';
import Session from 'components/utils/session';
import PageSelector from 'components/common/pagination/PageSelector';
import listWrapper from 'components/common/base/ListWrapper';
import PerPage from 'components/common/pagination/PerPage';
import AlertSelector from 'components/common/AlertSelector';
import { enqueueSnackbar } from 'components/common/Notifier/actions';
import { SNACKBAR_VARIANTS, NO_AVAILABLE_DATES } from 'components/common/Notifier/utils/constants';
import { loadAvailableDates } from 'components/graph/services/graphService';

import * as _ from 'lodash';
import * as en from 'intl/en';

import SearchBar from 'components/common/search/SearchBar';
import { loadDeviceLogs, toggleBaseStation } from './actions/deviceLogsActions';
import { downloadDeviceLog } from './services/deviceLogsService';
import { deviceLogsShape } from './utils/DeviceLogs.props';
import getStyles from './DeviceLogsTable.styles';
import VirtualizedDeviceLogsTable from './VirtualizedTable/VirtualizedDeviceLogsTable';

class DeviceLogsTable extends Component {
  constructor(props, context) {
    super(props);

    this.styles = getStyles();
    this.style = { border: 'none', paddingRight: 2, width: '100%', flex: 1 };
    this.roleId = Session.JWTDecoded.role_id;
    const tableFields = Session.logTableColumns.map(field => ({ ...field, style: this.style }));
    const actionColumnsByRole = Session.actionColumnsRoleId(Session.JWTDecoded.role_id);
    const actionColumns = actionColumnsByRole.map(field => ({ ...field, style: this.style }));

    this.state = {
      graph: {
        show: false,
        id: null,
      },
      error: null,
      newLogs: false,
      logsToHighlight: [],
      logs: [],
      tableFields,
      actionColumns,
      noAvailableDatesMessage: null,
    };

    this.FIELDS = [
      { text: intlWord(this.props.intl, 'files.filter.deviceLogId'), key: 'device_log_id' },
      { text: intlWord(this.props.intl, 'files.filter.firmware'), key: 'firmware_version' },
      { text: intlWord(this.props.intl, 'files.filter.logType'), key: 'request_type' },
      { text: intlWord(this.props.intl, 'files.filter.deviceId'), key: 'device_id' },
      { text: intlWord(this.props.intl, 'files.filter.serialNumber'), key: 'serial_number' },
      { text: intlWord(this.props.intl, 'files.filter.mac'), key: 'mac' },
      { text: intlWord(this.props.intl, 'files.filter.subjectId'), key: 'subject_id' },
      { text: intlWord(this.props.intl, 'files.filter.errorCode'), key: 'description' },
      { text: intlWord(this.props.intl, 'files.filter.errorDescription'), key: 'description_desc' },
      { text: intlWord(this.props.intl, 'files.filter.eventCode'), key: 'patient_event' },
      { text: intlWord(this.props.intl, 'files.filter.eventDescription'), key: 'patient_event_desc' },
      { text: intlWord(this.props.intl, 'files.filter.modemIccid'), key: 'modem_iccid' },
      { text: intlWord(this.props.intl, 'files.filter.modemImei'), key: 'modem_imei' },
      { text: intlWord(this.props.intl, 'files.filter.tla'), key: 'sku' },
    ];
  }

  componentWillMount() {
    if (Session.defaultLogTableColumns.length !== this.state.tableFields.length) {
      Session.removeLogTableColumns();
      const tableFields = Session.logTableColumns.map(field => ({ ...field, style: this.style }));

      this.setState({ tableFields });
    }

    if (Session.defaultActionColumns.length !== this.state.actionColumns.length) {
      Session.removeActionColumns();
      const actionColumnsByRole = Session.actionColumnsRoleId(Session.JWTDecoded.role_id);
      const actionColumns = actionColumnsByRole.map(field => ({ ...field, style: this.style }));

      this.setState({ actionColumns });
    }

    const config = {
      perPage: 25,
      selectedPage: 1,
      key: 'device_log_id',
      direction: -1,
    };

    this.props.loadDeviceLogs(config.selectedPage, config.perPage, config.key, config.direction);
    this.props.setSort({ key: config.key, direction: config.direction });
    this.props.setPagination({ perPage: config.perPage, selectedPage: config.selectedPage });
    this.props.setUpdateListFunction(this.updateList);
  }

  componentDidMount() {
    this.newLogFileNames = [];
    this.context.socket.on(wsEvents.NEW_DEVICE_LOG_AVAILABLE, data => {
      this.newLogFileNames.push(data.logFileName);
      this.setState({
        newLogs: true,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    const { pages, data } = nextProps.deviceLogs.logs;

    this.setState({ logs: data });
    if (pages) {
      this.setState({
        numberOfPages: pages.length,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      criteria: prevCriteria,
    } = prevProps;
    const { sort, criteria, pagination } = this.props;

    if (!_.isEqual(criteria, prevCriteria)) {
      this.props.setPagination({ selectedPage: 1 });
      this.props.loadDeviceLogs(
        pagination.selectedPage,
        pagination.perPage,
        this.parseKey(sort.key),
        sort.direction,
        criteria,
      );
    }
  }

  updateList = (sort, pagination, criteria) => {
    const { loadDeviceLogs: _loadDeviceLogs } = this.props;

    _loadDeviceLogs(pagination.selectedPage, pagination.perPage, this.parseKey(sort.key), sort.direction, criteria);
  }

  refreshDeviceLogs = e => {
    if (!enterOrSpacePressed(e)) {
      return;
    }
    const {
      criteria,
      sort,
      pagination,
    } = this.props;

    this.setState({
      newLogs: false,
      logsToHighlight: [...this.newLogFileNames],
    });
    this.newLogFileNames = [];

    this.updateList(sort, pagination, criteria);
  }

  parseKey = key => {
    return key.includes('.')
      ? _.last(key.split('.'))
      : key;
  }

  downloadFile = async e => {
    this.setState({
      error: null,
    });
    const id = e.currentTarget.dataset.logid;
    const fileName = e.currentTarget.dataset.filename;
    const response = await downloadDeviceLog(id);
    const { logsToHighlight } = this.state;

    if (response.message) {
      this.props._enqueueSnackbar({
        message: response,
        options: { variant: SNACKBAR_VARIANTS.error },
        disableRetry: true,
      });
    } else {
      window.location.assign(response.data.download_url);
    }

    _.pull(logsToHighlight, fileName);
    this.setState({
      logsToHighlight,
    });
  };

  showDialog = deviceLog => async e => {
    this.setState({
      noAvailableDatesMessage: null,
    });
    const { logsToHighlight } = this.state;
    const availableDates = deviceLog
      ? await loadAvailableDates(deviceLog.device_log_id)
      : [];
    _.pull(logsToHighlight, deviceLog.file_link);
    if (availableDates.length > 0) {
      this.setState({
        graph: {
          show: true,
          deviceLog,
        },
      });
    } else {
      this.setState({
        noAvailableDatesMessage: NO_AVAILABLE_DATES,
      });
    }
  };

  closeDialog = () => {
    this.setState({
      graph: {
        show: false,
        deviceLog: null,
      },
      noAvailableDatesMessage: null,
    });
  };

  updateColumns = tableFields => {
    Session.logTableColumns = tableFields;
    this.setState({
      tableFields,
    });
  }

  updateActionColumns = actionColumns => {
    Session.actionColumns = actionColumns;
    this.setState({
      actionColumns,
    });
  }

  toggleBaseStationSwitch = (id, checked) => {
    const { _toggleBaseStation } = this.props;

    _toggleBaseStation(id, checked);
  }

  render() {
    const {
      deviceLogs,
      sort,
      sortChange,
      searchChange,
      selectedPageChange,
      pagination,
      perPageChange,
      intl,
    } = this.props;
    const {
      error,
      graph,
      newLogs,
      logsToHighlight,
      numberOfPages,
      logs,
      tableFields,
      actionColumns,
      noAvailableDatesMessage,
    } = this.state;

    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <AlertSelector error={deviceLogs.logs.async.toggle.error} />
        {
          newLogs &&
          <div
            style={this.styles.newDeviceLogs}
            onClick={this.refreshDeviceLogs}
            onKeyUp={this.refreshDeviceLogs}
            role="button"
            tabIndex={0}
          >
            <NewUploadIcon
              style={this.styles.checkIcon}
            />
            <FormattedMessage
              id="files.table.newLogs"
              defaultMessage={en.files.table.newLogs}
            />
          </div>
        }
        <SearchBar
          onSearch={searchChange}
          fields={this.FIELDS}
        />
        <div
          id="table-wrapper"
          style={this.styles.tableWrapper}
        >
          <GraphModal
            isModalOpen={graph.show}
            deviceLog={graph.deviceLog}
            closeDialog={this.closeDialog}
          />
          {error && <Alert id="alert--error" text={error} type="danger" />}
        {noAvailableDatesMessage && this.props._enqueueSnackbar({
          message: noAvailableDatesMessage,
          options: { variant: SNACKBAR_VARIANTS.error },
          disableRetry: true,
        })}
          <div
            style={{
              ...this.styles.paginationContainer,
              display: 'flex',
            }}
          >
            <PerPage
              perPage={pagination.perPage}
              selectedPage={pagination.selectedPage}
              perPageChange={perPageChange}
              numberOfPages={numberOfPages}
              numberOfRecords={deviceLogs.logs.total}
              style={this.styles.perPage}
            />
            <PageSelector
              selectedPage={pagination.selectedPage}
              selectedPageChange={selectedPageChange}
              numberOfPages={numberOfPages}
              position="topRight"
            />
          </div>
          <div
            style={this.styles.tableScroller}
          >
            <VirtualizedDeviceLogsTable
              headerColumns={tableFields}
              actionColumns={actionColumns}
              rows={logs}
              onSort={sortChange}
              sortDirection={sort.direction}
              activeField={sort.key}
              styles={this.styles}
              showDialog={this.showDialog}
              downloadFile={this.downloadFile}
              intl={intl}
              updateColumns={this.updateColumns}
              updateActionColumns={this.updateActionColumns}
              toggleBaseStationSwitch={this.toggleBaseStationSwitch}
              isLoading={deviceLogs.logs.async.data.isProcessing}
              logsToHighlight={logsToHighlight}
              roleId={this.roleId}
              notSortable={logs.length === 0}
            />
          </div>
          {logs.length > 0 &&
            <div style={{ display: 'flex' }}>
              <PageSelector
                selectedPage={pagination.selectedPage}
                selectedPageChange={selectedPageChange}
                numberOfPages={numberOfPages}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

DeviceLogsTable.propTypes = {
  intl: intlShape.isRequired,
  loadDeviceLogs: PropTypes.func.isRequired,
  deviceLogs: deviceLogsShape,
  setSort: PropTypes.func.isRequired,
  sortChange: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  selectedPageChange: PropTypes.func.isRequired,
  perPageChange: PropTypes.func.isRequired,
  setUpdateListFunction: PropTypes.func.isRequired,
  searchChange: PropTypes.func.isRequired,
  _toggleBaseStation: PropTypes.func.isRequired,
  _enqueueSnackbar: PropTypes.func.isRequired,
  criteria: PropTypes.object,
};

DeviceLogsTable.contextTypes = {
  socket: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  deviceLogs: state.deviceLogs,
});

const mapDispatchToProps = dispatch => ({
  loadDeviceLogs: (selectedPage, perPage, sortField, sortType, criteria) => {
    dispatch(loadDeviceLogs(selectedPage, perPage, sortField, sortType, criteria));
  },
  _toggleBaseStation: (id, checked) => {
    dispatch(toggleBaseStation(id, checked));
  },
  _enqueueSnackbar: notification => {
    dispatch(enqueueSnackbar(notification));
  },
});

const enhance = compose(
  withStyles(getStyles),
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  listWrapper({
    displayName: 'DeviceLogsTable',
  }),
);

export default enhance(DeviceLogsTable);
