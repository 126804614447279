import { combineReducers } from 'redux';

import infoReducer from './infoReducer';
import asyncReducer from './asyncReducer';

const profileReducer = combineReducers({
  info: infoReducer,
  async: asyncReducer,
});

export default profileReducer;
