import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Tooltip } from '@material-ui/core';

import Message from 'components/common/Message';
import styles from './Compliance.styles';

const ExtraSessions = props => {
  const { extra, classes } = props;

  let element = null;

  if (extra) {
    element = (
      <Tooltip title={<Message id="patients.compliance.extraSessions" />}>
        <div className={classes.extraWrapper}>
          {`+${extra}`}
        </div>
      </Tooltip>
    );
  }

  return (
    <td align="middle">
      {element}
    </td>
  );
};

ExtraSessions.propTypes = {
  classes: PropTypes.object.isRequired,
  extra: PropTypes.number,
};

export default withStyles(styles)(ExtraSessions);
