
export const detailItems = [
  {
    text: 'operations.details.lotNumber',
    key: 'lot_name',
  },
  {
    text: 'operations.details.partNumber',
    key: 'skuNumber',
  },
  {
    text: 'operations.details.type',
    key: 'deviceName',
  },
  {
    text: 'operations.details.quantity',
    key: 'device_count',
  },
  {
    text: 'operations.details.dateOpen',
    key: 'created_date',
    type: 'date',
  },
  {
    text: 'operations.details.creator',
    key: 'created_by',
  },
  {
    text: 'operations.details.dateClose',
    key: 'close_date',
    type: 'date',
  },
];

const sharedHeaders = [
  {
    key: 'qty',
  },
  {
    key: 'sn',
    text: 'operations.details.sn',
  },
];

export const bsHeaders = [
  ...sharedHeaders,
  {
    key: 'mac_address',
    text: 'operations.details.mac',
  },
  {
    key: 'imei',
    text: 'operations.details.imei',
  },
  {
    key: 'iccid',
    text: 'operations.details.iccid',
  },
];

export const stimHeaders = [
  ...sharedHeaders,
  {
    key: 'mac_address',
    text: 'operations.details.mac',
  },
  {
    key: 'psoc',
    text: 'operations.details.psoc',
  },
  {
    key: 'nrf',
    text: 'operations.details.nrf',
  },
  {
    key: 'glyph',
    text: 'operations.details.glyph',
  },
];

export const bandHeaders = [
  ...sharedHeaders,
  {
    key: 'size',
    text: 'operations.details.description',
  },
];
