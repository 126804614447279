import Config from 'config';
import { getResponseData } from 'components/utils/asyncHelpers';

const {
  INVENTORY_API_ROOT,
  DEVICE_API_ROOT,
  SHIPPING_API_ROOT,
} = Config;

export const loadDeviceTypes = ({ selectedPage = 1, perPage = 200 }) => {
  const headers = {
    'X-Per-Page': perPage,
    'X-Page': selectedPage,
  };

  return getResponseData('get', `${INVENTORY_API_ROOT}/v0/device_types`, { headers });
};

export const addDeviceType = ({ deviceType }) =>
  getResponseData('post', `${INVENTORY_API_ROOT}/v0/device_types`, deviceType);

export const updateDeviceType = ({ deviceType }) =>
  getResponseData('put', `${INVENTORY_API_ROOT}/v0/device_types/${deviceType.device_type_id}`, deviceType);

export const loadDevices = () =>
  getResponseData('get', `${DEVICE_API_ROOT}/v0/device`);

export const placeOrder = order =>
  getResponseData('post', `${SHIPPING_API_ROOT}/orders`, order);

export const fetchDeviceStock = ({ siteId, deviceTypeIds }) =>
  getResponseData('post', `${INVENTORY_API_ROOT}/v0/stock/${siteId}`, { device_type_ids: deviceTypeIds });

export const fetchSkuList = () =>
  getResponseData('get', `${INVENTORY_API_ROOT}/v0/device_types/skus`);

export const addSku = ({ sku }) =>
  getResponseData('post', `${INVENTORY_API_ROOT}/v0/device_types/skus`, sku);
