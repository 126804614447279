import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import PrimaryButton from 'components/common/buttons/PrimaryButton';
import { printElement } from 'components/utils/helpers';
import { openLot } from 'components/operations/actions/operationsActions';

const printDevices = () => {
  printElement('table--operations-details-print');
};

const ClosedLotActions = props => {
  const {
    styles,
    lotDetails,
    _openLot,
    isAllowedRolePrintLot,
  } = props;

  let canOpen = true;

  _.forEach(lotDetails.devices, device => {
    if (device.status !== 'tl') {
      canOpen = false;
      return false;
    }
  });

  return (
    <Fragment>
      {
        canOpen &&
        <PrimaryButton
          onClick={() => { _openLot(lotDetails.lot_id); }}
          message="OperationsDetailsPage.openLot"
          customStyles={styles.button}
        />
      }
      {
        isAllowedRolePrintLot &&
        <PrimaryButton
          onClick={printDevices}
          message="OperationsDetailsPage.print"
          customStyles={styles.button}
        />
      }
    </Fragment>
  );
};

ClosedLotActions.propTypes = {
  styles: PropTypes.object.isRequired,
  lotDetails: PropTypes.object.isRequired,
  _openLot: PropTypes.func.isRequired,
  isAllowedRolePrintLot: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  lotDetails: state.operations.lotDetails.data,
});

const mapDispatchToProps = dispatch => ({
  _openLot: lotId => {
    dispatch(openLot(lotId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClosedLotActions);
