import { sagaRequestWithPayload, formSagaRequestWithPayload } from 'components/utils/reduxHelpers';
import { CREATE_PATIENT as CREATE_PATIENT_FORM, REQUEST_REPORT_FORM } from 'components/common/forms/names';
import * as PatientsService from '../services/patientsService';
import {
  LOAD_PATIENTS_LIST,
  LOAD_PATIENT_DETAILS,
  LOAD_PATIENT_COMPLIANCES,
  LOAD_COMPLIANCE_DETAILS,
  CREATE_PATIENT,
  REQUEST_REPORT,
} from '../actions/patientsActions';

export function* loadPatientsList(action) {
  yield* sagaRequestWithPayload(LOAD_PATIENTS_LIST, PatientsService.loadPatientsList, action);
}

export function* loadPatientDetails(action) {
  yield* sagaRequestWithPayload(LOAD_PATIENT_DETAILS, PatientsService.loadPatientDetails, action);
}

export function* loadPatientCompliances(action) {
  yield* sagaRequestWithPayload(LOAD_PATIENT_COMPLIANCES, PatientsService.loadPatientCompliances, action);
}

export function* loadComplianceDetails(action) {
  yield* sagaRequestWithPayload(LOAD_COMPLIANCE_DETAILS, PatientsService.loadComplianceDetails, action);
}

export function* createPatient({ promise, ...rest }) {
  yield* formSagaRequestWithPayload(CREATE_PATIENT_FORM, CREATE_PATIENT, PatientsService.createPatient, promise, rest);
}

export function* requestReport(action) {
  const { notification, promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    REQUEST_REPORT_FORM,
    REQUEST_REPORT,
    PatientsService.requestReport,
    promise,
    rest,
    notification,
  );
}
