import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'redux-form';
import { FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as en from 'intl/en';

import Header from 'components/common/Header';
import PrimaryButton from 'components/common/buttons/PrimaryButton';

import { translateLabel } from '../helpers';

import styles from './FileField.styles';

const handleChange = handler => e => {
  const { files } = e.target;
  const file = files && files.length && files[0];

  if (file) {
    handler({ file, name: file.name });
  } else {
    handler(null);
  }

  e.target.value = null; // clear file input after propagating change
};

const FileFieldInput = ({
  input: { onChange, onBlur, value, ...inputProps },
  meta: { touched, error },
  classes,
  label,
  intl,
  ...props
}) => (
  <div className={classes.container}>
    <Header text={label} />
    <input
      type="file"
      onChange={handleChange(onChange)}
      onBlur={handleChange(onBlur)}
      id={inputProps.name}
      hidden
    />
    <label htmlFor={inputProps.name} className={classes.inputControl}>
      <span className={classes.fileInputLabel}>
        {value ? value.name : ''}
      </span>
      <span className={classes.fileInputButton}>
        <PrimaryButton component="span" message="common.Form.browse" />
      </span>
    </label>
    {
      touched && error &&
      <FormHelperText error>
        {intl.formatMessage({ id: error.message, defaultMessage: en[error.message]}, error.values)}
      </FormHelperText>
    }
  </div>
);

FileFieldInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  classes: PropTypes.object,
  intl: PropTypes.object,
};

const FileFieldInputWithClasses = withStyles(styles)(FileFieldInput);

const FileField = ({ name, label, intl, validate, type, placeholder, ...otherProps }) => (
  <Field
    name={name}
    component={FileFieldInputWithClasses}
    label={translateLabel(label, intl)}
    placeholder={translateLabel(placeholder, intl)}
    validate={validate}
    intl={intl}
    type={type}
    {...otherProps}
  />
);

FileField.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.any,
  type: PropTypes.string,
};

export default injectIntl(FileField);
