/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NewUploadIcon = props => (
    <SvgIcon
      {...props}
      viewBox="0 0 18 18"
      fontSize="inherit"
    >
      <path
        d="M8.971 0A9.042 9.042 0 0 0 7.96.06l.176 1.555a7.435 7.435 0 1 1 .835 14.82 7.557 7.557 0 0 1-.83-.049l-.173 1.556a9.134 9.134 0 0 0 1 .058 9 9 0 1 0 0-18zM5.99.511a9.011 9.011 0 0 0-1.82.875l.837 1.324a7.435 7.435 0 0 1 1.5-.722zm-3.4 2.125a9.038 9.038 0 0 0-1.254 1.58l1.324.834a7.478 7.478 0 0 1 1.035-1.307zm9.658 2.016l-4.21 6.3-2.864-2.537-1.043 1.17 4.207 3.728 5.212-7.792zM.45 6.032A8.9 8.9 0 0 0 0 8l1.56.173a7.333 7.333 0 0 1 .375-1.62zm1.109 3.8l-1.56.174a8.925 8.925 0 0 0 .454 1.964l1.482-.517a7.358 7.358 0 0 1-.373-1.618zm1.1 3.118l-1.328.835a9.033 9.033 0 0 0 1.259 1.582L3.7 14.26a7.475 7.475 0 0 1-1.037-1.306zm2.351 2.341l-.836 1.325a9 9 0 0 0 1.821.874l.518-1.477a7.43 7.43 0 0 1-1.503-.722z"
        fill="#0f92b9"
      />
    </SvgIcon>
);

export default NewUploadIcon;
