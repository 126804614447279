import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  FormattedMessage,
} from 'react-intl';
import {
  withTheme,
  Dialog,
} from '@material-ui/core';
import * as en from 'intl/en';

import Icon from '@material-ui/core/Icon';
import getGlobalStyles from 'components/styles/global.styles';
import getStyles from './CalaDialog.styles';

const CalaDialog = props => {
  const {
    closeDialog,
    isModalOpen,
    theme,
    id,
    contentId,
    title,
    children,
    style,
    icon,
  } = props;
  const styles = getStyles(theme);
  const globalStyles = getGlobalStyles(theme);

  return (
    <Dialog
      id={id}
      open={isModalOpen}
      onClose={closeDialog}
      style={style}
    >
      <div
        style={{
          ...globalStyles.pageHeader,
          ...styles.header,
        }}
      >
        {icon}
        <h1
          style={{
            ...globalStyles.pageTitle,
            ...styles.title,
          }}
        >
          <FormattedMessage
            id={title}
            defaultMessage={en[title]}
          />
        </h1>
        <Icon
          style={styles.closeButton}
          onClick={closeDialog}
        >
          close
        </Icon>
      </div>
      <div
        id={contentId}
        style={styles.content}
      >
        {children}
      </div>
    </Dialog>
  );
};

CalaDialog.propTypes = {
  children: PropTypes.any.isRequired,
  theme: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  style: PropTypes.object,
  icon: PropTypes.object.isRequired,
};

const enhance = compose(withTheme);

export default enhance(CalaDialog);
