const getStyles = () => ({
  paper: {
    color: 'rgb(102, 102, 102)',
    backgroundColor: 'rgb(255, 255, 255)',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    fontFamily: 'Roboto, sans-serif',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 45px, rgba(0, 0, 0, 0.22) 0px 10px 18px',
    borderRadius: '2px',
    maxWidth: '768px',
    width: '768px',
  },
  text: {
    fontSize: '16px',
    color: 'rgba(102, 102, 102, 0.6)',
    padding: '0px 24px 24px',
    boxSizing: 'border-box',
    overflowY: 'hidden',
    borderTop: 'medium none',
    borderBottom: 'medium none',
    maxHeight: '660px',
  },
});

export default getStyles;
