export default {
  deviceType: 'Device Type',
  plusAddDeviceType: '+ Add a device type',
  addDeviceType: 'Add a device type',
  inStock: 'In Stock',
  allocated: 'Allocated',
  assigned: 'Assigned',
  returned: 'Returned',
  addDeviceTypeSuccess: 'Adding device type success',
  addDeviceTypeError: 'Adding device type error',
  updateDeviceTypeSuccess: 'Updating device type success',
  updateDeviceTypeError: 'Updating device type error',
};
