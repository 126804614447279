import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Icon } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import * as en from 'intl/en';
import { Link } from 'react-router-dom';
import PageWrapper from 'components/common/PageWrapper';

import Fetch from 'components/common/Fetch';
import getGlobalStyles from 'components/styles/global.styles';
import { mediaStyle } from 'components/utils/styles';
import { FormattedMessage } from 'react-intl';
import PatientDetails from 'components/patients/ui/patientDetails/PatientDetails';
import Details from 'components/common/Details';
import Compliance from 'components/patients/ui/compliance/Compliance';
import ComplianceDialog from 'components/patients/ui/compliance/ComplianceDialog';
import { loadComplianceDetails, loadPatientDetails } from 'components/patients/actions/patientsActions';
import { getPatientDetailsData } from 'components/patients/selectors';

import getStyles from './PatientDetailsPage.styles';

class PatientDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();

    this.state = {
      showComplianceDetails: false,
    };
  }

  componentDidMount() {
    const { patientId } = this.props.match.params;

    this.props.loadPatientDetails(patientId);
  }

  fetchComplianceDetails = () => {
    const { patientId } = this.props.match.params;

    this.props.loadComplianceDetails(patientId);
  }

  openComplianceDetails = () => {
    this.setState({
      showComplianceDetails: true,
    });
  }

  closeComplianceDetails = () => {
    this.setState({
      showComplianceDetails: false,
    });
  }

  render() {
    const { width, complianceDetails, patientDetailsData } = this.props;
    const { showComplianceDetails } = this.state;
    const items = [
      {
        key: 'subject_id',
        text: 'PatientDetailsPage.subjectId',
      },
      {
        key: 'patient_id',
        text: 'PatientDetailsPage.patientId',
      },
      {
        key: 'last_assigned_device',
        text: 'PatientDetailsPage.assignedDevice',
      },
    ];
    const { patientId } = this.props.match.params;

    return (
      <PageWrapper>
        <ComplianceDialog
          isOpen={showComplianceDetails}
          subjectId={patientDetailsData.subject_id}
          closeDialog={this.closeComplianceDetails}
          asyncInfo={complianceDetails.async}
          fetch={this.fetchComplianceDetails}
        />
        <div style={this.globalStyles.pageHeader}>
          <Link
            to="/patients"
          >
            <Icon
              className="material-icons"
              style={{
                ...this.globalStyles.pageIcon,
                ...this.styles.pageIcon,
              }}
            >
              arrow_back
            </Icon>
          </Link>
          <div>
            <h1 style={{ ...this.globalStyles.pageTitle, ...mediaStyle(this.styles.title, width) }}>
              <FormattedMessage
                id="PatientDetailsPage.title"
                defaultMessage={en.PatientDetailsPage.title}
              />
            </h1>
            <Details
              items={items}
              data={patientDetailsData}
            />
          </div>
          <div style={this.styles.complianceWrapper}>
            <Fetch fetch={this.fetchComplianceDetails} asyncInfo={complianceDetails.async}>
              <Compliance
                complianceData={complianceDetails.data}
                patientId={patientId}
                openComplianceDetails={this.openComplianceDetails}
              />
            </Fetch>
          </div>
        </div>
        <div style={mediaStyle(this.styles.content, width)}>
          <PatientDetails />
        </div>
      </PageWrapper>
    );
  }
}

PatientDetailsPage.propTypes = {
  width: PropTypes.string,
  match: PropTypes.object.isRequired,
  complianceDetails: PropTypes.object.isRequired,
  loadComplianceDetails: PropTypes.func.isRequired,
  loadPatientDetails: PropTypes.func.isRequired,
  patientDetailsData: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  complianceDetails: state.patients.complianceDetails,
  patientDetailsData: getPatientDetailsData(state),
});

const enhance = compose(
  withWidth(),
  connect(mapStateToProps, { loadComplianceDetails, loadPatientDetails }),
);

export default enhance(PatientDetailsPage);
