export default {
  pa: 'Packed',
  ap: 'Approved',
  sh: 'Shipped',
  al: 'Allocated',
  as: 'Assigned',
  lo: 'Lost',
  un: 'Unassigned',
  re: 'Returned',
  qa: 'QA',
  xx: 'Retired',
  in: 'In-Stock',
  ho: 'Hold',
  tl: 'TLA',
};
