const getStyles = () => ({
  container: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    width: '100%',
    maxWidth: 450,
    padding: '3em',
    marginTop: '160px',
  },
  title: {
    marginBottom: '1em',
  },
  logoContainer: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    width: '100%',
    maxWidth: 450,
    padding: '3em',
    position: 'absolute',
    top: '60px',
    left: 'auto',
    right: 'auto',
  },
  logo: {
    width: '170px',
    height: '100px',
    margin: '0 auto 140px 100px',
  },
});

export default getStyles;
