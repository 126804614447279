export default {
  deviceLogId: 'Device log ID',
  mac: 'Mac',
  deviceId: 'Device ID',
  serial: 'Serial#',
  uploadDate: 'Upload date',
  typeOfRequest: 'Type of request',
  UNSPECIFIED: 'Unspecified',
  LOG_UPLOAD: 'Log Upload',
  POWER_ON_SELF_TEST: 'Power-On Self Test',
  STATION_DEBUG_LOGS: 'Station Debug Logs',
  STATION_DFU_DOWNLOAD: 'Station DFU Download',
  DEVICE_DFU_DOWNLOAD: 'Device DFU Download',
};
