const getStyles = () => ({
  container: {
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    SMALL: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  title: {
    SMALL: {
      textAlign: 'center',
    },
  },
});

export default getStyles;
