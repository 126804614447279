import {
  LOAD_QUARANTINE_LIST,
  LOAD_QUARANTINE_LIST_SUCCESS,
  LOAD_QUARANTINE_LIST_FAILURE,
  LOAD_QUARANTINE_ITEM,
  LOAD_QUARANTINE_ITEM_SUCCESS,
  LOAD_QUARANTINE_ITEM_FAILURE,
} from '../actions/quarantineActions';

const initialState = {
  isProcessing: true,
  isLoaded: false,
  data: [],
  pages: 0,
  total: 0,
  error: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_QUARANTINE_LIST: {
      return { ...state, isProcessing: true };
    }
    case LOAD_QUARANTINE_LIST_SUCCESS: {
      const newList = action.payload.list;

      return { ...state, ...newList, isLoaded: true, isProcessing: false };
    }
    case LOAD_QUARANTINE_LIST_FAILURE: {
      const error = action.payload;

      return { ...state, error };
    }
    case LOAD_QUARANTINE_ITEM: {
      return { ...state, isProcessing: true };
    }
    case LOAD_QUARANTINE_ITEM_SUCCESS: {
      const newItem = action.payload.item;

      return { ...state, ...newItem, isLoaded: true, isProcessing: false };
    }
    case LOAD_QUARANTINE_ITEM_FAILURE: {
      const error = action.payload;

      return { ...state, error };
    }
    default: {
      return state;
    }
  }
}
