import React from 'react';
import PropTypes from 'prop-types';

import '../styles/css/version.css';

const LambdaVersion = props => {
  const {
    service,
    serviceName,
  } = props;

  return (
    <div
      id={`version--${service['Repo Name']}`}
      className="wrapper"
    >
      <h3 className="title">
        {serviceName}
      </h3>
        <div
          className="metric"
        >
          <div className="label">
              Operating Mode:&nbsp;
          </div>
          <div className="value">
              {service['Operating Mode'] || '-'}
          </div>
        </div>

          <div
            className="metric"
          >
            <div className="label">
                Version:&nbsp;
            </div>
            <div className="value">
                {service.Version || '-'}
            </div>
          </div>

          <div
            className="metric"
          >
            <div className="label">
                Last Commit:&nbsp;
            </div>
            <div className="value">
                {service['Last Commit'] || '-'}
            </div>
          </div>

          <div
            className="metric"
          >
            <div className="label">
                Last Commit - Short:&nbsp;
            </div>
            <div className="value">
                {service['Last Commit - Short'] || '-'}
            </div>
          </div>

          <div
            className="metric"
          >
            <div className="label">
                Build Date:&nbsp;
            </div>
            <div className="value">
                {service['Build Date'] || '-'}
            </div>
          </div>

          <div
            className="metric"
          >
            <div className="label">
                Branch:&nbsp;
            </div>
            <div className="value">
                {service.Branch || '-'}
            </div>

          </div>
    </div>
  );
};

LambdaVersion.propTypes = {
  service: PropTypes.object,
  serviceName: PropTypes.string,
};

export default LambdaVersion;
