import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from 'components/common/Message';

const Spinner = ({ isLoading, styles }) => {
  if (isLoading) {
    return (
      <CircularProgress
        size={50}
        style={{ ...styles.spinner.center, ...styles.spinner.progress }}
      />
    );
  }

  return (
    <div style={{ ...styles.spinner.center, ...styles.spinner.textHolder }}>
      <Message id="files.table.noLogs" />
    </div>
  );
};

Spinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  styles: PropTypes.object.isRequired,
};

export default Spinner;
