import { SNACKBAR_VARIANTS } from '../utils/constants';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const enqueueSnackbar = notification => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    key: Date.now(),
    ...notification,
    options: {
      variant: SNACKBAR_VARIANTS.info,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      ...notification.options,
    },
  },
});

export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});
