import theme from 'components/styles/default.theme';

const getStyles = () => ({
  button: {
    marginTop: theme.spacing(3),
  },
  text: {
    marginBottom: theme.spacing(4),
  },
  passwordText: {
    marginBottom: theme.spacing(2),
  },
  passwords: {
    paddingLeft: theme.padding.textInput,
    borderLeft: `2px solid ${theme.palette.highlightTextColor}`,
    error: {
      borderLeft: `2px solid ${theme.palette.dangerTextColor}`,
    },
    errorInput: {
      borderColor: theme.palette.dangerTextColor,
    },
  },
  avatar: {
    borderRadius: '50%',
    border: '2px solid #E0E0E0',
    backgroundClip: 'content-box',
    cursor: 'pointer',
    padding: 3,
    width: '9em',
    height: '9em',
    marginBottom: theme.spacing(4),
  },
  passwordInput: {
    color: theme.palette.defaultTextColor,
    border: `1px solid ${theme.palette.borderColor2}`,
    paddingLeft: '17px',
    height: '47px',
  },
  passwordInputLabel: {
    display: 'block',
    color: theme.palette.inputLabelColor,
    opacity: 0.54,
    marginTop: '1.25em',
    textTransform: 'uppercase',
    fontWeight: 700,
    marginBottom: '7px',
    lineHeight: 1,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
});

export default getStyles;
