import { ADD_USER as ADD_USER_FORM } from 'components/common/forms/names';
import { sagaRequestWithPayload, formSagaRequestWithPayload } from 'components/utils/reduxHelpers';
import {
  call,
  all,
} from 'redux-saga/effects';
import * as UsersService from '../services/usersService';
import {
  LOAD_USERS,
  ADD_USER,
  ADD_USER_SIGNUP,
  UPDATE_USER,
  FETCH_USER_DATA,
} from '../actions/usersActions';

export const getUsers = state => state.users;

export function* loadUsers(action) {
  yield* sagaRequestWithPayload(LOAD_USERS, UsersService.loadUsers, action);
}

export function* addUser(action) {
  const { promise, user } = action;

  yield* formSagaRequestWithPayload(
    ADD_USER_FORM,
    ADD_USER,
    UsersService.addUser,
    promise,
    user,
  );
}

export function* addUserSignUp(action) {
  const { promise, user } = action;

  yield* formSagaRequestWithPayload(
    ADD_USER_FORM,
    ADD_USER_SIGNUP,
    UsersService.addUserSignUp,
    promise,
    user,
  );
}

export function* updateUser(action) {
  const { promise, user } = action;
  const { pk } = user;

  yield* formSagaRequestWithPayload(
    ADD_USER_FORM,
    UPDATE_USER,
    UsersService.updateUser,
    promise,
    { pk, user },
  );
}

export function* fetchUserData(action) {
  yield* sagaRequestWithPayload(FETCH_USER_DATA, UsersService.fetchUserData, action);
}

export function* fetchMultipleUsersData(action) {
  const tasks = [];

  action.userIds.forEach(id => {
    tasks.push(call(fetchUserData, {
      userId: id,
    }));
  });

  yield all(tasks);
}
