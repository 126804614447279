import { call, all } from 'redux-saga/effects';
import { sagaRequestWithPayload, formSagaRequestWithPayload } from 'components/utils/reduxHelpers';
import { ASSIGN_DEVICE_FORM, UNASSIGN_DEVICE_FORM } from 'components/common/forms/names';

import * as OperationsService from '../services/operationsService';
import {
  LOAD_UNASSIGNED_DEVICES,
  DELETE_UNASSIGNED_DEVICE,
  OPERATIONS_TOGGLE_DEVICE,
  LOAD_DEVICES,
  ASSIGN_DEVICE,
  UNASSIGN_DEVICE,
  LOAD_PREFIX,
} from '../actions/devicesActions';

export function* loadUnassignedDevices(action) {
  yield* sagaRequestWithPayload(LOAD_UNASSIGNED_DEVICES, OperationsService.loadUnassignedDevices, action);
}

export function* deleteUnassignedDevice(action) {
  yield* sagaRequestWithPayload(DELETE_UNASSIGNED_DEVICE, OperationsService.deleteUnassignedDevice, action);
}

export function* toggleDevice(action) {
  yield* sagaRequestWithPayload(OPERATIONS_TOGGLE_DEVICE, OperationsService.toggleDevice, action);
}

export function* toggleAllDevices(action) {
  const tasks = [];

  action.deviceInventoryIds.forEach(deviceInventoryId => {
    tasks.push(call(toggleDevice, {
      deviceInventoryId: parseInt(deviceInventoryId, 0),
      checked: action.checked,
      body: action.body,
    }));
  });

  yield all(tasks);
}

export function* loadDevices(action) {
  yield* sagaRequestWithPayload(LOAD_DEVICES, OperationsService.loadDevices, action);
}

export function* assignDevice(action) {
  const { notification, promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    ASSIGN_DEVICE_FORM,
    ASSIGN_DEVICE,
    OperationsService.assignDevice,
    promise,
    rest,
    notification,
  );
}

export function* unassignDevice(action) {
  const { notification, promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    UNASSIGN_DEVICE_FORM,
    UNASSIGN_DEVICE,
    OperationsService.unassignDevice,
    promise,
    rest,
    notification,
  );
}

export function* loadPrefix(action) {
  yield* sagaRequestWithPayload(LOAD_PREFIX, OperationsService.loadPrefix, action);
}
