import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import * as en from 'intl/en';
import PageWrapper from 'components/common/PageWrapper';
import CalaDialog from 'components/common/calaDialog/CalaDialog';
import AccessControl from 'components/common/base/AccessControl';

import getGlobalStyles from 'components/styles/global.styles';
import CreateIcon from 'components/styles/icons/CreateIcon';
import { mediaStyle } from 'components/utils/styles';
import { FormattedMessage } from 'react-intl';
import { loadDeviceTypes, fetchSkuList } from 'components/inventory/actions/inventoryActions';
import {
  getSortedDeviceTypes,
  getShouldLoadDeviceTypes,
  getShouldLoadSkuList,
  getDeviceTypesWithSku,
} from 'components/inventory/selectors';
import AddDeviceType from 'components/inventory/ui/addDeviceType/AddDeviceType';
import AddSku from 'components/inventory/ui/addSku/AddSku';
import Session from 'components/utils/session';

import OperationsIcon from 'components/styles/icons/OperationsIcon';
import OperationsList from 'components/operations/ui/OperationsList';
import CreateLotForm from 'components/operations/ui/createLot/CreateLotForm';
import permissions from 'components/operations/utils/permissions';
import { isAllowedRole } from 'components/users/utils/userRoles';

import NavigationLinks from './ui/NavigationLinks';
import getStyles from './OperationsPage.styles';

class OperationsPage extends Component {
  constructor(props, context) {
    super(props);
    this.styles = getStyles(props.theme);
    this.globalStyles = getGlobalStyles(props.theme);

    this.state = {
      createLot: false,
      showAddDeviceType: false,
      showAddSku: false,
      deviceType: {},
    };

    this.isAllowedRole = isAllowedRole(Session.JWTDecoded.role_id, permissions.createNewLot);
    this.hasOperationsDetails = isAllowedRole(Session.JWTDecoded.role_id, permissions.showOperationsDetails);
  }

  componentDidMount() {
    const { shouldLoadSkuList, shouldLoadDeviceTypes } = this.props;

    if (shouldLoadDeviceTypes) {
      this.props.loadDeviceTypes();
    }

    if (shouldLoadSkuList) {
      this.props.fetchSkuList();
    }
  }

  openCreateLot = () => {
    this.setState({
      createLot: true,
    });
  }

  closeCreateLot = () => {
    this.setState({
      createLot: false,
    });
  }

  openAddDeviceType = (deviceType = {}) => {
    this.setState({
      showAddDeviceType: true,
      deviceType,
    });
  }

  closeAddDeviceType = isSuccess => {
    this.setState({
      showAddDeviceType: false,
      deviceType: {},
    });
  }

  openAddSku = () => {
    this.setState({
      showAddSku: true,
    });
  }

  closeAddSku = () => {
    this.setState({
      showAddSku: false,
    });
  }

  onLotCreated = lot => {
    this.props.history.push({
      pathname: `/operations/details/${lot.lot_id}`,
    });
  }

  render() {
    const {
      width,
      theme,
      deviceTypesWithSku,
    } = this.props;
    const {
      createLot,
      showAddDeviceType,
      deviceType,
      showAddSku,
    } = this.state;

    return (
      <PageWrapper>
        <AddDeviceType
          closeAddType={this.closeAddDeviceType}
          isModalOpen={showAddDeviceType}
          data={deviceType}
        />
        <AddSku
          isModalOpen={showAddSku}
          closeAddSku={this.closeAddSku}
        />
        <CalaDialog
          isModalOpen={createLot}
          closeDialog={this.closeCreateLot}
          id="modal--create-lot"
          contentId="create-lot__content"
          title="operations.form.createNewLot"
          icon={<CreateIcon />}
        >
          <CreateLotForm
            closeDialog={this.closeCreateLot}
            deviceTypes={deviceTypesWithSku}
            onLotCreated={this.onLotCreated}
          />
        </CalaDialog>
        <div style={this.globalStyles.pageHeader}>
          <OperationsIcon
            style={this.globalStyles.pageIcon}
            htmlColor={theme.palette.textColor}
          />
            <h1 style={{ ...this.globalStyles.pageTitle, ...mediaStyle(this.styles.title, width) }}>
              <FormattedMessage
                id="OperationsPage.title"
                defaultMessage={en.OperationsPage.title}
              />
            </h1>
            {
              this.isAllowedRole &&
              <Button
                style={{
                  ...this.globalStyles.primaryButton,
                  ...this.styles.button,
                }}
                onClick={this.openCreateLot}
              >
                <FormattedMessage
                  id="OperationsPage.createNewLot"
                  defaultMessage={en.OperationsPage.createNewLot}
                />
              </Button>
            }
          <AccessControl roles={permissions.addDeviceType}>
            <Button
              id="btn--add-device-type"
              type="button"
              children={
                <FormattedMessage
                  id="OperationsPage.addDeviceType"
                  defaultMessage={en.OperationsPage.addDeviceType}
                />
              }
              style={{
                ...this.globalStyles.primaryButton,
                ...this.styles.button,
                marginLeft: '15px',
              }}
              onClick={this.openAddDeviceType}
            />
          </AccessControl>
          <Button
            id="btn--add-sku"
            type="button"
            children={
              <FormattedMessage
                id="OperationsPage.createSku"
                defaultMessage={en.OperationsPage.createSku}
              />
            }
            style={{
              ...this.globalStyles.primaryButton,
              ...this.styles.button,
              marginLeft: '15px',
            }}
            onClick={this.openAddSku}
          />
        </div>
        <NavigationLinks />
        <div style={mediaStyle(this.styles.content, width)}>
          <OperationsList hasOperationsDetails={this.hasOperationsDetails} />
        </div>
      </PageWrapper>
    );
  }
}

OperationsPage.propTypes = {
  width: PropTypes.string,
  theme: PropTypes.object.isRequired,
  loadDeviceTypes: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  deviceTypesWithSku: PropTypes.array.isRequired,
  shouldLoadDeviceTypes: PropTypes.bool.isRequired,
  shouldLoadSkuList: PropTypes.bool.isRequired,
  fetchSkuList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  operations: state.operations,
  deviceTypeList: getSortedDeviceTypes(state),
  shouldLoadDeviceTypes: getShouldLoadDeviceTypes(state),
  shouldLoadSkuList: getShouldLoadSkuList(state),
  deviceTypesWithSku: getDeviceTypesWithSku(state),
});

const mapDispatchToProps = { loadDeviceTypes, fetchSkuList };

const enhance = compose(
  withWidth(),
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(OperationsPage);
