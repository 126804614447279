import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TableCell } from '@material-ui/core';
import { FormattedDate } from 'react-intl';
import _ from 'lodash';

const CellRenderer = ({ checked, rowData, dataKey }) => {
  if (!checked) {
    return null;
  }

  const style = {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'initial',
  };

  let content = '...';

  if (dataKey === 'created_at') {
    content = (
      <FormattedDate
        value={moment.utc(rowData.created_at).local()}
        day="numeric"
        month="numeric"
        year="numeric"
        hour="numeric"
        minute="numeric"
      />
    );
  } else if (dataKey === 'upload_date') {
    content = (
      <FormattedDate
        value={moment.utc(rowData.upload_date).local()}
        day="numeric"
        month="numeric"
        year="numeric"
        hour="numeric"
        minute="numeric"
      />
    );
  } else {
    content = _.get(rowData, dataKey);
  }

  return (
    <TableCell
      key={dataKey}
      component="div"
      variant="body"
      style={style}
    >
      { content }
    </TableCell>
  );
};

CellRenderer.propTypes = {
  checked: PropTypes.bool,
  rowData: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
};

export default CellRenderer;
