import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';

const StimTableBody = props => {
  const { row } = props;

  return (
    <Fragment>
      <TableCell>
        {row.device_details.mac_address}
      </TableCell>
    </Fragment>
  );
};

StimTableBody.propTypes = {
  row: PropTypes.object.isRequired,
};

export default StimTableBody;
