import {
  ADD_DEVICE_TYPE as ADD_DEVICE_TYPE_FORM,
  ADD_SKU as ADD_SKU_FORM,
} from 'components/common/forms/names';
import { sagaRequestWithPayload, formSagaRequestWithPayload } from 'components/utils/reduxHelpers';
import { call, all } from 'redux-saga/effects';
import * as InventoryService from '../services/inventoryService';
import {
  LOAD_DEVICE_TYPES,
  ADD_DEVICE_TYPE,
  UPDATE_DEVICE_TYPE,
  FETCH_DEVICE_STOCK,
  FETCH_SKU_LIST,
  ADD_SKU,
} from '../actions/inventoryActions';

export function* loadDeviceTypes(action) {
  yield* sagaRequestWithPayload(LOAD_DEVICE_TYPES, InventoryService.loadDeviceTypes, action);
}

export function* addDeviceType(action) {
  const { promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    ADD_DEVICE_TYPE_FORM,
    ADD_DEVICE_TYPE,
    InventoryService.addDeviceType,
    promise,
    rest,
  );
}

export function* updateDeviceType(action) {
  const { promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    ADD_DEVICE_TYPE_FORM,
    UPDATE_DEVICE_TYPE,
    InventoryService.updateDeviceType,
    promise,
    rest,
  );
}

export function* fetchDeviceStock(action) {
  yield* sagaRequestWithPayload(FETCH_DEVICE_STOCK, InventoryService.fetchDeviceStock, action);
}

export function* fetchMultipleDeviceStock(action) {
  const tasks = [];

  action.sites.forEach(site => {
    tasks.push(call(fetchDeviceStock, {
      siteId: site.siteId,
      deviceTypeIds: site.deviceTypeIds,
    }));
  });

  yield all(tasks);
}

export function* fetchSkuList(action) {
  yield* sagaRequestWithPayload(FETCH_SKU_LIST, InventoryService.fetchSkuList, action);
}

export function* addSku(action) {
  const { promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    ADD_SKU_FORM,
    ADD_SKU,
    InventoryService.addSku,
    promise,
    rest,
  );
}
