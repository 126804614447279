const getStyles = theme => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    SMALL: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  title: {
    SMALL: {
      textAlign: 'center',
    },
    marginBottom: 10,
  },
  pageIcon: {
    color: theme.palette.backgroundColor,
  },
  button: {
    padding: 'none',
    marginLeft: 5,
    marginRight: 5,
    width: 'auto',
    display: 'inline-flex',
  },
  actionWrapper: {
    marginLeft: 'auto',
  },
});

export default getStyles;
