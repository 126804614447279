import React from 'react';
import PropTypes from 'prop-types';

import '../styles/css/version.css';

const Version = props => {
  const {
    service,
    serviceName,
    services,
  } = props;

  return (
    <div
      id={`version--${service}`}
      className="wrapper"
    >
      <h3 className="title">
        {serviceName}
      </h3>
      {Object.keys(services[service]).map((metric, index) => (
        <div
          className="metric"
          key={index}
        >
          <div className="label">
            {metric}:&nbsp;
          </div>
          <div className="value">
            {services[service][metric] || '-'}
          </div>
        </div>
      ))}
    </div>
  );
};

Version.propTypes = {
  service: PropTypes.string,
  serviceName: PropTypes.string,
  services: PropTypes.object,
};

export default Version;
