import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import withWidth from '@material-ui/core/withWidth';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { DateRangePicker } from 'react-date-range';
import { IconButton, Button, Dialog, DialogTitle, withStyles } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Autosuggest from 'react-autosuggest';
import { format } from 'date-fns';
import * as _ from 'lodash';
import * as en from 'intl/en';
import { intlWord } from 'components/utils/helpers';

import CalendarIcon from 'components/styles/icons/CalendarIcon';
import { mediaStyle } from 'components/utils/styles';
import getGlobalStyles from 'components/styles/global.styles';
import Keyword from './Keyword';
import getStyles from './SearchBar.styles';
import './SearchBar.css';

const formatDateDisplay = (date, defaultText) => {
  if (!date) return defaultText;
  return format(date, 'MM/DD/YYYY');
};

const getDateRangeValue = (dateEnabled, selection) => {
  if (!dateEnabled) {
    return { };
  }

  return {
    startDate: format(selection.startDate, 'YYYY-MM-DDTHH:mm:ss.SSS'),
    endDate: format(selection.endDate, 'YYYY-MM-DDTHH:mm:ss.SSS'),
  };
};

const getSuggestionValue = suggestion => `${suggestion.text}:`;

const shouldRenderSuggestions = value => true;

const renderSuggestion = suggestion => (
  <div>
    {suggestion.text}
  </div>
);

class SearchBar extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      keywords: [],
      currentKeyword: '',
      suggestions: [],
      dateRange: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      },
      tmpDateRange: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      },
      dateEnabled: false,
      pickerOpen: false,
    };

    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();

    this.renderDatePicker = this.renderDatePicker.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.deleteKeyword = this.deleteKeyword.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
    this.setDateRange = this.setDateRange.bind(this);
    this.clearDate = this.clearDate.bind(this);
    this.setDate = this.setDate.bind(this);
    this.renderInputComponent = this.renderInputComponent.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.isValidKeyword = this.isValidKeyword.bind(this);
    this.getSearchText = this.getSearchText.bind(this);
    this.getSearchTerms = this.getSearchTerms.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { keywords, dateRange: { selection }, dateEnabled } = this.state;
    const oldSearchTerms = this.getSearchTerms(prevState.keywords);
    const newSearchTerms = this.getSearchTerms(keywords);
    const oldDateRangeValue = getDateRangeValue(prevState.dateEnabled, prevState.dateRange.selection);
    const newDateRangeValue = getDateRangeValue(dateEnabled, selection);

    const noKeywordChange = oldSearchTerms === newSearchTerms;
    const noDateChange = _.isEqual(oldDateRangeValue, newDateRangeValue);

    if (!noKeywordChange || !noDateChange) {
      this.props.onSearch({
        terms: newSearchTerms,
        dateRange: newDateRangeValue,
      });
    }
  }

  onKeyDown(ev) {
    if ((ev.keyCode === 8) && (this.state.currentKeyword === '')) {
      this.setState({
        currentKeyword: '',
        keywords: _.dropRight(this.state.keywords),
      });
    }
  }

  onKeyPress(ev) {
    if (ev.key !== 'Enter') {
      return;
    }

    const { currentKeyword } = this.state;

    if (!this.isValidKeyword(currentKeyword)) {
      return;
    }

    ev.preventDefault();
    this.setState({
      currentKeyword: '',
      keywords: _.uniq([
        ...this.state.keywords,
        this.state.currentKeyword,
      ]),
    });
  }

  deleteKeyword(keyword) {
    this.setState({
      keywords: _.without(this.state.keywords, keyword),
    });
  }

  clearDate() {
    this.setState({
      dateEnabled: false,
      dateRange: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      },
    });
  }
  setDate() {
    this.setState({
      dateEnabled: true,
      pickerOpen: false,
      dateRange: _.cloneDeep(this.state.tmpDateRange),
    });
  }

  togglePicker() {
    const newState = {
      pickerOpen: !this.state.pickerOpen,
    };

    if (newState.pickerOpen) {
      newState.tmpDateRange = _.cloneDeep(this.state.dateRange);
    }

    this.setState(newState);
  }

  setDateRange(ranges) {
    this.setState({
      tmpDateRange: ranges,
    });
  }

  renderDatePicker() {
    return (
      <Dialog
        id="modal--date-picker"
        classes={{ paper: this.props.classes.pickerModal }}
        open={this.state.pickerOpen}
        onClose={this.togglePicker}
      >
        <DialogTitle
          children={intlWord(this.props.intl, 'search.common.pickDate')}
        />
        <DateRangePicker
          ranges={[this.state.tmpDateRange.selection]}
          onChange={this.setDateRange}
        />
        <Button
          id="btn--save"
          type="button"
          children="Search"
          classes={{ root: this.props.classes.label }}
          style={this.globalStyles.primaryButton}
          onClick={this.setDate}
        />
        <Button
          id="btn--cancel"
          type="button"
          children="Cancel"
          style={this.globalStyles.linkButton}
          classes={{ label: this.props.classes.cancelLabel }}
          onClick={this.togglePicker}
        />
      </Dialog>
    );
  }

  renderInputComponent(inputProps) {
    const dateRange = _.get(this.state, 'dateRange.selection', {});

    return (
      <div style={this.styles.searchWidget}>
        {this.state.dateEnabled &&
          <Keyword
            key="date_range"
            onCancel={this.clearDate}
          >
            <FormattedMessage
              id="files.filter.uploadDate"
              defaultMessage={en.files.filter.uploadDate}
            />
            : {formatDateDisplay(dateRange.startDate)} - {formatDateDisplay(dateRange.endDate)}
          </Keyword>
        }
        {this.state.keywords.map((keyword, index) => (
          <Keyword
            key={index}
            onCancel={() => this.deleteKeyword(keyword)}
          >
            {keyword}
          </Keyword>
        ))}
        <div style={this.styles.searchBoxWrapper}>
          <input
            {...inputProps}
            style={this.styles.searchBox}
            onKeyPress={this.onKeyPress}
            onKeyDown={e => {
              inputProps.onKeyDown(e);
              this.onKeyDown(e);
            }}
          />
          <Icon
            className="material-icons"
            style={this.styles.searchIcon}
          >
            search
          </Icon>
        </div>
      </div>
    );
  }

  onChangeInput(event, { newValue }) {
    this.setState({
      currentKeyword: newValue,
    });
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  isValidKeyword(str) {
    const arrParts = str.split(':');

    if (arrParts.length < 2) {
      return false;
    }

    if (arrParts[1] === '') {
      return false;
    }

    if (arrParts[1].length < 2) {
      return false;
    }

    if (this.props.fields.map(f => f.text).indexOf(arrParts[0]) === -1) {
      return false;
    }

    return true;
  }

  getSearchText(value) {
    const arrParts = value.split(':');
    const fieldText = arrParts[0];

    arrParts.shift();
    const searchTerm = arrParts.join(':').trim();
    const field = this.props.fields.find(f => f.text === fieldText);

    if (field) {
      return `${field.key}:${searchTerm}`;
    } else {
      return '';
    }
  }

  getSearchTerms(keywords) {
    return keywords.map(k => this.getSearchText(k)).join(';');
  }

  getSuggestions(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? this.props.fields : this.props.fields.filter(field =>
      field.text.toLowerCase().includes(inputValue));
  }

  renderSearchInput() {
    const { currentKeyword, suggestions } = this.state;
    const inputProps = {
      placeholder: intlWord(this.props.intl, 'search.common.searchPlaceholder'),
      value: currentKeyword,
      onChange: this.onChangeInput,
    };

    if (this.props.fields.length === 0) {
      return null;
    }

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        shouldRenderSuggestions={shouldRenderSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={this.renderInputComponent}
      />
    );
  }

  render() {
    const { width, hideCalendar } = this.props;

    return (
      <div
        id="search_container"
        style={mediaStyle(this.styles.container, width)}
      >
        <div style={this.globalStyles.pageTagline}>
          <FormattedMessage
            id="search.common.searchTagline"
            defaultMessage={en.search.common.searchTagline}
          />
        </div>
        <div style={this.styles.widgets}>
          {this.renderSearchInput()}
          {
            !hideCalendar &&
            <div style={this.styles.dateWidget}>
              <IconButton
                className="btn--toggle-date-picker"
                onClick={this.togglePicker}
                style={this.styles.dateIconWrapper}
                disableRipple
              >
                <CalendarIcon />
              </IconButton>
            </div>
          }
        </div>
        {this.renderDatePicker()}
      </div>
    );
  }
}

SearchBar.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  fields: PropTypes.array,
  hideCalendar: PropTypes.bool,
};
SearchBar.defaultProps = {
  fields: [],
  hideCalendar: false,
};

const enhance = compose(
  injectIntl,
  withWidth(),
  withStyles(getStyles),
);

export default enhance(SearchBar);
