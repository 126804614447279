export const OPERATIONS_TOGGLE_DEVICE = 'OPERATIONS_TOGGLE_DEVICE';
export const OPERATIONS_TOGGLE_ALL_DEVICES = 'OPERATIONS_TOGGLE_ALL_DEVICES';
export const LOAD_UNASSIGNED_DEVICES = 'LOAD_UNASSIGNED_DEVICES';
export const DELETE_UNASSIGNED_DEVICE = 'DELETE_UNASSIGNED_DEVICE';
export const LOAD_DEVICES = 'LOAD_DEVICES';
export const ASSIGN_DEVICE = 'ASSIGN_DEVICE';
export const UNASSIGN_DEVICE = 'UNASSIGN_DEVICE';
export const LOAD_PREFIX = 'LOAD_PREFIX';

export const toggleDevice = (deviceInventoryId, checked, body) => {
  return {
    type: OPERATIONS_TOGGLE_DEVICE,
    deviceInventoryId,
    checked,
    body,
  };
};

export const toggleAllDevices = (deviceInventoryIds, checked, body) => {
  return {
    type: OPERATIONS_TOGGLE_ALL_DEVICES,
    deviceInventoryIds,
    checked,
    body,
  };
};

export const loadUnassignedDevices = lotId => {
  return {
    type: LOAD_UNASSIGNED_DEVICES,
    lotId,
  };
};

export const deleteUnassignedDevice = inventoryId => {
  return {
    type: DELETE_UNASSIGNED_DEVICE,
    inventoryId,
  };
};

export const loadDevices = (selectedPage, perPage, sortField, sortType, criteria) => {
  return {
    type: LOAD_DEVICES,
    selectedPage,
    perPage,
    sortField,
    sortType,
    criteria,
  };
};

export const assignDevice = (data, deviceInventoryId, promise, notification) => {
  return {
    type: ASSIGN_DEVICE,
    data,
    deviceInventoryId,
    promise,
    notification,
  };
};

export const unassignDevice = (data, deviceInventoryId, promise, notification) => {
  return {
    type: UNASSIGN_DEVICE,
    data,
    deviceInventoryId,
    promise,
    notification,
  };
};

export const loadPrefix = () => {
  return {
    type: LOAD_PREFIX,
  };
};
