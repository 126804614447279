import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'redux-form';
import ImageSelector from 'components/common/ImageSelector';

const ImageSelectorInput = ({input, intl, preloadData, ...otherProps}) => (
  <ImageSelector
    onSelect={file => input.onChange(file)}
    preloadData={input.value || preloadData}
    intl={intl}
    {...otherProps}
  />
);

ImageSelectorInput.propTypes = {
  intl: intlShape.isRequired,
  input: PropTypes.object.isRequired,
  preloadData: PropTypes.object,
};

const ImageSelectorField = ({ name, intl, ...otherProps }) => (
  <Field
    type="file"
    name={name}
    component={ImageSelectorInput}
    intl={intl}
    {...otherProps}
  />
);

ImageSelectorField.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  validate: PropTypes.any,
};

export default injectIntl(ImageSelectorField);
