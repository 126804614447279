export default {
  loadingOrderInformationError: 'Loading order details error',
  productsRequested: 'Products requested',
  productsForShipment: 'Products for shipment',
  studyName: 'Study name',
  protocolNumber: 'Protocol number',
  verify: 'VERIFY',
  unverify: 'UNVERIFY',
  verified: 'VERIFIED',
  approveForShipping: 'Approve for shipping',
  approvedForShippingSuccess: 'Successfully approved for shipping',
  rejectOrder: 'Reject order',
  orderRejected: 'Order rejected',
  orderApproved: 'Order approved',
  stimQty: 'Stim',
  bsQty: 'Base Station',
  bandQty: 'Band',
  qty: 'Qty',
  orderStatusChanged: 'Order status changed',
  receive: 'Receive',
  unreceive: 'Unreceive',
  shippingReceived: 'Received',
  reportedAsLost: 'These items will be reported as lost:',
  shippingDetails: 'Shipping details',
  approvedAndReceived: 'Approved and Received',

  receivedDate: 'Received date',
  regulatoryApproveDate: 'Approve date',
  operationsPrepDate: 'Operations prep date',
  handDeliveryPerson: 'Hand Delivery Person',
  handDeliveryNote: 'Hand Delivery Note',
  itemUpdated: 'Item updated',
  recipientVerification: 'Recipient Verification',
  operationsName: 'Operations',
  regulatoryName: 'Regulatory',
  requesterName: 'Requester',
};
