import { combineReducers } from 'redux';
import _ from 'lodash';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import { ADD_NOTE } from 'components/common/notes/actions/notesActions';

import { GET_LATEST_SITE_NOTE } from '../actions/clinicalStudyActions';

const addLatestNote = (state, action) => {
  const studySites = _.reduce(action.sites, (result, siteId) => {
    result[siteId] = {};
    return result;
  }, {});

  return {
    ...state,
    [action.studyId]: {
      ...state[action.studyId],
      ...studySites,
    },
  };
};

const addLatestNoteSuccess = (state, action) => {
  return {
    ...state,
    [action.studyId]: {
      ...state[action.studyId],
      [action.siteStudyId]: action.result,
    },
  };
};

const byStudyId = createReducer({}, {
  [`${GET_LATEST_SITE_NOTE}`]: addLatestNote,
  [`${GET_LATEST_SITE_NOTE}_SUCCESS`]: addLatestNoteSuccess,
});

const newLatest = createReducer(null, {
  [`${GET_LATEST_SITE_NOTE}`]: () => null,
  [`${ADD_NOTE}_SUCCESS`]: (state, action) => action.result,
});

const latestNotesReducer = combineReducers({
  byStudyId,
  async: asyncReducerHelper(GET_LATEST_SITE_NOTE),
  newLatest,
});

export default latestNotesReducer;
