import { call, put } from 'redux-saga/effects';
import * as GraphService from '../services/graphService';
import {
  LOAD_ERRORS_DATA,
  LOAD_PATIENT_DATA,
  LOAD_IMU_DATA,
} from '../actions/graphActions';

export function* loadErrorsData(action) {
  try {
    const result = yield call(
      GraphService.loadErrorsData,
      action.payload.logId,
      action.payload.pageNumber,
      action.payload.fromDate,
      action.payload.toDate,
    );

    yield put({ type: `${LOAD_ERRORS_DATA}_SUCCESS`, payload: result });
  } catch (error) {
    yield put({ type: `${LOAD_ERRORS_DATA}_FAILURE`, error });
  }
}

export function* loadPatientData(action) {
  try {
    const result = yield call(
      GraphService.loadPatientData,
      action.payload.logId,
      action.payload.pageNumber,
      action.payload.fromDate,
      action.payload.toDate,
    );

    yield put({ type: `${LOAD_PATIENT_DATA}_SUCCESS`, payload: result });
  } catch (error) {
    yield put({ type: `${LOAD_PATIENT_DATA}_FAILURE`, error });
  }
}

export function* loadImuData(action) {
  try {
    const result = yield call(
      GraphService.loadImuData,
      action.payload.logId,
      action.payload.pageNumber,
      action.payload.fromDate,
      action.payload.toDate,
    );

    yield put({ type: `${LOAD_IMU_DATA}_SUCCESS`, payload: result });
  } catch (error) {
    yield put({ type: `${LOAD_IMU_DATA}_FAILURE`, error });
  }
}
