import TokenCall from 'components/auth/services/token';
import Config from 'config';

const {
  NOTES_API_ROOT,
} = Config;

export const loadNotes = async(objectType, objectId) => {
  const response = await TokenCall.get(`${NOTES_API_ROOT}/notes/${objectType}/${objectId}`);

  return response.data;
};

export const addNote = async(objectType, objectId, note) => {
  const response = await TokenCall.post(`${NOTES_API_ROOT}/notes/${objectType}/${objectId}`, note);

  return response.data;
};

export const getLatestNote = async(objectType, objectId) => {
  const response = await TokenCall.get(`${NOTES_API_ROOT}/notes/${objectType}/${objectId}/latest`);

  return response.data;
};
