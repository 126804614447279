import { createSelector } from 'reselect';
import _ from 'lodash';
import { getDeviceTypeById } from './deviceTypeSelectors';

export const getSkusBySku = state => state.inventory.skus.bySku;

export const getSkusList = createSelector(
  getSkusBySku,
  bySku => _.map(bySku, (value, key) => ({ value: value.id, text: `${value.sku} ${value.description}` })),
);

export const getShouldLoadSkuList = state =>
  !state.inventory.skus.loadInfo.isSuccess && !state.inventory.skus.loadInfo.isProcessing;

export const getSku = (state, props) =>
  _.get(state.inventory.skus.bySku, props.skuNumber, {});

export const makeGetSkuType = () => {
  return createSelector(
    getSku,
    sku => sku.type,
  );
};

export const makeGetSkuTypeByDeviceTypeId = () => {
  return createSelector(
    [getDeviceTypeById, getSkusBySku],
    (deviceType, bySku) => {
      const skuNumber = deviceType.sku_number;

      if (!skuNumber) return undefined;

      return _.get(bySku, `[${skuNumber}].type`);
    },
  );
};
