import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';
import { withSnackbar } from 'notistack';

import { cleanAWSMessage } from 'components/utils/errors';

import ErrorSnackbarActions from './ErrorSnackbarActions';
import Message from '../Message';

export class Notifier extends Component {
  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props;

    let notExists = false;

    for (let i = 0; i < newSnacks.length; i++) {
      if (notExists) continue;
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach(notification => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) return;

      if (notification.retryAction &&
        notification.retryAction.type === 'FETCH_ORDER_DEVICE_COUNT' &&
        notification.message.message === 'Request failed with status code 404'
      ) return;

      // Display snackbar using notistack
      if (_.get(notification, 'options.variant') === 'error') {
        this.props.enqueueSnackbar(
          cleanAWSMessage(
            notification.message.message,
            notification.message,
          ),
          {
            ...notification.options,
            action: (
              <ErrorSnackbarActions
                key="retry"
                retryAction={() => this.props.dispatch(notification.retryAction)}
                disableRetry={notification.disableRetry}
              />
            ),
            autoHideDuration: 10000,
          },
        );
      } else {
        this.props.enqueueSnackbar(
          <Message id={notification.message} />,
          {
            autoHideDuration: 5000,
            ...notification.options,
          },
        );
      }
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key);
    });
  }

    displayed = [];

    storeDisplayed = id => {
      this.displayed = [...this.displayed, id];
    };

    render() {
      return null;
    }
}

Notifier.propTypes = {
  notifications: PropTypes.array.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = store => ({
  notifications: store.notifications,
});

const enhance = compose(
  connect(mapStateToProps),
  withSnackbar,
);

export default enhance(Notifier);
