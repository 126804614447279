export const LOAD_ERRORS_DATA = 'LOAD_ERRORS_DATA';
export const LOAD_PATIENT_DATA = 'LOAD_PATIENT_DATA';
export const LOAD_IMU_DATA = 'LOAD_IMU_DATA';

export function loadErrorsData(logId, pageNumber, fromDate, toDate) {
  return {
    type: LOAD_ERRORS_DATA,
    payload: {
      logId,
      pageNumber,
      fromDate,
      toDate,
    },
  };
}

export function loadPatientData(logId, pageNumber, fromDate, toDate) {
  return {
    type: LOAD_PATIENT_DATA,
    payload: {
      logId,
      pageNumber,
      fromDate,
      toDate,
    },
  };
}

export function loadImuData(logId, pageNumber, fromDate, toDate) {
  return {
    type: LOAD_IMU_DATA,
    payload: {
      logId,
      pageNumber,
      fromDate,
      toDate,
    },
  };
}
