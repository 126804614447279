const getStyles = () => ({
  wrapper: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  pageSelector: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  rightContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  leftContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '20px',
  },
  select: {
    textAlign: 'right',
    backgroundColor: '#189ACA',
    width: '110px',
    height: '30px',
    color: 'white',
    borderRadius: '50px',
    marginRight: '10px',
  },
  selectWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  icon: {
    fill: 'white',
    marginRight: '10px',
  },
  selectMenu: {
    color: 'white',
    fill: 'white',
    marginTop: '2px',
    textAlign: 'center',
  },
  selectMenuItem: {
    padding: '0 24px',
    lineHeight: '32px',
    color: '#666',
    fontSize: '15px',
  },
  selected: {
    color: '#189ACA',
    backgroundColor: 'white !important',
  },
});

export default getStyles;
