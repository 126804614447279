import { createSelector } from 'reselect';
import _ from 'lodash';

export const getTokens = state => state.users.pagination.pages.tokens;

export const getCurrentPage = state => state.users.pagination.pages.current;

export const getIsNextPageDisabled = createSelector(
  [getTokens],
  tokens => !_.last(tokens),
);

export const getIsPreviousPageDisabled = createSelector(
  [getCurrentPage],
  currentPage => !currentPage,
);

export const getCurrentPageNumber = state => (
  state.users.pagination.pages.tokens.indexOf(getCurrentPage(state)) + 1
);

export const getTotalPagesNumber = (state, perPage) => (
  Math.ceil(state.users.pagination.total / perPage)
);

export const makeGetPageNumbers = () => {
  return createSelector(
    [getCurrentPageNumber, getTotalPagesNumber],
    (currentPageNumber, totalPagesNumber) => {
      return {
        currentPageNumber,
        totalPagesNumber,
      };
    },
  );
};
