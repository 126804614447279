import _ from 'lodash';
import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_FIRMWARE_DEVICES,
  ASSIGN_FIRMWARE,
  UNASSIGN_FIRMWARE,
} from '../actions/firmwareActions';

const updateByIdState = (state, action) => ({ ...state, [action.result.id]: action.result });

export const byId = createReducer({}, {
  [`${LOAD_FIRMWARE_DEVICES}_SUCCESS`]: (state, action) => _.keyBy(action.result, f => f.id),
  [`${ASSIGN_FIRMWARE}_SUCCESS`]: updateByIdState,
  [`${UNASSIGN_FIRMWARE}_SUCCESS`]: updateByIdState,
});

export const allIds = createReducer([], {
  [`${LOAD_FIRMWARE_DEVICES}_SUCCESS`]: (state, action) => _.map(action.result, f => f.id),
});

const getFirmwareId = deviceFirmware => deviceFirmware.firmware_id || deviceFirmware.device.serial_number[0];

const updateByFirmwareIdState = (state, action) => {
  const deviceFirmware = action.result;
  const newFirmwareId = getFirmwareId(deviceFirmware);
  const newState = {};

  Object.keys(state).forEach(firmwareId => {
    newState[firmwareId] = _.filter(state[firmwareId], id => id !== deviceFirmware.id);
  });

  if (!newState[newFirmwareId]) {
    newState[newFirmwareId] = [];
  }

  newState[newFirmwareId].push(deviceFirmware.id);
  return newState;
};

export const byFirmwareId = createReducer({}, {
  [`${LOAD_FIRMWARE_DEVICES}_SUCCESS`]: (state, action) => {
    const devices = {};

    _.forEach(action.result, deviceFirmware => {
      const firmwareId = getFirmwareId(deviceFirmware);

      if (!deviceFirmware.firmware_id && firmwareId !== 'B' && firmwareId !== 'S') return;

      if (!devices[firmwareId]) {
        devices[firmwareId] = [];
      }

      devices[firmwareId].push(deviceFirmware.id);
    });

    return devices;
  },
  [`${ASSIGN_FIRMWARE}_SUCCESS`]: updateByFirmwareIdState,
  [`${UNASSIGN_FIRMWARE}_SUCCESS`]: updateByFirmwareIdState,
});

export const loadInfo = asyncReducerHelper(LOAD_FIRMWARE_DEVICES);

const firmwareDevicesReducer = combineReducers({
  loadInfo,
  byId,
  allIds,
  byFirmwareId,
});

export default firmwareDevicesReducer;
