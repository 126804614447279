/* eslint max-len: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { IconButton, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

import Session from 'components/utils/session';
import { requestLogout } from 'components/auth/actions/authActions';
import LogoIcon from 'components/styles/icons/LogoIcon';
import AvatarIcon from 'components/styles/icons/AvatarIcon';
import HomeIcon from 'components/styles/icons/HomeIcon';
import PatientListIcon from 'components/styles/icons/PatientListIcon';
import MenuIcon from 'components/styles/icons/MenuIcon';
import LogoutIcon from 'components/styles/icons/LogoutIcon';
import InventoryIcon from 'components/styles/icons/InventoryIcon';
import OperationsIcon from 'components/styles/icons/OperationsIcon';
import QuarantineIcon from 'components/styles/icons/QuarantineIcon';
import PJson from '../../../package.json';
import getStyles from './Sidebar.styles';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: Session.sidebar_collapsed,
    };

    this.styles = getStyles(this.state.collapsed);

    this.renderTabItem = this.renderTabItem.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    const collapsed = !this.state.collapsed;

    this.setState({
      collapsed,
    });
    Session.sidebar_collapsed = collapsed;

    this.styles = getStyles(collapsed);
  }

  renderTabIcon(tabKey, style) {
    switch (tabKey) {
      case 'home':
        return (
          <HomeIcon style={style} />
        );
      case 'patients':
        return (
          <PatientListIcon style={style} />
        );
      case 'inventory':
        return (
          <InventoryIcon style={style} />
        );
      case 'operations':
        return (
          <OperationsIcon style={style} />
        );
      case 'quarantine':
        return (
          <QuarantineIcon style={style} />
        );
      default:
        return (<i />);
    }
  }

  renderTitle() {
    const titleElement = (
      <div style={this.styles.header}>
        <span
          id="logo-clickable"
          style={this.styles.title}
        >
          <LogoIcon style={this.styles.title.icon} />
        </span>
      </div>
    );

    return titleElement;
  }

  renderTabItem(item) {
    const { activeTab } = this.props;

    let activeStyle;
    let iconActiveStyle;
    let markerActiveStyle;

    if (activeTab === item.key) {
      activeStyle = this.styles.tabs.item.ACTIVE;
      iconActiveStyle = this.styles.tabs.item.icon.ACTIVE;
      markerActiveStyle = this.styles.tabs.item.marker.ACTIVE;
    }

    const style = {
      ...this.styles.tabs.item,
      ...activeStyle,
    };
    const iconStyle = {
      ...this.styles.tabs.item.icon,
      ...iconActiveStyle,
    };
    const markerStyle = {
      ...this.styles.tabs.item.marker,
      ...markerActiveStyle,
    };

    return (
      <Link
        style={style}
        to={`/${item.key}`}
        key={item.key}
      >
        <div style={markerStyle} />
        {this.renderTabIcon(item.key, iconStyle)}
        <div style={this.styles.tabs.item.text}>
          <FormattedMessage
            id={item.intlKey}
            defaultMessage={item.defaultIntl}
          />
        </div>
      </Link>
    );
  }

  renderProfile() {
    const { _requestLogout } = this.props;

    return (
      <div style={this.styles.profile}>
        <div style={this.styles.profile.action}>
          <IconButton
            className="btn--toggle-menu"
            classes={{ root: this.props.classes.menuButton }}
            onClick={this.toggleCollapse}
            disableRipple
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div style={this.styles.profile.content}>
          <AvatarIcon
            id="profile__avatar"
            style={this.styles.profile.avatar}
          />
          <div style={this.styles.profile.name}>
            {Session.JWTDecoded.user.name}
          </div>
        </div>
        <div style={this.styles.profile.action}>
          <IconButton
            className="btn--toggle-menu"
            classes={{ root: this.props.classes.menuButton }}
            onClick={_requestLogout}
            disableRipple
          >
            <LogoutIcon />
          </IconButton>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        id="sidebar"
        style={this.styles.container}
      >
        {this.renderTitle()}
        {this.renderProfile()}
        <div style={this.styles.tabs}>
          {this.props.tabItems.map(this.renderTabItem)}
        </div>
        <p style={{fontSize: '1.2em'}}>Version {PJson.version}</p>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  _requestLogout: () => {
    dispatch(requestLogout());
  },
});

Sidebar.propTypes = {
  activeTab: PropTypes.string,
  _requestLogout: PropTypes.func.isRequired,
  tabItems: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withStyles(getStyles),
  connect(null, mapDispatchToProps),
);

export default enhance(Sidebar);
