const getStyles = () => ({
  container: {
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    SMALL: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  title: {
    SMALL: {
      textAlign: 'center',
    },
    marginBottom: 10,
  },
  pageIcon: {
    color: '#189ACA',
    margin: '50% 0 0 0',
  },
  complianceWrapper: {
    marginLeft: 'auto',
  },
});

export default getStyles;
