const getStyles = theme => ({
  table: {
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  tableText: {
    color: theme.palette.textColor,
    fontSize: theme.size.body,
    fontWeight: theme.font.bold,
  },
  orderBtn: {
    marginBottom: 50,
    maxHeight: 40,
    height: 'auto',
    lineHeight: 'auto',
    width: 'auto',
    float: 'right',
    padding: '0 20px',
  },
});

export default getStyles;
