import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import getStyles from './GraphModal.styles';
import Graph from './Graph';

class GraphModal extends Component {
  constructor(props) {
    super(props);

    this.styles = getStyles();
  }

  handleClose = () => {
    this.props.closeDialog();
  }

  render() {
    const {
      isModalOpen,
      deviceLog,
    } = this.props;

    return (
      <Dialog
        id="modal--graph"
        open={isModalOpen}
        classes={{ paper: this.props.classes.modal }}
        onClose={this.handleClose}
      >
        <Graph
          deviceLog={deviceLog || {}}
          isModalOpen={isModalOpen}
        />
      </Dialog>
    );
  }
}

GraphModal.propTypes = {
  classes: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool,
  deviceLog: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
};

export default withStyles(getStyles)(GraphModal);
