import React from 'react';
import PropTypes from 'prop-types';

import SortableHeaderColumn from 'components/common/SortableHeaderColumn';

const HeaderRenderer = ({
  checked,
  dataKey,
  title,
  notSortable,
  activeField,
  onSort,
  sortDirection,
  style,
  isLoading,
}) => {
  if (!checked) {
    return null;
  }

  return (<SortableHeaderColumn
    id={`column--${dataKey}`}
    title={title}
    field={dataKey}
    notSortable={notSortable}
    activeField={activeField}
    onSort={onSort}
    sortDirection={sortDirection}
    style={{
      style,
      borderBottom: 'none',
    }}
    cellProps={{
      component: 'div',
      variant: 'head',
    }}
    isLoading={isLoading}
  />);
};

HeaderRenderer.propTypes = {
  checked: PropTypes.bool,
  dataKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  notSortable: PropTypes.bool,
  activeField: PropTypes.string,
  onSort: PropTypes.func.isRequired,
  sortDirection: PropTypes.number,
  style: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default HeaderRenderer;
