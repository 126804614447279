export const FIRMWARE_OBJECT_TYPE = 'firmware';
export const BASESTATION_DEVICE_TYPE = 'B';
export const STIM_DEVICE_TYPE = 'S';

export const sharedColumns = [
  {
    title: 'operations.details.status',
    key: 'status',
  },
  {
    title: 'operations.details.deviceType',
    key: 'deviceName',
    notSortable: true,
  },
  {
    title: 'operations.details.sku',
    key: 'skuNumber',
    notSortable: true,
  },
  {
    title: 'operations.details.creator',
    key: 'created_by',
  },
];

export const bsColumns = [
  {
    title: 'operations.details.serialNumber',
    key: 'serial_number',
  },
  {
    title: 'operations.details.mac',
    key: 'device_details.mac_address',
  },
  {
    title: 'operations.details.iccid',
    key: 'device_details.iccid',
  },
  {
    title: 'operations.details.imei',
    key: 'device_details.imei',
  },
  ...sharedColumns,
];

export const stimColumns = [
  {
    title: 'operations.details.serialNumber',
    key: 'serial_number',
  },
  {
    title: 'operations.details.mac',
    key: 'device_details.mac_address',
  },
  ...sharedColumns,
];

export const bandColumns = [
  {
    title: 'operations.details.serialNumber',
    key: 'serial_number',
  },
  ...sharedColumns,
];

export const deviceStatus = {
  Packed: 'pa',
  Approved: 'ap',
  Shipped: 'sh',
  Allocated: 'al',
  Assigned: 'as',
  Lost: 'lo',
  Unassigned: 'un',
  Returned: 're',
  QA: 'qa',
  Retired: 'xx',
  InStock: 'in',
  Hold: 'ho',
  Tla: 'tl',
};

export const defaultPrefix = 'BT-';

export const exportListMessage = 'Check your email in few minutes for a link to download the data.';
