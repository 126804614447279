import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withWidth from '@material-ui/core/withWidth';
import { withTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PageWrapper from 'components/common/PageWrapper';

import { loadDeviceTypes } from 'components/inventory/actions/inventoryActions';
import { loadLotDetails } from 'components/operations/actions/operationsActions';
import Details from 'components/common/Details';
import getGlobalStyles from 'components/styles/newGlobal.styles';
import { mediaStyle } from 'components/utils/styles';
import OperationsDetails from 'components/operations/ui/OperationsDetails';
import { getDeviceProperty } from 'components/inventory/selectors';

import { Icon } from '@material-ui/core';

import getStyles from './OperationsDetailsPage.styles';
import LotActions from './ui/LotActions';

class OperationsDetailsPage extends Component {
  constructor(props, context) {
    super(props);
    this.styles = getStyles(props.theme);
    this.globalStyles = getGlobalStyles(props.theme);

    this.detailItems = [
      {
        text: 'OperationsDetailsPage.createDate',
        key: 'created_date',
        type: 'date',
      },
      {
        text: 'OperationsDetailsPage.creator',
        key: 'created_by',
        type: 'text',
      },
      {
        text: 'OperationsDetailsPage.closeDate',
        key: 'close_date',
        type: 'date',
      },
      {
        text: 'OperationsDetailsPage.devicesCount',
        key: 'device_count',
        type: 'text',
      },
      {
        text: 'OperationsDetailsPage.deviceType',
        key: 'deviceName',
        type: 'text',
      },
      {
        text: 'OperationsDetailsPage.sku',
        key: 'skuNumber',
        type: 'text',
      },
    ];
  }

  componentDidMount() {
    const { inventory } = this.props;

    this.props._loadLotDetails(this.props.match.params.lotId);

    if (!inventory.deviceType.async.load.isSuccess && !inventory.deviceType.async.load.isProcessing) {
      this.props._loadDeviceTypes();
    }
  }

  render() {
    const {
      width,
      operations,
      getDeviceProp,
    } = this.props;
    const isClosed = operations.lotDetails.data.close_date !== null;
    const { lotId } = this.props.match.params;

    return (
      <PageWrapper>
        <div style={this.globalStyles.pageHeader}>
          <Link
            to="/operations"
            style={this.globalStyles.linkIcon}
          >
            <Icon
              className="material-icons"
              style={{
                ...this.globalStyles.pageIcon,
                ...this.styles.pageIcon,
              }}
            >
              arrow_back
            </Icon>
          </Link>
          <div>
            <h1 style={{ ...this.globalStyles.pageTitle, ...mediaStyle(this.styles.title, width) }}>
                {operations.lotDetails.data.lot_name}
            </h1>
            <Details
              items={this.detailItems}
              data={{
                ...operations.lotDetails.data,
                deviceName: getDeviceProp(operations.lotDetails.data.device_type_id, 'device_commercial_name'),
                skuNumber: getDeviceProp(operations.lotDetails.data.device_type_id, 'sku_number'),
              }}
            />
          </div>
          <LotActions
            isLotClosed={isClosed}
            styles={this.styles}
          />
        </div>
        <div style={mediaStyle(this.styles.content, width)}>
          <OperationsDetails lotId={lotId} />
        </div>
      </PageWrapper>
    );
  }
}

OperationsDetailsPage.propTypes = {
  theme: PropTypes.object.isRequired,
  width: PropTypes.string,
  match: PropTypes.object.isRequired,
  inventory: PropTypes.object.isRequired,
  _loadLotDetails: PropTypes.func.isRequired,
  _loadDeviceTypes: PropTypes.func.isRequired,
  operations: PropTypes.object.isRequired,
  getDeviceProp: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  operations: state.operations,
  inventory: state.inventory,
  getDeviceProp: (deviceTypeId, prop) => getDeviceProperty(state, deviceTypeId, prop),
});

const mapDispatchToProps = dispatch => ({
  _loadLotDetails: lotId => {
    dispatch(loadLotDetails(lotId));
  },
  _loadDeviceTypes: () => {
    dispatch(loadDeviceTypes());
  },
});

const enhance = compose(
  withWidth(),
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(OperationsDetailsPage);
