import React from 'react';

const listWrapper = (config = {}) => WrappedComponent => {
  class ListWrapper extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        criteria: {},
        sort: {},
        pagination: {},
      };

      this.updateListFunction = null;
    }

    setPagination = pagination => {
      this.setState({
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      });
    }

    setSort = sort => {
      this.setState({
        sort,
      });
    }

    selectedPageChange = selectedPage => {
      this.setState({
        pagination: {
          ...this.state.pagination,
          selectedPage,
        },
      }, this.updateList);
    }

    perPageChange = perPage => {
      this.setState({
        pagination: {
          ...this.state.pagination,
          selectedPage: 1,
          perPage,
        },
      }, this.updateList);
    }

    searchChange = criteria => {
      this.setState(prevState => ({
        criteria,
        pagination: {
          ...prevState.pagination,
          selectedPage: 1,
        },
      }), this.updateList);
    }

    sortChange = (key, direction) => {
      this.setState({
        sort: {
          ...this.state.sort,
          key,
          direction,
        },
      }, this.updateList);
    }

    setUpdateListFunction = func => {
      this.updateListFunction = func;
    }

    updateList = () => {
      const { sort, pagination, criteria } = this.state;

      if (this.updateListFunction) {
        this.updateListFunction(sort, pagination, criteria);
      }
    }

    render() {
      return (
        <WrappedComponent
          setUpdateListFunction={this.setUpdateListFunction}

          setSort={this.setSort}
          sort={this.state.sort}
          sortChange={this.sortChange}

          setPagination={this.setPagination}
          pagination={this.state.pagination}
          perPageChange={this.perPageChange}
          selectedPageChange={this.selectedPageChange}

          searchChange={this.searchChange}
          criteria={this.state.criteria}

          {...this.props}
        />
      );
    }
  }

  ListWrapper.displayName = config.displayName || 'ListWrapper';

  return ListWrapper;
};

export default listWrapper;
