/* eslint  no-console: 0 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import createSagaMiddleware from 'redux-saga';
import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'; // v1.x
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import defaultTheme from 'components/styles/default.theme';
import Notifier from 'components/common/Notifier';
import greenApp from 'store/reducers';
import greenSaga from 'store/sagas';
import greenEpic from 'store/epics';
import Routes from 'containers/Routes';

import initialState from 'store/initialState';

import { IntlProvider } from 'react-intl-redux';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import Config from 'config';
import io from 'socket.io-client';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import SnackbarProviderOverride from 'SnackbarProviderOverride';
import IntlGlobalProvider from 'IntlGlobalProvider';

addLocaleData([...en, ...es]);

const greenMiddleware = createSagaMiddleware();
const epicMiddleware = createEpicMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                        || compose; // Redux browser plugin

export const store = createStore(
  greenApp,
  initialState,
  composeEnhancers(applyMiddleware(greenMiddleware, epicMiddleware)),
);

greenMiddleware.run(greenSaga);
epicMiddleware.run(greenEpic);

const theme = createMuiTheme(defaultTheme);

class App extends Component {
  constructor(props) {
    super(props);

    this.socket = io(Config.WS_DEVICE_URL, { path: Config.WS_PATH });
  }

  getChildContext() {
    return {
      socket: this.socket,
    };
  }

  render() {
    return (
      <Provider store={store}>
        <IntlProvider>
          <IntlGlobalProvider>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <div
                    id="app"
                    style={{ height: '100%', width: '100%' }}
                  >
                    <SnackbarProviderOverride >
                      <Fragment>
                        <Notifier />
                        <Routes />
                      </Fragment>
                    </SnackbarProviderOverride>
                  </div>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
          </IntlGlobalProvider>
        </IntlProvider>
      </Provider>
    );
  }
}

App.childContextTypes = {
  socket: PropTypes.object,
};

export default App;
