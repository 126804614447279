import TokenCall from 'components/auth/services/token';
import Config from 'config';
import { getResponseData } from 'components/utils/asyncHelpers';

const {
  SHIPPING_API_ROOT,
} = Config;

export const createShipmentOrder = ({ orderId, body }) =>
  getResponseData('post', `${SHIPPING_API_ROOT}/orders/${orderId}/shipment`, body);

export const createHandDelivery = async({ orderId, body }) => {
  const response = await TokenCall.post(`${SHIPPING_API_ROOT}/orders/${orderId}/hand_delivery`, body);

  return response.data;
};

export const deleteHandDelivery = async({ orderId }) => {
  const response = await TokenCall.delete(`${SHIPPING_API_ROOT}/orders/${orderId}/hand_delivery`);

  return response.data;
};

export const fetchRates = async({ orderId }) => {
  const response = await TokenCall.get(`${SHIPPING_API_ROOT}/orders/${orderId}/shipment/rates`);

  return response.data;
};

export const regenerateRates = async({ orderId }) => {
  const response = await TokenCall.get(`${SHIPPING_API_ROOT}/orders/${orderId}/shipment/rates/regenerate`);

  return response.data;
};

export const buyDelivery = async({ orderId, body }) => {
  const response = await TokenCall.post(`${SHIPPING_API_ROOT}/orders/${orderId}/shipment/buy`, body);

  return response.data;
};

export const getPostageLabel = async({ orderId }) => {
  const response = await TokenCall.get(`${SHIPPING_API_ROOT}/orders/${orderId}/shipment/postage_label`);

  return response.data;
};

export const getOrderShipment = async({ orderId }) => {
  const response = await TokenCall.get(`${SHIPPING_API_ROOT}/orders/${orderId}/shipment`);

  return response.data;
};

export const buyPickup = ({ orderId, body }) =>
  getResponseData('post', `${SHIPPING_API_ROOT}/orders/${orderId}/shipment/pickup`, body);

export const getPickup = ({ orderId }) =>
  getResponseData('get', `${SHIPPING_API_ROOT}/orders/${orderId}/shipment/pickup`);
