import axios from 'axios';
import Config from 'config';
import _ from 'lodash';
import { cleanAWSMessage } from 'components/utils/errors';

import { services } from '../utils/constants';

const {
  DEVICE_API_ROOT,
  INVENTORY_API_ROOT,
  LAMBDA_API_ROOT,
} = Config;

const defaultError = e => {
  return {
    data: {
      'Error Message': cleanAWSMessage(e.message, e),
    },
  };
};
const apiCall = route => (axios.get(route).catch(e => defaultError(e)));

const loadVersion = () => {
  const urls = [
    `${DEVICE_API_ROOT}/v0/status`,
    'version.json',
    `${INVENTORY_API_ROOT}/v0/status`,
  ];

  const promisePool = urls.map(url => apiCall(url));

  return Promise.all(promisePool)
    .then(res => {
      const response = {};

      _.forEach(services, (service, index) => {
        response[service] = _.get(res[index], 'data', {});
      });

      return response;
    })
    .catch(error => {
      return Promise.reject(_.get(error.response, 'data'));
    });
};

export const loadLambdaVersion = async() => {
  const response = await apiCall(`${LAMBDA_API_ROOT}/status`);

  return response.data;
};

export default {
  loadVersion,
};
