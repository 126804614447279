import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core';

import getStyles from './SimpleTable.styles';
import SimpleTableHeader from './SimpleTableHeader';

const SimpleTable = props => {
  const { theme, children, headers, customStyles } = props;
  const styles = getStyles(theme);

  return (
    <table style={{ ...styles.table, ...customStyles}}>
      <SimpleTableHeader headers={headers} styles={styles} />
      <tbody>
        {children}
      </tbody>
    </table>
  );
};

SimpleTable.propTypes = {
  theme: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  headers: PropTypes.array.isRequired,
  customStyles: PropTypes.object,
};

export default withTheme(SimpleTable);
