/* eslint max-len: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import '../css/operationsIcon.css';

const OperationsIcon = props => (
    <SvgIcon
      {...props}
      viewBox="0 0 23.01 23.008"
      fontSize="inherit"
    >
      <g
        id="settings"
        transform="translate(-0.501 -0.496)"
      >
        <path
          id="Path_308"
          data-name="Path 308"
          className="operations-1"
          d="M14,19l2.95,2.95a3.5,3.5,0,0,0,5,0h0a3.5,3.5,0,0,0,0-5L20,15"
          style={{
            stroke: props.htmlColor,
          }}
        />
        <line
          id="Line_329"
          data-name="Line 329"
          className="operations-2"
          x1="3.425"
          y1="3.425"
          transform="translate(6.5 6.8)"
          style={{
            stroke: props.htmlColor,
          }}
        />
        <path
          id="Path_309"
          data-name="Path 309"
          className="operations-1"
          d="M4.4,7.5l2.1-.7.7-2.1L4.4,1.9,1.6,4.7Z"
          style={{
            stroke: props.htmlColor,
          }}
        />
        <path
          id="Path_310"
          data-name="Path 310"
          className="operations-1"
          d="M22.678,4.922,19.6,7.987,16,4.411l3.08-3.066a4.212,4.212,0,0,0-2.259-.307,5.615,5.615,0,0,0-5.133,5.723A4.226,4.226,0,0,0,12,8.4L2.145,17.083a3.419,3.419,0,0,0-.205,4.905h0a3.287,3.287,0,0,0,4.928-.2l8.726-9.81a6.728,6.728,0,0,0,2.875.2A5.686,5.686,0,0,0,22.78,8.192a5.083,5.083,0,0,0-.1-3.27Z"
          style={{
          stroke: props.htmlColor,
        }}
        />
      </g>
    </SvgIcon>
);

OperationsIcon.propTypes = {
  htmlColor: PropTypes.string,
};

export default OperationsIcon;
