import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CalaDialog from 'components/common/calaDialog/CalaDialog';
import CreateIcon from 'components/styles/icons/CreateIcon';
import { defaultPrefix } from 'components/operations/utils/constants';
import AssignForm from './AssignForm';
import UnassignForm from './UnassignForm';

const Container = ({
  selectedDevice, closeDialog, prefixList, ...rest
}) => {
  if (!selectedDevice) {
    return null;
  }

  const renderAssignForm = selectedDevice.canAssign && (
    <AssignForm
      deviceInventoryId={selectedDevice.device_inventory_id}
      prefixList={prefixList}
      initialValues={
        {
          serial_number: selectedDevice.serial_number,
          assign_date: new Date(),
          prefix: defaultPrefix,
        }}
    />
  );
  const renderUnassignForm = selectedDevice.canUnassign && (
    <UnassignForm
      deviceInventoryId={selectedDevice.device_inventory_id}
      initialValues={
        {
          serial_number: selectedDevice.serial_number,
          unassign_date: moment().format('MM/DD/YYYY'),
          brighttree_id: selectedDevice.device_details.subject_id,
        }
      }
    />
  );
  const title = selectedDevice.canAssign ?
    'operations.form.assignDevice' :
    'operations.form.unassignDevice';

  return (
    <CalaDialog
      isModalOpen
      closeDialog={closeDialog}
      id="modal--assign-device"
      contentId="assign-device__content"
      title={title}
      icon={<CreateIcon />}
    >
      {renderAssignForm}
      {renderUnassignForm}
    </CalaDialog>
  );
};

Container.propTypes = {
  selectedDevice: PropTypes.object,
  prefixList: PropTypes.array.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default Container;
