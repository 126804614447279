import * as en from 'intl/en';
import _ from 'lodash';

export const translateLabel = (label, intl) => {
  // sets label without error message for missing translation displayed in console
  return _.get(en, label)
    ? intl.formatMessage({
      id: label,
      defaultMessage: _.get(en, label),
    })
    : label;
};
