import React, { Component } from 'react';
import PropTypes from 'prop-types';

import List from './List';

class DeviceAssignmentContainer extends Component {
  render() {
    return (
      <List prefixList={this.props.prefixList} />
    );
  }
}

DeviceAssignmentContainer.propTypes = {
  prefixList: PropTypes.array.isRequired,
};

export default DeviceAssignmentContainer;
