import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Column } from 'react-virtualized';
import { TableCell, Tooltip } from '@material-ui/core';

import * as en from 'intl/en';
import Switch from 'components/common/Switch';
import { isAllowedRole } from 'components/users/utils/userRoles';
import { showActionColumn } from '../../utils/helpers';
import permissions from '../../utils/permissions';
import { baseStationKey } from '../../utils/constants';

const BaseStationSwitch = props => {
  const {
    styles,
    toggleBaseStationSwitch,
    intl,
    roleId,
    actionColumns,
  } = props;
  const isEnabled = showActionColumn(actionColumns, baseStationKey);

  return (
    isEnabled ? (
      <Column
        headerRenderer={headerProps => (
          <TableCell
            component="div"
            variant="head"
            style={{ border: 'none' }}
          >
            <div style={{ width: 62, height: 50 }} />
          </TableCell>
        )}
        dataKey={baseStationKey}
        width={65}
        cellRenderer={cellProps => {
          const handleChange = (ev, checked) => {
            toggleBaseStationSwitch(cellProps.rowData.device.device_id, checked);
          };

          const isEnableBaseStation = _.get(cellProps.rowData, 'device.device_config.enable_basestation');

          let tooltipText;

          if (isEnableBaseStation) {
            tooltipText = intl.formatMessage({
              id: 'files.table.baseStationSync',
              defaultMessage: en.files.table.baseStationSync,
            });
          } else {
            tooltipText = intl.formatMessage({
              id: 'files.table.phoneSync',
              defaultMessage: en.files.table.phoneSync,
            });
          }

          const isAdmin = isAllowedRole(roleId, permissions.baseStation);

        return (
          <TableCell
            component="div"
            variant="body"
            style={{ border: 'none' }}
          >
            <div style={styles.switchWrapper}>
              {
                isEnableBaseStation !== undefined &&
                <Tooltip title={tooltipText}>
                  <Switch
                    checked={isEnableBaseStation}
                    onChange={handleChange}
                    value={cellProps.rowData.device_log_id.toString()}
                    disabled={!isAdmin}
                  />
                </Tooltip>
              }
            </div>
          </TableCell>
        );
      }}
        headerStyle={{ width: 62, margin: 0 }}
        style={{ margin: 0, paddingLeft: '10px', paddingTop: '6px' }}
      />
    ) : null
  );
};

BaseStationSwitch.propTypes = {
  roleId: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
  actionColumns: PropTypes.array.isRequired,
  toggleBaseStationSwitch: PropTypes.func.isRequired,
};

export default BaseStationSwitch;
