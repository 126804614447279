const _default = {
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  DEVICE_API_DOMAIN: process.env.REACT_APP_DEVICE_API_DOMAIN,
  INVENTORY_API_DOMAIN: process.env.REACT_APP_INVENTORY_API_DOMAIN,
  CLINIC_API_URI: 'clinic/api/v0',
  AUTH_API_URI: 'auth/api/v0',
  DEVICE_API_URI: 'device/api',
  CLINICAL_STUDY_API_URI: 'clinical_study/api/v0',
  NOTES_API_URI: 'notes/api/v0',
  INVENTORY_API_URI: 'inventory/api',
  SIM_API_URI: 'sim_service/api/v0',
  SHIPPING_API_URI: 'shipping/api/v0',
  LAMBDA_API_URI: 'lambda',
  WS_PATH: process.env.WS_PATH || '/device/socket.io',
  calaAddress: {
    street1: process.env.CALA_STREET1 || '875 Mahler Rd #168',
    street2: process.env.CALA_STREET2 || null,
    city: process.env.CALA_CITY || 'Burlingame',
    state: process.env.CALA_STATE || 'CA',
    zip: process.env.CALA_ZIP || '94010',
    country: process.env.CALA_COUNTRY || 'USA',
    company: process.env.CALA_COMPANY || 'Cala Health',
    phone: process.env.CALA_PHONE || '(415) 890-3961',
  },
};

// TODO remove unused services

const production = {};
const development = {
  /*
  CLINIC_API_PORT: 5001,
  AUTH_API_PORT: 5002,
  DEVICE_API_PORT: 5003,
  */
};
const test = {};

export default {
  ..._default,
  production,
  development,
  test
};
