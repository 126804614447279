const getStyles = () => ({
  graphButton: {
    color: '#189ACA',
  },
  downloadButton: {
    color: '#189ACA',
  },
  perPage: {
    padding: '20px',
    display: 'flex',
  },
  paginationContainer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableWrapper: {
    position: 'relative',
  },
  tableRow: {
    color: 'inherit',
    height: '48px',
    display: 'table-row',
    outline: 'currentcolor none medium',
    verticalAlign: 'middle',
    borderBottom: '1px solid rgb(160, 160, 160)',
  },
  searchMessage: {
    padding: '10px',
    position: 'relative',
    left: '680px',
    top: '10px',
    textTransform: 'uppercase',
  },
});

export default getStyles;
