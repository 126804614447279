const getStyles = theme => ({
  container: {
    width: '100%',
  },
  table: {
    minWidth: 700,
    header: {
      borderBottom: 'none',
      textTransform: 'uppercase',
    },
    headerRow: {
      color: 'inherit',
      height: '48px',
      display: 'table-row',
      outline: 'currentcolor none medium',
      verticalAlign: 'middle',
      borderBottom: '1px solid #A0A0A0',
    },
  },
  iconColumn: {
    width: 'auto',
  },
  tableCell: {
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.highlightTextColor,
  },
  logo: {
    maxWidth: 30,
    maxHeight: 30,
    display: 'inline-block',
  },
  perPage: {
    padding: '20px',
    display: 'flex',
  },
  paginationContainer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableWrapper: {
    position: 'relative',
  },
  errorMessage: {
    padding: '10px',
    position: 'relative',
    left: '580px',
    top: '10px',
    textTransform: 'uppercase',
    color: theme.palette.textColor,
  },
});

export default getStyles;
