import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import { FormHelperText } from '@material-ui/core';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import * as en from 'intl/en';

import { translateLabel } from '../helpers';
import styles from './Datepicker.styles';

const DatepickerInput = ({
  input,
  label,
  classes,
  meta: { touched, error },
  intl,
  style,
  ...custom
}) => (
  <span>
    <DatePicker
      error={error && touched}
      label={label}
      fullWidth
      format="MM/DD/YYYY"
      emptyLabel=""
      InputProps={{
        classes: {
          input: classes.input,
        },
        disableUnderline: true,
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
      }}
      autoOk
      {...input}
      {...custom}
    />
    {
      touched && error &&
      <FormHelperText error>
        {intl.formatMessage({ id: error.message, defaultMessage: en[error.message]}, error.values)}
      </FormHelperText>
    }
  </span>
);

DatepickerInput.propTypes = {
  intl: intlShape.isRequired,
  input: PropTypes.object,
  label: PropTypes.string,
  classes: PropTypes.object,
  meta: PropTypes.object,
  style: PropTypes.object,
};

const DatepickerInputWithClasses = withStyles(styles)(DatepickerInput);

const Datepicker = ({ name, label, intl, validate, placeholder, ...otherProps }) => (
  <Field
    name={name}
    component={DatepickerInputWithClasses}
    label={translateLabel(label, intl)}
    placeholder={translateLabel(placeholder, intl)}
    validate={validate}
    intl={intl}
    {...otherProps}
  />
);

Datepicker.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.any,
};

export default injectIntl(Datepicker);

