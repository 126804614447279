const getStyles = () => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  button: {
    position: 'absolute',
    top: '50%',
    right: '0.5em',
    transform: 'translateY(-50%)',
    zIndex: 20,
    width: 'auto',
  },
});

export default getStyles;
