import React from 'react';
import PropTypes from 'prop-types';
import { Column } from 'react-virtualized';
import { TableCell } from '@material-ui/core';

import { profileInfoShape } from '../utils/DeviceLogs.props';
import ControlColumns from '../ControlColumns';

const ValidTransferColumn = ({ styles, headerColumns, actionColumns, updateActionColumns, updateColumns, profile }) => (
  <Column
    headerRenderer={() => (
      <TableCell
        component="div"
        variant="head"
        style={{ border: 'none' }}
      >
        <ControlColumns
          columns={headerColumns}
          actionColumns={actionColumns}
          updateColumns={updateColumns}
          updateActionColumns={updateActionColumns}
          profile={profile}
        />
      </TableCell>
    )}
    dataKey="actions"
    width={50}
    flexShrink={1}
    cellRenderer={cellProps => (
      <TableCell
        component="div"
        variant="body"
        style={styles.validTransfer.column}
      >
      {
        cellProps.rowData.valid_transfer === null
        ? null
        : <div
          data-tip
          data-for="valid_transfer"
          style={{
              ...styles.validTransfer.mark,
              ...(cellProps.rowData.valid_transfer
                ? styles.validTransfer.mark.isValid
                : styles.validTransfer.mark.isInvalid),
            }}
        />
      }
      </TableCell>
  )}
  />
);

ValidTransferColumn.propTypes = {
  profile: profileInfoShape.isRequired,
  styles: PropTypes.object,
  headerColumns: PropTypes.array,
  actionColumns: PropTypes.array,
  updateColumns: PropTypes.func.isRequired,
  updateActionColumns: PropTypes.func.isRequired,
};

export default ValidTransferColumn;
