import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import { required } from 'components/common/forms/validation';
import Form from 'components/common/forms/Form';
import { ADD_DEVICE_TYPE } from 'components/common/forms/names';
import { Checkbox, TextField, SelectField } from 'components/common/forms/elements';

import { getSkusList } from '../../selectors';
import { addDeviceType, updateDeviceType } from '../../actions/inventoryActions';

const prefixItems = [{ text: 'B', value: 'B' }, { text: 'S', value: 'S' }];

const submit = (values, dispatch, { initialize }) => new Promise((resolve, reject) => {
  if (values.device_type_id) {
    initialize(values);
    dispatch(updateDeviceType(values, { resolve, reject }));
  } else {
    dispatch(addDeviceType(values, { resolve, reject }));
  }
});

const AddDeviceTypeForm = props => {
  const [selectedPrefix, setPrefix] = React.useState('');
  const [deviceTypeCheckboxes, setDeviceTypeCheckboxes] = React.useState({
    has_serial_number: false,
    has_mac_address: false,
    has_sim_card: false,
    has_security_key: false,
  });

  const onPrefixChange = value => setPrefix(value);

  const onCheckBoxValueChange = (name, value) => {
    setDeviceTypeCheckboxes({
      ...deviceTypeCheckboxes,
      [name]: value,
    });
  };

  /* eslint-disable complexity */
  const renderErrorBlock = () => {
    const stimConditions = (deviceTypeCheckboxes.has_serial_number &&
      deviceTypeCheckboxes.has_mac_address) &&
      (!deviceTypeCheckboxes.has_sim_card && !deviceTypeCheckboxes.has_security_key);

    const baseStationConditions = deviceTypeCheckboxes.has_serial_number &&
      deviceTypeCheckboxes.has_mac_address &&
      deviceTypeCheckboxes.has_security_key && deviceTypeCheckboxes.has_sim_card;

    const bandConditions = deviceTypeCheckboxes.has_serial_number ||
      deviceTypeCheckboxes.has_mac_address ||
      deviceTypeCheckboxes.has_security_key ||
      deviceTypeCheckboxes.has_sim_card;

    if (selectedPrefix === 'S' && !stimConditions) {
      return (
        <div>
          <span style={{ color: 'red' }}>
            Stim devices should only have Mac Address and Serial Number checkbox selected
          </span>
        </div>
      );
    } else if (selectedPrefix === 'B' && !baseStationConditions) {
      return (
        <div>
          <span style={{ color: 'red' }}>
            Base Station devices should have Mac Address, Serial Number, Sim Card,
            and Security Certificate checkbox selected
          </span>
        </div>
      );
    } else if (selectedPrefix === '' && bandConditions) {
      return (
        <div>
          <span style={{ color: 'red' }}>
            Band devices should not have any checkbox selected
          </span>
        </div>
      );
    }
  };

  const isEdit = !!props.data.device_type_id;
  const successMessage = {
    message: isEdit
      ? 'inventory.deviceTypes.updateDeviceTypeSuccess'
      : 'inventory.deviceTypes.addDeviceTypeSuccess',
  };
  const errorMessage = {
    message: isEdit
      ? 'inventory.deviceTypes.updateDeviceTypeError'
      : 'inventory.deviceTypes.addDeviceTypeError',
  };

  return (
    <Form
      id={ADD_DEVICE_TYPE}
      onSubmit={submit}
      successMessage={successMessage}
      errorMessage={errorMessage}
      {...props}
    >
      <TextField
        name="device_commercial_name"
        label="inventory.form.deviceCommercialName"
        validate={required}
      />
      <TextField
        name="device_code_name"
        label="inventory.form.deviceCodeName"
        validate={required}
        disabled={isEdit}
      />
      <SelectField
        name="prefix"
        label="inventory.form.prefix"
        items={prefixItems}
        itemValue="value"
        itemText="text"
        itemChange={onPrefixChange}
      />
      <SelectField
        name="sku_id"
        label="inventory.form.skuId"
        validate={required}
        items={props.skuList}
        itemValue="value"
        itemText="text"
      />
      <div>
        <Checkbox
          name="has_serial_number" label="inventory.form.serialNumber"
          disabled={isEdit}
          validate={selectedPrefix !== '' ? required : undefined}
          checkboxChange={onCheckBoxValueChange}
        />
        <Checkbox
          name="has_mac_address" label="inventory.form.macAddress"
          disabled={isEdit}
          validate={selectedPrefix !== '' ? required : undefined}
          checkboxChange={onCheckBoxValueChange}
        />
        <Checkbox
          name="has_sim_card" label="inventory.form.simCard" disabled={isEdit}
          validate={selectedPrefix === 'B' ? required : undefined}
          checkboxChange={onCheckBoxValueChange}
        />
        <Checkbox
          name="has_security_key" label="inventory.form.certifications" disabled={isEdit}
          validate={selectedPrefix === 'B' ? required : undefined}
          checkboxChange={onCheckBoxValueChange}
        />
      </div>
      {renderErrorBlock()}
    </Form>
  );
};

AddDeviceTypeForm.propTypes = {
  data: PropTypes.object,
  skuList: PropTypes.array.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  initialValues: ownProps.data,
  skuList: getSkusList(state),
});

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: ADD_DEVICE_TYPE,
    enableReinitialize: true,
  }),
);

export default enhance(AddDeviceTypeForm);
