import React from 'react';
import PropTypes from 'prop-types';

import SessionDetails from './SessionDetails';
import SessionDate from './SessionDate';
import ExtraSessions from './ExtraSessions';

const ComplianceTableRow = props => {
  const { data } = props;

  return (
    <tr>
      <SessionDate date={data.date} />
      <SessionDetails session={data.first_session} />
      <SessionDetails session={data.second_session} />
      <ExtraSessions extra={data.extra_sessions} />
    </tr>
  );
};

ComplianceTableRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ComplianceTableRow;
