/* eslint-disable complexity */
import theme from 'components/styles/default.theme';

const COLOR_FADE = 'rgba(255, 255, 255, 0.2)';

const getStyles = COLLAPSED => ({
  container: {
    minHeight: '100%',
    minWidth: 300,
    textAlign: 'center',
    color: theme.palette.secondaryTextColor,
    background: theme.palette.backgroundGradient,
    overflow: 'hidden auto',
    transition: 'min-width 0.5s',
    ...COLLAPSED && {
      minWidth: 0,
      width: 100,
    },
  },
  header: {
    display: 'flex',
    height: theme.height.pageHeader,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  title: {
    icon: {
      width: '10em',
      height: '10em',
      color: theme.palette.secondaryTextColor,
      ...COLLAPSED && {
        width: '5em',
        height: '5em',
      },
    },
  },
  profile: {
    display: 'flex',
    padding: `${theme.padding.pageSmall} ${theme.padding.textInput} ${theme.padding.pageMedium}`,
    borderTop: `1px solid ${COLOR_FADE}`,
    ...COLLAPSED && {
      display: 'block',
      padding: `${theme.padding.pageMedium} ${theme.padding.textInput} ${theme.padding.pageMedium}`,
    },
    action: {
      flex: '0 0 auto',
      button: {
        color: theme.palette.secondaryTextColor,
      },
    },
    content: {
      flex: '1 1 auto',
      width: 0,
      paddingTop: theme.padding.pageSmall,
      textAlign: 'center',
      ...COLLAPSED && {
        width: 'auto',
        paddingBottom: theme.padding.pageSmall,
      },
    },
    avatar: {
      borderRadius: '50%',
      border: `2px solid ${COLOR_FADE}`,
      backgroundClip: 'content-box',
      padding: 3,
      width: '9em',
      height: '9em',
      ...COLLAPSED && {
        padding: 1,
        width: '3em',
        height: '3em',
      },
    },
    name: {
      marginTop: theme.spacing(3),
      fontWeight: theme.font.bold,
      ...COLLAPSED && {
        display: 'none',
      },
    },
    address: {
      marginTop: theme.spacing(1),
      fontWeight: theme.font.thin,
      ...COLLAPSED && {
        display: 'none',
      },
    },
  },
  tabs: {
    padding: `${theme.padding.pageSmall} 0 ${theme.padding.pageMedium}`,
    marginBottom: 0,
    borderTop: `1px solid ${COLOR_FADE}`,
    ...COLLAPSED && {
      padding: theme.padding.textInput,
    },
    item: {
      display: 'flex',
      margin: `${theme.spacing(6)} 0`,
      cursor: 'pointer',
      alignItems: 'center',
      fontWeight: 200,
      color: COLOR_FADE,
      textDecoration: 'none',
      ...COLLAPSED && {
        justifyContent: 'center',
      },
      ACTIVE: {
        color: theme.palette.secondaryTextColor,
      },
      marker: {
        height: '1em',
        width: '0.5em',
        borderTopRightRadius: '0.5em',
        borderBottomRightRadius: '0.5em',
        backgroundColor: 'transparent',
        marginRight: '15%',
        ACTIVE: {
          backgroundColor: theme.palette.secondaryTextColor,
        },
        ...COLLAPSED && {
          display: 'none',
        },
      },
      icon: {
        width: '2em',
        height: '2em',
        fill: '#fff',
        marginRight: 25,
        opacity: 0.4,
        ACTIVE: {
          opacity: 1,
        },
        ...COLLAPSED && {
          marginRight: 0,
        },
      },
      text: {
        textTransform: 'uppercase',
        letterSpacing: theme.spacing(0),
        // transitionDelay: '0.4s',
        transition: 'visibility 0s ease 0.4s',
        ...COLLAPSED && {
          visibility: 'hidden',
          transition: 'visibility 0s linear 0s',
          width: 0,
          height: 0,
        },
      },
    },
  },
  menuButton: {
    color: theme.palette.secondaryTextColor,
  },
});

export default getStyles;
