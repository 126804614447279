import { reset, clearSubmitErrors, SubmissionError } from 'redux-form';
import { call, put } from 'redux-saga/effects';
import { enqueueSnackbar } from 'components/common/Notifier/actions';
import { SNACKBAR_VARIANTS } from 'components/common/Notifier/utils/constants';

export const asyncReducerHelper = type => {
  return (state = {}, action) => {
    switch (action.type) {
      case type:
        return {
          isProcessing: true,
        };
      case `${type}_SUCCESS`:
        return {
          ...state,
          isProcessing: false,
          isSuccess: true,
        };
      case `${type}_FAILURE`:
        return {
          ...state,
          isProcessing: false,
          isSuccess: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
};

export const createReducer = (initialState, handlers) => {
  return (state = initialState, action) => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
};

export function* sagaRequestWithPayload(type, action, payload = {}) {
  const { notification, ...rest } = payload;

  try {
    const result = yield call(action, rest);

    yield put({ type: `${type}_SUCCESS`, result, payload });
    yield call(handleNotification, { notification });
  } catch (error) {
    yield put({ type: `${type}_FAILURE`, error });
    yield call(handleNotification, { notification, type, error, payload });
  }
}

export function* handleNotification({ notification = {}, type, error, payload, disableRetry = false }) {
  if (!notification) return;

  if (error) {
    if (!notification.doNotShowError) {
      const errNotification = {
        ...notification,
        message: error,
        retryAction: {
          type,
          ...payload,
        },
        options: {
          ...notification.options,
          variant: SNACKBAR_VARIANTS.error,
        },
        disableRetry,
      };

      yield put(enqueueSnackbar(errNotification));
    }
  } else {
    if (notification && notification.message) {
      const successNotification = {
        ...notification,
        options: {
          ...notification.options,
          variant: SNACKBAR_VARIANTS.success,
        },
      };

      yield put(enqueueSnackbar(successNotification));
    }
  }
}

export function* formSagaRequestWithPayload(formId, type, action, promise, payload = {}, notification) {
  try {
    yield put(clearSubmitErrors(formId));
    const result = yield call(action, payload);

    yield put({ type: `${type}_SUCCESS`, result, payload });
    yield put(reset(formId));
    yield call(handleNotification, { notification });
    promise.resolve(result);
  } catch (error) {
    yield put({ type: `${type}_FAILURE`, error });
    yield call(handleNotification, { notification, type, error, payload, disableRetry: true });
    promise.reject(new SubmissionError({ _error: error }));
  }
}

export const getResult = (state, action) => action.result;

export const asyncSuccess = {
  isProcessing: false,
  isSuccess: true,
};
