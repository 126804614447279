import React from 'react';
import PropTypes from 'prop-types';
import Details from 'components/common/Details';
import SimpleTable from 'components/common/simpleTable/SimpleTable';
import { STIM, BASESTATION } from 'components/inventory/utils/constants';

import {
  detailItems,
  stimHeaders,
  bsHeaders,
  bandHeaders,
} from './constants';
import PrintBand from './PrintBand';
import PrintBaseStation from './PrintBaseStation';
import PrintStim from './PrintStim';

const OperationsDetailsPrintTable = props => {
  const {
    lotDetails,
    skuType,
  } = props;
  const style = {
    paddingLeft: 10,
    paddingRight: 10,
  };
  const skuNumber = props.getDeviceProp(lotDetails.device_type_id, 'sku_number');

  let headers = [];
  let Body = null;

  lotDetails.skuNumber = skuNumber;
  lotDetails.deviceName = props.getDeviceProp(lotDetails.device_type_id, 'device_commercial_name');

  if (skuType === STIM) {
    headers = stimHeaders;
    Body = PrintStim;
  } else if (skuType === BASESTATION) {
    headers = bsHeaders;
    Body = PrintBaseStation;
  } else {
    headers = bandHeaders;
    Body = PrintBand;
  }

  return (
    <div
      id="table--operations-details-print"
      style={{
        display: 'none',
      }}
    >
      <Details
        items={detailItems}
        data={lotDetails}
      />
      <br />
      <SimpleTable headers={headers}>
        <Body style={style} lotDetails={lotDetails} sku={skuNumber} />
      </SimpleTable>
    </div>
  );
};

OperationsDetailsPrintTable.propTypes = {
  lotDetails: PropTypes.object,
  getDeviceProp: PropTypes.func.isRequired,
  skuType: PropTypes.string,
};

export default OperationsDetailsPrintTable;
