import theme from 'components/styles/default.theme';

const getStyles = () => ({
  container: {
    textAlign: 'center',
    width: '100%',
  },
  table: {
    minWidth: 700,
    header: {
      borderBottom: 'none',
    },
    headerRow: {
      borderBottom: '1px solid #A0A0A0',
      height: '56px',
    },
  },
  iconColumn: {
    width: 'auto',
  },
  tableCell: {
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    maxWidth: 200,
  },
  buttonContainer: {
    textAlign: 'right',
    paddingRight: '20px',
    height: '57px',
  },
  requestReportButton: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    maxWidth: 200,
  },
});

export default getStyles;
