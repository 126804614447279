import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ProfilePage from './profile/ProfilePage';

const OtherRoutes = (
    <Switch>
      <Route
        exact
        path="/"
        component={ProfilePage}
      />
      <Route
        exact
        path="/profile"
        component={ProfilePage}
      />
      <Redirect
        from="*"
        to="/"
      />
    </Switch>
);

export default OtherRoutes;
