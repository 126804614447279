import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TableCell } from '@material-ui/core';

import * as en from 'intl/en';
import { FormattedMessage } from 'react-intl';
import { getDeviceProperty, makeGetSkuType } from 'components/inventory/selectors';
import { STIM, BASESTATION } from 'components/inventory/utils/constants';

import BaseStationTableBody from './BaseStationTableBody';
import StimTableBody from './StimTableBody';

const TableBody = props => {
  const { row, getDeviceProp, skuType, skuNumber } = props;

  let { deviceCommercialName } = props;
  let deviceTypeTable = null;

  if (!deviceCommercialName) {
    deviceCommercialName = getDeviceProp(row.device_type_id, 'device_commercial_name');
  }

  if (skuType === STIM) {
    deviceTypeTable = <StimTableBody row={row} />;
  } else if (skuType === BASESTATION) {
    deviceTypeTable = <BaseStationTableBody row={row} />;
  }

  return (
    <Fragment>
      <TableCell>
        {row.serial_number}
      </TableCell>
      {deviceTypeTable}
      <TableCell>
        <FormattedMessage
          id={`operations.deviceStatus.${row.status}`}
          defaultMessage={en[`operations.deviceStatus.${row.status}`]}
        />
      </TableCell>
      <TableCell>
        {deviceCommercialName}
      </TableCell>
      <TableCell>
        {skuNumber}
      </TableCell>
      <TableCell>
        {row.created_by}
      </TableCell>
    </Fragment>
  );
};

TableBody.propTypes = {
  row: PropTypes.object.isRequired,
  getDeviceProp: PropTypes.func.isRequired,
  skuType: PropTypes.string,
  deviceCommercialName: PropTypes.string,
  skuNumber: PropTypes.string,
};

const makeMapStateToProps = () => {
  const getSkuType = makeGetSkuType();

  const mapStateToProps = (state, props) => ({
    getDeviceProp: (deviceTypeId, prop) => getDeviceProperty(state, deviceTypeId, prop),
    skuType: getSkuType(state, { skuNumber: props.skuNumber }),
  });

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(TableBody);
