import theme from 'components/styles/default.theme';

const getStyles = () => ({
  body: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.backgroundColor,
  },
  title: {
    color: theme.palette.textColor,
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
});

export default getStyles;
