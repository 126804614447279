import { combineReducers } from 'redux';
import { createReducer } from 'components/utils/reduxHelpers';
import { LOAD_USERS, CLEAR_USER_PAGES } from '../actions/usersActions';

export const TOTAL_DEFAULT_STATE = null;
export const PAGES_DEFAULT_STATE = { current: null, tokens: [null] };

const isNextPageClicked = (state, action) => {
  const indexOfToken = state.tokens.indexOf(action.payload.selectedPage);

  return indexOfToken + 1 === state.tokens.length;
};
const isPreviousPageClicked = (state, action) => {
  const indexOfToken = state.tokens.indexOf(action.payload.selectedPage);

  return indexOfToken === state.tokens.length - 3;
};

const updateTokenList = (state, action) => {
  // if last page
  if (!action.result.nextToken && state.tokens[state.tokens.length - 1] !== null) {
    return [...state.tokens, null];
  }

  // if next page
  if (isNextPageClicked(state, action)) {
    return [...state.tokens, action.result.nextToken];
  } else if (isPreviousPageClicked(state, action)) {
    // if previous page is clicked, going back
    return state.tokens.slice(0, -1);
  } else return [...state.tokens];
};

export const pages = createReducer(PAGES_DEFAULT_STATE, {
  [`${LOAD_USERS}_SUCCESS`]: (state, action) => (
    {
      current: action.payload.selectedPage,
      tokens: updateTokenList(state, action),
    }
  ),
  [`${CLEAR_USER_PAGES}`]: () => PAGES_DEFAULT_STATE,
});

export const total = createReducer(TOTAL_DEFAULT_STATE, {
  [`${LOAD_USERS}_SUCCESS`]: (state, action) => (state || action.result.total),
  [`${CLEAR_USER_PAGES}`]: () => null,
});

const paginationReducer = combineReducers({
  pages,
  total,
});

export default paginationReducer;
