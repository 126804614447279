import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PatientsRoute from './patients/PatientsRoute';

const ClinicRoutes = (
    <Switch>
      <Route
        exact
        path="/"
        component={PatientsRoute}
      />
      <Route
        path="/patients"
        component={PatientsRoute}
      />
      <Redirect
        from="*"
        to="/"
      />
    </Switch>
);

export default ClinicRoutes;
