export default {
  serialNumber: 'Serial number',
  macAddress: 'MAC Address',
  simCard: 'Sim Card',
  certifications: 'Security cert',
  deviceCommercialName: 'Device Commercial Name',
  deviceCodeName: 'Device Code Name',
  prefix: 'Prefix',
  orderSuccess: 'Order success',
  orderError: 'Order error',
  skuId: 'SKU',
};
