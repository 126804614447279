import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { Button } from '@material-ui/core';
import getGlobalStyles from 'components/styles/global.styles';

import getStyles from './AvailableDates.styles';

class AvailableDates extends Component {
  constructor(props) {
    super(props);

    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();
  }

  render() {
    const {
      groupedDates,
      selectedDate,
      changeDate,
      isLoading,
    } = this.props;

    return (
      <div style={this.styles.wrapper}>
        {
          Object.keys(groupedDates).map((month, index) => {
            return (
              <div
                key={month}
                style={this.styles.datesContainer}
                className={`dates--${index}`}
              >
                <div
                  style={this.styles.month}
                  className={`dates--${index}__month`}
                >
                  <FormattedDate
                    value={groupedDates[month][0]}
                    month="short"
                  />
                </div>
                {
                  groupedDates[month].map((date, i) => {
                    return (
                      <Button
                        key={date}
                        className={`dates--${index}__day`}
                        style={{
                          ...this.globalStyles.primaryButton,
                          ...this.styles.day,
                          ...(selectedDate === date ? this.styles.selectedDay : {}),
                        }}
                        onClick={() => changeDate(date)}
                        disabled={isLoading}
                      >
                        <FormattedDate
                          value={date}
                          day="numeric"
                        />
                      </Button>
                    );
                  })
                }
              </div>
            );
          })
        }
      </div>
    );
  }
}

AvailableDates.propTypes = {
  groupedDates: PropTypes.object.isRequired,
  selectedDate: PropTypes.string,
  changeDate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default AvailableDates;
