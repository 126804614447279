import { combineReducers } from 'redux';
import _ from 'lodash';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_STUDIES,
  CREATE_STUDY,
  UPDATE_STUDY,
  ADD_SITE,
  RESET_NEW_ITEM,
  UPDATE_SITE,
} from '../actions/clinicalStudyActions';

const addOrUpdate = (state, action) => {
  return {
    ...state,
    [action.result.clinical_study_id]: action.result,
  };
};

const addSite = (state, action) => {
  const sites = [...state[action.studyId].sites];

  sites.push(action.result);

  return {
    ...state,
    [action.studyId]: {
      ...state[action.studyId],
      sites,
    },
  };
};

const updateSite = (state, action) => {
  const sites = state[action.payload.studyId].sites.map(site => {
    if (site.site_study_id === action.result.site_study_id) {
      return action.result;
    }
    return site;
  });

  return {
    ...state,
    [action.payload.studyId]: {
      ...state[action.payload.studyId],
      sites,
    },
  };
};

export const BY_ID_DEFAULT_STATE = {};

export const byId = createReducer(BY_ID_DEFAULT_STATE, {
  [`${LOAD_STUDIES}_SUCCESS`]: (state, action) => (_.keyBy(action.result, s => s.clinical_study_id)),
  [`${CREATE_STUDY}_SUCCESS`]: addOrUpdate,
  [`${UPDATE_STUDY}_SUCCESS`]: addOrUpdate,
  [`${ADD_SITE}_SUCCESS`]: addSite,
  [`${UPDATE_SITE}_SUCCESS`]: updateSite,
});

const addNewItem = (state, action) => {
  return action.result.site_study_id;
};

export const NEW_ITEM_DEAFULT_STATE = null;

export const newItem = createReducer(NEW_ITEM_DEAFULT_STATE, {
  [`${ADD_SITE}`]: () => null,
  [`${ADD_SITE}_SUCCESS`]: addNewItem,
  [`${RESET_NEW_ITEM}`]: () => null,
});

export const loadInfo = asyncReducerHelper(LOAD_STUDIES);
export const createInfo = asyncReducerHelper(CREATE_STUDY);
export const updateInfo = asyncReducerHelper(UPDATE_STUDY);
export const addingSiteInfo = asyncReducerHelper(ADD_SITE);

const studiesReducer = combineReducers({
  byId,
  newItem,
  loadInfo,
  createInfo,
  updateInfo,
  addingSiteInfo,
});

export default studiesReducer;
