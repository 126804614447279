/* eslint max-len: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';

class StarIcon extends Component {
  render() {
    const { filled, ...rest } = this.props;

    return (
      <SvgIcon
        style={{
          fill: filled ? this.props.theme.palette.backgroundColor : 'none',
          stroke: this.props.theme.palette.backgroundColor,
          strokeLinecap: 'square',
          strokeMiterlimit: 10,
          strokeWidth: 2,
        }}
        {...rest}
        viewBox="0 0 31.227 30.029"
      >
        <g transform="translate(0.422 0.5)">
          <path
            className="a"
            d="M16.672,1.427l3.194,6.491a1.647,1.647,0,0,0,1.339.927l7.109,1.03a1.671,1.671,0,0,1,.927,2.885L24.09,17.808a1.7,1.7,0,0,0-.515,1.545l1.236,7.109a1.738,1.738,0,0,1-2.473,1.854l-6.388-3.3a1.4,1.4,0,0,0-1.545,0l-6.388,3.3a1.738,1.738,0,0,1-2.473-1.854l1.236-7.109a1.793,1.793,0,0,0-.515-1.545L1.115,12.76a1.69,1.69,0,0,1,.927-2.885l7.109-1.03a2.023,2.023,0,0,0,1.339-.927l3.194-6.491A1.666,1.666,0,0,1,16.672,1.427Z"
          />
        </g>
      </SvgIcon>
    );
  }
}

StarIcon.propTypes = {
  filled: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      backgroundColor: PropTypes.string,
    }),
  }).isRequired,
};

export default withTheme(StarIcon);
