export default theme => ({
  complianceContainer: {
    cursor: 'pointer',
    display: 'inline-block',
  },
  complianceWrapper: {
    margin: '0 10px 0 10px',
    display: 'inline-block',
    fontWeight: theme.font.bold,
    color: theme.palette.warningColor,
  },
  periodWrapper: {
    marginBottom: 5,
    padding: '2px 10px',
    borderRadius: 5,
    color: theme.palette.secondaryTextColor,
    textTransform: 'uppercase',
    backgroundColor: theme.palette.warningColor,
    '&--achieved': {
      backgroundColor: theme.palette.mainTheme,
    },
  },
  percentageWrapper: {
    textAlign: 'center',
    '&--achieved': {
      color: theme.palette.highlightTextColor,
    },
  },
  dateWrapper: {
    marginBottom: 5,
    padding: '2px 10px',
    borderRadius: 5,
    color: theme.palette.secondaryTextColor,
    textTransform: 'uppercase',
    backgroundColor: theme.palette.borderColor1,
    textAlign: 'center',
    fontWeight: theme.font.bold,
  },
  sessionTable: {
    width: '100%',
    marginTop: 20,
  },
  extraWrapper: {
    color: theme.palette.secondaryTextColor,
    backgroundColor: theme.palette.mainTheme,
    textAlign: 'center',
    borderRadius: '50%',
    padding: 5,
    display: 'table-cell',
  },
});
