import { CREATE_STUDY as CREATE_STUDY_FORM, MANAGE_SITE as MANAGE_SITE_FORM } from 'components/common/forms/names';
import { sagaRequestWithPayload, formSagaRequestWithPayload } from 'components/utils/reduxHelpers';
import { delay } from 'redux-saga';
import { call, put, all } from 'redux-saga/effects';
import * as NotesService from 'components/common/notes/services/notesService';
import * as ClinicalStudyService from '../services/clinicalStudyService';
import {
  LOAD_STUDIES,
  LOAD_SITES,
  ADD_SITE,
  UPDATE_SITE,
  CREATE_STUDY,
  UPDATE_STUDY,
  GET_LATEST_SITE_NOTE,
} from '../actions/clinicalStudyActions';

export function* loadStudies(action) {
  yield* sagaRequestWithPayload(LOAD_STUDIES, ClinicalStudyService.loadStudies, action);
}

export function* loadSites(action) {
  yield* sagaRequestWithPayload(LOAD_SITES, ClinicalStudyService.loadSites, action);
}

export function* addMultipleSites(action) {
  const tasks = [];

  action.site.forEach(id => {
    tasks.push(call(addSite, {
      site: {
        clinic_id: id,
      },
      studyId: action.studyId,
    }));
  });

  tasks.push(call(delay, 1000));
  yield all(tasks);
}

export function* addSite(action) {
  try {
    const result = yield call(ClinicalStudyService.addSite, action.site, action.studyId);

    yield put({ type: `${ADD_SITE}_SUCCESS`, result, studyId: action.studyId });
  } catch (error) {
    yield put({ type: `${ADD_SITE}_FAILURE`, error });
  }
}

export function* updateSite(action) {
  const { notification, promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    MANAGE_SITE_FORM,
    UPDATE_SITE,
    ClinicalStudyService.updateSite,
    promise,
    rest,
    notification,
  );
}

export function* createStudy(action) {
  const { promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    CREATE_STUDY_FORM,
    CREATE_STUDY,
    ClinicalStudyService.createStudy,
    promise,
    rest,
  );
}

export function* updateStudy(action) {
  const { promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    CREATE_STUDY_FORM,
    UPDATE_STUDY,
    ClinicalStudyService.updateStudy,
    promise,
    rest,
  );
}

export function* getLatestNoteMultipleSites(action) {
  const tasks = [];

  action.sites.forEach(id => {
    tasks.push(call(getLatestNote, {
      studyId: action.studyId,
      siteStudyId: id,
    }));
  });

  tasks.push(call(delay, 1000));
  yield all(tasks);
}

export function* getLatestNote(action) {
  try {
    const result = yield call(
      NotesService.getLatestNote,
      'clinicalStudy',
      action.siteStudyId,
    );

    yield put({
      type: `${GET_LATEST_SITE_NOTE}_SUCCESS`,
      result,
      studyId: action.studyId,
      siteStudyId: action.siteStudyId,
    });
  } catch (error) {
    yield put({ type: `${GET_LATEST_SITE_NOTE}_FAILURE`, error });
  }
}
