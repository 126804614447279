import _ from 'lodash';
import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_UNASSIGNED_DEVICES,
  DELETE_UNASSIGNED_DEVICE,
} from '../actions/devicesActions';

const getById = (state, action) => {
  return _.keyBy(action.result, o => o.device_inventory_id);
};

const deleteDevice = (state, action) => {
  return _.omit(state, action.payload.inventoryId);
};

const byId = createReducer({}, {
  [`${LOAD_UNASSIGNED_DEVICES}_SUCCESS`]: getById,
  [`${DELETE_UNASSIGNED_DEVICE}_SUCCESS`]: deleteDevice,
});

const asyncReducer = combineReducers({
  devices: asyncReducerHelper(LOAD_UNASSIGNED_DEVICES),
  delete: asyncReducerHelper(DELETE_UNASSIGNED_DEVICE),
});

const asyncOverride = (state = {}, action) => {
  if (action.type === LOAD_UNASSIGNED_DEVICES) {
    return {
      devices: { isProcessing: true },
      delete: {},
    };
  }

  return asyncReducer(state, action);
};

const unassignedDevicesReducer = combineReducers({
  async: asyncOverride,
  byId,
});

export default unassignedDevicesReducer;
