import { put, call } from 'redux-saga/effects';
import {
  PUBLISH_DFU as PUBLISH_DFU_FORM,
  DEVICE_FIRMWARE as DEVICE_FIRMWARE_FORM,
  BASESTATION_FIRMWARE_QUOTA_FORM,
  STIM_FIRMWARE_QUOTA_FORM,
} from 'components/common/forms/names';
import { sagaRequestWithPayload, formSagaRequestWithPayload } from 'components/utils/reduxHelpers';
import * as OperationsService from '../services/operationsService';
import {
  PUBLISH_FIRMWARE,
  LOAD_FIRMWARES,
  LOAD_FIRMWARE_DEVICES,
  ASSIGN_FIRMWARE,
  UNASSIGN_FIRMWARE,
  DEFAULT_FIRMWARE,
  UPDATE_FIRMWARE,
  UPDATE_FIRMWARE_ARCHIVE,
  EXPORT_LIST,
  loadFirmwares as loadFirmwaresAction,
  loadFirmwareDevices as loadFirmwareDevicesAction,
} from '../actions/firmwareActions';

export function* publishFirmware({ promise, ...rest }) {
  yield* formSagaRequestWithPayload(
    PUBLISH_DFU_FORM,
    PUBLISH_FIRMWARE,
    OperationsService.publishFirmware,
    promise,
    rest,
  );
}

export function* loadFirmwares() {
  yield* sagaRequestWithPayload(LOAD_FIRMWARES, OperationsService.loadFirmwares);
  yield put(loadFirmwareDevicesAction());
}

export function* loadFirmwareDevices() {
  yield* sagaRequestWithPayload(LOAD_FIRMWARE_DEVICES, OperationsService.loadFirmwareDevices);
}

export function* defaultFirmware({ firmware }) {
  try {
    yield call(OperationsService.defaultFirmware, firmware);
    yield put({ type: `${DEFAULT_FIRMWARE}_SUCCESS` });
  } catch (error) {
    yield put({ type: `${DEFAULT_FIRMWARE}_FAILURE`, error });
    return;
  }

  yield put(loadFirmwaresAction());
}

export function* assignFirmware({ promise, ...rest }) {
  yield* formSagaRequestWithPayload(
    DEVICE_FIRMWARE_FORM,
    ASSIGN_FIRMWARE,
    OperationsService.assignFirmware,
    promise,
    rest,
  );
}

export function* updateFirmwareArchive(action) {
  yield* sagaRequestWithPayload(UPDATE_FIRMWARE_ARCHIVE, OperationsService.updateFirmwareArchive, action);
}

export function* updateFirmware({ promise, ...rest }) {
  const formName = rest.device_type === 'B' ?
    BASESTATION_FIRMWARE_QUOTA_FORM :
    STIM_FIRMWARE_QUOTA_FORM;

  yield* formSagaRequestWithPayload(
    formName,
    UPDATE_FIRMWARE,
    OperationsService.updateFirmware,
    promise,
    rest,
  );
}

export function* unassignFirmware({ promise, ...rest }) {
  yield* formSagaRequestWithPayload(
    DEVICE_FIRMWARE_FORM,
    UNASSIGN_FIRMWARE,
    OperationsService.unassignFirmware,
    promise,
    rest,
  );
}

export function* exportFirmwareList(action) {
  yield* sagaRequestWithPayload(EXPORT_LIST, OperationsService.exportFirmwareList, action);
}
