import axios from 'axios';
import TokenCall from 'components/auth/services/token';
import Config from 'config';
import { getResponseData } from 'components/utils/asyncHelpers';

const {
  DEVICE_API_ROOT,
} = Config;
const { CancelToken } = axios;

let cancelSource;

export const loadDeviceLogs = async function(
  selectedPage = 1, perPage = 10000,
  sortField = 'created_at', sortType = -1, criteria = {},
) {
  try {
    const headers = {
      'X-Per-Page': perPage,
      'X-Page': selectedPage,
      'X-SortField': sortField,
      'X-SortType': sortType === 1 ? 'asc' : 'desc',
    };

    if (criteria.terms && criteria.terms !== '') {
      headers['X-SearchTerms'] = criteria.terms;
    }
    if ((criteria.dateRange || {}).startDate) {
      headers['X-From'] = criteria.dateRange.startDate;
      headers['X-To'] = criteria.dateRange.endDate;
    }

    if (cancelSource) {
      cancelSource.cancel();
    }

    cancelSource = CancelToken.source();
    const response = await TokenCall.get(`${DEVICE_API_ROOT}/v0/devicelog`, {
      cancelToken: cancelSource.token,
      headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadDeviceLog = async id => {
  try {
    const response = await TokenCall.get(`${DEVICE_API_ROOT}/v0/url/download`, {
      headers: {
        'X-LogId': id,
      },
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const toggleBaseStation = ({ deviceId, isEnabled }) => {
  const body = {
    device_config: {
      enable_basestation: isEnabled,
    },
  };

  return getResponseData('put', `${DEVICE_API_ROOT}/v0/device/${deviceId}`, body);
};

export const deleteStationKey = ({ deviceId }) => {
  return getResponseData('delete', `${DEVICE_API_ROOT}/v0/device/${deviceId}/keys`);
};
