import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Message from 'components/common/Message';

const StationKeyDialog = ({
  isOpen,
  confirmAction,
  closeDialog,
  styles,
}) => (
  <Dialog
    open={isOpen}
    onClose={closeDialog}
  >
    <DialogTitle id="files.dialog.title">
      <Message id="files.dialog.title" />
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <Message id="files.dialog.content" />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        onClick={confirmAction}
        color="primary"
        style={styles.keyDialogConfirmBtn}
      >
          <Message id="files.dialog.yes" />
      </Button>
      <Button
        onClick={closeDialog}
        color="primary"
        style={styles.keyDialogCancelBtn}
      >
          <Message id="files.dialog.no" />
      </Button>
    </DialogActions>
  </Dialog>
);

StationKeyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
};

export default StationKeyDialog;
