const getStyles = () => ({
  container: {
  },
  content: {
    display: 'flex',
    padding: '2em',
    width: '100%',
    SMALL: {
      padding: '2em',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    left: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 500,
      paddingRight: 100,
      paddingBottom: 50,
    },
    right: {
      flexGrow: 1,
      flexShrink: 1,
      paddingLeft: 100,
    },
  },
  title: {
    SMALL: {
      textAlign: 'center',
    },
  },
});

export default getStyles;
