import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core';

import listWrapper from 'components/common/base/ListWrapper';
import { orderBy } from 'components/utils/helpers';
import AlertSelector from 'components/common/AlertSelector';
import {
  getDeviceProperty,
  getSkusBySku,
  getDeviceTypesById,
  getShouldLoadSkuList,
  makeGetSkuTypeByDeviceTypeId,
} from 'components/inventory/selectors';
import { BASESTATION, STIM, BAND } from 'components/inventory/utils/constants';
import { fetchSkuList } from 'components/inventory/actions/inventoryActions';
import { isAllowedRole } from 'components/users/utils/userRoles';
import permissions from 'components/operations/utils/permissions';
import Session from 'components/utils/session';

import OperationsDetailsTable from './tables/details/OperationsDetailsTable';
import OperationsDetailsPrintTable from './tables/print/OperationsDetailsPrintTable';
import { loadUnassignedDevices } from '../actions/devicesActions';
import getTableStyles from './tables/details/OperationsDetailsTable.styles';
import getStyles from './OperationsList.styles';
import { bsColumns, stimColumns, bandColumns } from '../utils/constants';

class OperationsDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };

    this.hasDeleteButton = isAllowedRole(Session.JWTDecoded.role_id, permissions.deleteDevicesOpenLot);

    this.tableStyles = getTableStyles(this.props.theme);
    this.styles = getStyles(this.props.theme);
    this.columns = [];
  }

  componentDidMount() {
    if (this.props.shouldLoadSkuList) {
      this.props.fetchSkuList();
    }
    this.props.setSort({ key: 'serial_number', direction: -1 });
    this.props.setUpdateListFunction(this.sortList);
  }

  componentDidUpdate(prevProps, prevState) {
    const { operations } = this.props;

    if (this.didDevicesChange(prevProps)) {
      this.setState({ data: Object.values(operations.unassignedDevices.byId) });
    }

    if (
      operations.lotDetails.async.load.isSuccess
      && !prevProps.operations.lotDetails.async.load.isSuccess
    ) {
      if (!operations.lotDetails.data.close_date) {
        this.props.loadUnassignedDevices(operations.lotDetails.data.lot_id);
      } else {
        this.setState({ data: operations.lotDetails.data.devices });
      }
    }
  }

  didDevicesChange = prevState => {
    const { operations } = this.props;

    return (
      operations.unassignedDevices.async.devices.isSuccess
      && !prevState.operations.unassignedDevices.async.devices.isSuccess
    ) || (operations.unassignedDevices.async.delete.isSuccess &&
      !prevState.operations.unassignedDevices.async.delete.isSuccess);
  }

  addColumnsForSku = () => {
    switch (this.props.skuType) {
      case BAND:
        return [...bandColumns];
      case BASESTATION:
        return [...bsColumns];
      case STIM:
      default:
        return [...stimColumns];
    }
  }

  addColumnsForOpenOrClose = lotDetails => {
    if (!lotDetails.close_date) {
      if (this.hasDeleteButton) {
        this.columns.push({
          title: 'operations.details.action',
          key: 'last-column',
          notSortable: true,
          style: this.tableStyles.lastColumn,
        });
      }
    }
  }

  sortList = sort => {
    const { data } = this.state;

    this.setState({ data: orderBy(data, sort.key, sort.direction) });
  }

  render() {
    const {
      sort,
      sortChange,
      operations,
      getDeviceProp,
      skuType,
    } = this.props;
    const { data } = this.state;
    const { lotDetails } = operations;
    const isClosed = !!operations.lotDetails.data.close_date;

    this.columns = this.addColumnsForSku(operations.lotDetails.data);
    this.addColumnsForOpenOrClose(operations.lotDetails.data);

    return (
      <div
        style={this.styles.container}
      >
        <AlertSelector error={operations.checkedDevices.async.toggle.error} />
        <AlertSelector error={operations.lotDetails.async.load.error} />
        <AlertSelector error={operations.unassignedDevices.async.delete.error} />
        <AlertSelector error={operations.unassignedDevices.async.devices.error} />
        <AlertSelector
          error={operations.lotDetails.async.closeLot.error}
          success={operations.lotDetails.async.closeLot.isSuccess}
          successMessage="operations.details.closeLotSuccess"
        />
        <AlertSelector
          error={operations.lotDetails.async.openLot.error}
          success={operations.lotDetails.async.openLot.isSuccess}
          successMessage="operations.details.openLotSuccess"
        />
        <OperationsDetailsTable
          data={data}
          columns={this.columns}
          activeField={sort.key}
          onSort={sortChange}
          sortDirection={sort.direction}
          hasDeleteButton={this.hasDeleteButton}
          isClosed={isClosed}
          getDeviceProp={getDeviceProp}
        />
        {
          isClosed &&
          <OperationsDetailsPrintTable
            getDeviceProp={getDeviceProp}
            lotDetails={lotDetails.data}
            skuType={skuType}
          />
        }
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getSkuTypeByDeviceTypeId = makeGetSkuTypeByDeviceTypeId();

  const mapStateToProps = (state, props) => ({
    operations: state.operations,
    getDeviceProp: (deviceTypeId, prop) => getDeviceProperty(state, deviceTypeId, prop),
    skuMap: getSkusBySku(state),
    deviceTypes: getDeviceTypesById(state),
    shouldLoadSkuList: getShouldLoadSkuList(state),
    skuType: getSkuTypeByDeviceTypeId(state, { deviceTypeId: state.operations.lotDetails.data.device_type_id }),
  });

  return mapStateToProps;
};

const mapDispatchToProps = { loadUnassignedDevices, fetchSkuList };

OperationsDetails.propTypes = {
  theme: PropTypes.object.isRequired,
  loadUnassignedDevices: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  sortChange: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  setUpdateListFunction: PropTypes.func.isRequired,
  getDeviceProp: PropTypes.func.isRequired,
  operations: PropTypes.object.isRequired,
  shouldLoadSkuList: PropTypes.bool.isRequired,
  fetchSkuList: PropTypes.func.isRequired,
  skuType: PropTypes.string,
};

const enhance = compose(
  withTheme,
  connect(makeMapStateToProps, mapDispatchToProps),
  listWrapper({ displayName: 'OperationsDetails' }),
);

export default enhance(OperationsDetails);
