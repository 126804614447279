import React from 'react';
import PropTypes from 'prop-types';
import * as en from 'intl/en';
import { FormattedMessage } from 'react-intl';

const SimpleTableHeader = props => {
  const { styles, headers } = props;

  return (
    <thead>
      <tr style={{...styles.tableText}}>
        {
          headers.map(header => {
            const { text } = header;

            if (text) {
              return (
                <th key={header.key} >
                  <FormattedMessage
                    id={text}
                    defaultMessage={en[text]}
                  />
                </th>
              );
            } else {
              return (<th key={header.key} />);
            }
          })
        }
      </tr>
    </thead>
  );
};

SimpleTableHeader.propTypes = {
  styles: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
};

export default SimpleTableHeader;
