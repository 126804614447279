import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch as MuiSwitch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import getStyles from './Switch.styles';

class Switch extends Component {
  render() {
    const {
      checked,
      onChange,
      value,
      classes,
      ...rest
    } = this.props;

    return (
      <MuiSwitch
        checked={checked}
        onChange={onChange}
        value={value}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        disableRipple
        role="radio"
        aria-checked={checked}
        {...rest}
      />
    );
  }
}

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(getStyles)(Switch);
