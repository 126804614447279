import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {
  injectIntl,
  intlShape,
  FormattedDate,
  FormattedMessage,
} from 'react-intl';
import getGlobalStyles from 'components/styles/global.styles';
import newGlobalStyles from 'components/styles/newGlobal.styles';
import NoteIcon from 'components/styles/icons/NoteIcon';
import Icon from '@material-ui/core/Icon';
import { Button, withStyles, withTheme } from '@material-ui/core';
import CheckIcon from 'components/styles/icons/CheckIcon';
import WarningIcon from 'components/styles/icons/WarningIcon';
import PrinterIcon from 'components/styles/icons/PrinterIcon';
import { fetchMultipleUsersData } from 'components/users/actions/usersActions';
import Alert from 'components/common/Alert';
import { cleanAWSMessage } from 'components/utils/errors';
import {
  getFullName,
  sanitizeHTML,
  printElement,
  convertUtcOffset,
} from 'components/utils/helpers';
import Message from 'components/common/Message';
import { profileInfoShape } from 'components/deviceLogs/utils/DeviceLogs.props';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState,
  convertToRaw,
  ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import AccessControl from 'components/common/base/AccessControl';

import * as en from 'intl/en';

import getStyles from './Notes.styles';
import {
  loadNotes,
  addNote,
  clearNotesErrors,
} from './actions/notesActions';
import permissions from './utils/permissions';

class Notes extends Component {
  constructor(props, context) {
    super(props, context);

    this.styles = getStyles(props.theme);
    this.globalStyles = getGlobalStyles(props.theme);
    this.state = {
      editorState: EditorState.createEmpty(),
    };

    this.saveNote = this.saveNote.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      objectType,
      objectId,
      notes,
    } = prevProps;

    // load only if something has changed and objectId is not null
    if (((objectType !== this.props.objectType) || (objectId !== this.props.objectId))
      && this.props.objectId) {
      this.props._loadNotes(this.props.objectType, this.props.objectId);
    }

    if (!notes.addingNoteSuccess && this.props.notes.addingNoteSuccess) {
      const cleanEditorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));

      this.setState({
        editorState: cleanEditorState,
      });
    }

    // Load list of authors when new notes have been loaded
    if (notes.isLoadingNotes && !this.props.notes.isLoadingNotes) {
      const usersToAdd = [];
      const { byId } = this.props.users.userDetails;

      this.props.notes.notes.forEach(note => {
        const user = byId[note.author];

        if (!user) {
          usersToAdd.push(note.author);
        }
      });
      const deduped = [...new Set(usersToAdd)];

      if (deduped.length > 0) {
        this.props._fetchMultipleUsersData(deduped);
      }
    }
  }

  saveNote = () => {
    const { objectType, objectId } = this.props;
    const { editorState } = this.state;

    if (!editorState.getCurrentContent().hasText() || !editorState.getCurrentContent().getPlainText().trim()) {
      return;
    }
    const txt = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    this.props._addNote(objectType, objectId, { content: txt }, this.props.extras);
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    });
  };

  getAuthorName = userId => {
    const { users, profile } = this.props;
    const user = users.userDetails.byId[userId];

    if (user && user.pk === profile.pk) {
      return getFullName(profile.first_name, profile.last_name, this.props.intl.formatMessage({
        id: 'shared.unknown',
        defaultMessage: en.shared.uknown,
      }));
    } else if (user) {
      return getFullName(user.first_name, user.last_name, this.props.intl.formatMessage({
        id: 'shared.unknown',
        defaultMessage: en.shared.uknown,
      }));
    } else {
      return 'N/A';
    }
  }

  closeDialog = () => {
    this.props._clearNotesErrors();
    this.props.closeDialog();
  }

  printNotes = () => {
    printElement('notes__container');
  }

  renderNote = (note, index, utcOffset) => {
    const cleanHtml = sanitizeHTML(note.content);

    return (
      <div
        key={index}
        id={`notes__note__${index}`}
        style={this.styles.note}
      >
        {note.is_valid !== null && note.is_valid !== undefined ?
          (note.is_valid ?
          <CheckIcon style={{...this.styles.hashIcon, ...this.styles.hashIcon.checkIcon}} /> :
          <WarningIcon style={{...this.styles.hashIcon, ...this.styles.hashIcon.warningIcon}} />)
          : ''
        }
        <div
          className="note__text"
          style={this.styles.noteText}
          dangerouslySetInnerHTML={{__html: cleanHtml}}
        />
        <div
          className="note__footer"
          style={this.styles.noteFooter}
        >
          <div
            className="note__footer__author"
            style={this.styles.footerAuthor}
          >
            {this.getAuthorName(note.author)}
            &nbsp;|&nbsp;
            <FormattedDate
              value={moment.utc(note.created_at).local()}
              day="numeric"
              month="numeric"
              year="numeric"
              hour="numeric"
              minute="numeric"
            />
            &nbsp;GMT{utcOffset}
          </div>
        </div>
      </div>
    );
  }

  renderNotes = notes => {
    const {
      objectId,
      objectType,
    } = this.props;
    const utcOffset = convertUtcOffset(moment().utcOffset());

    return (
      <div>
        <div
          id="notes__container-divider"
          style={this.styles.containerDivider}
        >
          <FormattedMessage
            id="common.notes.allNotes"
            defaultMessage={en.common.notes.allNotes}
          />
        </div>
        <div
          id="notes__container"
          style={this.styles.notesContainer}
          ref={node => { this.notesToPrint = node; }}
        >
          <div
            style={{
              ...this.styles.noteType,
            }}
          >
            <span
              style={this.styles.noteType.title}
            >
              <Message id="common.notes.type" />:
            </span>
            &nbsp;{objectType}&nbsp;&nbsp;
            <span
              style={this.styles.noteType.title}
            >
              <Message id="common.notes.id" />:
            </span>
            &nbsp;{objectId}
          </div>
          <AccessControl roles={permissions.readNotes}>
            {notes.notes.map((note, index) => {
              return this.renderNote(note, index, utcOffset);
            })}
          </AccessControl>
        </div>
      </div>
    );
  }

  render() {
    const {
      isModalOpen,
      notes,
      intl,
      classes,
    } = this.props;
    const { editorState } = this.state;

    return (
      <Dialog
        id="modal--notes"
        open={isModalOpen}
        PaperProps={{ style: this.styles.modal.content }}
        onClose={this.closeDialog}
        style={this.styles.modal}
      >
        <div
          style={{
            ...this.globalStyles.pageHeader,
            ...this.styles.header,
          }}
        >
          <NoteIcon
            style={this.globalStyles.pageIcon}
          />
          <h1 style={{ ...this.globalStyles.pageTitle }}>
            <Message id="common.notes.notes" />
          </h1>
          <div
            style={this.styles.buttonWrapper}
          >
            <AccessControl roles={permissions.printNotes}>
              <Icon
                style={this.styles.buttonWrapper.printerButton}
                onClick={this.printNotes}
              >
                <PrinterIcon />
              </Icon>
            </AccessControl>
            <Icon
              style={this.styles.buttonWrapper.closeButton.icon}
              onClick={this.closeDialog}
            >
              close
            </Icon>
          </div>
        </div>
        <div
          id="notes__editor-container"
          style={this.styles.editorContainer}
        >
          {
            notes.addingNoteError &&
            <Alert
              id="alert--error"
              text={cleanAWSMessage(notes.addingNoteError.message, notes.addingNoteError, intl)}
              type="danger"
            />
          }
          <Editor
            alt="input"
            role="presentation"
            initialEditorState={editorState}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorStyle={this.styles.editor}
            onEditorStateChange={this.onEditorStateChange}
            localization={{
              locale: intl.locale,
            }}
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list',
              'textAlign', 'colorPicker', 'link', 'embedded', 'emoji',
              'remove', 'history'],
            }}
          />
          <Button
            id="btn__save-note"
            type="button"
            className={classes.primaryButton}
            onClick={this.saveNote}
            style={this.styles.saveBtn}
          >
            <Message id="common.notes.saveNote" />
          </Button>
        </div>
        {
          this.renderNotes(notes)
        }
      </Dialog>
    );
  }
}

Notes.propTypes = {
  profile: profileInfoShape.isRequired,
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isModalOpen: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  notes: PropTypes.object,
  _fetchMultipleUsersData: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  objectId: PropTypes.string,
  objectType: PropTypes.string,
  _loadNotes: PropTypes.func.isRequired,
  _addNote: PropTypes.func.isRequired,
  _clearNotesErrors: PropTypes.func.isRequired,
  extras: PropTypes.any,
};

const mapStateToProps = state => ({
  profile: state.profile.info,
  users: state.users,
  notes: state.notes,
});

const mapDispatchToProps = dispatch => ({
  _fetchMultipleUsersData: userIds => {
    dispatch(fetchMultipleUsersData(userIds));
  },
  _loadNotes: (objectType, objectId) => {
    dispatch(loadNotes(objectType, objectId));
  },
  _addNote: (objectType, objectId, note, extras) => {
    dispatch(addNote(objectType, objectId, note, extras));
  },
  _clearNotesErrors: () => {
    dispatch(clearNotesErrors());
  },
});

const enhance = compose(
  withStyles(newGlobalStyles),
  withTheme,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(Notes);
