import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer, getResult } from 'components/utils/reduxHelpers';
import {
  CREATE_LOT,
} from '../actions/operationsActions';

const data = createReducer({}, {
  [`${CREATE_LOT}_SUCCESS`]: getResult,
});

const asyncReducer = combineReducers({
  lots: asyncReducerHelper(CREATE_LOT),
});

const lotsReducer = combineReducers({
  async: asyncReducer,
  data,
});

export default lotsReducer;
