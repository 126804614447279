import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import * as en from 'intl/en';

import styles from './SelectField.styles';

const renderTxt = (item, itemText, intl) => {
  if (item.i18n) {
    return intl.formatMessage(
      {
        id: item.i18n.message,
        defaultMessage: en[item.i18n.message],
      },
      item.i18n.values,
    );
  }
  return itemText ? item[itemText] : item;
};

const renderOther = (otherValue, intl) => {
  return intl.formatMessage(
    {
      id: 'shared.other',
      defaultMessage: en.shared.other,
    },
    otherValue,
  );
};

const SelectInput = ({
  input,
  label,
  classes,
  meta: { touched, error },
  intl,
  items,
  itemText,
  itemValue,
  otherOption,
  otherValue,
  ...custom
}) => {
  return (
  <FormControl classes={{ root: classes.form }} style={{ width: '100%' }} error={error && touched}>
    <InputLabel classes={{ root: classes.label }}>{label}</InputLabel>
    <Select
      fullWidth
      disableUnderline
      classes={{
        select: classes.input,
        icon: classes.icon,
      }}
      {...input}
      onChange={(event, child) => input.onChange(child.props.value)}
      {...custom}
    >
      <MenuItem value="" children="&nbsp;" />
    {
      items.map(item => (
        <MenuItem
          key={itemValue ? item[itemValue] : item}
          value={itemValue ? item[itemValue] : item}
        >
          {renderTxt(item, itemText, intl)}
        </MenuItem>
    ))}
    {
      otherOption &&
      <MenuItem key="other" value=" ">
        {renderOther(otherValue, intl)}
      </MenuItem>
    }
    </Select>
    {
      touched && error &&
      <FormHelperText error>
        {intl.formatMessage({ id: error.message, defaultMessage: en[error.message]}, error.values)}
      </FormHelperText>
    }
  </FormControl>
  );
};

SelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  items: PropTypes.array.isRequired,
  itemValue: PropTypes.string,
  itemText: PropTypes.string,
  otherOption: PropTypes.bool,
  otherValue: PropTypes.string,
};

SelectInput.defaultProps = {
  otherOption: false,
};

const SelectInputWithClasses = withStyles(styles)(SelectInput);

const SelectField = ({
  name,
  label,
  intl,
  validate,
  items,
  itemText,
  itemValue,
  otherOption,
  otherValue,
  itemChange,
}) => (
  <Field
    name={name}
    component={SelectInputWithClasses}
    label={intl.formatMessage({
      id: label,
      defaultMessage: en[label],
    })}
    intl={intl}
    validate={validate}
    items={items}
    itemText={itemText}
    itemValue={itemValue}
    otherOption={otherOption}
    otherValue={otherValue}
    onChange={e => name === 'prefix' && itemChange(e)}
  />
);

SelectField.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.any,
  items: PropTypes.array.isRequired,
  itemValue: PropTypes.string,
  itemText: PropTypes.string,
  otherOption: PropTypes.bool,
  otherValue: PropTypes.string,
  itemChange: PropTypes.func,
};

export default injectIntl(SelectField);
