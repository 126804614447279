import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as en from 'intl/en';

const Message = props => (
  <FormattedMessage
    defaultMessage={en[props.id]}
    {...props}
  />
);

Message.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Message;
