export default {
  shippingAddress: 'Shipping address',
  originAddress: 'Origin address',
  toShip: 'To ship',
  status: 'Status',
  reject: 'Reject',
  message: 'Message',
  resetDetails: 'Reset details',
  orderRejected: 'Order rejected',
};
