import React from 'react';
import PropTypes from 'prop-types';
import getGlobalStyles from 'components/styles/global.styles';

const PageWrapper = (props, context) => {
  const globalStyles = getGlobalStyles();

  return (
    <div
      id="page__content"
      style={globalStyles.page.content}
    >
      <div
        id="resize-wrapper"
      >
        {props.children}
      </div>
    </div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.array.isRequired,
};

export default PageWrapper;
