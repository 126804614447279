const getStyles = theme => ({
  table: {
    minWidth: 700,
    header: {
      borderBottom: 'none',
      textTransform: 'uppercase',
    },
    headerRow: {
      color: 'inherit',
      height: '48px',
      display: 'table-row',
      outline: 'none',
      verticalAlign: 'middle',
      borderBottom: '1px solid #A0A0A0',
    },
  },
  errorMessage: {
    padding: '10px',
    position: 'relative',
    left: '580px',
    top: '10px',
    textTransform: 'uppercase',
    color: theme.palette.textColor,
  },
});

export default getStyles;
