import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { FormattedDate } from 'react-intl';
import {
  injectValuesToString,
} from 'components/utils/helpers';
import getGlobalStyles from 'components/styles/newGlobal.styles';

const TableCellContent = props => {
  const {
    theme,
    field,
    row,
  } = props;
  const globalStyles = getGlobalStyles(theme);

  const value = _.get(row, field.key);

  let element = null;

  if (!value && field.type !== 'custom' && field.type !== 'customRender') {
    element = '-';
  } else if (field.type === 'date') {
    element = (
        <FormattedDate
          value={moment.utc(value).local()}
          day="numeric"
          month="numeric"
          year="numeric"
          hour="numeric"
          minute="numeric"
        />
    );
  } else if (field.type === 'custom') {
    element = field.item;
  } else if (field.type === 'link') {
    const values = [];

    field.params.forEach(param => {
      values.push(_.get(row, param));
    });

    const path = injectValuesToString(field.path, values);

    element = (
        <Link
          style={globalStyles.linkText}
          key={_.get(row, field.key)}
          to={path}
        >
          {_.get(row, field.textKey) || _.get(row, field.text)}
        </Link>
    );
  } else if (field.type === 'function') {
    element = row[field.key]();
  } else if (field.type === 'customRender') {
    element = field.render(row);
  } else {
    element = value;
  }

  return element;
};

TableCellContent.propTypes = {
  theme: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
};

export default TableCellContent;
