import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import KeyIcon from 'components/styles/icons/KeyIcon';

import { deleteStationKey } from 'components/deviceLogs/actions/deviceLogsActions';
import StationKeyDialog from './StationKeyDialog';

class StationKeyDialogContainer extends Component {
  static propTypes = {
    _deleteStationKey: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    log: PropTypes.object.isRequired,
    styles: PropTypes.object.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  confirmAction = () => {
    const { _deleteStationKey, log } = this.props;

    _deleteStationKey(log.device.device_id);
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen } = this.state;
    const {
      handleOpen,
      handleClose,
      confirmAction,
    } = this;
    const {
      index,
      log,
      styles,
      isEnabled,
      isError,
    } = this.props;

    return (
      <Fragment>
        <IconButton
          id={`button__key__${index}`}
          data-logid={log.device_log_id}
          onClick={handleOpen}
          disabled={!isEnabled}
          disableRipple
        >
        <KeyIcon
          isEnabled={isEnabled}
          isError={isError}
        />
        </IconButton>
        <StationKeyDialog
          isOpen={isOpen}
          closeDialog={handleClose}
          confirmAction={confirmAction}
          styles={styles}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  _deleteStationKey: deviceId => {
    dispatch(deleteStationKey(deviceId));
  },
});

export default connect(null, mapDispatchToProps)(StationKeyDialogContainer);
