const getStyles = theme => ({
  container: {
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    SMALL: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  title: {
    SMALL: {
      textAlign: 'center',
    },
  },
  button: {
    padding: 'none',
    marginLeft: 'auto',
    width: 'auto',
    display: 'inline-flex',
  },
});

export default getStyles;
