import React from 'react';
import PropTypes from 'prop-types';
import * as en from 'intl/en';
import {
  intlShape,
} from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  withStyles,
} from '@material-ui/core';
import getStyles from './ExportDialog.styles';

const ExportDialog = (props, context) => {
  const actions = [
    <Button
      key="OK"
      children={
        props.intl.formatMessage({
          id: 'shared.ok',
          defaultMessage: en.shared.ok,
        })
      }
      color="primary"
      onClick={props.handleClose}
    />,
  ];

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      classes={{ paper: props.classes.paper }}
    >
      <DialogTitle
        children={props.intl.formatMessage({
          id: 'shared.message',
          defaultMessage: en.shared.message,
        })}
      />
      <DialogContentText
        children={props.exportMessage}
        classes={{ root: props.classes.text }}
      />
      <DialogActions children={actions} />
    </Dialog>
  );
};

ExportDialog.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  exportMessage: PropTypes.string,
};

export default withStyles(getStyles)(ExportDialog);
