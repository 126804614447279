/* eslint max-len: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import '../css/operationsIcon.css';

const QuarantineIcon = ({ grayIcon, ...rest }) => {
  if (grayIcon) {
    return (
      <SvgIcon
        {...rest}
        viewBox="0 0 24.241 23.148"
        fontSize="inherit"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg" width="24.241" height="23.148"
          viewBox="0 0 24.241 23.148"
        >
          <g id="icon_quarantine_gray" transform="translate(-1.432 -4.412)">
            <path
              id="Path_372" data-name="Path 372" d="M19.138,36.73,15.1,44.515a11.589,11.589,0,0,0,13.034-.051l-4.01-7.734"
              transform="translate(-8.079 -19.435)" fill="none" stroke="#474747"
              strokeMiterlimit="10" strokeWidth="1"
            />
            <path
              id="Path_373" data-name="Path 373" d="M35.364,16.713l8.76-.4A11.589,11.589,0,0,0,37.563,5.054l-4.693,7.34"
              transform="translate(-18.981)" fill="none" stroke="#474747"
              strokeMiterlimit="10" strokeWidth="1"
            />
            <path
              id="Path_374" data-name="Path 374" d="M10.723,16.713l-8.76-.4A11.589,11.589,0,0,1,8.524,5.054l4.693,7.34"
              transform="translate(0)" fill="none" stroke="#474747"
              strokeMiterlimit="10" strokeWidth="1"
            />
            <circle
              id="Ellipse_154" data-name="Ellipse 154" cx="3.092"
              cy="3.092" r="3.092" transform="translate(10.461 12.375)"
              fill="none" stroke="#474747" strokeLinecap="square"
              strokeMiterlimit="10" strokeWidth="1"
            />
          </g>
        </svg>
      </SvgIcon>
    );
  }
  return (
    <SvgIcon
      {...rest}
      viewBox="0 0 24.241 23.148"
      fontSize="inherit"
    >
    <svg
      xmlns="http://www.w3.org/2000/svg" width="24.241" height="23.148"
      viewBox="0 0 24.241 23.148"
    >
      <g id="icon_quarantine_white" transform="translate(-1.432 -4.412)">
        <path
          id="Path_372" data-name="Path 372" d="M19.138,36.73,15.1,44.515a11.589,11.589,0,0,0,13.034-.051l-4.01-7.734"
          transform="translate(-8.079 -19.435)" fill="none" stroke="#fff"
          strokeMiterlimit="10" strokeWidth="1"
        />
        <path
          id="Path_373" data-name="Path 373" d="M35.364,16.713l8.76-.4A11.589,11.589,0,0,0,37.563,5.054l-4.693,7.34"
          transform="translate(-18.981)" fill="none" stroke="#fff"
          strokeMiterlimit="10" strokeWidth="1"
        />
        <path
          id="Path_374" data-name="Path 374" d="M10.723,16.713l-8.76-.4A11.589,11.589,0,0,1,8.524,5.054l4.693,7.34"
          transform="translate(0)" fill="none" stroke="#fff"
          strokeMiterlimit="10" strokeWidth="1"
        />
        <circle
          id="Ellipse_154" data-name="Ellipse 154" cx="3.092"
          cy="3.092" r="3.092" transform="translate(10.461 12.375)"
          fill="none" stroke="#fff" strokeLinecap="square"
          strokeMiterlimit="10" strokeWidth="1"
        />
      </g>
    </svg>
    </SvgIcon>
  );
};

QuarantineIcon.propTypes = {
  grayIcon: PropTypes.bool,
};

QuarantineIcon.defaultProps = {
  grayIcon: false,
};

export default QuarantineIcon;
