import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closeNotes } from './actions/notesActions';

import Notes from './Notes';

class NotesDialog extends Component {
  static propTypes = {
    closeNotes: PropTypes.func.isRequired,
    notesDialog: PropTypes.object.isRequired,
  }

  closeDialog = () => {
    this.props.closeNotes();
  }

  render() {
    const {
      objectType,
      objectId,
      extras,
      isOpen,
    } = this.props.notesDialog;

    return (
      <Notes
        objectType={objectType}
        objectId={objectId}
        extras={extras}
        isModalOpen={isOpen}
        closeDialog={this.closeDialog}
      />
    );
  }
}

const mapStateToProps = ({ notesDialog }) => ({
  notesDialog,
});

export default connect(mapStateToProps, { closeNotes })(NotesDialog);
