import { combineReducers } from 'redux';
import _ from 'lodash';
import { createReducer } from 'components/utils/reduxHelpers';
import { LOAD_PROFILE } from 'components/profile/actions/profileActions';

import { LOAD_USERS, FETCH_USER_DATA} from '../actions/usersActions';

const DEFAULT_STATE = {};

const addUser = (state, action) => {
  return {
    ...state,
    [action.result.pk]: action.result,
  };
};

const addUserList = (state, action) => {
  const newUsers = {};

  _.forEach(action.result.data, user => {
    newUsers[user.pk] = user;
  });

  return {
    ...state,
    ...newUsers,
  };
};

export const byId = createReducer(DEFAULT_STATE, {
  [`${FETCH_USER_DATA}_SUCCESS`]: addUser,
  [`${LOAD_USERS}_SUCCESS`]: addUserList,
  [`${LOAD_PROFILE}_SUCCESS`]: addUser,
});

const userDetailsReducer = combineReducers({
  byId,
});

export default userDetailsReducer;
