export default {
  viewAll: 'View all',
  status: 'Status',
  patients: 'Patients',
  startDate: 'Start Date',
  sites: 'Sites',
  enrolledSites: 'Enrolled Sites',
  studiesList: 'Studies List',
  addSite: '+ Add a Site',
  siteAdded: 'Site added',
};
