const getStyles = theme => ({
  table: {
    minWidth: 700,
    header: {
      borderBottom: 'none',
      textTransform: 'uppercase',
    },
    headerRow: {
      borderBottom: '1px solid #A0A0A0',
    },
  },
  actionColumn: {
    width: '2%',
    color: 'red',
  },
  lastColumn: {
    width: '10%',
  },
  uncheckedRow: {
    color: theme.palette.defaultTextColor,
  },
});

export default getStyles;
