import { combineReducers } from 'redux';
import { createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_CLINICS,
  ADD_CLINIC,
  RESET_NEW_ITEM,
} from 'components/clinics/actions/clinicsActions';

export const DEFAULT_STATE = null;

export const clinic = createReducer(DEFAULT_STATE, {
  [`${LOAD_CLINICS}_SUCCESS`]: (state, action) => (null),
  [`${ADD_CLINIC}_SUCCESS`]: (state, action) => (action.result.id),
  [`${RESET_NEW_ITEM}`]: (state, action) => (null),
});

const newClinicReducer = combineReducers({
  clinic,
});

export default newClinicReducer;
