export default {
  createDate: 'Create Date',
  closeDate: 'Close Date',
  creator: 'Creator',
  devicesCount: 'Devices Count',
  deviceType: 'Device Type',
  closeLot: 'Close lot',
  openLot: 'Open lot',
  print: 'Print',
  sku: 'SKU',
};
