import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DeviceLogsPage from './deviceLogs/DeviceLogsPage';
import PatientsRoute from './patients/PatientsRoute';
import OperationsRoute from './operations/OperationsRoute';
import QuarantineRoute from './quarantine/QuarantineRoute';

const CalaRoutes = (
    <Switch>
      <Route
        exact
        path="/"
        component={DeviceLogsPage}
      />
      <Route
        exact
        path="/home"
        component={DeviceLogsPage}
      />
      <Route
        path="/patients"
        component={PatientsRoute}
      />
      <Route
        path="/operations"
        component={OperationsRoute}
      />
      <Route
        path="/quarantine"
        component={QuarantineRoute}
      />
      <Redirect
        from="*"
        to="/"
      />
    </Switch>
);

export default CalaRoutes;
