import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_ORDER_INFORMATION,
  ORDER_DETAIL_ACTION,
  ORDER_ACTION,
  REJECT_ORDER,
  APPROVE_ORDER,
  ORDER_DETAIL_ACTION_BY_TYPE,
} from '../actions/shipmentsActions';
import {
  CREATE_HAND_DELIVERY,
  DELETE_HAND_DELIVERY,
} from '../actions/shipmentOrderActions';
import { orderStatuses } from '../utils/constants';

const updateOrderDetail = (state, action) => {
  return {
    ...state,
    orderDetails: state.orderDetails.map(detail => {
      if (detail.id === action.result.id) {
        return {
          ...action.result,
          isVerified: !!action.result.verifyId,
          isReceived: !!action.result.receivedVerifyId,
        };
      }
      return detail;
    }),
  };
};

const updateOrder = (state, action) => {
  return {
    ...state,
    status: action.payload.status,
  };
};

const loadOrderInfo = (state, action) => {
  return {
    ...action.result,
    orderDetails: action.result.orderDetails.map(detail => ({
      ...detail,
      isVerified: !!detail.verifyId,
      isReceived: !!detail.receivedVerifyId,
    })),
  };
};

const rejectOrder = (state, action) => {
  return {
    ...state,
    status: orderStatuses.rejected,
  };
};

const approveOrder = (state, action) => {
  return {
    ...state,
    ...action.result,
  };
};

const handDeliveryCreated = (state, action) => {
  const { handDeliveryNote, handDeliveryPerson } = action.payload.body;
  
  return {
    ...state,
    handDeliveryNote,
    handDeliveryPerson,
  };
};

const handDeliveryDeleted = (state, action) => {
  return {
    ...state,
    handDeliveryNote: null,
    handDeliveryPerson: null,
  };
};

// update items in orderDetails list after ORDER_DETAIL_ACTION_BY_TYPE_SUCCESS
const updateSingleOrderDetailByType = (state, action) => {
  const { deviceTypeId } = action.payload;
  const isVerified = action.payload.status === 'verify';
  const isReceived = action.payload.status === 'receive';

  return {
    ...state,
    orderDetails: state.orderDetails.map(detail => {
      if (detail.deviceTypeId === deviceTypeId) {
        return {
          ...detail,
          isVerified,
          isReceived,
        };
      }
      return detail;
    }),
  };
};

const orderInfo = createReducer({}, {
  [`${LOAD_ORDER_INFORMATION}_SUCCESS`]: loadOrderInfo,
  [`${ORDER_DETAIL_ACTION}_SUCCESS`]: updateOrderDetail,
  [`${ORDER_ACTION}_SUCCESS`]: updateOrder,
  [`${REJECT_ORDER}_SUCCESS`]: rejectOrder,
  [`${APPROVE_ORDER}_SUCCESS`]: approveOrder,
  [`${CREATE_HAND_DELIVERY}_SUCCESS`]: handDeliveryCreated,
  [`${DELETE_HAND_DELIVERY}_SUCCESS`]: handDeliveryDeleted,
  [`${ORDER_DETAIL_ACTION_BY_TYPE}_SUCCESS`]: updateSingleOrderDetailByType,
});

const groupOrderDetailsByDevice = (state, action) => {
  const newState = {...state};

  action.result.orderDetails.forEach(detail => {
    const { deviceTypeId } = detail;

    newState[deviceTypeId] = (
      newState[deviceTypeId] ||
      { qty: 0, isVerified: true, isReceived: true }
    );
    newState[deviceTypeId].qty += 1;
    
    if (!detail.verifyId) {
      // if a single device is unverified mark the whole batch as unverified
      newState[deviceTypeId].isVerified = false;
    }
    if (!detail.receivedVerifyId) {
      newState[deviceTypeId].isReceived = false;
    }
  });

  return newState;
};

const updateOrderDetailByType = (state, action) => {
  const { deviceTypeId } = action.payload;
  const isVerified = action.payload.status === 'verify';
  const isReceived = action.payload.status === 'receive';

  return {
    ...state,
    [deviceTypeId]: {
      ...state[deviceTypeId],
      isVerified,
      isReceived,
    },
  };
};

const orderDetailsByDevice = createReducer({}, {
  LOAD_ORDER_INFORMATION: () => ({}),
  [`${LOAD_ORDER_INFORMATION}_SUCCESS`]: groupOrderDetailsByDevice,
  [`${ORDER_DETAIL_ACTION_BY_TYPE}_SUCCESS`]: updateOrderDetailByType,
});

const asyncReducer = combineReducers({
  orderInfo: asyncReducerHelper(LOAD_ORDER_INFORMATION),
  orderAction: asyncReducerHelper(ORDER_ACTION),
  orderDetailAction: asyncReducerHelper(ORDER_DETAIL_ACTION),
  orderDetailActionByType: asyncReducerHelper(ORDER_DETAIL_ACTION_BY_TYPE),
  rejectOrder: asyncReducerHelper(REJECT_ORDER),
  approveOrder: asyncReducerHelper(APPROVE_ORDER),
  createHandDelivery: asyncReducerHelper(CREATE_HAND_DELIVERY),
  deleteHandDelivery: asyncReducerHelper(DELETE_HAND_DELIVERY),
});

const asyncOverride = (state = {}, action) => {
  const cleanState = {
    orderInfo: {},
    orderAction: {},
    orderDetailAction: {},
    orderDetailActionByType: {},
    rejectOrder: {},
    approveOrder: {},
    createHandDelivery: {},
    deleteHandDelivery: {},
  };

  if (action.type === LOAD_ORDER_INFORMATION) {
    return {
      ...cleanState,
      orderInfo: { isProcessing: true },
    };
  }

  if (action.type === ORDER_DETAIL_ACTION) {
    return {
      ...state,
      orderDetailAction: { isProcessing: true },
      orderDetailActionByType: {},
    };
  }

  if (action.type === ORDER_DETAIL_ACTION_BY_TYPE) {
    return {
      ...state,
      orderDetailAction: {},
      orderDetailActionByType: { isProcessing: true },
    };
  }

  return asyncReducer(state, action);
};

const orderInfoReducer = combineReducers({
  async: asyncOverride,
  data: orderInfo,
  byDevice: orderDetailsByDevice,
});

export default orderInfoReducer;
