import Session from 'components/utils/session';

export const LOGIN = 'LOGIN';
export const CANCEL_PENDING_REQUEST = 'CANCEL_PENDING_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';
export const FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'VERIFY_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export function forgotPassword(info, promise, notification) {
  return {
    type: FORGOT_PASSWORD,
    email: info.email,
    promise,
    notification,
  };
}

export function resetPassword(data, promise, notification) {
  return {
    type: RESET_PASSWORD,
    data,
    promise,
    notification,
  };
}

export const requestLogin = url => ({
  url,
  type: LOGIN_REQUEST,
});

export const loginRequested = payload => ({
  payload,
  type: LOGIN_REQUEST_SUCCESS,
});

export function forceChangePassword(data, promise, notification) {
  return {
    type: FORCE_CHANGE_PASSWORD,
    data,
    promise,
    notification,
  };
}

export function requestVerify(data) {
  return {
    type: VERIFY_REQUEST,
    data,
  };
}

export function requestLogout() {
  Session.purge();
  return {
    type: LOGOUT_SUCCESS,
  };
}
