import { createSelector } from 'reselect';
import _ from 'lodash';
import { getFullName } from 'components/utils/helpers';

export const getUserDetailsList = state => state.users.userDetails.byId;

export const getUserDetailsById = (state, props) => (
  _.get(state.users.userDetails.byId, `${props.userId}`, {})
);

export const getUserValues = (state, props) => props.user;

export const makeGetFullName = () => {
  return createSelector(
    getUserDetailsById,
    user => getFullName(user.first_name, user.last_name, '-'),
  );
};

export const makeGetUserFormValues = () => {
  return createSelector(
    getUserValues,
    user => {
      if (!user) {
        return {};
      } return ({
        ...user,
        name: getFullName(user.first_name, user.last_name, '-'),
        clinics: _.get(user, 'clinics[0]', null),
      });
    },
  );
};
