import moment from 'moment';
import Session from 'components/utils/session';
import { store } from 'App';
import { LOGOUT_SUCCESS } from 'components/auth/actions/authActions';

const logout = () => {
  Session.purge();
  store.dispatch({
    type: LOGOUT_SUCCESS,
    error: {
      message: 'Session expired.',
    },
  });
};

const isExpired = function() {
  const now = moment().unix();

  if (now >= Session.JWTDecoded.exp) {
    logout();
  }

  return true;
};

const safeCall = async function(coreFunc, args) {
  try {
    isExpired();
  } catch (error) {
    throw error;
  }

  try {
    return await Session.Tokenized[coreFunc].apply(null, args);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      logout();
    }
    throw error;
  }
};

export default {
  get(...args) {
    return safeCall('get', args);
  },
  post(...args) {
    return safeCall('post', args);
  },
  delete(...args) {
    return safeCall('delete', args);
  },
  put(...args) {
    return safeCall('put', args);
  },
  patch(...args) {
    return safeCall('patch', args);
  },
};
