import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import omit from 'lodash/omit';

import getGlobalStyles from 'components/styles/global.styles';
import getStyles from './TouchInput.styles';

class TouchInput extends Component {
  render() {
    const {
      touchText, onTouch,
    } = this.props;

    const styles = getStyles();
    const globalStyles = getGlobalStyles();

    return (
      <div style={styles.container}>
        <TextField
          {...omit(this.props, ['onTouch', 'touchText'])}
        />
        <Button
          type="button"
          children={touchText}
          style={{ ...globalStyles.linkButton, ...globalStyles.linkButton.label, ...styles.button }}
          onClick={onTouch}
        />
      </div>
    );
  }
}

TouchInput.propTypes = {
  touchText: PropTypes.string.isRequired,
  onTouch: PropTypes.func.isRequired,
};

export default TouchInput;
