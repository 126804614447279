import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'recompose';
import { withTheme, withStyles } from '@material-ui/core';

import listWrapper from 'components/common/base/ListWrapper';
import { intlWord } from 'components/utils/helpers';
import { enqueueSnackbar } from 'components/common/Notifier/actions';
import { SNACKBAR_VARIANTS } from 'components/common/Notifier/utils/constants';
import { emptySearchMessageQuarantine } from '../../utils/constants';
import { loadQuarantineList, cancelPendingRequest } from '../../actions/quarantineActions';
import { downloadQuarantine } from '../../services/quarantineService';
import QuarantineListPresenter from './QuarantineListPresenter';
import getStyles from './QuarantineList.styles';

class QuarantineListContainer extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    quarantineList: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      serial_number: PropTypes.string,
      device_id: PropTypes.number,
      mac: PropTypes.string,
      request_date: PropTypes.string,
      request_type: PropTypes.string,
      file_link: PropTypes.string,
    })).isRequired,
    error: PropTypes.shape({}).isRequired,
    setUpdateListFunction: PropTypes.func.isRequired,
    searchChange: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    sort: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    selectedPageChange: PropTypes.func.isRequired,
    sortChange: PropTypes.func.isRequired,
    perPageChange: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    numberOfRecords: PropTypes.number.isRequired,
    _loadQuarantineList: PropTypes.func.isRequired,
    _enqueueSnackbar: PropTypes.func.isRequired,
    _cancelPendingRequest: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    numberOfPages: PropTypes.number,
  }

  constructor(props) {
    super(props);

    this.state = {
      emptySearchMessage: null,
    };

    this.styles = getStyles();

    this.searchFields = [
      { text: intlWord(this.props.intl, 'quarantine.list.mac'), key: 'mac' },
      { text: intlWord(this.props.intl, 'quarantine.list.serial'), key: 'serial_number' },
    ];

    this.sortableFields = [
      { title: 'quarantine.list.mac', key: 'mac' },
      { title: 'quarantine.list.deviceId', key: 'device_id' },
      { title: 'quarantine.list.serial', key: 'serial_number' },
      { title: 'quarantine.list.uploadDate', key: 'request_date' },
      { title: 'quarantine.list.typeOfRequest', key: 'request_type', notSortable: true },
    ];
  }

  componentDidMount() {
    const {
      _loadQuarantineList,
      setUpdateListFunction,
      setPagination,
      setSort,
    } = this.props;

    const config = {
      perPage: 25,
      selectedPage: 1,
      key: 'request_date',
      direction: -1,
    };

    _loadQuarantineList(config.selectedPage, config.perPage, config.key, config.direction);
    setSort({ key: config.key, direction: config.direction });
    setPagination({ perPage: config.perPage, selectedPage: config.selectedPage });
    setUpdateListFunction(this.sortList);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      error,
      _enqueueSnackbar,
      quarantineList,
      isLoaded,
    } = this.props;

    if (
      quarantineList.length < 1
      && isLoaded
      && prevProps.isProcessing
    ) {
      this.setState({ emptySearchMessage: emptySearchMessageQuarantine });
    }

    if (prevProps.error !== error && error !== {}) {
      _enqueueSnackbar({
        message: error,
        options: { variant: SNACKBAR_VARIANTS.error },
        disableRetry: true,
      });
    }
  }

  componentWillUnmount() {
    const { _cancelPendingRequest } = this.props;

    _cancelPendingRequest();
  }

  sortList = (sort, pagination, criteria) => {
    const { _loadQuarantineList } = this.props;

    this.setState({ emptySearchMessage: null });

    _loadQuarantineList(
      pagination.selectedPage,
      pagination.perPage,
      sort.key,
      sort.direction,
      criteria,
    );
  }

  downloadFile = async e => {
    const { _enqueueSnackbar } = this.props;

    const id = e.currentTarget.dataset.quarantinelogid;
    const response = await downloadQuarantine(id);

    if (response.message) {
      _enqueueSnackbar({
        message: response,
        options: { variant: SNACKBAR_VARIANTS.error },
        disableRetry: true,
      });
    } else {
      window.location.assign(response.download_url);
    }
  };

  render() {
    const { emptySearchMessage } = this.state;

    return (
      <QuarantineListPresenter
        searchFields={this.searchFields}
        sortableFields={this.sortableFields}
        style={this.styles}
        downloadFile={this.downloadFile}
        emptySearchMessage={emptySearchMessage}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => ({
  quarantineList: state.quarantine.items.data,
  numberOfRecords: state.quarantine.items.total,
  numberOfPages: state.quarantine.items.pages.length,
  error: state.quarantine.items.error,
  isLoaded: state.quarantine.items.isLoaded,
  isProcessing: state.quarantine.items.isProcessing,
});

const mapDispatchToProps = dispatch => ({
  _loadQuarantineList: (selectedPage, perPage, sortField, sortType, criteria) => {
    dispatch(loadQuarantineList(selectedPage, perPage, sortField, sortType, criteria));
  },
  _enqueueSnackbar: notification => {
    dispatch(enqueueSnackbar(notification));
  },
  _cancelPendingRequest: () => {
    dispatch(cancelPendingRequest());
  },
});

const enhance = compose(
  injectIntl,
  withTheme,
  withStyles(getStyles),
  connect(mapStateToProps, mapDispatchToProps),
  listWrapper({ displayName: 'QuarantineList' }),
);

export default enhance(QuarantineListContainer);
