export default {
  packageDetails: 'Package Details',
  pickupDetails: 'Pickup Details',
  packageDetailsSuccess: 'Package details submit success',
  length: 'Length',
  width: 'Width',
  height: 'Height',
  weight: 'Weight',
  zeroInch: '0"',
  zeroLbs: '0 lbs.',
  selectParcelSize: 'Select Parcel Size',
  requestPickup: 'Request a pickup',
  overnight: 'Overnight',
  twoDay: '2-Day',
  ground: 'Ground',
  standard: 'Standard',
  handDelivery: 'Hand Delivery',
  sendRequest: 'Send Request',
  confirmTitle: 'Warning',
  confirmText: 'Request cannot be cancelled once sent.',
  handDeliverySuccess: 'Request success',
  whoWillPickUp: 'Who will pick up',
  note: 'Note',
  instructions: 'Instructions',
  ship: 'Ship',
  pickupDate: 'Pickup date',
  readyNow: 'Ready now',
  thisAfternoon: 'This afternoon',
  tomorrowMorning: 'Tomorrow morning',
  selectPriority: 'Select priority',
  requestRates: 'Request rates',
  cancelHandDelivery: 'Cancel hand delivery',
  fetchingRates: 'Fetching rates',
  deliveryBought: 'Delivery bought',
  buyDelivery: 'Buy delivery',
  inTransit: 'Confirm package is in transit',
  postageLabel: 'Postage label',
  trackerUrl: 'Tracker URL',
};
