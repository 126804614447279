import theme from 'components/styles/default.theme';

const getGlobalStyles = () => ({
  primaryButton: {
    backgroundColor: theme.palette.backgroundColor,
    color: theme.palette.secondaryTextColor,
    borderRadius: 50,
    fontSize: theme.size.body,
    textTransform: 'uppercase',
    letterSpacing: theme.spacing(0),
    '&:hover': {
      backgroundColor: theme.palette.backgroundColor,
      color: theme.palette.secondaryTextColor,
    },
    '&:disabled': {
      color: theme.palette.secondaryTextColor,
      backgroundColor: theme.palette.steel,
    },
  },
  secondaryButton: {
    borderRadius: 50,
    fontSize: theme.size.body,
    textTransform: 'uppercase',
    letterSpacing: theme.spacing(0),
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    borderRadius: 0,
    color: theme.palette.highlightTextColor,
    fontWeight: theme.font.bold,
    textTransform: 'uppercase',
    fontSize: 14,
  },
  linkButton: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: theme.palette.backgroundColor,
    fontSize: theme.size.body,
    textTransform: 'uppercase',
    letterSpacing: theme.spacing(0),
    fontWeight: theme.font.thin,
    textDecoration: 'none',
  },
  linkIcon: {
    textDecoration: 'none',
  },
  linkText: {
    textDecoration: 'none',
    color: theme.palette.highlightTextColor,
  },
  pageHeader: {
    display: 'flex',
    padding: theme.padding.pageSmall,
    alignItems: 'center',
    height: theme.height.pageHeader,
    borderBottom: `1px solid ${theme.palette.borderColor1}`,
  },
  pageTitle: {
    fontSize: theme.size.header,
    color: theme.palette.textColor,
    letterSpacing: theme.spacing(0) * 2,
    fontWeight: 300,
  },
  primaryInputLabel: {
    display: 'block',
    color: theme.palette.textColor,
    fontSize: theme.size.body,
    marginBottom: theme.spacing(7),
    textTransform: 'uppercase',
    fontWeight: theme.font.bold,
    letterSpacing: theme.spacing(0),
  },
  plainListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: theme.size.body,
    paddingTop: theme.padding.textInput,
    paddingBottom: theme.padding.textInput,
    borderBottom: `1px solid ${theme.palette.borderColor2}`,
    content: {
      flexGrow: 1,
      flexShrink: 1,
      color: theme.palette.backgroundColor,
      fontWeight: theme.font.bold,
      text: {
        marginBottom: theme.spacing(8),
      },
      tagline: {
        color: theme.palette.defaultTextColor,
        fontWeight: theme.font.thin,
        fontSize: 14,
      },
    },
    actions: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
      button: {
        width: 32,
        height: 32,
        padding: 0,
        icon: {
          color: theme.palette.backgroundColor,
          width: 24,
          height: 24,
        },
      },
    },
  },
  text: {
    boldUpper: {
      color: theme.palette.textColor,
      fontSize: theme.size.body,
      fontWeight: theme.font.bold,
    },
  },
  expandIcon: {
    color: `${theme.palette.highlightTextColor} !important`,
  },
  expandBody: {
    width: '652px',
  },
  pageIcon: {
    width: '2em',
    height: '2em',
    fill: theme.palette.textColor,
    marginRight: theme.spacing(1) * 4,
  },
});

export default getGlobalStyles;
