import { CREATE_LOT as CREATE_LOT_FORM } from 'components/common/forms/names';
import { sagaRequestWithPayload, formSagaRequestWithPayload } from 'components/utils/reduxHelpers';
import { call, put } from 'redux-saga/effects';
import * as OperationsService from '../services/operationsService';
import {
  LOAD_LOTS,
  LOAD_LOT_DETAILS,
  CREATE_LOT,
  CLOSE_LOT,
  OPEN_LOT,
} from '../actions/operationsActions';

export function* loadLots(action) {
  yield* sagaRequestWithPayload(
    LOAD_LOTS,
    OperationsService.loadLots,
    action,
  );
}

export function* loadLotDetails(action) {
  yield* sagaRequestWithPayload(LOAD_LOT_DETAILS, OperationsService.loadLotDetails, action);
}

export function* createLot(action) {
  const { promise, ...rest } = action;

  yield* formSagaRequestWithPayload(
    CREATE_LOT_FORM,
    CREATE_LOT,
    OperationsService.createLot,
    promise,
    rest,
  );
}

export function* closeLot(action) {
  try {
    const result = yield call(
      OperationsService.closeLot,
      action,
    );

    yield put({ type: LOAD_LOT_DETAILS, lotId: action.lotId});
    yield put({ type: `${CLOSE_LOT}_SUCCESS`, result, payload: action });
    // TODO: needs to support notifications
  } catch (error) {
    yield put({ type: `${CLOSE_LOT}_FAILURE`, error });
  }
}

export function* openLot(action) {
  try {
    const result = yield call(
      OperationsService.openLot,
      action,
    );

    yield put({ type: LOAD_LOT_DETAILS, lotId: action.lotId});
    yield put({ type: `${OPEN_LOT}_SUCCESS`, result, payload: action });
    // TODO: needs to support notifications
  } catch (error) {
    yield put({ type: `${OPEN_LOT}_FAILURE`, error });
  }
}
