import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { required } from 'components/common/forms/validation';
import Form from 'components/common/forms/Form';
import { CREATE_LOT } from 'components/common/forms/names';
import { SelectField, TextField } from 'components/common/forms/elements';

import { createLot } from '../../actions/operationsActions';

const submit = (values, dispatch) => new Promise((resolve, reject) => {
  dispatch(createLot(values, { resolve, reject }));
});

const CreateLotForm = props => {
  const successMessage = { message: 'operations.form.createLotSuccess' };
  const errorMessage = { message: 'operations.form.createLotError' };

  return (
    <Form
      id={CREATE_LOT}
      onSubmit={submit}
      successMessage={successMessage}
      errorMessage={errorMessage}
      submitBtnTxt="operations.form.createLot"
      {...props}
    >
      <TextField name="lot_name" label="operations.form.lotNumber" validate={required} />
      <SelectField
        name="device_type_id"
        label="operations.form.selectDeviceType"
        validate={required}
        items={props.deviceTypes}
        itemValue="device_type_id"
        itemText="sku_number"
      />
    </Form>
  );
};

CreateLotForm.propTypes = {
  deviceTypes: PropTypes.array.isRequired,
};

export default reduxForm({
  form: CREATE_LOT,
  onSubmitSuccess: (result, dispatch, props) => {
    props.onLotCreated(result);
  },
})(CreateLotForm);
