import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import withWidth from '@material-ui/core/withWidth';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import PageWrapper from 'components/common/PageWrapper';
import getGlobalStyles from 'components/styles/global.styles';
import { mediaStyle } from 'components/utils/styles';
import PatientList from 'components/patients/ui/patientList/PatientList';
import * as en from 'intl/en';

import PatientListIcon from 'components/styles/icons/PatientListIcon';
import getStyles from './PatientListPage.styles';

class PatientListPage extends Component {
  constructor(props) {
    super(props);
    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();
  }

  openAddPatient = () => {
    this.props.history.push('/patients/create');
  }

  render() {
    const { width } = this.props;

    return (
      <PageWrapper>
        <div style={this.globalStyles.pageHeader}>
          <PatientListIcon
            style={this.globalStyles.pageIcon}
          />
          <h1 style={{ ...this.globalStyles.pageTitle, ...mediaStyle({}, width) }}>
            <FormattedMessage
              id="PatientListPage.title"
              defaultMessage={en.PatientListPage.title}
            />
          </h1>
        </div>
        <div style={mediaStyle(this.styles.content, width)}>
          <PatientList />
        </div>
      </PageWrapper>
    );
  }
}

PatientListPage.propTypes = {
  width: PropTypes.string,
  history: PropTypes.any.isRequired,
};

const enhance = compose(
  withRouter,
  withWidth(),
);

export default enhance(PatientListPage);
