import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { requestLogin } from 'components/auth/actions/authActions';
import * as en from 'intl/en';

class OAuthLandingPage extends Component {
  componentDidMount() {
    const { _requestLogin } = this.props;
    const querystring = window.location.search;

    // eslint-disable-next-line max-len
    const url = `${process.env.REACT_APP_CALA_AUTH_LOGIN_URI}${querystring}&client_id=${process.env.REACT_APP_CLIENT_ID}`;

    _requestLogin(url);
  }

  componentDidUpdate() {
    const {
      isAuthenticated,
      history,
      location,
    } = this.props;
    const { from } = location.state || { from: { pathname: '/' } };

    if (isAuthenticated) {
      history.replace(from.pathname);
    }
  }

  render() {
    return <p> {en.shared.oauth} </p>;
  }
}

OAuthLandingPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  _requestLogin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  _requestLogin: url => {
    dispatch(requestLogin(url));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OAuthLandingPage);
