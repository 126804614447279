const getStyles = () => ({
  datesContainer: {
    marginBottom: '10px',
  },
  month: {
    display: 'inline-block',
    fontWeight: 'bold',
    color: '#727272',
    marginRight: '10px',
  },
  day: {
    display: 'inline-block',
    margin: '0 5px',
    backgroundColor: '#727272',
    color: 'white',
    padding: '7px',
    borderRadius: '50%',
    minWidth: '33px',
    width: '33px',
    textAlign: 'center',
    lineHeight: 1.45,
  },
  selectedDay: {
    backgroundColor: '#189ACA',
  },
  wrapper: {
    width: 'fit-content',
    overflow: 'auto',
    maxHeight: '12vh',
  },
});

export default getStyles;
