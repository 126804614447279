export default {
  deviceId: 'Device ID',
  downloadTime: 'Download Time',
  patientId: 'Patient ID',
  subjectId: 'Subject ID',
  handed: 'Handed',
  bandSize: 'Band Size',
  serialNumber: 'Serial #',
  mac: 'MAC',
  deviceLogId: 'Device Log ID',
};
