import { reset, clearSubmitErrors, SubmissionError } from 'redux-form';
import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { formSagaRequestWithPayload, sagaRequestWithPayload } from 'components/utils/reduxHelpers';
import {
  PACKAGE_DETAILS as PACKAGE_DETAILS_FORM,
  HAND_DELIVERY as HAND_DELIVERY_FORM,
  BUY_DELIVERY as BUY_DELIVERY_FORM,
  BUY_PICKUP as BUY_PICKUP_FORM,
} from 'components/common/forms/names';
import * as ShipmentOrderService from '../services/shipmentOrderService';
import {
  CREATE_SHIPMENT_ORDER,
  CREATE_HAND_DELIVERY,
  DELETE_HAND_DELIVERY,
  FETCH_RATES,
  REGENERATE_RATES,
  BUY_DELIVERY,
  GET_POSTAGE_LABEL,
  GET_ORDER_SHIPMENT,
  BUY_PICKUP,
  GET_PICKUP,
} from '../actions/shipmentOrderActions';

export function* createShipmentOrder(action) {
  const { promise, orderId, body } = action;

  try {
    yield put(clearSubmitErrors(PACKAGE_DETAILS_FORM));
    const result = yield call(ShipmentOrderService.createShipmentOrder, { orderId, body });
    
    yield put({ type: `${CREATE_SHIPMENT_ORDER}_SUCCESS`, result, payload: { orderId, body } });
    yield put({ type: FETCH_RATES, orderId });
    yield put(reset(PACKAGE_DETAILS_FORM));
    promise.resolve(result);
  } catch (error) {
    yield put({ type: `${CREATE_SHIPMENT_ORDER}_FAILURE`, error });
    promise.reject(new SubmissionError({ _error: error }));
  }
}

export function* createHandDelivery(action) {
  const { orderId, body } = action;

  yield* formSagaRequestWithPayload(
    HAND_DELIVERY_FORM,
    CREATE_HAND_DELIVERY,
    ShipmentOrderService.createHandDelivery,
    action.promise,
    { orderId, body },
  );
}

export function* deleteHandDelivery(action) {
  yield* sagaRequestWithPayload(
    DELETE_HAND_DELIVERY,
    ShipmentOrderService.deleteHandDelivery,
    action,
  );
}

let regenerateCounter = 0;

export function* fetchRates(action) {
  try {
    regenerateCounter = 0;
    const result = yield call(ShipmentOrderService.fetchRates, action);

    if (result.length === 0) {
      yield put({ type: `${FETCH_RATES}_FAILURE` });
      yield put({ type: REGENERATE_RATES, orderId: action.orderId });
    } else {
      yield put({ type: `${FETCH_RATES}_SUCCESS`, result, payload: action });
    }
  } catch (error) {
    yield put({ type: `${FETCH_RATES}_FAILURE`, error });
    yield put({ type: REGENERATE_RATES, orderId: action.orderId });
  }
}

export function* regenerateRates(action) {
  try {
    const result = yield call(ShipmentOrderService.regenerateRates, action);

    // if regenerate success, try to refetch rates
    yield put({ type: FETCH_RATES, orderId: action.orderId });
    yield put({ type: `${REGENERATE_RATES}_SUCCESS`, result, payload: action });
  } catch (error) {
    regenerateCounter++;
    if (regenerateCounter < 5) {
      // retry every second, 5 retries
      yield delay(1000);
      yield put({ type: REGENERATE_RATES, orderId: action.orderId });
    } else {
      yield put({ type: `${REGENERATE_RATES}_FAILURE`, error });
    }
  }
}

export function* buyDelivery(action) {
  const { promise, orderId, body } = action;

  try {
    yield put(clearSubmitErrors(BUY_DELIVERY_FORM));
    const result = yield call(ShipmentOrderService.buyDelivery, { orderId, body });

    yield put({ type: GET_POSTAGE_LABEL, orderId });
    yield put({ type: GET_ORDER_SHIPMENT, orderId });
    yield put({ type: `${BUY_DELIVERY}_SUCCESS`, result, payload: action });
    yield put(reset(BUY_DELIVERY_FORM));
    promise.resolve(result);
  } catch (error) {
    yield put({ type: `${BUY_DELIVERY}_FAILURE`, error });
    promise.reject(new SubmissionError({ _error: error }));
  }
}

export function* getPostageLabel(action) {
  yield* sagaRequestWithPayload(
    GET_POSTAGE_LABEL,
    ShipmentOrderService.getPostageLabel,
    action,
  );
}

export function* getOrderShipment(action) {
  yield* sagaRequestWithPayload(
    GET_ORDER_SHIPMENT,
    ShipmentOrderService.getOrderShipment,
    action,
  );
}

export function* getPickup(action) {
  yield* sagaRequestWithPayload(
    GET_PICKUP,
    ShipmentOrderService.getPickup,
    action,
  );
}

export function* buyPickup(action) {
  const { orderId, body } = action;

  yield* formSagaRequestWithPayload(
    BUY_PICKUP_FORM,
    BUY_PICKUP,
    ShipmentOrderService.buyPickup,
    action.promise,
    { orderId, body },
  );
}
