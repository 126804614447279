import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import * as en from 'intl/en';
import PageWrapper from 'components/common/PageWrapper';

import getGlobalStyles from 'components/styles/global.styles';
import { mediaStyle } from 'components/utils/styles';
import { FormattedMessage } from 'react-intl';

import UserIcon from 'components/styles/icons/UserIcon';
import ProfileForm from 'components/profile/forms/ProfileForm';
import { loadProfile } from 'components/profile/actions/profileActions';
import { getShouldLoadProfile } from 'components/profile/selectors';
import getStyles from './ProfilePage.styles';

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();

    this.closeForm = this.closeForm.bind(this);
  }

  componentDidMount() {
    const { _loadProfile, shouldLoadProfile } = this.props;

    if (shouldLoadProfile) {
      _loadProfile();
    }
  }

  closeForm() {
    this.props.history.goBack();
  }

  render() {
    const { width } = this.props;

    return (
        <PageWrapper>
          <div style={this.globalStyles.pageHeader}>
            <UserIcon style={this.globalStyles.pageIcon} />
            <h1 style={{ ...this.globalStyles.pageTitle, ...mediaStyle(this.styles.title, width) }}>
              <FormattedMessage
                id="ProfilePage.title"
                defaultMessage={en.ProfilePage.title}
              />
            </h1>
          </div>
          <div style={mediaStyle(this.styles.content, width)}>
            <div style={this.styles.content.left}>
              <ProfileForm
                delegates={{
                  closeForm: this.closeForm,
                }}
              />
            </div>
          </div>
        </PageWrapper>
    );
  }
}

const mapStateToProps = state => ({
  shouldLoadProfile: getShouldLoadProfile(state),
});

const mapDispatchToProps = dispatch => ({
  _loadProfile: () => {
    dispatch(loadProfile());
  },
});

ProfilePage.propTypes = {
  width: PropTypes.string,
  history: PropTypes.any.isRequired,
  shouldLoadProfile: PropTypes.bool.isRequired,
  _loadProfile: PropTypes.func.isRequired,
};

const enhance = compose(
  withRouter,
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(ProfilePage);
