import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Message from 'components/common/Message';

const CompliancePercentage = props => {
  const {
    achieved,
    percentage,
    classes,
    message,
  } = props;

  return (
    <div className={classes.complianceWrapper}>
      <div
        className={classNames(classes.periodWrapper, {
          [`${classes.periodWrapper}--achieved`]: achieved,
        })}
      >
        <Message id={message} />
      </div>
      <div
        className={classNames(classes.percentageWrapper, {
          [`${classes.percentageWrapper}--achieved`]: achieved,
        })}
      >
        {`${percentage}%`}
      </div>
    </div>
  );
};

CompliancePercentage.propTypes = {
  achieved: PropTypes.bool,
  percentage: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
};

export default CompliancePercentage;
