export default {
  required: 'Required',
  invalidEmail: 'Invalid email address',
  invalidPhone: 'Invalid phone number, must be 10 digits',
  alphanumeric: 'Only alphanumeric characters',
  tooYoung: 'You do not meet the minimum age requirement!',
  minValue: 'Must be at least {min}',
  maxLength: 'Must be {max} characters or less',
  minLength: 'Must be {min} characters or more',
  mustBeANumber: 'Must be a number',
  passwordMinLength: 'Password must be atleast 8 characters or more',
  lowercaseCharacter: 'Password must contain a lowercase character',
  uppercaseCharacter: 'Password must contain a uppercase character',
  numberCharacter: 'Password must contain a number',
  specialCharacter: 'Password must contain a special character',
};
