import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as en from 'intl/en';
import { Button, withTheme } from '@material-ui/core';
import getGlobalStyles from 'components/styles/newGlobal.styles';

const SecondaryButton = props => {
  const {
    theme,
    onClick,
    isDisabled,
    message,
    customStyle,
    color,
    fullWidth,
    ...rest
  } = props;
  const globalStyles = getGlobalStyles(theme);

  return (
    <Button
      onClick={onClick}
      disabled={isDisabled}
      style={{
        ...globalStyles.secondaryButton,
        ...customStyle,
      }}
      color={color}
      fullWidth={fullWidth}
      {...rest}
    >
      <FormattedMessage
        id={message}
        defaultMessage={en[message]}
      />
    </Button>
  );
};

SecondaryButton.propTypes = {
  theme: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  message: PropTypes.string.isRequired,
  customStyle: PropTypes.object,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
};

SecondaryButton.defaultProps = {
  customStyle: {},
  color: 'primary',
  fullWidth: true,
};

export default withTheme(SecondaryButton);
