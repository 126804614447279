/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HomeIcon = props => (
    <SvgIcon
      {...props}
      viewBox="0 0 28 28"
      fontSize="inherit"
    >
      <path
        d="M14.061,0c-.088,0-.263.087-.351.087L.271,11.025a.661.661,0,0,0-.088.875.5.5,0,0,0,.79.088l.79-.613V27.387A.6.6,0,0,0,2.379,28H25.742a.6.6,0,0,0,.615-.613V11.375l.79.7a.557.557,0,0,0,.79-.087.671.671,0,0,0-.088-.875h0L23.371,7.525V2.975H19.858V4.55L14.324.087C14.324,0,14.148,0,14.061,0Zm0,1.4,11.155,9.013V26.775H18.189V15.225H10.02v11.55H2.994V10.412Zm7.027,2.8h1.142V6.475l-1.142-.962ZM11.162,16.45h5.885V26.775H11.162Z"
        transform="translate(-0.061)"
      />
    </SvgIcon>
);

export default HomeIcon;
