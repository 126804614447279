/* eslint-disable complexity */
import theme from 'components/styles/default.theme';

const getStyles = () => ({
  container: {
    flex: '0 0 auto',
    width: '100%',
    padding: '0px 45px 20px 15px',
    marginTop: 15,
  },
  widgets: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
  },
  dateWidget: {
    flex: '0 0 auto',
  },
  searchWidget: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    padding: '5px 5px 0',
  },
  searchBoxWrapper: {
    flexGrow: 1,
    position: 'relative',
    marginBottom: 5,
    borderRadius: 25,
    minWidth: 250,
  },
  searchBox: {
    color: theme.palette.textColor,
    padding: 10,
    paddingRight: 30,
    border: 'none',
    borderRadius: 25,
    fontSize: 16,
    height: 40,
    width: '100%',
  },
  searchIcon: {
    position: 'absolute',
    color: theme.palette.borderColor1,
    top: '50%',
    right: 5,
    transform: 'translateY(-50%)',
    zIndex: 20,
  },
  keywordWrapper: {
    display: 'flex',
    width: '100%',
  },
  pickerModal: {
    fontSize: '16px',
    color: 'rgba(102, 102, 102, 0.6)',
    padding: '0px 24px 24px',
    boxSizing: 'border-box',
    overflowY: 'hidden',
    borderTop: 'medium none',
    borderBottom: 'medium none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '712px',
    width: '768px',
    maxWidth: '768px',
  },
  dateIcon: {
    fontSize: 30,
    color: theme.palette.borderColor1,
  },
  dateIconWrapper: {
    width: 48,
    height: 48,
    padding: 9,
    fontSize: 30,
    color: theme.palette.borderColor1,
  },
  label: {
    fontSize: theme.size.body,
    textTransform: 'uppercase',
    letterSpacing: theme.spacing(0),
  },
  cancelLabel: {
    fontSize: theme.size.body,
    textTransform: 'uppercase',
    letterSpacing: theme.spacing(0),
    fontWeight: theme.font.thin,
  },
});

export default getStyles;
