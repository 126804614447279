import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'containers/common/Sidebar';

import getGlobalStyles from 'components/styles/global.styles';
import {
  isCalaUser,
  isClinicUser,
  isClinicalSciences,
  isAllowedRole,
} from 'components/users/utils/userRoles';
import NotesDialog from 'components/common/notes/NotesDialog';
import { quarantinePermission } from 'components/quarantine/utils/permissions';
import patientListPermissions from 'components/patients/utils/permissions';
import userPermissions from 'components/users/utils/permissions';
import Session from 'components/utils/session';

import {
  calaItems,
  clinicItems,
  clinicalScienceItems,
} from '../common/utils/tabItems';
import CalaRoutes from './CalaRoutes';
import ClinicRoutes from './ClinicRoutes';
import ClinicalSciencesRoutes from './ClinicalSciencesRoutes';
import OtherRoutes from './OtherRoutes';
import { filterTabItems } from './utils/helper';

class CoreRoute extends Component {
  constructor(props) {
    super(props);

    this.roleId = Session.JWTDecoded.role_id;

    this.isAllowedPatientList = isAllowedRole(this.roleId, patientListPermissions.showPatientList);
    this.isAllowedUserManagement = isAllowedRole(this.roleId, userPermissions.showUserManagement);
    this.isAllowedQuarantine = isAllowedRole(this.roleId, quarantinePermission.allowedRoles);
  }

  render() {
    const { match } = this.props;
    const {
      isAllowedPatientList,
      isAllowedUserManagement,
      isAllowedQuarantine,
    } = this;

    let tabItems = [];
    let routes = null;
    let activeTab;

    if (isCalaUser(this.roleId)) {
      tabItems = calaItems;

      if (!isAllowedPatientList) {
        tabItems = filterTabItems(tabItems, 'patients');
      }
      if (!isAllowedUserManagement) {
        tabItems = filterTabItems(tabItems, 'users');
      }
      if (!isAllowedQuarantine) {
        tabItems = filterTabItems(tabItems, 'quarantine');
      }
      routes = CalaRoutes;
      activeTab = 'home';
    } else if (isClinicUser(this.roleId)) {
      tabItems = clinicItems;
      routes = ClinicRoutes;
      activeTab = 'patients';
    } else if (isClinicalSciences(this.roleId)) {
      tabItems = clinicalScienceItems;
      routes = ClinicalSciencesRoutes;
      activeTab = 'home';
    } else {
      tabItems = [];
      routes = OtherRoutes;
      activeTab = 'profile';
    }

    return (
      <div
        id="page"
        className="full-root primary-layout"
        style={getGlobalStyles().page}
      >
        <NotesDialog />
        <Sidebar
          activeTab={match.params.tab || activeTab}
          tabItems={tabItems}
        />
        {routes}
      </div>
    );
  }
}

CoreRoute.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      tab: PropTypes.string,
    }),
  }).isRequired,
};

export default CoreRoute;
