import { createSelector } from 'reselect';

export const getDevicesById = state => state.operations.devices.byId;

export const getDevicesList = state => state.operations.devices.list;

export const getPrefixList = state => state.operations.devices.prefix;

export const getDevicesLoadAsyncInfo = state => state.operations.devices.loadAsyncInfo;

export const getDevicesLoadAsyncIsProcessing = state => state.operations.devices.loadAsyncInfo.isProcessing;

export const getDevicesPages = state => state.operations.devices.pages;

export const getPrefixDescriptions = createSelector(
  [getPrefixList],
  prefixList => prefixList.reduce((acc, item) => {
    acc[item.name] = item.description;

    return acc;
  }, {}),
);
