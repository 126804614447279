import { combineReducers } from 'redux';
import _ from 'lodash';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import { FETCH_DEVICE_STOCK } from '../actions/inventoryActions';

export const DEFAULT_STATE = {};

const getStockById = (state, action) => {
  return {
    ...state,
    [action.payload.siteId]: _.mapValues(_.keyBy(action.result, 'device_type_id'), 'stock'),
  };
};

export const byId = createReducer(DEFAULT_STATE, {
  [`${FETCH_DEVICE_STOCK}_SUCCESS`]: getStockById,
});

const asyncByIdHandler = asyncReducerHelper(FETCH_DEVICE_STOCK);

const updateAsyncById = (state, action) => {
  const siteId = action.type === FETCH_DEVICE_STOCK ? action.siteId : action.payload.siteId;
  const oldState = state[siteId] || {};

  return {
    ...state,
    [siteId]: asyncByIdHandler(oldState, action),
  };
};

export const asyncById = createReducer(DEFAULT_STATE, {
  [`${FETCH_DEVICE_STOCK}`]: updateAsyncById,
  [`${FETCH_DEVICE_STOCK}_SUCCESS`]: updateAsyncById,
  [`${FETCH_DEVICE_STOCK}_FAILURE`]: updateAsyncById,
});

const deviceStockReducer = combineReducers({
  byId,
  asyncById,
});

export default deviceStockReducer;
