import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  injectIntl,
  intlShape,
  FormattedDate,
  FormattedMessage,
} from 'react-intl';
import {
  Button,
  withStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

import listWrapper from 'components/common/base/ListWrapper';
import moment from 'moment';
import Alert from 'components/common/Alert';
import { cleanAWSMessage } from 'components/utils/errors';
import SortableHeaderColumn from 'components/common/SortableHeaderColumn';
import TableLoadIndicator from 'components/common/TableLoadIndicator';
import { orderBy } from 'components/utils/helpers';
import * as en from 'intl/en';
import getGlobalStyles from 'components/styles/global.styles';
import newGlobalStyles from 'components/styles/newGlobal.styles';
import Session from 'components/utils/session';

import getStyles from './PatientDetails.styles';
import { exportData } from '../../services/patientsService';
import ExportDialog from '../exportDialog/ExportDialog';
import { getPatientDetailsData, getPatientDetailsAsync } from '../../selectors';
import RequestReportForm from './RequestReportForm/RequestReportForm';

class PatientDetails extends Component {
  constructor(props) {
    super(props);

    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();

    this._sortableFields = [
      { title: 'patients.details.deviceSn', key: 'device_sn' },
      { title: 'patients.details.preset', key: 'preset' },
      { title: 'patients.details.centerFrequency', key: 'center_freq' },
      { title: 'patients.details.sessionNumber', key: 'num_of_sessions' },
      { title: 'patients.details.assignDate', key: 'assign_date' },
      { title: 'patients.details.unassignDate', key: 'unassign_date' },
    ];

    this.state = {
      showExport: false,
      exportMessage: '',
      assignedDevices: [],
      isRequestReportFormOpen: false,
    };
  }

  componentDidMount() {
    const {
      setSort,
      setUpdateListFunction,
    } = this.props;

    setSort({ key: this._sortableFields[0].key, direction: -1 });
    setUpdateListFunction(this.sortList);
  }

  componentDidUpdate(prevProps, prevState) {
    const { patientDetailsData } = prevProps;

    if (patientDetailsData !== this.props.patientDetailsData) {
      this.setState({
        assignedDevices: this.props.patientDetailsData.assigned_devices,
      });
    }
  }

  sortList = sort => {
    const { assignedDevices } = this.state;

    this.setState({
      assignedDevices: orderBy(assignedDevices, sort.key, sort.direction),
    });
  }

  openRequestForm = () => {
    this.setState({ isRequestReportFormOpen: true });
  }

  closeRequestForm = () => {
    this.setState({ isRequestReportFormOpen: false });
  }

  openExportData = async() => {
    const {
      intl,
      patientDetailsData,
    } = this.props;

    try {
      await exportData(patientDetailsData.patient_id, Session.JWTDecoded.email);
      this.setState({
        showExport: true,
        exportMessage: intl.formatMessage({
          id: 'patients.details.exportMessage',
          defaultMessage: en.patients.details.exportMessage,
        }),
      });
    } catch (error) {
      this.setState({
        showExport: true,
        exportMessage: cleanAWSMessage(
          error.message,
          error,
          intl,
        ),
      });
    }
  }

  closeExportData = () => {
    this.setState({
      showExport: false,
    });
  }

  render() {
    const {
      intl,
      classes,
      sort,
      sortChange,
      patientDetailsData,
      patientDetailsAsync,
    } = this.props;
    const {
      showExport,
      exportMessage,
      assignedDevices,
    } = this.state;

    return (
      <div
        style={this.styles.container}
      >
        <RequestReportForm
          patient_id={patientDetailsData.patient_id}
          isOpen={this.state.isRequestReportFormOpen}
          closeRequestForm={this.closeRequestForm}
        />
        <ExportDialog
          handleClose={this.closeExportData}
          isOpen={showExport}
          intl={intl}
          exportMessage={exportMessage}
        />
        {
          patientDetailsAsync.error &&
          <Alert
            id="alert--error"
            text={cleanAWSMessage(
              patientDetailsAsync.error.message,
              patientDetailsAsync.error,
              intl,
            )}
            type="danger"
          />
        }
        <Table
          id="table--patient-details"
        >
          <TableHead
            style={this.styles.table.header}
          >
            <TableRow style={this.styles.table.headerRow}>
              {this._sortableFields.map(field =>
              (<SortableHeaderColumn
                id={`column--${field.key}`}
                title={field.title}
                field={field.key}
                key={field.key}
                notSortable={field.notSortable || assignedDevices.length === 0}
                activeField={sort.key}
                onSort={sortChange}
                sortDirection={sort.direction}
                style={field.style}
              />))}
            </TableRow>
          </TableHead>
          <TableBody>
            {patientDetailsAsync.isProcessing &&
              <TableLoadIndicator
                id="load-indicator"
                size={50}
                columnCount={4}
              />
            }
            {assignedDevices.map((detail, index) => (
              <TableRow
                key={detail.device_sn}
                style={{
                  color: 'inherit',
                  height: '48px',
                  display: 'table-row',
                  outline: 'none',
                  verticalAlign: 'middle',
                }}
              >
                <TableCell>
                  {detail.device_sn}
                </TableCell>
                <TableCell>
                  {detail.preset}
                </TableCell>
                <TableCell>
                  {detail.center_freq}
                </TableCell>
                <TableCell>
                  {detail.num_of_sessions}
                </TableCell>
                <TableCell>
                  <FormattedDate
                    value={moment.utc(detail.assign_date).local()}
                    day="numeric"
                    month="numeric"
                    year="numeric"
                  />
                </TableCell>
                <TableCell>
                  {
                    detail.unassign_date ?
                      <FormattedDate
                        value={moment.utc(detail.unassign_date).local()}
                        day="numeric"
                        month="numeric"
                        year="numeric"
                      />
                      : '-'
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {
          !patientDetailsAsync.isProcessing &&
          patientDetailsData.assigned_devices.length > 0 &&
          <div style={this.styles.buttonContainer}>
            <Button
              id="btn--export"
              type="button"
              className={classes.primaryButton}
              onClick={this.openExportData}
              style={this.styles.button}
            >
              <FormattedMessage
                id="patients.details.export"
                defaultMessage={en.patients.details.export}
              />
            </Button>
            <Button
              id="btn--requestReport"
              type="button"
              className={classes.primaryButton}
              onClick={this.openRequestForm}
              style={this.styles.requestReportButton}
            >
              <FormattedMessage
                id="patients.details.exportEvents"
                defaultMessage={en.patients.details.exportEvents}
              />
            </Button>
          </div>
        }
      </div>
    );
  }
}

PatientDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  setSort: PropTypes.func.isRequired,
  sortChange: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  setUpdateListFunction: PropTypes.func.isRequired,
  patientDetailsData: PropTypes.object.isRequired,
  patientDetailsAsync: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  patients: state.patients,
  patientDetailsData: getPatientDetailsData(state),
  patientDetailsAsync: getPatientDetailsAsync(state),
});

const enhance = compose(
  withStyles(newGlobalStyles),
  injectIntl,
  connect(mapStateToProps),
  listWrapper({
    displayName: 'PatientDetails',
  }),
);

export default enhance(PatientDetails);
