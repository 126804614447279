import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import { FormattedMessage } from 'react-intl';
import * as en from 'intl/en';

import getGlobalStyles from 'components/styles/global.styles';
import { mediaStyle } from 'components/utils/styles';
import PageWrapper from 'components/common/PageWrapper';
import QuarantineIcon from 'components/styles/icons/QuarantineIcon';
import QuarantineListContainer from 'components/quarantine/ui/QuarantineList/QuarantineListContainer';
import { quarantinePermission } from 'components/quarantine/utils/permissions';
import { isAllowedRole } from 'components/users/utils/userRoles';
import Session from 'components/utils/session';

class QuarantinePage extends Component {
  static propTypes = {
    width: PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.isAllowed = isAllowedRole(Session.JWTDecoded.role_id, quarantinePermission.allowedRoles);
    this.globalStyles = getGlobalStyles();
  }

  render() {
    const { width } = this.props;
    const { globalStyles, isAllowed } = this;

    return (
      isAllowed &&
        <PageWrapper>
        <div style={globalStyles.pageHeader}>
          <QuarantineIcon
            grayIcon
            style={globalStyles.pageIcon}
          />
          <h1 style={{ ...globalStyles.pageTitle, ...mediaStyle({}, width) }}>
            <FormattedMessage
              id="QuarantinePage.title"
              defaultMessage={en.QuarantinePage.title}
            />
          </h1>
        </div>
        <div>
          <QuarantineListContainer />
        </div>
        </PageWrapper>
    );
  }
}

export default withWidth()(QuarantinePage);
