const getStyles = theme => ({
  iconColor: {
    color: theme.palette.backgroundColor,
  },
  dialog: {
    backgroundColor: 'white',
    position: 'absolute',
    right: 50,
    top: 5,
    padding: 20,
    boxShadow: `0 0 10px 0`,
    zIndex: 999,
  },
  title: {
    paddingBottom: 5,
    borderBottom: `1px solid ${theme.palette.borderColor1}`,
    textTransform: 'uppercase',
    color: theme.palette.controlColumnTitleColor,
  },
  content: {
    height: '420px',
    overflow: 'auto',
  },
});

export default getStyles;
