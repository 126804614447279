export default theme => ({
  container: {
    margin: '30px 20px',
  },
  link: {
    '&:after': {
      content: '"|"',
      display: 'inline-block',
      width: 30,
      textAlign: 'center',
      color: theme.palette.textColor,
    },
    '&:last-child': {
      '&:after': {
        content: '""',
      },
    },
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: theme.font.bold,
    color: theme.palette.backgroundColor,
    '&:hover': {
      color: theme.palette.textColor,
    },
  },
  linkActive: {
    color: theme.palette.textColor,
  },
});
