export const handedness = [
  {
    value: 'left',
    i18n: {
      message: 'patients.form.left',
    },
  },
  {
    value: 'right',
    i18n: {
      message: 'patients.form.right',
    },
  },
];

export const bandSizes = [
  {
    value: 'small',
    i18n: {
      message: 'patients.form.small',
    },
  },
  {
    value: 'medium',
    i18n: {
      message: 'patients.form.medium',
    },
  },
  {
    value: 'large',
    i18n: {
      message: 'patients.form.large',
    },
  },
];

export const tremorPostures = [
  { value: 'tremor_task', text: 'Tremor Task' },
  { value: 'lateral_wing_beating', text: 'Lateral Wing-Beating' },
];

export const countries = [
  {
    value: 'US',
    text: 'United States',
  },
];
