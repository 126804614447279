const widthString = width => {
  switch (width) {
    case 1:
      return 'SMALL';
    case 2:
      return 'MEDIUM';
    case 3:
      return 'LARGE';
    default:
      return 'NA';
  }
};

export const mediaStyle = (style, width) => ({
  ...style,
  ...style[widthString(width)],
});
