import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './Compliance.styles';
import CompliancePercentage from './CompliancePercentage';

const Compliance = props => {
  const {
    openComplianceDetails,
    patientId,
    subjectId,
    complianceData,
    ...rest
  } = props;

  if (!complianceData) {
    return '-';
  }

  return (
    <div>
      <div
        className={props.classes.complianceContainer}
        onClick={() => openComplianceDetails(patientId, subjectId)}
        onKeyPress={() => openComplianceDetails(patientId, subjectId)}
        role="button"
        tabIndex="0"
      >
        <CompliancePercentage
          achieved={complianceData.weekly_achieved}
          percentage={complianceData.weekly_percentage}
          message="patients.compliance.weekly"
          {...rest}
        />
      </div>
      <CompliancePercentage
        achieved={complianceData.total_achieved}
        percentage={complianceData.total_percentage}
        message="patients.compliance.total"
        {...rest}
      />
    </div>
  );
};

Compliance.propTypes = {
  data: PropTypes.object,
  openComplianceDetails: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  complianceData: PropTypes.object,
  classes: PropTypes.object,
  subjectId: PropTypes.string,
};

export default withStyles(styles)(Compliance);
