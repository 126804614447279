import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import Message from 'components/common/Message';
import TremorIcon from 'components/styles/icons/compliance/TremorIcon';
import SessionIcon from 'components/styles/icons/compliance/SessionIcon';
import StarIcon from 'components/styles/icons/compliance/StarIcon';

const SessionDetails = props => {
  const { session } = props;

  return (
    <td align="middle">
      <Tooltip title={<Message id="patients.compliance.preMeasurement" />}>
        <TremorIcon filled={session.pre_measurement_exists} />
      </Tooltip>
      <Tooltip title={<Message id="patients.compliance.validSession" />}>
        <SessionIcon filled={session.valid_session} />
      </Tooltip>
      <Tooltip title={<Message id="patients.compliance.postMeasurement" />}>
        <TremorIcon filled={session.post_measurement_exists} />
      </Tooltip>
      <Tooltip title={<Message id="patients.compliance.ratingExists" />}>
        <StarIcon filled={session.rating_exists} />
      </Tooltip>
    </td>
  );
};

SessionDetails.propTypes = {
  session: PropTypes.object.isRequired,
};

export default SessionDetails;
