import { combineReducers } from 'redux';

import lotDetailsReducer from './lotDetailsReducer';
import lotsReducer from './lotsReducer';
import unassignedDevicesReducer from './unassignedDevicesReducer';
import createLotReducer from './createLotReducer';
import checkedDevicesReducer from './checkedDevicesReducer';
import firmwareReducer from './firmwareReducer';
import uiReducer from './ui';
import devicesReducer from './devicesReducer';

const operationsReducer = combineReducers({
  lotDetails: lotDetailsReducer,
  lots: lotsReducer,
  unassignedDevices: unassignedDevicesReducer,
  createLot: createLotReducer,
  checkedDevices: checkedDevicesReducer,
  firmware: firmwareReducer,
  ui: uiReducer,
  devices: devicesReducer,
});

export default operationsReducer;
