import PropTypes from 'prop-types';

export const versionShape = PropTypes.shape({
  auth: PropTypes.object,
  clinic: PropTypes.object,
  frontend: PropTypes.object,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  loadError: PropTypes.any,
});
