import TokenCall from 'components/auth/services/token';
import Config from 'config';
import { getResponseData } from 'components/utils/asyncHelpers';

const {
  DEVICE_API_ROOT,
  AUTH_API_ROOT,
} = Config;

const createPatientIdsBody = list => (
  {
    patient_ids: list.map(patient => patient.patient_id),
  }
);

export const loadPatientsList = async({ selectedPage, perPage, sortField, sortType, criteria }) => {
  const headers = {
    'X-Page': selectedPage,
    'X-Per-Page': perPage,
    'X-SortField': sortField,
    'X-SortType': sortType === 1 ? 'asc' : 'desc',
  };

  if (criteria && criteria.terms && criteria.terms !== '') {
    headers['X-SearchTerms'] = criteria.terms;
  }

  const response = await TokenCall.get(`${DEVICE_API_ROOT}/v1/devicesassigned`, { headers });

  return response.data;
};

export const loadPatientDetails = ({ patientId }) =>
  getResponseData('get', `${DEVICE_API_ROOT}/v1/devicesassigned/${patientId}`);

export const exportData = (patientId, email) =>
  // eslint-disable-next-line max-len
  getResponseData('get', `${DEVICE_API_ROOT}/v0/exportpatient?patient_id=${encodeURIComponent(patientId)}&user_mail=${encodeURIComponent(email)}`);

export const loadPatientCompliances = ({ patientIds }) => {
  const body = createPatientIdsBody(patientIds);

  return getResponseData('post', `${DEVICE_API_ROOT}/v0/compliance/summary`, body);
};

export const loadComplianceDetails = ({ patientId }) =>
  getResponseData('get', `${DEVICE_API_ROOT}/v0/compliance/patient/${patientId}`);

export const createPatient = ({ data }) =>
  getResponseData('post', `${AUTH_API_ROOT}/patient/signup`, data);

export const requestReport = ({ data, patient_id }) => {
  const { startDate, endDate } = data;

  getResponseData('post', `${DEVICE_API_ROOT}/v0/report/uniquelogs`, {
    patient_id,
    date_from: startDate,
    date_to: endDate,
  });
};

