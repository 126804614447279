import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Message from 'components/common/Message';

const ConfirmationDialog = ({
  isOpen,
  confirmAction,
  closeDialog,
}) => (
  <Dialog
    open={isOpen}
    onClose={closeDialog}
  >
    <DialogTitle id="operations.confirmationDialog.title">
      <Message id="operations.confirmationDialog.title" />
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <Message id="operations.confirmationDialog.content" />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={confirmAction} color="primary">
          <Message id="operations.confirmationDialog.confirm" />
      </Button>
      <Button onClick={closeDialog} color="secondary">
          <Message id="operations.confirmationDialog.no" />
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
