export default {
  patientId: 'Patient ID',
  subjectId: 'Subject ID',
  clinic: 'Clinic',
  handed: 'Handed',
  bandSize: 'Band Size',
  lastSessionOn: 'Last Session On',
  numberOfSessions: '# of Sessions',
  details: 'Details',
  deviceSn: 'Device SN',
  compliance: 'Compliance',
  serialNumber: 'Serial Number',
  mac: 'MAC',
};
