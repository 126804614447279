import { combineReducers } from 'redux';

import dataReducer from './dataReducer';
import paginationReducer from './paginationReducer';
import userDetailsReducer from './userDetailsReducer';
import asyncReducer from './asyncReducer';

const usersReducer = combineReducers({
  data: dataReducer,
  pagination: paginationReducer,
  userDetails: userDetailsReducer,
  async: asyncReducer,
});

export default usersReducer;
