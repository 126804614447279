import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { MenuItem, Select, withStyles } from '@material-ui/core';
import * as en from 'intl/en';
import _ from 'lodash';
import getGlobalStyles from 'components/styles/global.styles';
import getStyles from './PerPage.styles';

/**
 * @description One part of the Pagination component which consists of two parts. The PerPage component enables the user to select a different amount of data per page to be selected
 * @class
 * @return { Component } React Component
 */
class PerPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();
  }

  /**
   * @param {Object} ev Event
   * @param {number} index Index of select option
   * @param {number} perPage Value of selected option
   * @return {void}
   */
  _handlePerPageChange = (ev, index) => {
    this.props.perPageChange(ev.target.value);
  }

  _numberOfRecordsTxt = () => {
    const {
      selectedPage,
      perPage,
      numberOfRecords,
    } = this.props;
    const start = ((selectedPage || 1) * perPage) - (perPage - 1);

    let end = (selectedPage || 1) * perPage;

    end = end > numberOfRecords ? numberOfRecords : end;

    return this.props.intl.formatMessage({
      id: 'common.pagination.numberOfRecords',
      defaultMessage: _.get(en, 'common.pagination.numberOfRecords'),
    }, {
      start,
      end,
      numberOfRecords,
    });
  }

  render() {
    const {
      perPage,
      perPageOptions,
      intl,
      hide,
      style,
      classes,
    } = this.props;
    const txt = this._numberOfRecordsTxt();

    return (
      <div
        id="pagination_wrapper"
        style={{
          ...this.globalStyles.pageTagline,
          display: hide ? 'none' : 'inline-flex',
          ...this.styles.wrapper,
          ...style,
        }}
      >
        <div
          id="pagination_left"
          style={this.styles.leftContainer}
        >
          {txt}
        </div>
        <div
          id="pagination_right"
          style={this.styles.rightContainer}
        >
          <div style={this.styles.selectWrapper}>
            <Select
              id={perPage.toString()}
              value={perPage}
              onChange={this._handlePerPageChange}
              className="select__per-page"
              classes={{
                icon: classes.icon,
                selectMenu: classes.selectMenu,
              }}
              renderValue={value => value}
              style={this.styles.select}
              disableUnderline
            >
              <MenuItem
                classes={{ root: classes.selectMenuItem }}
                value={null}
                disabled
              >
                Per page
              </MenuItem>
              {
                perPageOptions.map((value, index) => {
                  return (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selected,
                      }}
                      key={value}
                      value={value}
                      children={value}
                    />
                  );
                })
              }
            </Select>
          </div>
          <span style={this.styles.perPage}>
          {
            intl.formatMessage({
              id: 'files.pagination.perPage',
              defaultMessage: _.get(en, 'files.pagination.perPage'),
            })
          }
          </span>
        </div>
      </div>
    );
  }
}

PerPage.propTypes = {
  intl: intlShape,
  perPageOptions: PropTypes.arrayOf(PropTypes.number),
  perPageChange: PropTypes.func.isRequired,
  perPage: PropTypes.number,
  selectedPage: PropTypes.number,
  hide: PropTypes.bool,
  numberOfRecords: PropTypes.number,
  style: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

PerPage.defaultProps = {
  hide: false,
  perPageOptions: [10, 25, 50],
  perPage: 25,
  selectedPage: 1,
};

const enhance = compose(injectIntl, withStyles(getStyles));

export default enhance(PerPage);
