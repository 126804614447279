import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextField as MuiTextField, FormHelperText } from '@material-ui/core';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import * as en from 'intl/en';

import { translateLabel } from '../helpers';

import styles from './TextField.styles';

const TextFieldInput = ({
  input,
  label,
  meta: { touched, error },
  classes,
  intl,
  style,
  grayBackground,
  ...custom
}) => (
  <span>
    <MuiTextField
      error={error && touched}
      label={label}
      margin="normal"
      fullWidth
      style={style}
      InputProps={{
        classes: {
          input: classNames(classes.input, {
            [`${classes.input}--gray`]: grayBackground,
          }),
        },
        disableUnderline: true,
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
      }}
      {...input}
      {...custom}
    />
    {
      touched && error &&
      <FormHelperText error>
        {intl.formatMessage({ id: error.message, defaultMessage: en[error.message]}, error.values)}
      </FormHelperText>
    }
  </span>
);

TextFieldInput.propTypes = {
  intl: intlShape.isRequired,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  classes: PropTypes.object,
  style: PropTypes.object,
  grayBackground: PropTypes.bool,
};

TextFieldInput.defaultProps = {
  grayBackground: false,
};

const TextFieldInputWithClasses = withStyles(styles)(TextFieldInput);

const TextField = ({ name, label, intl, validate, type, placeholder, ...otherProps }) => (
  <Field
    name={name}
    component={TextFieldInputWithClasses}
    label={translateLabel(label, intl)}
    placeholder={translateLabel(placeholder, intl)}
    validate={validate}
    intl={intl}
    type={type}
    parse={value => (type === 'number' ? Number(value) : value)}
    {...otherProps}
  />
);

TextField.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.any,
  type: PropTypes.string,
};

export default injectIntl(TextField);
