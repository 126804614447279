export default {
  name: 'Name',
  fullName: 'Full Name',
  email: 'Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  oldPassword: 'Enter old password',
  newPassword: 'Enter new password',
  newPasswordConfirm: 'Confirm new password',
  address: 'Address',
  role: 'Role',
  phoneNumber: 'Phone Number',
  register: 'Create User',
  signUp: 'Sign Up',
  login: 'Log In',
  resetPassword: 'Reset Password',
  clinic: 'Clinic',
  list: 'Users List',
  selectRole: 'Select a Role',
  selectClinic: 'Select a Clinic',
  searchBox: 'Start typing...',
  save: 'Save Changes',
  forgotPassword: 'Forgot password?',
  loginSuccessful: 'Login successful',
  loginError: 'Login was not successsful',
};
