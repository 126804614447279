import TokenCall from 'components/auth/services/token';
import Config from 'config';

const { DEVICE_API_ROOT } = Config;
const _perPage = 300;

export const loadImuData = async function(deviceLogId, pageNumber = 1, fromDate, toDate, perPage = _perPage) {
  try {
    const response = await TokenCall.get(`${DEVICE_API_ROOT}/v0/dataimu/${deviceLogId}`, {
      headers: {
        'X-Page': pageNumber,
        'X-Per-Page': _perPage,
        'X-Fields': 'data  {timestamp_utc, milliseconds} pages',
        'X-From': fromDate,
        'X-To': toDate,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loadErrorsData = async function(deviceLogId, pageNumber = 1, fromDate, toDate, perPage = _perPage) {
  try {
    const response = await TokenCall.get(`${DEVICE_API_ROOT}/v0/datadeviceerrors/${deviceLogId}`, {
      headers: {
        'X-Page': pageNumber,
        'X-Per-Page': perPage,
        'X-From': fromDate,
        'X-To': toDate,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loadPatientData = async function(deviceLogId, pageNumber = 1, fromDate, toDate, perPage = _perPage) {
  try {
    const response = await TokenCall.get(`${DEVICE_API_ROOT}/v0/datadevicepatient/${deviceLogId}`, {
      headers: {
        'X-Page': pageNumber,
        'X-Per-Page': perPage,
        'X-From': fromDate,
        'X-To': toDate,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loadAvailableDates = async deviceLogId => {
  try {
    const response = await TokenCall.get(`${DEVICE_API_ROOT}/v0/devicelog/${deviceLogId}`, {
      headers: {
        'X-Fields': 'available_dates',
      },
    });

    return response.data.available_dates;
  } catch (error) {
    throw error;
  }
};
