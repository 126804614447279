import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer, getResult } from 'components/utils/reduxHelpers';
import { LOAD_PATIENT_DETAILS } from '../actions/patientsActions';

export const DEFAULT_STATE = { assigned_devices: [] };

const data = createReducer(DEFAULT_STATE, {
  [`${LOAD_PATIENT_DETAILS}`]: () => (DEFAULT_STATE),
  [`${LOAD_PATIENT_DETAILS}_SUCCESS`]: getResult,
});

const patientDetailsReducer = combineReducers({
  async: asyncReducerHelper(LOAD_PATIENT_DETAILS),
  data,
});

export default patientDetailsReducer;
