import theme from 'components/styles/default.theme';

const getStyles = () => ({
  row: {
    borderBottom: 'none',
  },
  column: {
    textAlign: 'center',
    height: 150,
    color: theme.palette.backgroundColor,
  },
  loader: {
    color: theme.palette.backgroundColor,
  },
});

export default getStyles;
