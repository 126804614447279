/* eslint max-len: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/core';

const PrinterIcon = props => {
  return (
    <SvgIcon
      style={{ fill: props.theme.palette.backgroundColor }}
      {...props}
      viewBox="0 0 24 24"
    >
      <path
        d="M6.7,2V8.783H3.565A1.567,1.567,0,0,0,2,10.348v9.391A1.567,1.567,0,0,0,3.565,21.3H6.7V26H21.3V21.3h3.13A1.567,1.567,0,0,0,26,19.739V10.348a1.567,1.567,0,0,0-1.565-1.565H21.3V2ZM7.739,3.043H20.261V8.783H7.739Zm14.609,8.348A1.043,1.043,0,1,1,21.3,12.435,1.043,1.043,0,0,1,22.348,11.391ZM7.739,16.609H20.261v8.348H7.739V16.609Zm2.087,1.565a.522.522,0,1,0,0,1.044h8.348a.522.522,0,1,0,0-1.044Zm0,2.087a.522.522,0,1,0,0,1.044h6.261a.522.522,0,1,0,0-1.044Zm0,2.087a.522.522,0,1,0,0,1.044h8.348a.522.522,0,1,0,0-1.044Z"
        transform="translate(-2 -2)"
      />
    </SvgIcon>
  );
};

PrinterIcon.propTypes = {
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      backgroundColor: PropTypes.string,
    }),
  }).isRequired,
};

export default withTheme(PrinterIcon);
