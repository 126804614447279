export default theme => ({
  input: {
    border: `1px solid ${theme.palette.borderColor2}`,
    paddingLeft: theme.padding.textInput,
    paddingRight: theme.padding.textInput,
    marginTop: '5px',
    height: '32px',
    color: theme.palette.defaultTextColor,
    '&--gray': {
      backgroundColor: theme.palette.grayBackgroundTextField,
    },
  },
  label: {
    fontWeight: theme.font.bold,
    textTransform: 'uppercase',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
});
