export default {
  weeklyCompliance: 'Weekly compliance',
  weekly: 'Weekly',
  total: 'Total',
  firstSession: '1st Session',
  secondSession: '2nd Session',
  preMeasurement: 'Pre Measurement',
  postMeasurement: 'Post Measurement',
  validSession: 'Valid Session',
  ratingExists: 'Rating Exists',
  extraSessions: 'Extra Sessions',
};
