import theme from 'components/styles/default.theme';

const getStyles = () => ({
  button: {
    display: 'flex',
    color: theme.palette.textColor,
    flex: '0 0 auto',
    padding: '5px 0px 5px 15px',
    alignItems: 'center',
    height: 40,
    borderRadius: 40,
    marginRight: 5,
    marginBottom: 5,
    backgroundColor: '#E2F3F8',
  },
  keyword: {
    marginRight: 10,
  },
  icon: {
    backgroundColor: theme.palette.red,
    color: theme.palette.blankColor,
    fontSize: 14,
    borderRadius: '50%',
    width: 20,
    height: 20,
    padding: 3,
  },
});

export default getStyles;
