import React from 'react';
import PropTypes from 'prop-types';
import { Column } from 'react-virtualized';
import { TableCell } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import AccessControl from 'components/common/base/AccessControl';
import { showActionColumn } from '../../utils/helpers';
import permissions from '../../utils/permissions';
import { downloadlogKey } from '../../utils/constants';

const DownloadLogColumn = ({ styles, downloadFile, actionColumns }) => {
  const isEnabled = showActionColumn(actionColumns, downloadlogKey);

  return (
    isEnabled ? (
      <Column
        headerRenderer={headerProps => (
          <TableCell
            component="div"
            variant="head"
            style={{ border: 'none' }}
          >
            <div style={{ width: 58, height: 50 }} />
          </TableCell>
        )}
        dataKey={downloadlogKey}
        width={58}
        cellRenderer={cellProps => {
        return (
          <TableCell
            component="div"
            variant="body"
            style={{ border: 'none' }}
          >
            <AccessControl roles={permissions.downloadLog}>
              <IconButton
                id={`button__download__${cellProps.rowIndex}`}
                className="material-icons"
                data-logid={cellProps.rowData.device_log_id}
                data-filename={cellProps.rowData.file_link}
                onClick={downloadFile}
                disableRipple
              >
                file_download
              </IconButton>
            </AccessControl>
          </TableCell>
        );
      }}
        headerStyle={{ width: 58, margin: 0 }}
        style={{ margin: 0 }}
      />
    ) : null
  );
};

DownloadLogColumn.propTypes = {
  styles: PropTypes.object.isRequired,
  downloadFile: PropTypes.func.isRequired,
  actionColumns: PropTypes.array.isRequired,
};

export default DownloadLogColumn;
