import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer, getResult } from 'components/utils/reduxHelpers';
import {
  LOAD_LOT_DETAILS,
  CLOSE_LOT,
  OPEN_LOT,
} from '../actions/operationsActions';

const data = createReducer({}, {
  [`${LOAD_LOT_DETAILS}`]: () => ({}),
  [`${LOAD_LOT_DETAILS}_SUCCESS`]: getResult,
});

const asyncReducer = combineReducers({
  load: asyncReducerHelper(LOAD_LOT_DETAILS),
  closeLot: asyncReducerHelper(CLOSE_LOT),
  openLot: asyncReducerHelper(OPEN_LOT),
});

const asyncOverride = (state = {}, action) => {
  if (action.type === LOAD_LOT_DETAILS) {
    return {
      load: { isProcessing: true },
      closeLot: {},
      openLot: {},
    };
  }

  return asyncReducer(state, action);
};

const lotDetailsReducer = combineReducers({
  async: asyncOverride,
  data,
});

export default lotDetailsReducer;
