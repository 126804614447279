import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { DateRangePicker } from 'react-date-range';
import { Button, Dialog, DialogTitle, withStyles } from '@material-ui/core';
import moment from 'moment';

import { intlWord } from 'components/utils/helpers';
import { REQUEST_REPORT_FORM } from 'components/common/forms/names';
import Form from 'components/common/forms/Form';
import getGlobalStyles from 'components/styles/global.styles';
import getStyles from './RequestReportForm.styles';
import ExportDialog from '../../exportDialog/ExportDialog';
import { message } from '../../../utils/constants';

import { requestReport } from '../../../actions/patientsActions';

const successMessage = { message: 'patients.form.successMessage' };
const errorMessage = { message: 'patients.form.errorMessage' };

class RequestReportForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeRequestForm: PropTypes.func.isRequired,
    _requestReport: PropTypes.func.isRequired,
    patient_id: PropTypes.string,
  }

  constructor(props, context) {
    super(props);

    this.state = {
      showMessage: false,
      dateRange: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      },
    };

    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;

    if (isOpen !== prevProps.isOpen) {
      this.setState({
        dateRange: {
          selection: {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
          },
        },
      });
    }
  }

  setDateRange = ranges => {
    this.setState({
      dateRange: ranges,
    });
  }

  setDate = () => {
    const { dateRange } = this.state;

    const startDate = moment(dateRange.selection.startDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
    const endDate = moment(dateRange.selection.endDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');

    this.submit({ startDate, endDate });
  }

  closeMessage = () => {
    this.setState({
      showMessage: false,
    });
  }

  submit = values => new Promise((resolve, reject) => {
    const { _requestReport, patient_id, closeRequestForm } = this.props;
    const showDialogAfterSuccess = result => {
      this.setState({ showMessage: true });
      resolve(result);
    };

    _requestReport(
      values,
      patient_id,
      { resolve: showDialogAfterSuccess, reject },
    );

    closeRequestForm();
  });

  renderDatePicker() {
    const { isOpen, closeRequestForm, intl, classes } = this.props;

    return (
      <Dialog
        id="modal--date-picker"
        classes={{ paper: classes.pickerModal }}
        open={isOpen}
        onClose={closeRequestForm}
      >
        <DialogTitle
          children={intlWord(intl, 'patients.details.exportEvents')}
        />
        <DateRangePicker
          ranges={[this.state.dateRange.selection]}
          onChange={this.setDateRange}
        />
        <Button
          id="btn--export"
          type="button"
          children="Export"
          style={{ ...this.styles.exportButton, ...this.globalStyles.primaryButton.label }}
          onClick={this.setDate}
        />
      </Dialog>
    );
  }

  render() {
    const { showMessage } = this.state;
    const { intl } = this.props;

    return (
      <Form
        id={REQUEST_REPORT_FORM}
        successMessage={successMessage}
        errorMessage={errorMessage}
        onSubmit={this.submit}
        hideSubmitBtn
        {...this.props}
      >
        {this.renderDatePicker()}
        <ExportDialog
          handleClose={this.closeMessage}
          isOpen={showMessage}
          intl={intl}
          exportMessage={message}
        />
      </Form>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  _requestReport: (data, patient_id, promise) => {
    dispatch(requestReport(data, patient_id, promise));
  },
});

const enhance = compose(
  withStyles(getStyles),
  injectIntl,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: REQUEST_REPORT_FORM,
    enableReinitialize: true,
  }),
);

export default enhance(RequestReportForm);
