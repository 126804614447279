import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer, getResult } from 'components/utils/reduxHelpers';
import {
  FETCH_RATES,
  REGENERATE_RATES,
  CREATE_SHIPMENT_ORDER,
} from '../actions/shipmentOrderActions';
import { LOAD_ORDER_INFORMATION } from '../actions/shipmentsActions';

const data = createReducer([], {
  [`${LOAD_ORDER_INFORMATION}`]: () => [],
  [`${CREATE_SHIPMENT_ORDER}`]: () => [],
  [`${FETCH_RATES}`]: () => [],
  [`${FETCH_RATES}_SUCCESS`]: getResult,
});

const asyncReducer = combineReducers({
  rates: asyncReducerHelper(FETCH_RATES),
  regenerate: asyncReducerHelper(REGENERATE_RATES),
});

const asyncOverride = (state = {}, action) => {
  if (action.type === LOAD_ORDER_INFORMATION) {
    return {
      rates: {},
      regenerate: {},
    };
  }

  return asyncReducer(state, action);
};

const ratesReducer = combineReducers({
  async: asyncOverride,
  data,
});

export default ratesReducer;
