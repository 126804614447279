import React from 'react';
import PropTypes from 'prop-types';
import newGlobalStyles from 'components/styles/global.styles';
import * as en from 'intl/en';
import { withTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import getStyles from './Header.styles';

const Header = props => {
  const { theme, title, children, text } = props;
  const styles = getStyles(theme);
  const globalStyles = newGlobalStyles(theme);

  return (
    <div
      style={{
        ...styles.header,
      }}
    >
      <h1
        style={{
          ...globalStyles.primaryInputLabel,
          display: 'inline-flex',
        }}
      >
        {
          text ||
          <FormattedMessage
            id={title}
            defaultMessage={en[title]}
          />
        }
      </h1>
      {children}
    </div>
  );
};

Header.propTypes = {
  theme: PropTypes.object.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.any,
};

export default withTheme(Header);
