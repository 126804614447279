export default {
  deviceType: 'Device type',
  firmware: 'Firmware',
  filename: 'Filename',
  deviceTypesB: 'Basestation',
  deviceTypesS: 'Stim',
  addDeviceDialogTrigger: 'Add',
  addDeviceTitle: 'Assign device to firmware',
  addDeviceCancel: 'Close',
  addDeviceMessage: `
    Assigning device to specific firmware will trigger the device to be
    updated with custom firmware version.
  `,
  removeDeviceTitle: 'Reset device to default firmware',
  removeDeviceMessage: `
    Setting device to be updated to default firmware will remove custom firmware
    from the device on the next update and update it with the current default firmware.
  `,
  removeDeviceCancel: 'Cancel',
  selectedDevices: 'Selected devices',
  uploadedBy: 'Uploaded by:',
  uploadDate: 'Upload date:',
  makeDefault: 'Apply for everybody',
  defaultFirmwareDialogTitle: 'Apply firmware for all devices',
  defaultFirmwareDialogMessage: `
    By clicking CONFIRM you are scheduling all devices to be updated with this
    firmware version
  `,
  defaultFirmwareDialogConfirm: 'Confirm',
  defaultFirmwareDialogCancel: 'Cancel',
  noDefaultFirmware: 'No default firmware defined for device type',
  downloadedFirmwareAndConfirmed: 'Downloaded firmware and confirmed:',
  downloadedFirmware: 'Downloaded but not confirmed:',
  noResponse: 'No response:',
  archiveA: 'Set Active',
  archiveB: 'Add to archive',
  firmwares: 'Firmwares',
  show: 'Show',
  active: 'Active',
  archived: 'Archived',
};
