import {
  graphColors,
} from './configurations';

const _imuConstant = 2000;

export const tagResetColors = [
  '#727476',
  '#64B822',
  '#E19B27',
  '#009C9F',
  '#E7681B',
  '#936AAC',
];
export const getTagResetColor = tagReset => {
  return tagResetColors[(tagReset - 1) % 6];
};
const tagResetMarker = tagReset => {
  return tagReset > 0
    ? {
      lineWidth: 3,
      lineColor: getTagResetColor(tagReset),
    }
    : {};
};

export const patientEvents = {
  STIM_INCR_AMPL: 'STIM_INCR_AMPL',
  STIM_DECR_AMPL: 'STIM_DECR_AMPL',
  TREMOR_RATING: 'TREMOR_RATING',
  MEASUREMENT: 'MEASUREMENT',
  CALIBRATION: 'CALIBRATION',
  STIM_SESSION_START: 'STIM_SESSION_START',
  STIM_SESSION_END: 'STIM_SESSION_END',
  CHANGE_USER_SETTINGS: 'CHANGE_USER_SETTINGS',
};

const getBtnClickIcon = (orientation, tagReset) => {
  let arrowOrientation = 'arrow_upward';
  let tagResetConfig = '';

  if (orientation === patientEvents.STIM_DECR_AMPL) {
    arrowOrientation = 'arrow_downward';
  }
  if (tagReset) {
    tagResetConfig = `border: solid 1px ${getTagResetColor(tagReset)}`;
  }

  return `<i class="material-icons" style="font-size: 12px; border-radius: 50px;${tagResetConfig}">
            ${arrowOrientation}
          </i>`;
};

export const batteryLevels = {
  CHARGE: 'BattLvlChg',
  WARNING: 'BattWarnLow',
  CRITICAL: 'BattCritLow',
};

export const frequencyFlag = (value, type, timestamp, timestampUtc, tagReset) => {
  let background = graphColors.calaGreen;

  if (type === patientEvents.MEASUREMENT) {
    background = graphColors.blue;
  }

  const flag = (
    `<div style="background-color: ${background}; color: white; padding: 3px;">
      ${(value / 1000).toFixed(1)} Hz
    </div>`
  );

  return (
    {
      x: timestamp,
      title: flag,
      color: tagReset ? getTagResetColor(tagReset) : background,
      fillColor: tagReset ? getTagResetColor(tagReset) : background,
      key: {
        date: timestampUtc,
        text: `Frequency ${value} mHz`,
      },
    }
  );
};

export const stimSessionModel = (timestamp, timestampUtc, log) => {
  let fillColor = graphColors.blue;
  let textColor = 'white';

  if (log.patient_event_desc === patientEvents.STIM_SESSION_END) {
    fillColor = 'white';
    textColor = graphColors.blue;
  }

  return {
    x: timestamp,
    y: 0,
    z: 50,
    dataLabels: {
      format: `${log.sessions_num}`,
      color: textColor,
    },
    marker: {
      fillColor,
      ...tagResetMarker(log.tag_reset),
    },
    key: {
      date: timestampUtc,
      text: `Stim session: ${log.sessions_num}`,
    },
  };
};

export const buttonClickModel = (timestamp, timestampUtc, log) => {
  const arrow = getBtnClickIcon(log.patient_event_desc, log.tag_reset);

  return {
    x: timestamp,
    title: arrow,
    key: {
      date: timestampUtc,
      text: '',
      value: log.patient_event_desc,
    },
  };
};

export const tremorRatingModel = (timestamp, timestampUtc, log) => {
  return {
    x: timestamp,
    y: 0,
    dataLabels: {
      format: `${log.tremor_rating}`,
    },
    marker: {
      ...tagResetMarker(log.tag_reset),
    },
    key: {
      date: timestampUtc,
      text: `Tremor rating ${log.tremor_rating}`,
    },
  };
};

export const userSettingsModel = (timestamp, timestampUtc, tagReset) => {
  return {
    x: timestamp,
    y: 0,
    dataLabels: {
      format: 'U',
    },
    marker: {
      ...tagResetMarker(tagReset),
    },
    key: {
      date: timestampUtc,
      text: '',
    },
  };
};

export const stimAmpModel = (timestamp, timestampUtc, log) => {
  const milliamps = log.stim_amp / 1000;

  return {
    x: timestamp,
    y: milliamps,
    marker: {
      ...tagResetMarker(log.tag_reset),
    },
    key: {
      date: timestampUtc,
      text: `${milliamps} mA`,
    },
  };
};

export const imuModel = (timestamp, timestampUtc, tagReset) => {
  return {
    x: timestamp,
    x2: timestamp + _imuConstant,
    y: 0,
    marker: {
      ...tagResetMarker(tagReset),
    },
    key: {
      text: '',
      date: timestampUtc,
    },
  };
};

export const errorModel = (timestamp, timestampUtc, errorKey, index, tagReset) => {
  return {
    x: timestamp,
    y: index + 1,
    marker: {
      ...tagResetMarker(tagReset),
    },
    key: {
      text: errorKey,
      date: timestampUtc,
    },
  };
};

export const batteryLevelModel = (timestamp, timestampUtc, type, level, tagReset) => {
  let symbol;

  const folder = 'icons/graph';

  type = type.trim();
  level = level.trim();

  if (tagReset) {
    symbol = `url(/${folder}/icon_battery_01.png)`;
  } else {
    symbol = `url(/${folder}/icon_battery_05.png)`;
  }

  return {
    x: timestamp,
    y: parseInt(level, 0),
    marker: {
      symbol,
      height: 8,
      width: 16,
    },
    key: {
      date: timestampUtc,
      text: `${type} = ${level}`,
    },
  };
};

export const impedanceModel = (timestamp, timestampUtc, value, isBC, tagReset) => {
  const folder = 'icons/graph';
  const kiloohm = value / 1000;

  let symbol = `url(/${folder}/ImpedanceAC.png)`;

  if (isBC) {
    symbol = `url(/${folder}/ImpedanceBC.png)`;
  }

  if (tagReset > 0) {
    symbol = `url(/${folder}/ImpedanceReset.png)`;
  }

  return {
    x: timestamp,
    y: kiloohm,
    marker: {
      symbol,
      height: 16,
      width: 16,
    },
    key: {
      date: timestampUtc,
      text: `${kiloohm} kiloohm ${isBC ? 'ImpedanceBC' : 'ImpedanceAC'}`,
    },
  };
};
