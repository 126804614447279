export const LOAD_USERS = 'LOAD_USERS';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SIGNUP = 'ADD_USER_SIGNUP';
export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USER_PAGES = 'CLEAR_USER_PAGES';
export const CLEAR_USER_ERRORS = 'CLEAR_USER_ERRORS';
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_MULTIPLE_USERS_DATA = 'FETCH_MULTIPLE_USERS_DATA';

export function loadUsers(selectedPage, perPage) {
  return {
    type: LOAD_USERS,
    selectedPage,
    perPage,
  };
}

export function addUser(user, promise) {
  return { type: ADD_USER, user, promise };
}

export function addUserSignUp(user, promise) {
  return { type: ADD_USER_SIGNUP, user, promise };
}

export function updateUser(user, promise) {
  return { type: UPDATE_USER, user, promise };
}

export function clearUserErrors() {
  return { type: CLEAR_USER_ERRORS };
}

export function clearUserPages() {
  return { type: CLEAR_USER_PAGES };
}

export function fetchUserData(userId) {
  return {
    type: FETCH_USER_DATA,
    userId,
  };
}

export function fetchMultipleUsersData(userIds) {
  return {
    type: FETCH_MULTIPLE_USERS_DATA,
    userIds,
  };
}
