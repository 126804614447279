import TokenCall from 'components/auth/services/token';
import Config from 'config';
import { getResponseData } from 'components/utils/asyncHelpers';

const { CLINIC_API_ROOT } = Config;

export const loadClinicLogo = async function(id) {
  try {
    const response = await TokenCall.get(`${CLINIC_API_ROOT}/${id}/logo`);

    return response.data.logo;
  } catch (error) {
    throw error;
  }
};

const uploadClinicLogo = async function(clinic, file) {
  if (!file || !file.preview) {
    return clinic;
  }

  const data = new FormData();

  data.append('logo_file', file);

  await TokenCall.put(`${CLINIC_API_ROOT}/${clinic.id}/logo`, data);
  return clinic;
};

export const loadClinicsWithoutLogo = () =>
  getResponseData('get', CLINIC_API_ROOT);

export const loadClinics = async function() {
  try {
    const response = await TokenCall.get(CLINIC_API_ROOT);
    const clinics = response.data;
    const promisePool = [];

    for (const i in clinics) {
      if (!clinics.hasOwnProperty(i)) continue;

      const clinic = clinics[i];

      const clinicPromise = new Promise(resolve => {
        TokenCall.get(`${CLINIC_API_ROOT}/${clinic.id}/logo`)
          .then(
            res => resolve(res.data.logo),
            () => resolve(null),
          );
      });

      promisePool.push(clinicPromise);
    }

    const logos = await Promise.all(promisePool);

    for (const i in clinics) {
      if (!clinics.hasOwnProperty(i)) continue;

      if (logos[i]) {
        clinics[i].logo = logos[i];
      }
    }

    return clinics;
  } catch (error) {
    throw error;
  }
};

export const addClinic = async function({ clinic }) {
  try {
    const response = await TokenCall.post(CLINIC_API_ROOT, clinic);
    const newClinic = response.data;

    return await uploadClinicLogo(newClinic, clinic.logo);
  } catch (error) {
    throw error;
  }
};

export const updateClinic = async function({id, clinic}) {
  try {
    await TokenCall.put(`${CLINIC_API_ROOT}/${id}`, clinic);

    return await uploadClinicLogo(clinic, clinic.logo);
  } catch (error) {
    throw error;
  }
};
