import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer, getResult } from 'components/utils/reduxHelpers';
import { LOAD_PATIENTS_LIST } from '../actions/patientsActions';

export const DEFAULT_STATE = {
  data: [],
  pages: [],
  total: 1,
};

const list = createReducer(DEFAULT_STATE, {
  [`${LOAD_PATIENTS_LIST}_SUCCESS`]: getResult,
});

const patientDetailsReducer = combineReducers({
  async: asyncReducerHelper(LOAD_PATIENTS_LIST),
  list,
});

export default patientDetailsReducer;
