/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LogoutIcon = props => (
    <SvgIcon
      {...props}
      viewBox="4815 1207.8 25 22.5"
    >
        <path
          data-name="Path 34"
          d="M4815 1219.05a11.238 11.238 0 0 0 20.586 6.25h-3.242a8.75 8.75 0 1 1 0-12.5h3.242a11.238 11.238 0 0 0-20.586 6.25zm8.75 1.25h10v3.75l6.25-5-6.25-5v3.75h-10z"
          fill="#fff"
        />
    </SvgIcon>
);

export default LogoutIcon;
