import _config from './config';

let config = Object.assign({}, _config, _config[process.env.NODE_ENV || 'development']);
const apiDomain = process.env.REACT_APP_API_DOMAIN || config.API_DOMAIN;
const deviceApiDomain = process.env.REACT_APP_DEVICE_API_DOMAIN || config.DEVICE_API_DOMAIN;
const inventoryApiDomain = process.env.REACT_APP_INVENTORY_API_DOMAIN || config.INVENTORY_API_DOMAIN;

const deviceAPIHost = getAPIHost(deviceApiDomain, config.DEVICE_API_PORT);
const inventoryAPIHost = getAPIHost(inventoryApiDomain, config.INVENTORY_API_PORT);
const lambdaAPIHost = getAPIHost(apiDomain, config.LAMBDA_API_PORT);

// TODO: remove unused services
const clinicAPIHost = getAPIHost(deviceApiDomain, config.CLINIC_API_PORT);
const authAPIHost = getAPIHost(deviceApiDomain, config.AUTH_API_PORT);
const clinicalStudyAPIHost = getAPIHost(deviceApiDomain, config.CLINICAL_STUDY_API_PORT);
const notesAPIHost = getAPIHost(deviceApiDomain, config.NOTES_API_PORT);
const simAPIHost = getAPIHost(deviceApiDomain, config.SIM_API_PORT);
const shippingAPIHost = getAPIHost(deviceApiDomain, config.SHIPPING_API_PORT);

config.DEVICE_API_ROOT = `${deviceAPIHost}`;
config.INVENTORY_API_ROOT = `${inventoryAPIHost}`;
config.LAMBDA_API_ROOT = `${lambdaAPIHost}/${config.LAMBDA_API_URI}`;
config.CLINIC_API_ROOT = `${clinicAPIHost}/${config.CLINIC_API_URI}`;
config.AUTH_API_ROOT = `${authAPIHost}/${config.AUTH_API_URI}`;
config.CLINICAL_STUDY_API_ROOT = `${clinicalStudyAPIHost}/${config.CLINICAL_STUDY_API_URI}`;
config.NOTES_API_ROOT = `${notesAPIHost}/${config.NOTES_API_URI}`;
config.SIM_API_ROOT = `${simAPIHost}/${config.SIM_API_URI}`;
config.SHIPPING_API_ROOT = `${shippingAPIHost}/${config.SHIPPING_API_URI}`;

const ws_device_scheme = apiDomain.replace(/^(https{0,1}):\/\/.*/, '$1').replace(/http/, 'ws');
const ws_device_host = apiDomain.replace(/^https{0,1}:\/\//, '');
const ws_device_nonstandard_port = config.DEVICE_API_PORT &&
                                 config.DEVICE_API_PORT !== 80 &&
                                 config.DEVICE_API_PORT !== 443;
config.WS_DEVICE_URL = ws_device_nonstandard_port
                     ?  `${ws_device_scheme}://${ws_device_host}:${config.DEVICE_API_PORT}`
                     : `${ws_device_scheme}://${ws_device_host}`;

function getAPIHost(domain, port) {
  let portSuffix = '';
  if (port) {
    portSuffix = `:${port}`;
  }

  return `${domain}${portSuffix}`;
}

export default config;
