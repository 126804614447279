import { combineReducers } from 'redux';
import _ from 'lodash';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_ORDERS,
  ORDER_ACTION,
  REJECT_ORDER,
  APPROVE_ORDER,
} from '../actions/shipmentsActions';

const getById = (state, action) => {
  return _.keyBy(action.result, o => o.id);
};

const removeIdFromMap = (state, action) => {
  return _.omit(state, action.payload.orderId);
};

const byId = createReducer({}, {
  [`${LOAD_ORDERS}_SUCCESS`]: getById,
  [`${ORDER_ACTION}_SUCCESS`]: removeIdFromMap,
  [`${REJECT_ORDER}_SUCCESS`]: removeIdFromMap,
  [`${APPROVE_ORDER}_SUCCESS`]: removeIdFromMap,
});

const getAllIds = (state, action) => {
  return action.result.map(order => order.id);
};

const removeIdFromList = (state, action) => {
  return _.without(state, action.payload.orderId);
};

const allIds = createReducer([], {
  [`${LOAD_ORDERS}_SUCCESS`]: getAllIds,
  [`${ORDER_ACTION}_SUCCESS`]: removeIdFromList,
  [`${REJECT_ORDER}_SUCCESS`]: removeIdFromList,
  [`${APPROVE_ORDER}_SUCCESS`]: removeIdFromList,
});

const ordersReducer = combineReducers({
  byId,
  allIds,
  async: asyncReducerHelper(LOAD_ORDERS),
});

export default ordersReducer;
