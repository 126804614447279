import theme from 'components/styles/default.theme';

const getStyles = () => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: 200,
    borderRadius: 5,
    borderColor: theme.palette.backgroundColor,
    borderStyle: 'dashed',
    borderWidth: 1,
  },
  zone: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 20,
    alignItems: 'center',
    cursor: 'pointer',
  },
  title: {
    padding: '1em',
    textAlign: 'center',
    width: '100%',
    letterSpacing: theme.spacing(0),
    icon: {
      color: theme.palette.defaultTextColor,
      fontSize: '3em',
      marginBottom: theme.spacing(1) * 3,
    },
    dnd: {
      color: theme.palette.defaultTextColor,
      textTransform: 'uppercase',
    },
    browse: {
      marginTop: theme.spacing(8),
      color: theme.palette.defaultTextColor,
      fontWeight: theme.font.thin,
    },
  },
  preview: {
    position: 'absolute',
    padding: 10,
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
});

export default getStyles;
