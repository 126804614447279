import _ from 'lodash';

export const orderStatuses = {
  requested: 'requested',
  rejected: 'rejected',
  pendingApproval: 'pending approval',
  approved: 'approved',
  shipped: 'shipped',
  received: 'received',
  approveAndReceive: 'approve_and_receive',
};

// maxWeight: lbs
export const parcelValues = {
  custom: {
    value: 'custom',
    i18n: { message: 'shipments.parcel.custom' },
    maxWeight: 100,
  },
  FedExEnvelope: {
    value: 'FedExEnvelope',
    i18n: { message: 'shipments.parcel.FedExEnvelope' },
    maxWeight: 0.5,
  },
  FedExSmallBox: {
    value: 'FedExSmallBox',
    i18n: { message: 'shipments.parcel.FedExSmallBox' },
    maxWeight: 20,
  },
  FedExMediumBox: {
    value: 'FedExMediumBox',
    i18n: { message: 'shipments.parcel.FedExMediumBox' },
    maxWeight: 20,
  },
  FedExLargeBox: {
    value: 'FedExLargeBox',
    i18n: { message: 'shipments.parcel.FedExLargeBox' },
    maxWeight: 20,
  },
  FedExExtraLargeBox: {
    value: 'FedExExtraLargeBox',
    i18n: { message: 'shipments.parcel.FedExExtraLargeBox' },
    maxWeight: 20,
  },
  FedExPak: {
    value: 'FedExPak',
    i18n: { message: 'shipments.parcel.FedExPak' },
    maxWeight: 5.5,
  },
  FedExTube: {
    value: 'FedExTube',
    i18n: { message: 'shipments.parcel.FedExTube' },
    maxWeight: 20,
  },
  FedEx10kgBox: {
    value: 'FedEx10kgBox',
    i18n: { message: 'shipments.parcel.FedEx10kgBox' },
    maxWeight: 22,
  },
  FedEx25kgBox: {
    value: 'FedEx25kgBox',
    i18n: { message: 'shipments.parcel.FedEx25kgBox' },
    maxWeight: 55,
  },
};

export const parcelSizes = _.map(parcelValues, (value, key) => ({ value: key, i18n: value.i18n }));
