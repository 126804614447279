import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import LoginPage from 'containers/auth/login/LoginPage';
import OAuthLandingPage from 'containers/auth/OAuthLandingPage';
import CoreRoute from 'containers/core/CoreRoute';
import VersionPage from 'components/version/VersionPage';

const RoutesUser = (
  <Switch>
    <Route
      exact
      path="/"
      component={CoreRoute}
    />
    <Route
      path="/status"
      component={VersionPage}
    />
    <Route
      path="/:tab"
      component={CoreRoute}
    />
    <Redirect
      from="*"
      to="/"
    />
  </Switch>
);

const RoutesGuest = (
    <Switch>
        <Route
          exact
          path="/"
          component={LoginPage}
        />
        <Route
          path="/oauth2/callback"
          component={OAuthLandingPage}
        />
        <Route
          path="/status"
          component={VersionPage}
        />
        <Redirect
          from="*"
          to="/"
        />
    </Switch>
);

const AppRoutes = props => {
  const { isAuthenticated } = props;

  if (!isAuthenticated) {
    return RoutesGuest;
  }

  return RoutesUser;
};

const Routes = props => (
    <BrowserRouter>
        <AppRoutes {...props} />
    </BrowserRouter>
);

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  locale: state.intl.locale,
});

const propTypes = {
  isAuthenticated: PropTypes.bool,
};

AppRoutes.propTypes = propTypes;
Routes.propTypes = propTypes;

export default connect(mapStateToProps)(Routes);
