import React from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import _ from 'lodash';
import TableLoadIndicator from 'components/common/TableLoadIndicator';

import TableCellContent from './TableCellContent';
import Message from '../Message';

const CalaTableBody = props => {
  const {
    data,
    rowKey,
    dataFields,
    theme,
    isLoading,
    indicatorSize,
    columnCount,
    styles,
  } = props;

  return (
    <TableBody>
      {
        isLoading &&
        <TableLoadIndicator
          id="load-indicator"
          size={indicatorSize || 50}
          columnCount={columnCount || 8}
        />
      }
      {
        !isLoading && data.length === 0 &&
        <TableRow key={rowKey} style={styles.errorMessage}>
          {rowKey === 'device_inventory_id' ?
            <TableCell colSpan={dataFields.length}>
              <Message id="operations.list.noDevices" />
            </TableCell> :
            <TableCell colSpan={dataFields.length}>
              <Message id="operations.list.noLots" />
            </TableCell>}
        </TableRow>
      }
      {
        data.map((row, rowIndex) => {
          return (
            <TableRow
              key={row[rowKey]}
              style={{
                color: 'inherit',
                height: '48px',
                display: 'table-row',
                outline: 'none',
                verticalAlign: 'middle',
              }}
            >
              {
                dataFields.map((field, cellIndex) => {
                  const onClick = evt => {
                    if (evt.target && evt.target.nodeName === 'TD') {
                      return;
                    }
                    field.onClick(row, evt, rowIndex, cellIndex);
                  };

                  return (
                    <TableCell
                      key={field.key}
                      onClick={field.onClick ? onClick : _.noop}
                    >
                      <TableCellContent
                        row={row}
                        field={field}
                        theme={theme}
                      />
                    </TableCell>
                  );
                })
              }
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

CalaTableBody.propTypes = {
  data: PropTypes.array.isRequired,
  dataFields: PropTypes.array.isRequired,
  rowKey: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  columnCount: PropTypes.number,
  indicatorSize: PropTypes.number,
  styles: PropTypes.object,
};

export default CalaTableBody;
