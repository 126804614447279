import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import
{
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import SortableHeaderColumn from 'components/common/SortableHeaderColumn';
import TableLoadIndicator from 'components/common/TableLoadIndicator';

import getStyles from './OperationsDetailsTable.styles';
import OpenLotTableBody from './OpenLotTableBody';
import ClosedLotTableBody from './ClosedLotTableBody';
import { toggleDevice, toggleAllDevices } from '../../../actions/devicesActions';
import { isProcessingLotDetails } from '../../../reducers/operationsSelectors';

const OperationsDetailsTable = props => {
  const {
    theme,
    columns,
    activeField,
    sortDirection,
    onSort,
    data,
    isClosed,
    operations,
    showSpinner,
    getDeviceProp,
    hasDeleteButton,
  } = props;
  const styles = getStyles(theme);
  const createRequestBody = checked => {
    const lotId = props.operations.lotDetails.data.lot_id;
    const body = {};

    if (checked) {
      body.lot_id = lotId;
    }

    return body;
  };
  const checkboxChange = (evt, checked) => {
    const deviceInventoryId = parseInt(evt.target.value, 0);
    const body = createRequestBody(checked);

    props._toggleDevice(deviceInventoryId, checked, body);
  };
  const checkboxChangeAll = (evt, checked) => {
    const deviceInventoryIds = Object.keys(props.operations.unassignedDevices.byId);
    const body = createRequestBody(checked);

    props._toggleAllDevices(deviceInventoryIds, checked, body);
  };

  const isLoadingElement = showSpinner
    ? <TableLoadIndicator id="load-indicator" size={50} columnCount={columns.length || 4} />
    : null;

  let tableBodyElement = (
    <OpenLotTableBody
      data={data}
      checkedDevices={operations.checkedDevices.list}
      checkboxChange={checkboxChange}
      hasDeleteButton={hasDeleteButton}
    />
  );

  if (isClosed) {
    tableBodyElement = (
      <ClosedLotTableBody
        data={data}
        deviceTypeId={operations.lotDetails.data.device_type_id}
        getDeviceProp={getDeviceProp}
      />
    );
  }

  return (
    <Table
      id="table--operations-details"
    >
      <TableHead>
        <TableRow style={styles.table.headerRow}>
        {
          !isClosed &&
          <TableCell>
            <Checkbox
              color="default"
              role="checkbox"
              onChange={checkboxChangeAll}
              checked={operations.checkedDevices.allChecked}
              aria-checked={operations.checkedDevices.allChecked}
            />
          </TableCell>
        }
        {
          columns.map(field =>
            (<SortableHeaderColumn
              id={`column--${field.key}`}
              title={field.title}
              field={field.key}
              key={field.key}
              notSortable={field.notSortable}
              activeField={activeField}
              onSort={onSort}
              sortDirection={sortDirection}
              style={field.style}
            />))
        }
        </TableRow>
      </TableHead>
      <TableBody>
      {
        isLoadingElement || tableBodyElement
      }
      </TableBody>
    </Table>
  );
};

OperationsDetailsTable.propTypes = {
  theme: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  activeField: PropTypes.string,
  sortDirection: PropTypes.number,
  onSort: PropTypes.func,
  data: PropTypes.array.isRequired,
  isClosed: PropTypes.bool,
  _toggleDevice: PropTypes.func.isRequired,
  _toggleAllDevices: PropTypes.func.isRequired,
  operations: PropTypes.object.isRequired,
  getDeviceProp: PropTypes.func.isRequired,
  hasDeleteButton: PropTypes.bool.isRequired,
  showSpinner: PropTypes.bool,
};

const mapStateToProps = state => ({
  operations: state.operations,
  showSpinner: isProcessingLotDetails(state),
});

const mapDispatchToProps = dispatch => ({
  _toggleDevice: (deviceInventoryId, checked, body) => {
    dispatch(toggleDevice(deviceInventoryId, checked, body));
  },
  _toggleAllDevices: (deviceInventoryIds, checked, body) => {
    dispatch(toggleAllDevices(deviceInventoryIds, checked, body));
  },
});

const enhance = compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(OperationsDetailsTable);
