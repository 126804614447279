import * as en from 'intl/en';

export const calaItems = [
  {
    key: 'home',
    intlKey: 'Sidebar.tab.home',
    defaultIntl: en.Sidebar.tab.home,
  },
  {
    key: 'patients',
    intlKey: 'Sidebar.tab.patients',
    defaultIntl: en.Sidebar.tab.patients,
  },
  {
    key: 'operations',
    intlKey: 'Sidebar.tab.operations',
    defaultIntl: en.Sidebar.tab.operations,
  },
  {
    key: 'quarantine',
    intlKey: 'Sidebar.tab.quarantine',
    defaultIntl: en.Sidebar.tab.quarantine,
  },
];

export const clinicItems = [
  {
    key: 'patients',
    intlKey: 'Sidebar.tab.patients',
    defaultIntl: en.Sidebar.tab.patients,
  },
];

export const clinicalScienceItems = [
  {
    key: 'home',
    intlKey: 'Sidebar.tab.home',
    defaultIntl: en.Sidebar.tab.home,
  },
  {
    key: 'patients',
    intlKey: 'Sidebar.tab.patients',
    defaultIntl: en.Sidebar.tab.patients,
  },
  {
    key: 'operations',
    intlKey: 'Sidebar.tab.operations',
    defaultIntl: en.Sidebar.tab.operations,
  },
];
