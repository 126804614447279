/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MenuIcon = props => (
    <SvgIcon
      {...props}
      viewBox="0 0 50 50"
    >
      <path d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z " />
    </SvgIcon>
);

export default MenuIcon;
