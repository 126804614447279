import { takeLatest } from 'redux-saga/effects';

import {
  FORCE_CHANGE_PASSWORD,
  VERIFY_REQUEST,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from 'components/auth/actions/authActions';
import {
  forceChangePassword,
  requestVerify,
  requestForgotPassword,
  requestResetPassword,
} from 'components/auth/sagas/authSaga';
import {
  LOAD_DEVICE_LOGS,
  TOGGLE_BASE_STATION,
  DELETE_STATION_KEY,
} from 'components/deviceLogs/actions/deviceLogsActions';
import {
  loadDeviceLogs,
  toggleBaseStation,
  deleteStationKey,
} from 'components/deviceLogs/sagas/deviceLogsSaga';
import {
  LOAD_ERRORS_DATA,
  LOAD_PATIENT_DATA,
  LOAD_IMU_DATA,
} from 'components/graph/actions/graphActions';
import {
  loadErrorsData,
  loadPatientData,
  loadImuData,
} from 'components/graph/sagas/graphSaga';
import {
  LOAD_USERS,
  ADD_USER,
  ADD_USER_SIGNUP,
  UPDATE_USER,
  FETCH_USER_DATA,
  FETCH_MULTIPLE_USERS_DATA,
} from 'components/users/actions/usersActions';
import {
  loadUsers,
  addUser,
  addUserSignUp,
  updateUser,
  fetchUserData,
  fetchMultipleUsersData,
} from 'components/users/sagas/usersSaga';
import { LOAD_PROFILE, SAVE_PROFILE } from 'components/profile/actions/profileActions';
import { loadProfile, saveProfile } from 'components/profile/sagas/profileSaga';
import {
  LOAD_CLINICS,
  LOAD_CLINICS_WITHOUT_LOGO,
  ADD_CLINIC,
  UPDATE_CLINIC,
} from 'components/clinics/actions/clinicsActions';
import {
  loadClinics,
  loadClinicsWithoutLogo,
  addClinic,
  updateClinic,
} from 'components/clinics/sagas/clinicsSaga';
import { LOAD_VERSION, LOAD_LAMBDA } from 'components/version/actions/version';
import { loadVersion, loadLambdaVersions } from 'components/version/sagas/version';

import {
  LOAD_STUDIES,
  LOAD_SITES,
  ADD_SITE,
  UPDATE_SITE,
  CREATE_STUDY,
  UPDATE_STUDY,
  GET_LATEST_SITE_NOTE,
} from 'components/clinicalStudy/actions/clinicalStudyActions';
import {
  loadStudies,
  loadSites,
  addMultipleSites,
  updateSite,
  createStudy,
  updateStudy,
  getLatestNoteMultipleSites,
} from 'components/clinicalStudy/sagas/clinicalStudySaga';

import {
  LOAD_NOTES,
  ADD_NOTE,
} from 'components/common/notes/actions/notesActions';
import {
  loadNotes,
  addNote,
} from 'components/common/notes/sagas/notesSaga';
import {
  LOAD_DEVICE_TYPES,
  ADD_DEVICE_TYPE,
  UPDATE_DEVICE_TYPE,
  FETCH_DEVICE_STOCK,
  FETCH_MULTIPLE_DEVICE_STOCK,
  FETCH_SKU_LIST,
  ADD_SKU,
} from 'components/inventory/actions/inventoryActions';
import {
  loadDeviceTypes,
  addDeviceType,
  updateDeviceType,
  fetchDeviceStock,
  fetchMultipleDeviceStock,
  fetchSkuList,
  addSku,
} from 'components/inventory/sagas/inventorySaga';

import {
  LOAD_PATIENTS_LIST,
  LOAD_PATIENT_DETAILS,
  LOAD_PATIENT_COMPLIANCES,
  LOAD_COMPLIANCE_DETAILS,
  CREATE_PATIENT,
  REQUEST_REPORT,
} from 'components/patients/actions/patientsActions';
import {
  loadPatientsList,
  loadPatientDetails,
  loadPatientCompliances,
  loadComplianceDetails,
  createPatient,
  requestReport,
} from 'components/patients/sagas/patientsSaga';

import {
  LOAD_LOTS,
  LOAD_LOT_DETAILS,
  CREATE_LOT,
  CLOSE_LOT,
  OPEN_LOT,
} from 'components/operations/actions/operationsActions';

import {
  PUBLISH_FIRMWARE,
  LOAD_FIRMWARES,
  LOAD_FIRMWARE_DEVICES,
  ASSIGN_FIRMWARE,
  UNASSIGN_FIRMWARE,
  DEFAULT_FIRMWARE,
  UPDATE_FIRMWARE,
  UPDATE_FIRMWARE_ARCHIVE,
  EXPORT_LIST,
} from 'components/operations/actions/firmwareActions';
import {
  OPERATIONS_TOGGLE_DEVICE,
  OPERATIONS_TOGGLE_ALL_DEVICES,
  LOAD_UNASSIGNED_DEVICES,
  DELETE_UNASSIGNED_DEVICE,
  LOAD_DEVICES,
  ASSIGN_DEVICE,
  UNASSIGN_DEVICE,
  LOAD_PREFIX,
} from 'components/operations/actions/devicesActions';
import {
  loadLots,
  loadLotDetails,
  createLot,
  closeLot,
  openLot,
} from 'components/operations/sagas/operationsSaga';

import {
  publishFirmware,
  loadFirmwares,
  loadFirmwareDevices,
  assignFirmware,
  unassignFirmware,
  defaultFirmware,
  updateFirmware,
  updateFirmwareArchive,
  exportFirmwareList,
} from 'components/operations/sagas/firmwareSaga';
import {
  toggleDevice,
  toggleAllDevices,
  loadUnassignedDevices,
  deleteUnassignedDevice,
  loadDevices,
  assignDevice,
  unassignDevice,
  loadPrefix,
} from 'components/operations/sagas/devicesSaga';

import {
  LOAD_ORDERS,
  LOAD_ORDER_INFORMATION,
  ORDER_DETAIL_ACTION,
  ORDER_DETAIL_ACTION_BY_TYPE,
  ORDER_ACTION,
  REJECT_ORDER,
  APPROVE_ORDER,
  FETCH_ORDER_DEVICE_COUNT,
  FETCH_MULTIPLE_ORDER_DEVICE_COUNT,
} from 'components/shipments/actions/shipmentsActions';
import {
  loadOrders,
  loadOrderInformation,
  orderDetailAction,
  orderDetailActionByType,
  orderAction,
  rejectOrder,
  approveOrder,
  fetchDeviceCount,
  fetchMultipleDeviceCount,
} from 'components/shipments/sagas/shipmentsSaga';
import {
  CREATE_SHIPMENT_ORDER,
  CREATE_HAND_DELIVERY,
  DELETE_HAND_DELIVERY,
  FETCH_RATES,
  REGENERATE_RATES,
  BUY_DELIVERY,
  GET_POSTAGE_LABEL,
  GET_ORDER_SHIPMENT,
  GET_PICKUP,
  BUY_PICKUP,
} from 'components/shipments/actions/shipmentOrderActions';
import {
  createShipmentOrder,
  createHandDelivery,
  deleteHandDelivery,
  fetchRates,
  regenerateRates,
  buyDelivery,
  getPostageLabel,
  getOrderShipment,
  getPickup,
  buyPickup,
} from 'components/shipments/sagas/shipmentOrderSaga';

function* greenSaga() {
  yield takeLatest(FORCE_CHANGE_PASSWORD, forceChangePassword);
  yield takeLatest(LOAD_PROFILE, loadProfile);
  yield takeLatest(SAVE_PROFILE, saveProfile);
  yield takeLatest(VERIFY_REQUEST, requestVerify);
  yield takeLatest(FORGOT_PASSWORD, requestForgotPassword);
  yield takeLatest(RESET_PASSWORD, requestResetPassword);

  yield takeLatest(LOAD_DEVICE_LOGS, loadDeviceLogs);
  yield takeLatest(TOGGLE_BASE_STATION, toggleBaseStation);
  yield takeLatest(DELETE_STATION_KEY, deleteStationKey);

  yield takeLatest(LOAD_ERRORS_DATA, loadErrorsData);
  yield takeLatest(LOAD_PATIENT_DATA, loadPatientData);
  yield takeLatest(LOAD_PATIENT_COMPLIANCES, loadPatientCompliances);
  yield takeLatest(LOAD_COMPLIANCE_DETAILS, loadComplianceDetails);
  yield takeLatest(CREATE_PATIENT, createPatient);
  yield takeLatest(REQUEST_REPORT, requestReport);

  yield takeLatest(LOAD_IMU_DATA, loadImuData);
  yield takeLatest(LOAD_USERS, loadUsers);
  yield takeLatest(ADD_USER, addUser);
  yield takeLatest(ADD_USER_SIGNUP, addUserSignUp);
  yield takeLatest(UPDATE_USER, updateUser);
  yield takeLatest(FETCH_USER_DATA, fetchUserData);
  yield takeLatest(FETCH_MULTIPLE_USERS_DATA, fetchMultipleUsersData);
  yield takeLatest(LOAD_CLINICS, loadClinics);
  yield takeLatest(LOAD_CLINICS_WITHOUT_LOGO, loadClinicsWithoutLogo);
  yield takeLatest(ADD_CLINIC, addClinic);
  yield takeLatest(UPDATE_CLINIC, updateClinic);
  yield takeLatest(LOAD_VERSION, loadVersion);
  yield takeLatest(LOAD_LAMBDA, loadLambdaVersions);

  yield takeLatest(LOAD_STUDIES, loadStudies);
  yield takeLatest(LOAD_SITES, loadSites);
  yield takeLatest(ADD_SITE, addMultipleSites);
  yield takeLatest(UPDATE_SITE, updateSite);
  yield takeLatest(CREATE_STUDY, createStudy);
  yield takeLatest(UPDATE_STUDY, updateStudy);

  yield takeLatest(LOAD_NOTES, loadNotes);
  yield takeLatest(ADD_NOTE, addNote);
  yield takeLatest(GET_LATEST_SITE_NOTE, getLatestNoteMultipleSites);

  yield takeLatest(LOAD_PATIENTS_LIST, loadPatientsList);
  yield takeLatest(LOAD_PATIENT_DETAILS, loadPatientDetails);

  yield takeLatest(LOAD_DEVICE_TYPES, loadDeviceTypes);
  yield takeLatest(ADD_DEVICE_TYPE, addDeviceType);
  yield takeLatest(UPDATE_DEVICE_TYPE, updateDeviceType);
  yield takeLatest(FETCH_DEVICE_STOCK, fetchDeviceStock);
  yield takeLatest(FETCH_MULTIPLE_DEVICE_STOCK, fetchMultipleDeviceStock);
  yield takeLatest(FETCH_SKU_LIST, fetchSkuList);
  yield takeLatest(ADD_SKU, addSku);

  yield takeLatest(LOAD_LOTS, loadLots);
  yield takeLatest(LOAD_LOT_DETAILS, loadLotDetails);
  yield takeLatest(CREATE_LOT, createLot);
  yield takeLatest(CLOSE_LOT, closeLot);
  yield takeLatest(OPEN_LOT, openLot);
  yield takeLatest(LOAD_UNASSIGNED_DEVICES, loadUnassignedDevices);
  yield takeLatest(DELETE_UNASSIGNED_DEVICE, deleteUnassignedDevice);
  yield takeLatest(OPERATIONS_TOGGLE_DEVICE, toggleDevice);
  yield takeLatest(OPERATIONS_TOGGLE_ALL_DEVICES, toggleAllDevices);
  yield takeLatest(LOAD_DEVICES, loadDevices);
  yield takeLatest(ASSIGN_DEVICE, assignDevice);
  yield takeLatest(UNASSIGN_DEVICE, unassignDevice);
  yield takeLatest(LOAD_PREFIX, loadPrefix);

  yield takeLatest(PUBLISH_FIRMWARE, publishFirmware);
  yield takeLatest(LOAD_FIRMWARES, loadFirmwares);
  yield takeLatest(LOAD_FIRMWARE_DEVICES, loadFirmwareDevices);
  yield takeLatest(ASSIGN_FIRMWARE, assignFirmware);
  yield takeLatest(UNASSIGN_FIRMWARE, unassignFirmware);
  yield takeLatest(DEFAULT_FIRMWARE, defaultFirmware);
  yield takeLatest(UPDATE_FIRMWARE, updateFirmware);
  yield takeLatest(UPDATE_FIRMWARE_ARCHIVE, updateFirmwareArchive);
  yield takeLatest(EXPORT_LIST, exportFirmwareList);

  yield takeLatest(LOAD_ORDERS, loadOrders);
  yield takeLatest(LOAD_ORDER_INFORMATION, loadOrderInformation);
  yield takeLatest(ORDER_DETAIL_ACTION, orderDetailAction);
  yield takeLatest(ORDER_DETAIL_ACTION_BY_TYPE, orderDetailActionByType);
  yield takeLatest(ORDER_ACTION, orderAction);
  yield takeLatest(REJECT_ORDER, rejectOrder);
  yield takeLatest(APPROVE_ORDER, approveOrder);
  yield takeLatest(FETCH_ORDER_DEVICE_COUNT, fetchDeviceCount);
  yield takeLatest(FETCH_MULTIPLE_ORDER_DEVICE_COUNT, fetchMultipleDeviceCount);
  yield takeLatest(CREATE_SHIPMENT_ORDER, createShipmentOrder);
  yield takeLatest(CREATE_HAND_DELIVERY, createHandDelivery);
  yield takeLatest(DELETE_HAND_DELIVERY, deleteHandDelivery);
  yield takeLatest(FETCH_RATES, fetchRates);
  yield takeLatest(REGENERATE_RATES, regenerateRates);
  yield takeLatest(BUY_DELIVERY, buyDelivery);
  yield takeLatest(GET_POSTAGE_LABEL, getPostageLabel);
  yield takeLatest(GET_ORDER_SHIPMENT, getOrderShipment);
  yield takeLatest(GET_PICKUP, getPickup);
  yield takeLatest(BUY_PICKUP, buyPickup);
}

export default greenSaga;
