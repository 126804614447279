import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MouseDown extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.props.handler);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.props.handler);
  }

  render() {
    const { styles, children } = this.props;

    return (
      <div
        style={styles}
      >
        {children}
      </div>
    );
  }
}

MouseDown.propTypes = {
  handler: PropTypes.func.isRequired,
  styles: PropTypes.object,
  children: PropTypes.any.isRequired,
};

export default MouseDown;
