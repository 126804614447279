import { call, put } from 'redux-saga/effects';
import VersionService, {loadLambdaVersion} from '../services/version';
import {
  LOAD_VERSION_SUCCESS,
  LOAD_VERSION_FAILURE,
  LOAD_LAMBDA_SUCCESS,
  LOAD_LAMBDA_FAILURE,
} from '../actions/version';

export function* loadVersion() {
  try {
    const result = yield call(VersionService.loadVersion);

    yield put({ type: LOAD_VERSION_SUCCESS, result });
  } catch (error) {
    yield put({ type: LOAD_VERSION_FAILURE, error });
  }
}

export function* loadLambdaVersions() {
  try {
    const result = yield call(loadLambdaVersion);

    yield put({ type: LOAD_LAMBDA_SUCCESS, result });
  } catch (error) {
    yield put({ type: LOAD_LAMBDA_FAILURE, error });
  }
}
