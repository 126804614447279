import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core';

import getAlertStyles from 'components/common/Alert.styles';

const SnackbarProviderOverride = props => {
  const { children, classes } = props;

  return (
    <SnackbarProvider
      maxSnack={6}
      hideIconVariant
      classes={{
        variantSuccess: classes.success,
        variantError: classes.danger,
        variantInfo: classes.primary,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

SnackbarProviderOverride.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
};

const enhance = compose(
  withSnackbar,
  withStyles(getAlertStyles),
);

export default enhance(SnackbarProviderOverride);
