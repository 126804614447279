import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { LOGOUT_SUCCESS } from 'components/auth/actions/authActions';
import { fetchMessages, getUserLocale } from 'components/utils/i18n';
import * as _ from 'lodash';

import { reducer as formReducer } from 'redux-form';
import authReducer from 'components/auth/reducers/authReducer';
import profileReducer from 'components/profile/reducers';
import versionReducer from 'components/version/reducers/version';
import lambdaVersionReducer from 'components/version/reducers/lambdaVersion';
import deviceLogsReducer from 'components/deviceLogs/reducers';
import deviceLogGraphReducer from 'components/graph/reducers/graphReducer';
import usersReducer from 'components/users/reducers';
import clinicsReducer from 'components/clinics/reducers';
import clinicalStudyReducer from 'components/clinicalStudy/reducers';
import notesReducer from 'components/common/notes/reducers/notesReducer';
import notesDialogReducer from 'components/common/notes/reducers/notesDialogReducer';
import patientsReducer from 'components/patients/reducers';
import inventoryReducer from 'components/inventory/reducers';
import operationsReducer from 'components/operations/reducers';
import shipmentsReducer from 'components/shipments/reducers';
import quarantineCombinedReducer from 'components/quarantine/reducers';
import notificationsReducer from 'components/common/Notifier/reducers';

const appReducer = combineReducers({
  form: formReducer,
  intl: intlReducer,
  auth: authReducer,
  version: versionReducer,
  lambdaVersion: lambdaVersionReducer,
  profile: profileReducer,
  deviceLogs: deviceLogsReducer,
  deviceLogGraph: deviceLogGraphReducer,
  users: usersReducer,
  clinics: clinicsReducer,
  clinicalStudy: clinicalStudyReducer,
  notes: notesReducer,
  notesDialog: notesDialogReducer,
  patients: patientsReducer,
  inventory: inventoryReducer,
  operations: operationsReducer,
  shipments: shipmentsReducer,
  quarantine: quarantineCombinedReducer,
  notifications: notificationsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }

  if (_.isEmpty(_.get(state, 'intl.messages'))) {
    const locale = getUserLocale();

    state = state || {
      intl: {
        defaultLocale: 'en',
      },
    };
    state.intl = {
      ...state.intl,
      locale,
      messages: fetchMessages(locale),
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
