import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Alert from 'components/common/Alert';
import getGlobalStyles from 'components/styles/global.styles';
import { loadVersion, loadLambdaVersions } from './actions/version';
import { versionShape } from './utils/customPropTypes';
import Version from './Version';
import LambdaVersion from './LambdaVersion';
import { services } from './utils/constants';

import '../styles/css/version.css';

class VersionPage extends Component {
  constructor(props, context) {
    super(props);

    this.globalStyles = getGlobalStyles();
  }

  componentDidMount() {
    const { loadVersion: _loadVersion, _loadLambdaVersions } = this.props;

    _loadVersion();
    _loadLambdaVersions();
  }

  render() {
    const { version, lambdaVersion } = this.props;

    return (
        <div id="page" style={this.globalStyles.coloredPage}>
          <div id="page__content" className="container">
            {
              version.loadError &&
              <Alert
                id="alert--error"
                text={version.loadError.message || 'Failed to load the version information. Please try again.'}
                type="danger"
              />
            }
            {
              lambdaVersion.loadError &&
              <Alert
                id="alert--error"
                text={lambdaVersion.loadError.message || 'Failed to load the version information. Please try again.'}
                type="danger"
              />

            }
            {
              version.isLoaded &&
              <div>
                {
                  services.map(service => {
                    let serviceName = service.charAt(0).toUpperCase() + service.slice(1);

                    serviceName = serviceName.replace(/([a-z])([A-Z])/g, '$1 $2');

                    return (
                      <Version
                        key={service}
                        service={service}
                        serviceName={serviceName}
                        services={version.services}
                      />
                    );
                  })
                }
                {
                lambdaVersion.lambdaServices.map(service => (
                  <LambdaVersion
                    key={service['Repo Name']}
                    service={service}
                    serviceName={service['Repo Name']}
                  />
                ))
              }
              </div>
            }
          </div>
        </div>
    );
  }
}

VersionPage.propTypes = {
  version: versionShape,
  lambdaVersion: PropTypes.object.isRequired,
  loadVersion: PropTypes.func.isRequired,
  _loadLambdaVersions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  version: state.version,
  lambdaVersion: state.lambdaVersion,
});

const mapDispatchToProps = dispatch => ({
  loadVersion: () => {
    dispatch(loadVersion());
  },
  _loadLambdaVersions: () => {
    dispatch(loadLambdaVersions());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VersionPage);
