import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Session from 'components/utils/session';

const AccessControl = props => {
  const {
    children,
    roles,
    accessCheck,
    extraAccessData,
  } = props;
  const hasRoles = _.get(roles, 'length', 0) > 0;
  const roleId = Session.JWTDecoded.role_id;

  let isAuthorized = true;

  if (accessCheck && hasRoles) {
    isAuthorized = _.includes(roles, roleId) && accessCheck(extraAccessData, roleId);
  } else if (hasRoles) {
    isAuthorized = _.includes(roles, roleId);
  }

  return (
    <span
      style={{
        display: isAuthorized ? null : 'none',
      }}
    >
      {children}
    </span>
  );
};

AccessControl.propTypes = {
  children: PropTypes.any.isRequired,
  roles: PropTypes.array,
  accessCheck: PropTypes.func,
  extraAccessData: PropTypes.object,
};

export default AccessControl;
