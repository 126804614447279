export default {
  stimSessions: 'Stim sessions',
  tremorRating: 'Tremor rating',
  tremorFrequency: 'Tremor frequency',
  settingsChange: 'Settings change',
  maxCurrent: 'Max current',
  imu: 'IMU',
  buttonClicks: 'Button clicks',
  mainYAxis: 'Max current (mA)',
  batteryYAxis: 'Battery level (%)',
  impedanceYAxis: 'Impedance (kiloohm)',
  impedanceAc: 'ImpedanceAC',
  impedanceBc: 'ImpedanceBC',
};
