export default {
  deviceSn: 'DEVICE SN',
  preset: 'PRESET (mA)',
  centerFrequency: 'CENTER FREQUENCY (Hz)',
  sessionNumber: 'SESSION #',
  assignDate: 'ASSIGN DATE',
  unassignDate: 'UNASSIGN DATE',
  export: 'Export Sessions',
  exportEvents: 'Export Events',
  exportMessage: 'Check your email in few minutes for a link to download the data.',
};
