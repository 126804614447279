import React, { Component } from 'react';

import DeviceAssignment from './DeviceAssignment';

class DeviceAssignmentContainer extends Component {
  render() {
    return (
      <DeviceAssignment />
    );
  }
}

export default DeviceAssignmentContainer;
