export default theme => ({
  pageHeader: {
    display: 'flex',
    padding: theme.padding.pageSmall,
    alignItems: 'center',
    height: theme.height.pageHeader,
    borderBottom: `1px solid ${theme.palette.borderColor1}`,
  },
  pageTitle: {
    fontSize: theme.size.header,
    color: theme.palette.textColor,
    letterSpacing: theme.spacing(0) * 2,
    fontWeight: 300,
  },
  pageDetails: {
    display: 'block',
    marginTop: 10,
  },
  pageIcon: {
    marginRight: 20,
    width: '2em',
    height: '2em',
    fill: 'rgb(102, 102, 102)',
  },
  button: {
    width: 'auto%',
    padding: 'none',
    height: 'auto',
    cursor: 'pointer',
    backgroundColor: theme.palette.backgroundColor,
    color: theme.palette.secondaryTextColor,
    borderRadius: 50,
    label: {
      fontSize: theme.size.body,
      textTransform: 'uppercase',
      letterSpacing: theme.spacing(0),
    },
    marginLeft: 'auto',
  },
});
