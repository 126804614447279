import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTheme, Icon } from '@material-ui/core';

import getGlobalStyles from '../newGlobal.styles';

const BackIcon = ({ link, theme }) => {
  const globalStyles = getGlobalStyles(theme);

  return (
    <Link
      to={link}
    >
      <Icon
        className="material-icons"
        style={{
          ...globalStyles.pageIcon,
          color: theme.palette.backgroundColor,
          margin: '50% 0 0 0',
        }}
      >
        arrow_back
      </Icon>
    </Link>
  );
};

BackIcon.propTypes = {
  theme: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

export default withTheme(BackIcon);
