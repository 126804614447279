/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CalendarIcon = props => (
    <SvgIcon
      {...props}
      viewBox="0 0 32.75 32.75"
    >
      <path d="M29.375 1.25h-1.123c.029-.093.059-.186.059-.289a.962.962 0 0 0-1.924 0c0 .103.028.196.059.289h-3.68c.029-.093.059-.186.059-.289a.963.963 0 0 0-1.925 0c0 .103.029.196.06.289h-3.681c.029-.093.058-.186.058-.289a.96.96 0 1 0-1.922 0c0 .103.028.196.058.289h-3.681c.029-.093.059-.186.059-.289a.961.961 0 0 0-1.923 0c0 .103.028.196.058.289h-3.68a.93.93 0 0 0 .059-.289.96.96 0 1 0-1.923 0c0 .103.028.196.058.289H3.375A2.752 2.752 0 0 0 .625 4v26a2.751 2.751 0 0 0 2.75 2.75H26.27l5.855-5.855V4a2.752 2.752 0 0 0-2.75-2.75zm1.25 25.023l-.311.311h-2.355c-1.102 0-2 .9-2 2v2.355l-.311.311H3.375c-.689 0-1.25-.561-1.25-1.25V5h28.5v21.273z" />
      <path d="M9.892 7.535h3.536v3.536H9.892zM14.607 7.535h3.536v3.536h-3.536zM19.322 7.535h3.535v3.536h-3.535zM24.037 7.535h3.537v3.536h-3.537zM5.176 12.25h3.537v3.537H5.176zM9.892 12.25h3.536v3.537H9.892zM14.607 12.25h3.536v3.537h-3.536zM19.322 12.25h3.535v3.537h-3.535zM24.037 12.25h3.537v3.537h-3.537zM5.176 16.965h3.537v3.537H5.176zM9.892 16.965h3.536v3.537H9.892zM14.607 16.965h3.536v3.537h-3.536zM19.322 16.965h3.535v3.537h-3.535zM24.037 16.965h3.537v3.537h-3.537zM5.176 21.68h3.537v3.536H5.176zM9.892 21.68h3.536v3.536H9.892zM14.607 21.68h3.536v3.536h-3.536zM19.322 21.68h3.535v3.536h-3.535zM24.037 21.68h3.537v3.536h-3.537zM5.176 26.984h8.251v1.768H5.176z" />
    </SvgIcon>
);

export default CalendarIcon;
