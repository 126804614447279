import { UPDATE_PROFILE } from 'components/common/forms/names';
import { sagaRequestWithPayload, formSagaRequestWithPayload } from 'components/utils/reduxHelpers';
import * as ProfileService from '../services/profileService';
import {
  LOAD_PROFILE, SAVE_PROFILE,
} from '../actions/profileActions';

export function* loadProfile() {
  yield* sagaRequestWithPayload(LOAD_PROFILE, ProfileService.loadProfile);
}

export function* saveProfile(action) {
  const { promise, ...rest } = action;

  yield* formSagaRequestWithPayload(UPDATE_PROFILE, SAVE_PROFILE, ProfileService.saveProfile, promise, rest);
}
