import { combineReducers } from 'redux';
import _ from 'lodash';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import { UPDATE_STUDY } from 'components/clinicalStudy/actions/clinicalStudyActions';
import {
  LOAD_DEVICE_TYPES,
  ADD_DEVICE_TYPE,
  UPDATE_DEVICE_TYPE,
} from '../actions/inventoryActions';

export const DEFAULT_STATE = {};

const addOrUpdateDeviceById = (state, action) => {
  return {
    ...state,
    [action.result.device_type_id]: action.result,
  };
};

const updateDeviceType = (state, action) => {
  const { sortedDevices } = action.payload.study;

  const device = { ...sortedDevices };
  const sortedByDeviceTypeId = _.keyBy(device, element => element.device_type_id);

  return { ...state, ...sortedByDeviceTypeId };
};

export const byId = createReducer(DEFAULT_STATE, {
  [`${LOAD_DEVICE_TYPES}_SUCCESS`]: (state, action) => _.keyBy(action.result.data, o => o.device_type_id),
  [`${ADD_DEVICE_TYPE}_SUCCESS`]: addOrUpdateDeviceById,
  [`${UPDATE_DEVICE_TYPE}_SUCCESS`]: addOrUpdateDeviceById,
  [`${UPDATE_STUDY}_SUCCESS`]: updateDeviceType,
});

const asyncReducer = combineReducers({
  load: asyncReducerHelper(LOAD_DEVICE_TYPES),
  add: asyncReducerHelper(ADD_DEVICE_TYPE),
  update: asyncReducerHelper(UPDATE_DEVICE_TYPE),
});

const deviceTypeReducer = combineReducers({
  async: asyncReducer,
  byId,
});

export default deviceTypeReducer;
