export const LOAD_STUDIES = 'LOAD_STUDIES';
export const LOAD_SITES = 'LOAD_SITES';
export const ADD_SITE = 'ADD_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const CREATE_STUDY = 'CREATE_STUDY';
export const UPDATE_STUDY = 'UPDATE_STUDY';
export const CLEAR_STUDY_ERRORS = 'CLEAR_STUDY_ERRORS';
export const GET_LATEST_SITE_NOTE = 'GET_LATEST_SITE_NOTE';
export const RESET_NEW_ITEM = 'RESET_NEW_ITEM';

export function loadStudies(notification) {
  return {
    type: LOAD_STUDIES,
    notification,
  };
}

export function loadSites(notification) {
  return {
    type: LOAD_SITES,
    notification,
  };
}

export function addSite(site, studyId) {
  return {
    type: ADD_SITE,
    site,
    studyId,
  };
}

export function updateSite(site, studyId, promise, notification) {
  return {
    type: UPDATE_SITE,
    site,
    studyId,
    promise,
    notification,
  };
}

export function createStudy(study, promise) {
  return {
    type: CREATE_STUDY,
    study,
    promise,
  };
}

export function updateStudy(study, promise) {
  return {
    type: UPDATE_STUDY,
    study,
    promise,
  };
}

export function clearErrors() {
  return {
    type: CLEAR_STUDY_ERRORS,
  };
}

export const getLatestNote = (studyId, sites) => {
  return {
    type: GET_LATEST_SITE_NOTE,
    studyId,
    sites,
  };
};

export const resetNewItem = () => {
  return {
    type: RESET_NEW_ITEM,
  };
};
