/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const WarningIcon = props => (
    <SvgIcon
      {...props}
      viewBox="0 0 14.255 12.36"
    >
      <path
        d="M7.136,1.008a1.528,1.528,0,0,0-1.327.736L.216,11.09a1.528,1.528,0,0,0,1.323,2.278h11.19a1.531,1.531,0,0,0,1.33-2.278l-5.6-9.346A1.528,1.528,0,0,0,7.136,1.008Zm0,.936a.6.6,0,0,1,.513.29l5.593,9.343a.548.548,0,0,1-.513.84H1.539a.547.547,0,0,1-.509-.84L6.623,2.234A.6.6,0,0,1,7.136,1.944ZM6.652,4.806V9.563H7.6V4.806Zm0,5.708v.951H7.6v-.951Z"
        transform="translate(0)"
      />
    </SvgIcon>
);

export default WarningIcon;
