import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { reduxForm, formValueSelector } from 'redux-form';

import Form from 'components/common/forms/Form';
import { UPDATE_PROFILE } from 'components/common/forms/names';
import { TextField } from 'components/common/forms/elements';
import { required } from 'components/common/forms/validation';
import { saveProfile } from 'components/profile/actions/profileActions';
import { getFullName } from 'components/utils/helpers';

import * as en from 'intl/en';
import AvatarIcon from 'components/styles/icons/AvatarIcon';
import TouchInput from 'components/common/TouchInput';
import Alert from 'components/common/Alert';
import getGlobalStyles from 'components/styles/global.styles';
import Message from 'components/common/Message';

import getStyles from '../ProfileForm.styles';
import {
  NEW_PASSWORD_ERROR,
  OLD_PASSWORD_ERROR,
  UNKNOWN_PASSWORD_ERROR,
  getPasswordErrorType,
} from '../utils/helper';

class ProfileForm extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      showPasswords: false,
      passwordMismatch: false,
      oldPasswordEmpty: false,
      newPasswordEmpty: false,
      repetitivePasswords: false,
      markRed: {
        oldPassword: false,
        newPassword: false,
      },
      alertDismisser: null,
    };
    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();
    this.markPasswordFieldsFromError = this.markPasswordFieldsFromError.bind(this);
    this.showPasswords = this.showPasswords.bind(this);
    this.validatePasswords = this.validatePasswords.bind(this);
  }

  markPasswordFieldsFromError(error) {
    const errorType = getPasswordErrorType(error);

    this.setState({
      markRed: {
        oldPassword: errorType === OLD_PASSWORD_ERROR || errorType === UNKNOWN_PASSWORD_ERROR,
        newPassword: errorType === NEW_PASSWORD_ERROR || errorType === UNKNOWN_PASSWORD_ERROR,
      },
    });
  }

  showPasswords() {
    this.setState({
      showPasswords: true,
      oldPasswordEmpty: false,
      newPasswordEmpty: false,
      repetitivePasswords: false,
      passwordMismatch: false,
      markRed: {},
    });
  }

  validatePasswords() {
    const { oldPasswordValue, newPasswordValue } = this.props;

    let { newPasswordConfirmedValue } = this.props;

    const defaultStates = {
      oldPasswordEmpty: false,
      newPasswordEmpty: false,
      repetitivePasswords: false,
      passwordMismatch: false,
    };

    if (oldPasswordValue === '') {
      this.setState({
        ...defaultStates,
        oldPasswordEmpty: true,
        markRed: {
          oldPassword: true,
        },
      });
      return false;
    }
    if (newPasswordValue === '') {
      this.setState({
        ...defaultStates,
        newPasswordEmpty: true,
        markRed: {
          newPassword: true,
        },
      });
      return false;
    }
    if (newPasswordValue === oldPasswordValue) {
      this.setState({
        ...defaultStates,
        repetitivePasswords: true,
        markRed: {
          oldPassword: true,
          newPassword: true,
        },
      });
      return false;
    }
    if (newPasswordValue !== newPasswordConfirmedValue) {
      this.setState({
        ...defaultStates,
        passwordMismatch: true,
        markRed: {
          newPassword: true,
        },
      });
      newPasswordConfirmedValue = '';
      return false;
    }
    this.setState({
      ...defaultStates,
      markRed: {},
    });
    return true;
  }

  onSubmit = (values, dispatch) => new Promise((resolve, reject) => {
    const newValues = {...values};

    if (this.state.showPasswords && !this.validatePasswords()) {
      return reject();
    }
    this.setState({showPasswords: false});

    dispatch(saveProfile(newValues, { resolve, reject }));
  });

  renderPasswordBlock() {
    const { intl } = this.props;
    const { markRed: { oldPassword, newPassword } } = this.state;
    const containerStyle = {
      ...this.styles.passwords,
      ...(oldPassword || newPassword) && this.styles.passwords.error,
    };

    return (
      <div>
        {!this.state.showPasswords &&
          <TouchInput
            id="input--password"
            name="password"
            touchText="Change Password"
            onTouch={this.showPasswords}
            placeholder={intl.formatMessage({
              id: 'user.common.password',
              defaultMessage: en.user.common.password,
            })}
            type="password"
            value="password"
            fullWidth
            InputProps={{ disableUnderline: true }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ style: { height: '34px' }}}
            style={this.styles.passwordInput}
            disabled
          />
        }
        {this.state.showPasswords &&
          <div style={containerStyle}>
            <TextField
              name="old_password"
              label="user.common.oldPassword"
              validate={required}
              type="password"
            />
            <TextField
              name="new_password"
              label="user.common.newPassword"
              validate={required}
              type="password"
            />
            <TextField
              name="new_password_confirm"
              label="user.common.newPasswordConfirm"
              validate={required}
              type="password"
            />
          </div>
        }
      </div>
    );
  }
  render() {
    const { onSubmit } = this;
    const { children } = this.props;
    const { closeForm } = this.props.delegates;
    const successMessage = { message: 'profile.form.operationSuccess' };
    const errorMessage = { message: 'profile.form.saveError' };

    return (
        <Form
          id={UPDATE_PROFILE}
          onSubmit={onSubmit}
          successMessage={successMessage}
          errorMessage={errorMessage}
          submitBtnTxt="user.form.save"
          cancelBtnTxt="user.form.cancel"
          cancel={closeForm}
          showCancel
          {...this.props}
        >
          {this.state.passwordMismatch &&
            <Alert
              id="alert--password-mismatch"
              text={
                <Message id="user.form.passwordMismatch" />
              }
              type="danger"
            />
          }
          {this.state.oldPasswordEmpty &&
            <Alert
              id="alert--old-password-empty"
              text={
                <Message id="user.form.oldPasswordEmpty" />
              }
              type="danger"
            />
          }
          {this.state.newPasswordEmpty &&
            <Alert
              id="alert--password-empty"
              text={
                <Message id="user.form.newPasswordEmpty" />
              }
              type="danger"
            />
          }
          {this.state.repetitivePasswords &&
            <Alert
              id="alert--password-empty"
              text={
                <Message id="user.form.repetitivePasswords" />
              }
              type="danger"
            />
          }
          {this.state.alertDismisser && children}
          <AvatarIcon
            id="profile__avatar"
            style={this.styles.avatar}
          />
          <TextField
            name="name"
            label="user.common.fullName"
            validate={required}
          />
          <TextField
            name="email"
            label="user.common.email"
            validate={required}
          />
          <label
            style={this.styles.passwordInputLabel}
            htmlFor="password"
          >
            <Message id="user.common.password" />
          </label>
          {this.renderPasswordBlock()}
          <TextField
            name="address"
            label="user.common.address"
            validate={required}
          />
          <TextField
            name="phone"
            label="user.common.phoneNumber"
            validate={required}
          />
        </Form>
    );
  }
}

const selector = formValueSelector(UPDATE_PROFILE);

const mapStateToProps = state => ({
  initialValues: {
    ...state.profile.info,
    name: getFullName(state.profile.info.first_name, state.profile.info.last_name),
  },
  oldPasswordValue: selector(state, 'old_password'),
  newPasswordValue: selector(state, 'new_password'),
  newPasswordConfirmedValue: selector(state, 'new_password_confirm'),
});

ProfileForm.propTypes = {
  intl: intlShape.isRequired,
  delegates: PropTypes.any,
  children: PropTypes.any,
  oldPasswordValue: PropTypes.string,
  newPasswordValue: PropTypes.string,
  newPasswordConfirmedValue: PropTypes.string,
};

const enhance = compose(
  injectIntl,
  connect(mapStateToProps),
  reduxForm({
    form: UPDATE_PROFILE,
    enableReinitialize: true,
  }),
);

export default enhance(ProfileForm);
