import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from 'components/common/Alert';
import { cleanAWSMessage } from 'components/utils/errors';

import PrimaryButton from 'components/common/buttons/PrimaryButton';

import styles from './Fetch.styles';

class Fetch extends Component {
  static propTypes = {
    asyncInfo: PropTypes.object.isRequired,
    fetch: PropTypes.func,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    skipFetchOnMount: PropTypes.bool,
  }

  static defaultProps = {
    fetch: null,
    skipFetchOnMount: false,
  }

  componentDidMount() {
    if (!this.props.skipFetchOnMount) {
      this.fetch();
    }
  }

  get isFetchDefined() {
    return _.isFunction(this.props.fetch);
  }

  fetch = () => {
    const { asyncInfo, fetch } = this.props;

    if (this.isFetchDefined && !asyncInfo.isProcessing) {
      fetch();
    }
  }

  errorInfoComponent() {
    const { asyncInfo: { error }, classes } = this.props;

    return (
      <div>
        <Alert
          id="fetch--alert"
          text={cleanAWSMessage(error.message, error)}
          type="danger"
        />
        {this.isFetchDefined &&
          <div className={classes.tryAgainButton}>
            <PrimaryButton
              id="btn__try_again"
              type="button"
              onClick={this.fetch}
              message="common.shared.tryAgain"
            />
          </div>
        }
      </div>
    );
  }

  render() {
    const { asyncInfo, children, classes } = this.props;

    if (asyncInfo.isSuccess) {
      return children;
    } else if (asyncInfo.error) {
      return this.errorInfoComponent();
    }

    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
}

export default withStyles(styles)(Fetch);
