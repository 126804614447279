import axios from 'axios';
import Session from 'components/utils/session';
import Config from 'config';

const API_ROOT = Config.AUTH_API_ROOT;

export function googleLogin(url) {
  const axiosCall = axios.get(url);

  axiosCall.then(value => {
    const { token } = value.data;
    localStorage.setItem('token', token);

    const indexString = 16;
    const indexSlice = -2;
    const helperFormatJWT = c =>
      (`00${c.charCodeAt(0).toString(indexString)}`).slice(indexSlice);

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
      return `%${helperFormatJWT(c)}`;
    }).join(''));

    localStorage.setItem('JWTDecoded', jsonPayload);
  });

  return axiosCall;
}

const login = async function({ creds }) {
  const { email, password } = creds;

  try {
    const response = await axios.post(`${API_ROOT}/login`, {
      email,
      password,
    }, {
      headers: {
        Accept: 'application/json',
      },
    });

    const token = response.data;

    Session.token = token;
    Session.email = email;

    return token;
  } catch (error) {
    throw error;
  }
};

const verify = async function({ email, code }) {
  try {
    return await axios.put(`${API_ROOT}/confirm_sign_up`, {
      email,
      confirmation_code: code,
    });
  } catch (error) {
    throw error;
  }
};

const forgotPassword = async function({ email }) {
  try {
    return await axios.post(`${API_ROOT}/forgotpassword`, {
      email,
    });
  } catch (error) {
    throw error;
  }
};

const forceChangePassword = async function({ data }) {
  const { email, new_password, temporary_password } = data;

  try {
    return await axios.post(`${API_ROOT}/login/force_change_password`, {
      email,
      new_password,
      temporary_password,
    });
  } catch (error) {
    throw error;
  }
};

const resetPassword = async function({ data }) {
  const { email, password, code } = data;

  try {
    return await axios.post(`${API_ROOT}/confirmpassword`, { email, password, confirmation_code: code });
  } catch (error) {
    throw error;
  }
};

export default {
  login,
  verify,
  forgotPassword,
  resetPassword,
  forceChangePassword,
};
