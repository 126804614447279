export default {
  registrationSuccess: 'User is successfully added',
  registrationError: 'There was error in the system. Please try again.',
  signUpSuccess: 'Sign-up successful. We have sent you the verification link.',
  updateSuccess: 'User is successfully updated',
  updateError: 'There was error in the system. Please try again.',
  forgotPasswordSuccess: 'Redirecting ...',
  resetPasswordSuccess: 'New password is successfully set.',
  passwordMismatch: 'Passwords do not match. Please confirm again.',
  oldPasswordEmpty: 'Old password cannot be empty.',
  newPasswordEmpty: 'New password cannot be empty.',
  repetitivePasswords: 'New password cannot be identical to the old password.',
  save: 'Save Changes',
  cancel: 'Cancel',
  close: 'Close window',
  gotoLogin: 'Go to Login',
};
