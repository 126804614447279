import Config from 'config';
import { getResponseData } from 'components/utils/asyncHelpers';

const {
  SHIPPING_API_ROOT,
} = Config;

export const loadOrders = ({ status }) => {
  const append = status ? `?status=${status}` : '';

  return getResponseData('get', `${SHIPPING_API_ROOT}/orders${append}`);
};

export const loadOrderInformation = ({ orderId }) =>
  getResponseData('get', `${SHIPPING_API_ROOT}/orders/${orderId}`);

export const orderAction = ({ orderId, status }) =>
  getResponseData('post', `${SHIPPING_API_ROOT}/orders/${orderId}/${status}`);

export const orderDetailAction = ({ orderId, orderDetailId, status }) =>
  getResponseData('post', `${SHIPPING_API_ROOT}/orders/${orderId}/order_detail/${orderDetailId}/${status}`);

export const orderDetailActionByType = ({ orderId, deviceTypeId, status }) =>
  getResponseData('post', `${SHIPPING_API_ROOT}/orders/${orderId}/order_detail/${status}`, { deviceTypeId });

export const rejectOrder = ({ orderId, body }) =>
  getResponseData('post', `${SHIPPING_API_ROOT}/orders/${orderId}/reject`, body);

export const fetchDeviceCount = ({ siteId }) =>
  getResponseData('get', `${SHIPPING_API_ROOT}/orders/site/${siteId}/active`);
