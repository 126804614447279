import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { compose } from 'recompose';

import getGlobalStyles from 'components/styles/global.styles';
import LogoIconWithColoredText from 'components/styles/icons/LogoIconWithColoredText';
import PrimaryButton from 'components/common/buttons/PrimaryButton';
import getStyles from './LoginPage.styles';
/**
 * Component representing the Login route<br>
 */
class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.oauthSignIn = this.oauthSignIn.bind(this);
  }

  componentDidMount() {
    const { isAuthenticated, history } = this.props;

    if (isAuthenticated) {
      history.replace('/');
    }
  }

  oauthSignIn() {
    // Parameters to pass to OAuth 2.0 endpoint.
    const params = {
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
      redirect_uri: process.env.REACT_APP_LOGIN_CALLBACK || '',
      response_type: 'code',
      scope: 'email profile openid https://www.googleapis.com/auth/admin.directory.user.readonly',
      include_granted_scopes: 'false',
      prompt: 'select_account',
    };
    const loginURL = `${process.env.REACT_APP_GOOGLE_OAUTH2_ENDPOINT}?${queryString.stringify(params)}`;

    window.location.href = loginURL;
  }

  render() {
    const styles = getStyles();
    const globalStyles = getGlobalStyles();

    return (
            <div
              id="page"
              style={globalStyles.flexPage}
            >
                <div style={styles.logoContainer} >
                  <LogoIconWithColoredText style={styles.logo} />
                </div>
                <div
                  id="page__content"
                  style={styles.container}
                >
                    <h1 style={{ ...globalStyles.pageTitle, ...styles.title }}>Sign in to CalaHealth</h1>
                    <PrimaryButton
                      id="btn--login"
                      message="shared.googleSignIn"
                      onClick={this.oauthSignIn}
                    />
                </div>
            </div>
    );
  }
}

LoginPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
);

export default enhance(LoginPage);
