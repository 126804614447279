import { ROLES } from 'components/users/utils/userRoles';

export const downloadPermission = {
  allowedRoles: [
    ROLES.ADMIN,
    ROLES.CLINICAL_OPERATION,
    ROLES.OPERATIONS,
    ROLES.OUS_CALA,
    ROLES.ENGINEER,
  ],
};

export default {
  applyForEverybody: [ROLES.ADMIN, ROLES.REGULATORY],
  createNewLot: [
    ROLES.ADMIN,
    ROLES.REGULATORY,
    ROLES.OPERATIONS,
    ROLES.OUS_CALA,
    ROLES.PRODUCTION,
    ROLES.ENGINEER,
  ],
  closeLot: [
    ROLES.ADMIN,
    ROLES.REGULATORY,
    ROLES.OPERATIONS,
    ROLES.OUS_CALA,
    ROLES.ENGINEER,
  ],
  printLot: [
    ROLES.ADMIN,
    ROLES.REGULATORY,
    ROLES.OPERATIONS,
    ROLES.OUS_CALA,
    ROLES.PRODUCTION,
    ROLES.ENGINEER,
  ],
  assignDevice: [
    ROLES.ADMIN,
    ROLES.CLINICAL_OPERATION,
    ROLES.REGULATORY,
    ROLES.OPERATIONS,
    ROLES.CLINICAL_SCIENCES,
    ROLES.OUS_CALA,
    ROLES.PRODUCTION,
    ROLES.ENGINEER,
  ],
  showOperationsDetails: [
    ROLES.ADMIN,
    ROLES.CLINICAL_OPERATION,
    ROLES.REGULATORY,
    ROLES.OPERATIONS,
    ROLES.CLINICAL_SCIENCES,
    ROLES.OUS_CALA,
    ROLES.ENGINEER,
  ],
  deleteDevicesOpenLot: [
    ROLES.ADMIN,
    ROLES.REGULATORY,
    ROLES.OPERATIONS,
    ROLES.PATIENT,
    ROLES.OUS_CALA,
    ROLES.ENGINEER,
  ],
  showLotDetailsNotes: [
    ROLES.ADMIN,
    ROLES.CLINICAL_OPERATION,
    ROLES.REGULATORY,
    ROLES.OPERATIONS,
    ROLES.CLINICAL_SCIENCES,
    ROLES.PATIENT,
    ROLES.OUS_CALA,
    ROLES.ENGINEER,
  ],
  addDeviceType: [
    ROLES.ADMIN,
    ROLES.OUS_CALA,
    ROLES.REGULATORY,
  ],
};
