import { formSagaRequestWithPayload, sagaRequestWithPayload } from 'components/utils/reduxHelpers';
import {
  REJECT_ORDER as REJECT_ORDER_FORM,
} from 'components/common/forms/names';
import { call, all } from 'redux-saga/effects';
import * as ShipmentsService from '../services/shipmentsService';
import {
  LOAD_ORDERS,
  LOAD_ORDER_INFORMATION,
  ORDER_DETAIL_ACTION,
  ORDER_DETAIL_ACTION_BY_TYPE,
  ORDER_ACTION,
  REJECT_ORDER,
  APPROVE_ORDER,
  FETCH_ORDER_DEVICE_COUNT,
} from '../actions/shipmentsActions';

export function* loadOrders(action) {
  const { status } = action;

  yield* sagaRequestWithPayload(
    LOAD_ORDERS,
    ShipmentsService.loadOrders,
    { status },
  );
}

export function* loadOrderInformation(action) {
  yield* sagaRequestWithPayload(
    LOAD_ORDER_INFORMATION,
    ShipmentsService.loadOrderInformation,
    action,
  );
}

export function* orderDetailAction(action) {
  const { notification, ...rest } = action;

  yield* sagaRequestWithPayload(
    ORDER_DETAIL_ACTION,
    ShipmentsService.orderDetailAction,
    rest,
    notification,
  );
}

export function* orderDetailActionByType(action) {
  yield* sagaRequestWithPayload(
    ORDER_DETAIL_ACTION_BY_TYPE,
    ShipmentsService.orderDetailActionByType,
    action,
  );
}

export function* orderAction(action) {
  const { orderId, status } = action;

  yield* sagaRequestWithPayload(
    ORDER_ACTION,
    ShipmentsService.orderAction,
    { orderId, status },
  );
}

export function* rejectOrder(action) {
  const { orderId, body } = action;

  yield* formSagaRequestWithPayload(
    REJECT_ORDER_FORM,
    REJECT_ORDER,
    ShipmentsService.rejectOrder,
    action.promise,
    { orderId, body },
  );
}

export function* approveOrder(action) {
  yield* sagaRequestWithPayload(
    APPROVE_ORDER,
    ShipmentsService.orderAction,
    action,
  );
}

export function* fetchDeviceCount(action) {
  const { siteId } = action;

  yield* sagaRequestWithPayload(
    FETCH_ORDER_DEVICE_COUNT,
    ShipmentsService.fetchDeviceCount,
    { siteId },
  );
}

export function* fetchMultipleDeviceCount(action) {
  const tasks = [];

  action.sites.forEach(site => {
    tasks.push(call(fetchDeviceCount, {
      siteId: site.siteId,
    }));
  });

  yield all(tasks);
}
