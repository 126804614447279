export const OPEN_DEVICE_FIRMWARE_DIALOG = 'OPEN_DEVICE_FIRMWARE_DIALOG';
export const openFirmwareDeviceDialog = payload => ({
  type: OPEN_DEVICE_FIRMWARE_DIALOG,
  payload,
});

export const CLOSE_DEVICE_FIRMWARE_DIALOG = 'CLOSE_DEVICE_FIRMWARE_DIALOG';
export const closeFirmwareDeviceDialog = () => ({ type: CLOSE_DEVICE_FIRMWARE_DIALOG });

export const OPEN_DEFAULT_FIRMWARE_DIALOG = 'OPEN_DEFAULT_FIRMWARE_DIALOG';
export const openDefaultFirmwareDialog = payload => ({
  type: OPEN_DEFAULT_FIRMWARE_DIALOG,
  payload,
});

export const CLOSE_DEFAULT_FIRMWARE_DIALOG = 'CLOSE_DEFAULT_FIRMWARE_DIALOG';
export const closeDefaultFirmwareDialog = () => ({ type: CLOSE_DEFAULT_FIRMWARE_DIALOG });
