import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

import * as en from 'intl/en';

import styles from './NavigationLinks.styles';

const NavigationLinks = ({ links, classes }) => {
  return (
    <div className={classes.container}>
      {links.map(link => (
        <NavLink
          key={link.url}
          to={link.url}
          className={classes.link}
          activeClassName={classes.linkActive}
          exact
        >
          <FormattedMessage id={link.name} defaultMessage={en[link.name]} />
        </NavLink>
      ))}
    </div>
  );
};

NavigationLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavigationLinks);
