import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import getGlobalStyles from 'components/styles/global.styles';
import * as en from 'intl/en';

import getStyles from './AddDeviceType.styles';
import AddDeviceTypeForm from './AddDeviceTypeForm';

class AddDeviceType extends Component {
  constructor(props) {
    super(props);

    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();
  }

  render() {
    const {
      isModalOpen,
      closeAddType,
      data,
    } = this.props;

    return (
      <Dialog
        id="modal--device-type"
        open={isModalOpen}
        onClose={closeAddType}
        PaperProps={{ style: this.styles.modalBody }}
        style={this.styles.modal}
      >
        <div
          style={{
            ...this.globalStyles.pageHeader,
            ...this.styles.header,
          }}
        >
          <Icon
            className="material-icons"
            style={{
              ...this.globalStyles.pageIcon,
              ...this.styles.pageIcon,
            }}
          >
            add_circle_outline
          </Icon>
          <h1 style={{ ...this.globalStyles.pageTitle }}>
            <FormattedMessage
              id="inventory.deviceTypes.addDeviceType"
              defaultMessage={en.inventory.deviceTypes.addDeviceType}
            />
          </h1>
          <Icon
            className="material-icons"
            style={{ ...this.styles.closeButton, ...this.styles.closeIcon }}
            onClick={closeAddType}
          >
            close
          </Icon>
        </div>
        <div
          id="add-device-type__content"
          style={this.styles.content}
        >
          <AddDeviceTypeForm
            data={data}
            closeDialog={closeAddType}
          />
        </div>
      </Dialog>
    );
  }
}

AddDeviceType.propTypes = {
  isModalOpen: PropTypes.bool,
  closeAddType: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default AddDeviceType;
