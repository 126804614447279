import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer, getResult } from 'components/utils/reduxHelpers';
import {
  GET_POSTAGE_LABEL,
  GET_ORDER_SHIPMENT,
  BUY_PICKUP,
  GET_PICKUP,
} from '../actions/shipmentOrderActions';
import { LOAD_ORDER_INFORMATION } from '../actions/shipmentsActions';

const data = createReducer({}, {
  [`${LOAD_ORDER_INFORMATION}`]: () => ({}),
  [`${GET_ORDER_SHIPMENT}_SUCCESS`]: getResult,
});

const postageLabel = createReducer({}, {
  [`${LOAD_ORDER_INFORMATION}`]: () => ({}),
  [`${GET_POSTAGE_LABEL}_SUCCESS`]: getResult,
});

const pickup = createReducer({}, {
  [`${LOAD_ORDER_INFORMATION}`]: () => ({}),
  [`${BUY_PICKUP}_SUCCESS`]: getResult,
  [`${GET_PICKUP}_SUCCESS`]: getResult,
});

const asyncReducer = combineReducers({
  orderShipment: asyncReducerHelper(GET_ORDER_SHIPMENT),
  postageLabel: asyncReducerHelper(GET_POSTAGE_LABEL),
  buyPickup: asyncReducerHelper(BUY_PICKUP),
  getPickup: asyncReducerHelper(GET_PICKUP),
});

const asyncOverride = (state = {}, action) => {
  if (action.type === LOAD_ORDER_INFORMATION) {
    return {
      orderShipment: {},
      postageLabel: {},
      buyPickup: {},
      getPickup: {},
    };
  }

  return asyncReducer(state, action);
};

const shipmentOrderReducer = combineReducers({
  async: asyncOverride,
  data,
  postageLabel,
  pickup,
});

export default shipmentOrderReducer;
