import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { TableCell, Icon, withStyles } from '@material-ui/core';
import {
  injectIntl,
  intlShape,
} from 'react-intl';
import _ from 'lodash';
import * as en from 'intl/en';

import getGlobalStyles from 'components/styles/global.styles';
import getStyles from './SortableHeaderColumn.styles';

class SortableHeaderColumn extends Component {
  constructor(props) {
    super(props);

    this.styles = getStyles();
    this.globalStyles = getGlobalStyles();

    this.onSortChange = this.onSortChange.bind(this);
    this.setIcon = this.setIcon.bind(this);

    this._direction = 0;
    this.setIcon(this._direction);
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeField } = prevProps;

    if (activeField !== this.props.field) {
      this._direction = 0;
      this.setIcon(this._direction);
    }
    if (activeField === this.props.field && this._direction === 0) {
      this._direction = this.props.sortDirection;
      this.setIcon(this._direction);
    }
  }

  onSortChange() {
    const { onSort, field } = this.props;

    if (this._direction === 0) {
      this._direction = 1;
    } else {
      this._direction = -this._direction;
    }

    this.setIcon(this._direction);
    onSort(field, this._direction);
  }

  setIcon(direction) {
    switch (direction) {
      case 1:
        this._icon = 'arrow_drop_down';
        break;
      case -1:
        this._icon = 'arrow_drop_up';
        break;
      case 0:
      default:
        this._icon = 'swap_vert';
    }
  }

  render() {
    const {
      title,
      id,
      notSortable,
      style,
      intl,
      cellProps,
      classes,
      isLoading,
    } = this.props;

    return (
      <TableCell
        id={id}
        style={style}
        {...cellProps}
      >
        <div style={this.styles.body}>
          <span style={this.styles.title}>
            {
              title
                ? intl.formatMessage({
                  id: title,
                  defaultMessage: _.get(en, title),
                })
                : ''
            }
          </span>
          {(isLoading || !notSortable) &&
            <Icon
              className="btn--sort-column"
              classes={{ root: classes.icon }}
              onClick={this.onSortChange}
            >
              {this._icon}
            </Icon>
          }
        </div>
      </TableCell>
    );
  }
}

SortableHeaderColumn.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  onSort: PropTypes.func,
  title: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  id: PropTypes.string,
  activeField: PropTypes.string,
  notSortable: PropTypes.bool,
  sortDirection: PropTypes.number,
  style: PropTypes.object,
  cellProps: PropTypes.object,
  isLoading: PropTypes.bool,
};

const enhance = compose(injectIntl, withStyles(getStyles));

export default enhance(SortableHeaderColumn);
