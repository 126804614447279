export const LOAD_VERSION = 'LOAD_VERSION';
export const LOAD_VERSION_SUCCESS = 'LOAD_VERSION_SUCCESS';
export const LOAD_VERSION_FAILURE = 'LOAD_VERSION_FAILURE';
export const LOAD_LAMBDA = 'LOAD_LAMBDA';
export const LOAD_LAMBDA_SUCCESS = 'LOAD_LAMBDA_SUCCESS';
export const LOAD_LAMBDA_FAILURE = 'LOAD_LAMBDA_FAILURE';

export function loadVersion() {
  return { type: LOAD_VERSION };
}

export function loadLambdaVersions() {
  return { type: LOAD_LAMBDA };
}
