import _ from 'lodash';
import { combineReducers } from 'redux';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import {
  LOAD_LOTS,
} from '../actions/operationsActions';

const getById = (state, action) => {
  return _.keyBy(action.result.data, o => o.lot_id);
};

const byId = createReducer({}, {
  [`${LOAD_LOTS}_SUCCESS`]: getById,
});

const getList = (state, action) => {
  return action.result.data;
};

const list = createReducer([], {
  LOAD_LOTS: () => [],
  [`${LOAD_LOTS}_SUCCESS`]: getList,
});

const getPaginationData = (state, action) => {
  return {
    pages: action.result.pages,
    total: action.result.total,
  };
};

const data = createReducer({ pages: [], total: 0 }, {
  [`${LOAD_LOTS}_SUCCESS`]: getPaginationData,
});

const asyncReducer = combineReducers({
  lots: asyncReducerHelper(LOAD_LOTS),
});

const lotsReducer = combineReducers({
  async: asyncReducer,
  byId,
  data,
  list,
});

export default lotsReducer;
