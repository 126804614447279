import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { FormattedDate } from 'react-intl';

import styles from './Compliance.styles';

const SessionDate = props => {
  const { classes, date } = props;
  const dayOfMonth = date ? date.split('-')[2] : 'N/A';

  return (
    <td align="middle">
      <div className={classes.dateWrapper}>
        <FormattedDate
          value={date}
          weekday="long"
        />
        {` - (${dayOfMonth})`}
      </div>
    </td>
  );
};

SessionDate.propTypes = {
  classes: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
};

export default withStyles(styles)(SessionDate);
