export default {
  tab: {
    home: 'Home',
    patients: 'Patients list',
    user: 'User management',
    clinic: 'Clinic management',
    clinicalStudy: 'Study management',
    inventory: 'Inventory',
    operations: 'Operations',
    shipments: 'Shipments',
    quarantine: 'Quarantine',
  },
};
