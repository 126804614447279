export default theme => ({
  errorMessage: {
    display: 'block',
    padding: 10,
    color: theme.palette.dangerTextColor,
  },
  tryAgainButton: {
    display: 'block',
    width: 200,
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loadingContainer: {
    width: 40,
    margin: 20,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.mainTheme,
  },
});
