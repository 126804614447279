import auth from 'components/auth/reducers/initialState';
import version from 'components/version/reducers/initialState';
import deviceLogGraph from 'components/graph/reducers/initialState';

import { fetchMessages, getUserLocale } from 'components/utils/i18n';

const locale = getUserLocale();

const intl = {
  defaultLocale: 'en',
  locale,
  messages: fetchMessages(locale),
};

export default {
  intl,
  auth,
  version,
  deviceLogGraph,
};
