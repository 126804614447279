import get from 'lodash/get';

export const OLD_PASSWORD_ERROR = 'OLD_PASSWORD_ERROR';
export const NEW_PASSWORD_ERROR = 'NEW_PASSWORD_ERROR';
export const UNKNOWN_PASSWORD_ERROR = 'UNKNOWN_PASSWORD_ERROR';

export const getPasswordErrorType = error => {
  const message = get(error, 'response.data.message', null);

  if (!message) {
    return UNKNOWN_PASSWORD_ERROR;
  }

  if (message === 'Incorrect username or password.') {
    return OLD_PASSWORD_ERROR;
  }

  return NEW_PASSWORD_ERROR;
};
