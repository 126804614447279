import theme from 'components/styles/default.theme';

export default () => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    SMALL: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  navLink: {
    width: '70%',
    display: 'block',
    float: 'left',
  },
  prefixLegend: {
    container: {
      display: 'block',
      float: 'left',
      width: '350px',
      marginTop: '27px',
      fontSize: '0.8125rem',
    },
    title: {
      color: theme.palette.textColor,
      display: 'block',
      float: 'left',
      marginTop: '3px',
    },
    prefixInfo: {
      padding: '3px',
    },
    content: {
      color: theme.palette.textColor,
      display: 'block',
      float: 'left',
    },
    message: {
      display: 'block',
      float: 'left',
      marginLeft: '7px',
    },
    text: {
      color: theme.palette.highlightTextColor,
      marginLeft: '3px',
      display: 'block',
      float: 'left',
    },
  },
});
