import theme from 'components/styles/default.theme';

const getStyles = () => ({
  padding: 15,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 5,
  marginBottom: theme.spacing(7),
  width: '100%',
  danger: {
    color: '#A94442',
    backgroundColor: '#F2DEDE',
    borderColor: '#EBCCD1',
  },
  success: {
    color: '#3C763D',
    backgroundColor: '#DFF0D8',
    borderColor: '#D6E9C6',
  },
  primary: {
    color: '#588C9C',
    backgroundColor: '#EFF4F5',
    borderColor: '#588C9C',
  },
});

export default getStyles;
