import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import SearchBar from 'components/common/search/SearchBar';
import { intlWord } from 'components/utils/helpers';
import { withTheme, Button } from '@material-ui/core';
import listWrapper from 'components/common/base/ListWrapper';
import PerPage from 'components/common/pagination/PerPage';
import PageSelector from 'components/common/pagination/PageSelector';
import Message from 'components/common/Message';
import permissions from 'components/operations/utils/permissions';
import { isAllowedRole } from 'components/users/utils/userRoles';
import Session from 'components/utils/session';

import CalaTable from 'components/common/calaTable/CalaTable';
import getStyles from './List.styles';
import { loadDevices } from '../../actions/devicesActions';
import {
  getDevicesList,
  getDevicesLoadAsyncIsProcessing,
  getDevicesPages,
} from '../../selectors';
import { deviceStatus } from '../../utils/constants';
import AssignmentDialog from './dialog';

class List extends Component {
  constructor(props) {
    super(props);

    this.styles = getStyles(this.props.theme);

    this.state = {
      selectedDevice: null,
    };

    this.isAllowedRole = isAllowedRole(Session.JWTDecoded.role_id, permissions.assignDevice);

    this.searchFields = [
      { text: intlWord(this.props.intl, 'operations.list.subjectId'), key: 'subject_id' },
      { text: intlWord(this.props.intl, 'operations.list.serialNumber'), key: 'serial_number' },
      { text: intlWord(this.props.intl, 'operations.list.mac'), key: 'mac_address' },
    ];

    this.columns = [
      { title: 'operations.list.serialNumber', key: 'serial_number' },
      { title: 'operations.list.mac', key: 'mac_address' },
      { title: 'operations.list.subjectId', key: 'subject_id', notSortable: true },
      { title: 'operations.list.status', key: 'status' },
      { title: '', key: '', notSortable: true, style: { width: '8%' } },
    ];

    this.dataFields = [
      { key: 'serial_number' },
      { key: 'device_details.mac_address' },
      { key: 'device_details.subject_id' },
      {
        type: 'customRender',
        key: 'status',
        render: row => <Message id={`operations.deviceStatus.${row.status}`} />,
      },
      {
        type: 'customRender',
        key: 'action',
        render: row => {
          const canAssign = (
            row.status !== deviceStatus.Assigned &&
            row.status !== deviceStatus.Lost &&
            row.status !== deviceStatus.Retired
          );
          const canUnassign = row.status === deviceStatus.Assigned;
          const showAction = canAssign || canUnassign;

          if (!showAction || !this.isAllowedRole) {
            return null;
          }

          const renderAssignText = canAssign && (
            <Message id="shared.assign" />
          );
          const renderUnassignText = canUnassign && (
            <Message id="shared.unassign" />
          );
          const updateState = () => (
            this.setState({
              selectedDevice: {
                ...row,
                canAssign,
                canUnassign,
              },
            })
          );

          return (
            <Button
              color="primary"
              onClick={updateState}
            >
              {renderAssignText}
              {renderUnassignText}
            </Button>
          );
        },
      },
    ];
  }

  componentDidMount() {
    const {
      _loadDevices,
      setPagination,
      setSort,
      setUpdateListFunction,
    } = this.props;

    _loadDevices();
    setPagination({ perPage: 25, selectedPage: 1 });
    setSort({ key: 'created_date', direction: -1 });
    setUpdateListFunction(this.sortList);
  }

  sortList = (sort, pagination, criteria) => {
    this.props._loadDevices(
      pagination.selectedPage,
      pagination.perPage,
      sort.key,
      sort.direction,
      criteria,
    );
  }

  closeDialog = () => this.setState({ selectedDevice: null });

  render() {
    const {
      pagination,
      sort,
      perPageChange,
      selectedPageChange,
      searchChange,
      sortChange,
      devices,
      operations,
      isProcessing,
      pages,
      prefixList,
    } = this.props;
    const { selectedDevice } = this.state;
    const numberOfPages = pages.length;

    return (
      <div
        style={this.styles.container}
      >
        <AssignmentDialog
          selectedDevice={selectedDevice}
          prefixList={prefixList}
          closeDialog={this.closeDialog}
        />
        <SearchBar
          onSearch={searchChange}
          fields={this.searchFields}
          hideCalendar
        />
        <div
          style={this.styles.paginationContainer}
        >
          <PerPage
            perPage={pagination.perPage}
            selectedPage={pagination.selectedPage}
            perPageChange={perPageChange}
            numberOfPages={numberOfPages}
            numberOfRecords={operations.lots.data.total}
            style={this.styles.perPage}
          />
          <PageSelector
            selectedPage={pagination.selectedPage}
            selectedPageChange={selectedPageChange}
            numberOfPages={numberOfPages}
            position="topRight"
          />
        </div>
        <div
          style={this.styles.tableWrapper}
        >
          <CalaTable
            rowKey="device_inventory_id"
            id="table--device-assignment"
            data={devices}
            dataFields={this.dataFields}
            columns={this.columns}
            activeField={sort.key}
            onSort={sortChange}
            sortDirection={sort.direction}
            isLoading={isProcessing}
            notSortable={devices.length === 0}
          />
          {
            devices.length > 0 &&
            <PageSelector
              selectedPage={pagination.selectedPage}
              selectedPageChange={selectedPageChange}
              numberOfPages={numberOfPages}
            />
          }
        </div>
      </div>
    );
  }
}

List.propTypes = {
  theme: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  operations: PropTypes.object.isRequired,
  _loadDevices: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  sortChange: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  selectedPageChange: PropTypes.func.isRequired,
  perPageChange: PropTypes.func.isRequired,
  setUpdateListFunction: PropTypes.func.isRequired,
  searchChange: PropTypes.func.isRequired,
  devices: PropTypes.array.isRequired,
  prefixList: PropTypes.array.isRequired,
  isProcessing: PropTypes.bool,
  pages: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  operations: state.operations,
  devices: getDevicesList(state),
  isProcessing: getDevicesLoadAsyncIsProcessing(state),
  pages: getDevicesPages(state),
});

const mapDispatchToProps = dispatch => ({
  _loadDevices: (selectedPage, perPage, key, direction, criteria) => {
    dispatch(loadDevices(selectedPage, perPage, key, direction, criteria));
  },
});

const enhance = compose(
  injectIntl,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
  listWrapper({ displayName: 'DevicesList' }),
);

export default enhance(List);
