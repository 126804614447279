/* eslint max-len: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/core';

const KeyIcon = props => {
  const { isEnabled, isError, ...otherProps } = props;
  const enabledKeyIcon = (
    <SvgIcon
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <path
        d="M3.75,18H0V14.25L5.656,8.594a6.4,6.4,0,1,1,3.75,3.75L8.25,13.5H6v2.25H3.75V18ZM11.625,4.5A1.875,1.875,0,1,0,13.5,6.375,1.877,1.877,0,0,0,11.625,4.5Z"
        transform="translate(2 2)"
        fill="#189aca"
      />
    </SvgIcon>
  );

  const errorKeyIcon = (
    <SvgIcon
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <path
        d="M3.75,18H0V14.25L5.656,8.594a6.4,6.4,0,1,1,3.75,3.75L8.25,13.5H6v2.25H3.75V18ZM11.625,4.5A1.875,1.875,0,1,0,13.5,6.375,1.877,1.877,0,0,0,11.625,4.5Z"
        transform="translate(2 2)"
        fill="#ee3755"
      />
    </SvgIcon>
  );

  const keyIcon = (
    <SvgIcon
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <path
        d="M3.75,18H0V14.25L5.656,8.594a6.4,6.4,0,1,1,3.75,3.75L8.25,13.5H6v2.25H3.75V18ZM11.625,4.5A1.875,1.875,0,1,0,13.5,6.375,1.877,1.877,0,0,0,11.625,4.5Z"
        transform="translate(2 2)"
        fill="#d1d2d2"
      />
    </SvgIcon>
  );

  switch (true) {
    case isEnabled: {
      return enabledKeyIcon;
    }

    case isError: {
      return errorKeyIcon;
    }

    default: {
      return keyIcon;
    }
  }
};

KeyIcon.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
};

export default withTheme(KeyIcon);
