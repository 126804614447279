import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  Table,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import SortableHeaderColumn from '../SortableHeaderColumn';
import getStyles from './CalaTable.styles';
import CalaTableBody from './CalaTableBody';

const CalaTable = props => {
  const {
    theme,
    id,
    columns,
    activeField,
    sortDirection,
    onSort,
    data,
    dataFields,
    rowKey,
    isLoading,
    notSortable,
  } = props;
  const styles = getStyles(theme);

  return (
    <Table
      id={id}
    >
      <TableHead>
        <TableRow style={styles.table.headerRow}>
          {columns.map(field =>
          (<SortableHeaderColumn
            id={`column--${field.key}`}
            title={field.title}
            field={field.key}
            key={field.key}
            notSortable={notSortable}
            activeField={activeField}
            onSort={onSort}
            sortDirection={sortDirection}
            style={field.style}
            isLoading={isLoading}
          />))}
        </TableRow>
      </TableHead>
      <CalaTableBody
        data={data}
        dataFields={dataFields}
        rowKey={rowKey}
        theme={theme}
        isLoading={isLoading}
        styles={styles}
      />
    </Table>
  );
};

CalaTable.propTypes = {
  theme: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  activeField: PropTypes.string,
  sortDirection: PropTypes.number,
  onSort: PropTypes.func,
  data: PropTypes.array.isRequired,
  dataFields: PropTypes.array.isRequired,
  rowKey: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  notSortable: PropTypes.bool,
};

const enhance = compose(withTheme);

export default enhance(CalaTable);
