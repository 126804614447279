const getStyles = theme => ({
  container: {
    width: '100%',
  },
  tableWrapper: {
    position: 'relative',
  },
  perPage: {
    paddingLeft: theme.padding.pageSmall,
  },
  paginationContainer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default getStyles;
