import { combineReducers } from 'redux';
import _ from 'lodash';
import { asyncReducerHelper, createReducer } from 'components/utils/reduxHelpers';
import { FETCH_SKU_LIST, ADD_SKU } from '../actions/inventoryActions';

export const DEFAULT_STATE = {};

export const bySku = createReducer(DEFAULT_STATE, {
  [`${FETCH_SKU_LIST}_SUCCESS`]: (state, action) => _.keyBy(action.result, o => o.sku),
  [`${ADD_SKU}_SUCCESS`]: (state, action) => ({ ...state, [action.result.sku]: action.result }),
});

export const loadInfo = asyncReducerHelper(FETCH_SKU_LIST);

const skuReducer = combineReducers({
  loadInfo,
  bySku,
});

export default skuReducer;
