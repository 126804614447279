import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Icon from '@material-ui/core/Icon';
import { intlShape, FormattedMessage } from 'react-intl';
import _ from 'lodash';

import * as en from 'intl/en';
import getStyles from './ImageSelector.styles';

/**
 * Re-usable component representing drop zone for image uploads
 */
class ImageSelector extends Component {
  /**
   * Create an ImageSelector component
   * @constructor
   * @param {Object} props Properties passed to the ImageSelector
   */
  constructor(props) {
    /**
     * Component properties
     * @name props
     * @memberOf ImageSelector
     * @instance
     * @type {Object}
     * @property {Func} onSelect Called when the image is selected
     */
    super(props);

    this.onDrop = this.onDrop.bind(this);
  }

  /**
   * Called when the images are selected via the DropZone
   * @param  {Array} files Array of selected files
   * @return {void}
   */
  onDrop(files) {
    const { onSelect } = this.props;

    onSelect(files[0]);
  }

  render() {
    const { intl, id, preloadData } = this.props;
    const styles = getStyles();

    let preloadImg = null;

    if (_.get(preloadData, 'preview')) {
      preloadImg = preloadData.preview;
    } else if (preloadData) {
      preloadImg = `data:image/jpeg;base64, ${preloadData}`;
    }

    return (
            <div
              id={id}
              style={styles.container}
            >
                <Dropzone
                  accept="image/*"
                  multiple={false}
                  style={styles.zone}
                  onDrop={this.onDrop}
                >
                    {!(preloadImg) &&
            <div style={styles.title}>
                <Icon
                  className="material-icons"
                  style={styles.title.icon}
                >panorama
                </Icon>
                <div style={styles.title.dnd}>
                    <FormattedMessage
                      id="common.ImageSelector.dragAndDrop"
                      defaultMessage={en.common.ImageSelector.dragAndDrop}
                    />
                </div>
                <div style={styles.title.browse}>
                    <FormattedMessage
                      id="common.ImageSelector.chooseAFile"
                      defaultMessage={en.common.ImageSelector.chooseAFile}
                    />
                </div>
            </div>
                    }
                </Dropzone>
                <div
                  id="selector__preview"
                  style={styles.preview}
                >
                    {(preloadImg) &&
                      <img
                        style={styles.preview.img}
                        src={preloadImg}
                        alt={
                          intl.formatMessage({
                            id: 'common.ImageSelector.preview',
                            defaultMessage: en.common.ImageSelector.preview,
                          })
                        }
                      />
                    }
                </div>
            </div>
    );
  }
}

/**
 * Component properties validation
 * @memberOf ImageSelector
 * @type {Object}
 * @static
 */
ImageSelector.propTypes = {
  intl: intlShape.isRequired,
  preloadData: PropTypes.any,
  onSelect: PropTypes.func,
  id: PropTypes.string,
};

export default ImageSelector;
