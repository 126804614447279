export default {
  deviceId: 'Device ID',
  deviceLogId: 'Device Log ID',
  uploadDate: 'Upload Date',
  mac: 'MAC',
  serialNumber: 'Serial Number',
  patientId: 'Patient ID',
  eventCode: 'Event Code',
  eventDescription: 'Event Description',
  subjectId: 'Subject ID',
  firmware: 'Firmware',
  logType: 'Log Type',
  errorCode: 'Error Code',
  errorDescription: 'Error Description',
  modemIccid: 'ICCID',
  modemImei: 'IMEI',
  tla: 'TLA',
  showOrHide: 'Show or hide columns',
  done: 'Done',
};
