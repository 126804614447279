/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AvatarIcon = props => (
    <SvgIcon
      {...props}
      viewBox="0 0 110 111"
      fontSize="inherit"
    >
      <defs>
        <clipPath id="a">
          <path
            data-name="Path 65"
            d="M66.8 81.1a86.77 86.77 0 0 1-.2-10.1c1.7-.9 4.9-6.8 5.4-11.7 1.4-.1 3.5-1.4 4.2-6.7.3-2.8-1-4.4-1.9-4.9 2.2-6.7 6.9-27.5-8.6-29.7-1.6-2.8-5.7-4.2-11-4.2-21.2.4-23.8 16-19.1 33.9a5.04 5.04 0 0 0-1.9 4.9c.6 5.3 2.8 6.6 4.2 6.7.5 4.9 3.8 10.8 5.5 11.7 0 3.5.1 6.2-.2 10.1-4.1 11.3-32.4 8.1-33.7 29.9h91C99.2 89.2 71 92.4 66.8 81.1z"
          />
        </clipPath>
      </defs>
      <g data-name="Group 265">
        <circle
          data-name="Ellipse 23"
          cx="55"
          cy="55"
          r="55"
          fill="#fff"
        />
      </g>
      <g data-name="Group 267">
        <g
          data-name="Group 266"
          clipPath="url(#a)"
        >
          <circle
            data-name="Ellipse 24"
            cx="55"
            cy="55"
            r="55"
            fill="#eaeff2"
          />
        </g>
      </g>
    </SvgIcon>
);

export default AvatarIcon;
