import React from 'react';
import PropTypes from 'prop-types';
import NavigationLinks from 'components/common/NavigationLinks';

const LINKS = [
  { url: '/operations', name: 'OperationsDFU.lotManagement' },
  { url: '/operations/assign', name: 'OperationsDFU.deviceAssignment' },
];

const OperationsNavigationLinks = ({ style }) => {
  return <div style={style}><NavigationLinks links={LINKS} /></div>;
};

OperationsNavigationLinks.propTypes = {
  style: PropTypes.shape({}),
};

OperationsNavigationLinks.defaultProps = {
  style: {},
};

export default OperationsNavigationLinks;
