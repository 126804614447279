import axios from 'axios';
import moment from 'moment';
import TokenCall from 'components/auth/services/token';
import Config from 'config';
import { getResponseData } from 'components/utils/asyncHelpers';

const {
  INVENTORY_API_ROOT,
  DEVICE_API_ROOT,
} = Config;
const { CancelToken } = axios;

let cancelSource;

export const loadLots = async({
  selectedPage = 1, perPage = 25,
  sortField = 'created_date', sortType = -1, criteria = {},
}) => {
  const headers = {
    'X-Per-Page': perPage,
    'X-Page': selectedPage,
    'X-SortField': sortField,
    'X-SortType': sortType === 1 ? 'asc' : 'desc',
  };

  if (criteria.terms && criteria.terms !== '') {
    headers['X-SearchTerms'] = criteria.terms;
  }
  if ((criteria.dateRange || {}).startDate) {
    headers['X-From'] = criteria.dateRange.startDate;
    headers['X-To'] = criteria.dateRange.endDate;
  }

  if (cancelSource) {
    cancelSource.cancel();
  }

  cancelSource = CancelToken.source();
  const response = await TokenCall.get(`${INVENTORY_API_ROOT}/v1/lots`, {
    cancelToken: cancelSource.token,
    headers,
  });

  return response.data;
};

export const loadLotDetails = async({ lotId }) => {
  const response = await TokenCall.get(`${INVENTORY_API_ROOT}/v0/lots/${lotId}`);

  return response.data;
};

export const createLot = ({ lot }) =>
  getResponseData('post', `${INVENTORY_API_ROOT}/v0/lots`, lot);

export const closeLot = ({ lotId, devices }) => {
  const data = {
    device_inventory_ids: devices,
  };

  return getResponseData('put', `${INVENTORY_API_ROOT}/v0/lots/${lotId}/close`, data);
};

export const openLot = ({ lotId }) =>
  getResponseData('put', `${INVENTORY_API_ROOT}/v0/lots/${lotId}/open`);

export const loadUnassignedDevices = ({ lotId }) =>
  getResponseData('get', `${INVENTORY_API_ROOT}/v0/lots/${lotId}/devices/unassigned`);

export const deleteUnassignedDevice = ({ inventoryId }) =>
  getResponseData('delete', `${INVENTORY_API_ROOT}/v0/device_inventory/${inventoryId}`);

export const toggleDevice = ({ deviceInventoryId, body }) =>
  getResponseData('patch', `${INVENTORY_API_ROOT}/v0/device_inventory/${deviceInventoryId}`, body);

export const publishFirmware = ({ firmware }) => {
  const data = new FormData();

  Object.keys(firmware).forEach(key => data.append(key, firmware[key]));

  return getResponseData('post', `${DEVICE_API_ROOT}/v0/firmware`, data);
};

export const loadFirmwares = () => getResponseData('get', `${DEVICE_API_ROOT}/v0/firmware`);

export const updateFirmware = ({ firmware }) =>
  getResponseData('put', `${DEVICE_API_ROOT}/v0/firmware/${firmware.id}`, firmware);

export const updateFirmwareArchive = ({ firmware }) =>
  getResponseData('put', `${DEVICE_API_ROOT}/v0/firmware/${firmware.id}`, firmware);

export const defaultFirmware = firmware =>
  getResponseData('put', `${DEVICE_API_ROOT}/v0/firmware/${firmware.id}/default`, firmware);

export const loadFirmwareDevices = () => getResponseData('get', `${DEVICE_API_ROOT}/v0/firmware/devices`);

const deviceFirmwareUrl = ({ firmwareId, serialNumber }) =>
  `${DEVICE_API_ROOT}/v0/firmware/${firmwareId}/devices/${serialNumber}`;

export const assignFirmware = ({ deviceFirmware }) => getResponseData('put', deviceFirmwareUrl(deviceFirmware));

export const unassignFirmware = ({ deviceFirmware }) => getResponseData('delete', deviceFirmwareUrl(deviceFirmware));

export const loadDevices = ({
  selectedPage = 1, perPage = 25,
  sortField = 'created_at', sortType = -1, criteria = {},
}) => {
  const headers = {
    'X-Per-Page': perPage,
    'X-Page': selectedPage,
    'X-SortField': sortField,
    'X-SortType': sortType === 1 ? 'asc' : 'desc',
  };

  if (criteria.terms && criteria.terms !== '') {
    headers['X-SearchTerms'] = criteria.terms;
  }
  if ((criteria.dateRange || {}).startDate) {
    headers['X-From'] = criteria.dateRange.startDate;
    headers['X-To'] = criteria.dateRange.endDate;
  }

  if (cancelSource) {
    cancelSource.cancel();
  }

  cancelSource = CancelToken.source();

  return getResponseData('get', `${INVENTORY_API_ROOT}/v1/device_inventory`, {
    cancelToken: cancelSource.token,
    headers,
  });
};

export const assignDevice = ({ data }) => {
  const { serial_number, prefix, brighttree_id, assign_date } = data;

  const date = moment.utc(assign_date).format();
  const subjectId = prefix.concat(brighttree_id).trim();

  return getResponseData('post', `${INVENTORY_API_ROOT}/v1/device_inventory/assign`, {
    serial_number,
    brighttree_id: subjectId,
    assign_date: date,
  });
};

export const unassignDevice = ({ data }) => {
  const { brighttree_id, serial_number, unassign_date } = data;
  const today = moment();

  const date = today.format('MM/DD/YYYY') === unassign_date ?
    moment(today).utc().format() :
    moment.utc(unassign_date).add(86399, 'seconds').format();

  return getResponseData('post', `${INVENTORY_API_ROOT}/v1/device_inventory/unassign`, {
    brighttree_id,
    serial_number,
    unassign_date: date,
  });
};

export const downloadFirmware = async id => {
  try {
    const response = await TokenCall.get(`${DEVICE_API_ROOT}/v0/firmware/${id}/download`);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const loadPrefix = () => {
  const response = {
    data: [
      {
        id: 1,
        prefix: 'BT-',
        name: 'Comercial',
        description: 'comercial description',
      },
      {
        id: 2,
        prefix: 'RD-',
        name: 'Test',
        description: 'test description',
      },
    ],
  };

  return response.data;
};

export const exportFirmwareList = ({ deviceType }) => {
  const type = {
    device_type: deviceType,
  };

  return getResponseData('post', `${DEVICE_API_ROOT}/v0/report/devicefirmware`, type);
};
