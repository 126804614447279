import theme from 'components/styles/default.theme';

const getStyles = () => ({
  modal: {
    paddingTop: '-200px',
    zIndex: 999,
  },
  modalBody: {
    width: '768px',
    maxWidth: '768px',
  },
  header: {
    height: 'auto',
    maxHeight: '50px',
  },
  closeIcon: {
    color: 'white',
    background: '#C2C2C2',
    borderRadius: '50px',
  },
  closeButton: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  content: {
    margin: '25px 35px',
  },
  containerDivider: {
    borderBottom: `1px solid ${theme.palette.borderColor1}`,
    borderTop: `1px solid ${theme.palette.borderColor1}`,
    background: theme.palette.secondaryBackgroundColor,
    color: theme.palette.textColor,
    padding: '10px 30px',
  },
  pageIcon: {
    margin: '20px 0 0 0',
  },
  checkbox: {
    borderBottom: '1px solid lightgray',
    marginBottom: 5,
    padding: 5,
    iconStyle: {
      fill: theme.palette.highlightTextColor,
    },
  },
  checkboxContainer: {
    maxHeight: 200,
    overflow: 'auto',
    marginBottom: 10,
    border: 'solid 1px lightgray',
    padding: '5px 10px',
  },
});

export default getStyles;
