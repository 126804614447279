export default {
  createNewLot: 'Create New Lot',
  createLot: 'Create Lot',
  lotNumber: 'Lot Number',
  selectDeviceType: 'Select Device Type',
  createLotSuccess: 'Lot created successfully',
  createLotError: 'Error creating lot',
  selectSku: 'Select SKU',
  publishDFUSuccess: 'New firmware version successfully published',
  publishDFUFailure: 'Unable to upload new firmware version',
  publishDFU: 'Publish firmware',
  firmwareVersion: 'Firmware version',
  note: 'Note',
  deviceType: 'Device type',
  stim: 'Stim device',
  baseStation: 'Basestation',
  chooseFile: 'Choose a file',
  addDeviceToFirmware: 'Assign',
  removeDeviceToFirmware: 'Set to default',
  serialNumber: 'Device serial number',
  deviceFirmwareChangeSuccess: 'Device firmware successfully changed',
  deviceFirmwareChangeFailure: 'Device firmware not set',
  firmwareQuotaChangeSuccess: 'Release per day limit updated successfully',
  firmwareQuotaChangeFailure: 'Unable to update firmware release per day limit',
  saveFirmwareQuota: 'Save',
  releasePerDay: 'Units to be released per day',
  invalidFirmwareVersion: 'Please enter valid firmware version. Firmware version should be in form of xx.xx.xx',
  number: 'Each part of the firmware version should be provided with a number.',
  range: 'Values should be in the range from {min} to {max}.',
  invalidVersionNumber: 'Firmware version does not support all {number}.',
  assignDevice: 'Assign device',
  unassignDevice: 'Unassign device',
  assignDeviceSuccess: 'Device assigned',
  assignDeviceError: 'Device could not be assigned',
  brightreeId: 'Brightree ID',
  subjectId: 'Subject ID',
  assignDate: 'Assign date',
  unassignDate: 'Unassign date',
  unassignDeviceSuccess: 'Device unassigned',
  unassignDeviceError: 'Device could not be unassigned',
  prefix: 'prefix',
};
