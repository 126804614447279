export const CREATE_SHIPMENT_ORDER = 'CREATE_SHIPMENT_ORDER';
export const CREATE_HAND_DELIVERY = 'CREATE_HAND_DELIVERY';
export const DELETE_HAND_DELIVERY = 'DELETE_HAND_DELIVERY';
export const FETCH_RATES = 'FETCH_RATES';
export const REGENERATE_RATES = 'REGENERATE_RATES';
export const BUY_DELIVERY = 'BUY_DELIVERY';
export const GET_POSTAGE_LABEL = 'GET_POSTAGE_LABEL';
export const GET_ORDER_SHIPMENT = 'GET_ORDER_SHIPMENT';
export const GET_PICKUP = 'GET_PICKUP';
export const BUY_PICKUP = 'BUY_PICKUP';

export const createShipmentOrder = (orderId, body, promise) => {
  return {
    type: CREATE_SHIPMENT_ORDER,
    orderId,
    body,
    promise,
  };
};

export const createHandDelivery = (orderId, body, promise) => {
  return {
    type: CREATE_HAND_DELIVERY,
    orderId,
    body,
    promise,
  };
};

export const deleteHandDelivery = orderId => {
  return {
    type: DELETE_HAND_DELIVERY,
    orderId,
  };
};

export const fetchRates = orderId => {
  return {
    type: FETCH_RATES,
    orderId,
  };
};

export const regenerateRates = orderId => {
  return {
    type: REGENERATE_RATES,
    orderId,
  };
};

export const buyDelivery = (orderId, body, promise) => {
  return {
    type: BUY_DELIVERY,
    promise,
    orderId,
    body,
  };
};

export const getPostageLabel = orderId => {
  return {
    type: GET_POSTAGE_LABEL,
    orderId,
  };
};

export const getOrderShipment = orderId => {
  return {
    type: GET_ORDER_SHIPMENT,
    orderId,
  };
};

export const buyPickup = (orderId, body, promise) => {
  return {
    type: BUY_PICKUP,
    orderId,
    body,
    promise,
  };
};

export const getPickup = orderId => {
  return {
    type: GET_PICKUP,
    orderId,
  };
};
