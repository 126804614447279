export default {
  listTitle: 'List of shipments',
  requested: 'Requested',
  rejected: 'Rejected',
  pendingApproval: 'Pending',
  approved: 'Approved',
  shipped: 'Shipped',
  received: 'Received',
  'pending%20approval': 'Pending',
};
