/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DoubleCheckIcon = props => (
    <SvgIcon
      style={{
        fill: 'none',
        stroke: '#474747',
        strokeLinecap: 'square',
        strokeMiterlimit: 10,
      }}
      {...props}
      viewBox="0 0 21.412 13.445"
    >
      <g id="check-double" transform="translate(-1.293 -4.295)">
        <path
          id="Path_273"
          data-name="Path 273"
          className="double-check-1"
          d="M2,13l4,4L16,5"
        />
        <path
          id="Path_274"
          data-name="Path 274"
          className="double-check-1"
          d="M11,16l1,1L22,5"
        />
      </g>
    </SvgIcon>
);

export default DoubleCheckIcon;
