import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { RadioGroup as MuiRadioGroup, FormHelperText } from '@material-ui/core';
import { Field } from 'redux-form';
import Header from 'components/common/Header';
import * as en from 'intl/en';

const RadioGroupField = ({ input, label, intl, meta: { touched, error }, ...props }) => {
  return (
    <Fragment>
      <Header title={label} />
      {
        touched && error &&
        <FormHelperText error>
          {intl.formatMessage({ id: error.message, defaultMessage: en[error.message]}, error.values)}
        </FormHelperText>
      }
      <MuiRadioGroup
        {...input}
        {...props}
        role="radiogroup"
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      />
    </Fragment>
  );
};

RadioGroupField.propTypes = {
  intl: intlShape.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
};

const RadioGroup = ({ name, validate, label, intl, ...props }) => (
  <Field
    name={name}
    component={RadioGroupField}
    validate={validate}
    label={label}
    intl={intl}
    {...props}
  />
);

RadioGroup.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.any,
};

export default injectIntl(RadioGroup);
