import React from 'react';
import PropTypes from 'prop-types';

const PrintStim = props => {
  const { style, lotDetails } = props;

  return (
    lotDetails.devices.map((row, index) => {
      return (
        <tr key={row.device_inventory_id}>
          <td style={{ ...style }}>{index + 1}</td>
          <td style={{ ...style }}>{row.serial_number}</td>
          <td style={{ ...style }}>{row.device_details.mac_address}</td>
          <td style={{ ...style }}>{row.device_details.psoc}</td>
          <td style={{ ...style }}>{row.device_details.nrf}</td>
          <td style={{ ...style }}>{row.device_details.glyph}</td>
        </tr>
      );
    })
  );
};

PrintStim.propTypes = {
  style: PropTypes.object.isRequired,
  lotDetails: PropTypes.object.isRequired,
};

export default PrintStim;
