import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape, FormattedDate } from 'react-intl';
import * as en from 'intl/en';
import * as _ from 'lodash';
import moment from 'moment';

import getStyles from './GraphHeader.styles';

const GraphHeader = props => {
  const styles = getStyles();
  const { intl } = props;

  let { deviceLog } = props;

  deviceLog = deviceLog || { device: {} };

  return (
    <div style={styles.table}>
      <div style={styles.heading}>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
          {intl.formatMessage({
            id: 'graph.header.deviceLogId',
            defaultMessage: en.graph.header.deviceLogId,
          })}
        </div>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
          {intl.formatMessage({
            id: 'graph.header.mac',
            defaultMessage: en.graph.header.mac,
          })}
        </div>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
          {intl.formatMessage({
            id: 'graph.header.serialNumber',
            defaultMessage: en.graph.header.serialNumber,
          })}
        </div>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
          {intl.formatMessage({
            id: 'graph.header.downloadTime',
            defaultMessage: en.graph.header.downloadTime,
          })}
        </div>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
          {intl.formatMessage({
            id: 'graph.header.subjectId',
            defaultMessage: en.graph.header.subjectId,
          })}
        </div>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
          {intl.formatMessage({
            id: 'graph.header.handed',
            defaultMessage: en.graph.header.handed,
          })}
        </div>
        <div style={styles.cell}>
          {intl.formatMessage({
            id: 'graph.header.bandSize',
            defaultMessage: en.graph.header.bandSize,
          })}
        </div>
      </div>
      <div style={styles.row}>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
              {
                deviceLog.device_log_id ||
                intl.formatMessage({
                  id: 'graph.common.na',
                  defaultMessage: en.graph.common.na,
                })
              }
        </div>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
            {
              deviceLog.device.mac ||
              intl.formatMessage({
                id: 'graph.common.na',
                defaultMessage: en.graph.common.na,
              })
            }
        </div>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
            {
              deviceLog.device.serial_number ||
              intl.formatMessage({
                id: 'graph.common.na',
                defaultMessage: en.graph.common.na,
              })
            }
        </div>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
          <FormattedDate
            value={moment.utc(deviceLog.created_at).local()}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          />
        </div>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
            {
              _.get(deviceLog, 'subject_id') ||
              intl.formatMessage({
                id: 'graph.common.na',
                defaultMessage: en.graph.common.na,
              })
            }
        </div>
        <div style={{ ...styles.cell, ...styles.cellBorder}}>
            {
              deviceLog.device.handedness ||
              intl.formatMessage({
                id: 'graph.common.na',
                defaultMessage: en.graph.common.na,
              })
            }
        </div>
        <div style={{ ...styles.cell }}>
            {
              deviceLog.device.wrist_band_size ||
              intl.formatMessage({
                id: 'graph.common.na',
                defaultMessage: en.graph.common.na,
              })
            }
        </div>
      </div>
    </div>
  );
};

GraphHeader.propTypes = {
  intl: intlShape,
  deviceLog: PropTypes.object,
};

const enhance = compose(injectIntl);

export default enhance(GraphHeader);
