import React from 'react';
import PropTypes from 'prop-types';
import
{
  TableCell,
} from '@material-ui/core';

const PatientCell = props => {
  return (
    <TableCell>
      {props.children || '-'}
    </TableCell>
  );
};

PatientCell.propTypes = {
  children: PropTypes.any,
};

export default PatientCell;
