import { ROLES } from 'components/users/utils/userRoles';

export default {
  showPatientList: [
    ROLES.ADMIN,
    ROLES.CLINICAL_OPERATION,
    ROLES.REGULATORY,
    ROLES.OPERATIONS,
    ROLES.CLINICAL_SCIENCES,
    ROLES.OUS_CALA,
    ROLES.ENGINEER,
  ],
};
