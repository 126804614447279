import list from './en/list';
import exportList from './en/exportList';
import details from './en/details';
import form from './en/form';
import deviceStatus from './en/deviceStatus';
import dfu from './en/dfu';
import confirmationDialog from './en/confirmationDialog';

export default {
  list,
  exportList,
  details,
  form,
  deviceStatus,
  dfu,
  confirmationDialog,
};
