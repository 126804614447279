import { orderStatuses } from '../utils/constants';

export const LOAD_ORDERS = 'LOAD_ORDERS';
export const LOAD_ORDER_INFORMATION = 'LOAD_ORDER_INFORMATION';
export const ORDER_DETAIL_ACTION = 'ORDER_DETAIL_ACTION';
export const ORDER_DETAIL_ACTION_BY_TYPE = 'ORDER_DETAIL_ACTION_BY_TYPE';
export const ORDER_ACTION = 'ORDER_ACTION';
export const REJECT_ORDER = 'REJECT_ORDER';
export const APPROVE_ORDER = 'APPROVE_ORDER';
export const FETCH_ORDER_DEVICE_COUNT = 'FETCH_ORDER_DEVICE_COUNT';
export const FETCH_MULTIPLE_ORDER_DEVICE_COUNT = 'FETCH_MULTIPLE_ORDER_DEVICE_COUNT';

export const loadOrders = status => {
  return {
    type: LOAD_ORDERS,
    status,
  };
};

export const loadOrderInformation = orderId => {
  return {
    type: LOAD_ORDER_INFORMATION,
    orderId,
  };
};

export const orderDetailAction = (orderId, orderDetailId, status, notification) => {
  return {
    type: ORDER_DETAIL_ACTION,
    orderId,
    orderDetailId,
    status,
    notification,
  };
};

export const orderDetailActionByType = (orderId, deviceTypeId, status) => {
  return {
    type: ORDER_DETAIL_ACTION_BY_TYPE,
    orderId,
    deviceTypeId,
    status,
  };
};

export const orderAction = (orderId, status) => {
  return {
    type: ORDER_ACTION,
    orderId,
    status,
  };
};

export const rejectOrder = (orderId, body, promise) => {
  return {
    type: REJECT_ORDER,
    orderId,
    body,
    promise,
  };
};

export const approveOrder = orderId => {
  return {
    type: APPROVE_ORDER,
    orderId,
    status: orderStatuses.approveAndReceive,
  };
};

export const fetchDeviceCount = siteId => {
  return {
    type: FETCH_ORDER_DEVICE_COUNT,
    siteId,
  };
};

export function fetchMultipleDeviceCount(sites) {
  return {
    type: FETCH_MULTIPLE_ORDER_DEVICE_COUNT,
    sites,
  };
}
