import _ from 'lodash';
import { createSelector } from 'reselect';

export const getDeviceTypesById = state => state.inventory.deviceType.byId;

export const getDeviceProperty = (state, deviceTypeId, prop) => (
  _.get(state.inventory.deviceType.byId, `[${deviceTypeId}][${prop}]`, 'N/A')
);

export const getSortedDeviceTypes = createSelector(
  getDeviceTypesById,
  byId => {
    const unsortedList = _.toArray(byId);

    return _.sortBy(unsortedList, ['device_commercial_name']);
  },
);

export const getShouldLoadDeviceTypes = state => (
  !state.inventory.deviceType.async.load.isSuccess && !state.inventory.deviceType.async.load.isProcessing
);

export const getDeviceTypeById = (state, props) => (
  _.get(state.inventory.deviceType.byId, props.deviceTypeId, {})
);

export const getCommercialName = (state, props) => (
  _.get(state.inventory.deviceType.byId, `${props.deviceTypeId}.device_commercial_name`, 'N/A')
);

export const getDeviceTypesWithSku = createSelector(
  getDeviceTypesById,
  byId => {
    const unsortedList = _.map(byId, (value, key) => (
      { device_type_id: Number(key), sku_number: `${value.sku_number} ${value.device_code_name}`}
    ));

    return _.orderBy(unsortedList, type => type.sku_number);
  },
);
