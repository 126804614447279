import PropTypes from 'prop-types';

export const deviceLogsShape = PropTypes.shape({
  logs: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      device_log_id: PropTypes.number,
      device_id: PropTypes.string,
      log_type: PropTypes.string,
      file_link: PropTypes.string,
      upload_date: PropTypes.string,
      patient_id: PropTypes.string,
      firmware_version: PropTypes.string,
      clinic_id: PropTypes.string,
    })),
    pages: PropTypes.arrayOf(PropTypes.string),
  }),
  isLoading: PropTypes.bool,
});

export const profileInfoShape = PropTypes.shape({
  address: PropTypes.string,
  clinics: PropTypes.string,
  devices: PropTypes.string,
  email: PropTypes.string,
  email_verified: PropTypes.bool,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  phone: PropTypes.string,
  pk: PropTypes.string,
  role_id: PropTypes.number,
  username: PropTypes.string,
});
