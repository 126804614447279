import { createSelector } from 'reselect';
import { getFullName } from 'components/utils/helpers';

export const getIsLoadInfoClean = state => (
  !state.profile.async.loadInfo.isProcessing
  && !state.profile.async.loadInfo.isSuccess
  && !state.profile.async.loadInfo.error
);

export const getShouldLoadProfile = state => (
  !state.profile.async.loadInfo.isProcessing && !state.profile.async.loadInfo.isSuccess
);

export const getProfileInfo = state => state.profile.info;

export const getProfileFullName = createSelector(
  getProfileInfo,
  info => getFullName(info.first_name, info.last_name),
);

export const getRoleId = state => state.profile.info.role_id;
