import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  injectIntl,
  intlShape,
  FormattedDate,
} from 'react-intl';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  withTheme,
} from '@material-ui/core';
import listWrapper from 'components/common/base/ListWrapper';
import SearchBar from 'components/common/search/SearchBar';
import PageSelector from 'components/common/pagination/PageSelector';
import PerPage from 'components/common/pagination/PerPage';
import moment from 'moment';
import Alert from 'components/common/Alert';
import { cleanAWSMessage } from 'components/utils/errors';
import { intlWord } from 'components/utils/helpers';
import SortableHeaderColumn from 'components/common/SortableHeaderColumn';
import TableLoadIndicator from 'components/common/TableLoadIndicator';

import getStyles from './PatientList.styles';
import {
  loadPatientsList,
  loadPatientCompliances,
  loadComplianceDetails,
} from '../../actions/patientsActions';
import PatientCell from '../simple/PatientCell';
import Compliance from '../compliance/Compliance';
import ComplianceDialog from '../compliance/ComplianceDialog';
import { getCompliance } from '../../selectors';

class PatientList extends Component {
  constructor(props, context) {
    super(props);

    this.styles = getStyles(props.theme);
    this._sortableFields = [
      { title: 'patients.list.deviceSn', key: 'assigned_device', notSortable: true },
      { title: 'patients.list.subjectId', key: 'subject_id' },
      { title: 'patients.list.handed', key: 'handed', notSortable: true },
      { title: 'patients.list.bandSize', key: 'band_size', notSortable: true },
      { title: 'patients.list.lastSessionOn', key: 'last_session_on', notSortable: true },
      { title: 'patients.list.numberOfSessions', key: 'number_of_sessions', notSortable: true },
      { title: 'patients.list.compliance', key: 'compliance', notSortable: true },
    ];
    this.FIELDS = [
      { text: intlWord(this.props.intl, 'patients.list.patientId'), key: 'patient_id' },
      { text: intlWord(this.props.intl, 'patients.list.subjectId'), key: 'subject_id' },
      { text: intlWord(this.props.intl, 'patients.list.serialNumber'), key: 'serial_number' },
      { text: intlWord(this.props.intl, 'patients.list.mac'), key: 'mac' },
    ];

    this.state = {
      patientsList: [],
      showComplianceDetails: false,
      patientId: null,
      subjectId: null,
      errorMessage: null,
    };
  }

  componentDidMount() {
    const {
      _loadPatientsList,
      setSort,
      setUpdateListFunction,
      setPagination,
    } = this.props;

    const config = {
      perPage: 25,
      selectedPage: 1,
      key: 'subject_id',
      direction: -1,
    };

    _loadPatientsList(config.selectedPage, config.perPage, config.key, config.direction);

    setSort({ key: config.key, direction: config.direction });
    setPagination({ perPage: config.perPage, selectedPage: config.selectedPage });
    setUpdateListFunction(this.updateList);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      patients,
      _loadPatientCompliances,
    } = this.props;

    if (
      patients.patientList.list.data.length > 0
      && patients.patientList.async.isSuccess
      && prevProps.patients.patientList.async.isProcessing
    ) {
      this.setState({
        patientsList: patients.patientList.list.data,
        numberOfPages: patients.patientList.list.pages.length,
      });

      _loadPatientCompliances(patients.patientList.list.data);
    } else if (
      patients.patientList.list.data.length < 1
      && patients.patientList.async.isSuccess
      && prevProps.patients.patientList.async.isProcessing
    ) {
      this.setState({
        patientsList: [],
        numberOfPages: 0,
        errorMessage: 'No Patients Found',
      });
    }
  }

  updateList = (sort, pagination, criteria) => {
    const { _loadPatientsList } = this.props;

    this.setState({
      errorMessage: null,
    });

    _loadPatientsList(pagination.selectedPage, pagination.perPage, sort.key, sort.direction, criteria);
  }

  fetchComplianceDetails = () => {
    this.props._loadComplianceDetails(this.state.patientId);
  }

  openComplianceDetails = (patientId, subjectId) => {
    this.setState({
      showComplianceDetails: true,
      patientId,
      subjectId,
    }, this.fetchComplianceDetails);
  }

  closeComplianceDetails = () => {
    this.setState({
      showComplianceDetails: false,
      patientId: null,
      subjectId: null,
    });
  }

  getClinicLogo = clinicId => {
    return _.get(this.props.clinics.list.byId, `${clinicId}.logo`);
  }

  render() {
    const {
      patients,
      sortChange,
      _getCompliance,
      pagination,
      searchChange,
      selectedPageChange,
      perPageChange,
    } = this.props;
    const {
      patientsList,
      showComplianceDetails,
      subjectId,
      numberOfPages,
      errorMessage,
    } = this.state;
    const { error } = patients.patientList.async;

    return (
      <div
        style={this.styles.container}
      >
        <ComplianceDialog
          isOpen={showComplianceDetails}
          subjectId={subjectId}
          closeDialog={this.closeComplianceDetails}
          asyncInfo={patients.complianceDetails.async}
          fetch={this.fetchComplianceDetails}
        />
        {
          error &&
          <Alert
            id="alert--error"
            text={cleanAWSMessage(error.message, error)}
            type="danger"
          />
        }
        <SearchBar
          onSearch={searchChange}
          fields={this.FIELDS}
          hideCalendar
        />
        <div
          style={this.styles.paginationContainer}
        >
          <PerPage
            perPage={pagination.perPage}
            selectedPage={pagination.selectedPage}
            perPageChange={perPageChange}
            numberOfPages={numberOfPages}
            numberOfRecords={patients.patientList.list.total}
            style={this.styles.perPage}
          />
          <PageSelector
            selectedPage={pagination.selectedPage}
            selectedPageChange={selectedPageChange}
            numberOfPages={numberOfPages}
            position="topRight"
          />
        </div>
        <div style={this.styles.tableWrapper}>
          <Table
            id="table--patient-list"
          >
            <TableHead
              style={this.styles.table.header}
            >
              <TableRow style={this.styles.table.headerRow}>
                {this._sortableFields.map(field =>
                (<SortableHeaderColumn
                  id={`column--${field.key}`}
                  title={field.title}
                  field={field.key}
                  key={field.key}
                  notSortable={field.notSortable || patientsList.length === 0}
                  activeField={this.props.sort.key}
                  onSort={sortChange}
                  sortDirection={this.props.sort.direction}
                  style={field.style}
                />))}
              </TableRow>
            </TableHead>
            {
              !errorMessage ?
                <TableBody>
                  {(patients.patientList.async.isProcessing &&
                    <TableLoadIndicator
                      id="load-indicator"
                      size={50}
                      columnCount={6}
                    />
                  ) || patientsList.map((patient, index) => (
                    <TableRow
                      key={patient.patient_id}
                    >
                      <PatientCell>
                        {patient.assigned_device}
                      </PatientCell>
                      <TableCell>
                        <Link
                          style={this.styles.link}
                          to={{
                            pathname: `/patients/details/${patient.patient_id}`,
                            state: {
                              patient,
                            },
                          }}
                          key={patient.patient_id}
                        >
                          {patient.subject_id}
                        </Link>
                      </TableCell>
                      <PatientCell>
                        {patient.handed}
                      </PatientCell>
                      <PatientCell>
                        {patient.band_size}
                      </PatientCell>
                      <TableCell>
                        {
                          patient.last_session_on ?
                            <FormattedDate
                              value={moment.utc(patient.last_session_on).local()}
                              day="numeric"
                              month="numeric"
                              year="numeric"
                              hour="numeric"
                              minute="numeric"
                            /> : '-'
                        }
                      </TableCell>
                      <TableCell>
                        {patient.number_of_sessions}
                      </TableCell>
                      <PatientCell>
                        <Compliance
                          complianceData={_getCompliance(patient.patient_id)}
                          patientId={patient.patient_id}
                          subjectId={patient.subject_id}
                          openComplianceDetails={this.openComplianceDetails}
                        />
                      </PatientCell>
                    </TableRow>
                  ))}
                </TableBody>
                : <div style={this.styles.errorMessage}>{errorMessage}</div>
            }
          </Table>
          {
            !errorMessage &&
            <PageSelector
              selectedPage={pagination.selectedPage}
              selectedPageChange={selectedPageChange}
              numberOfPages={numberOfPages}
            />
          }
        </div>
      </div>
    );
  }
}

PatientList.propTypes = {
  theme: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  patients: PropTypes.object.isRequired,
  clinics: PropTypes.object.isRequired,
  _loadPatientsList: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  sortChange: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  setUpdateListFunction: PropTypes.func.isRequired,
  _loadPatientCompliances: PropTypes.func.isRequired,
  _loadComplianceDetails: PropTypes.func.isRequired,
  _getCompliance: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  selectedPageChange: PropTypes.func.isRequired,
  perPageChange: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  searchChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  patients: state.patients,
  clinics: state.clinics,
  _getCompliance: patientId => getCompliance(state, patientId),
});

const mapDispatchToProps = dispatch => ({
  _loadPatientsList: (selectedPage, perPage, sortField, sortType, criteria) => {
    dispatch(loadPatientsList(selectedPage, perPage, sortField, sortType, criteria));
  },
  _loadPatientCompliances: patientId => {
    dispatch(loadPatientCompliances(patientId));
  },
  _loadComplianceDetails: patientId => {
    dispatch(loadComplianceDetails(patientId));
  },
});

const enhance = compose(
  injectIntl,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
  listWrapper({
    displayName: 'PatientList',
  }),
);

export default enhance(PatientList);
