import TokenCall from 'components/auth/services/token';
import { extractNameParts } from 'components/utils/helpers';
import Config from 'config';
import * as _ from 'lodash';
import { getResponseData } from 'components/utils/asyncHelpers';

const { AUTH_API_ROOT } = Config;

const changePassword = async function(data) {
  try {
    const response = await TokenCall.post(
      `${AUTH_API_ROOT}/user/changepassword`,
      _.pick(data, ['new_password', 'old_password']),
    );

    return response.data;
  } catch (error) {
    const passwordError = {
      ...error,
      type: 'Password',
    };

    throw passwordError;
  }
};

export const loadProfile = () =>
  getResponseData('get', `${AUTH_API_ROOT}/user`);

export const saveProfile = async({ data }) => {
  const nameParts = extractNameParts(data.name);
  const updatedData = {
    ...data,
    first_name: nameParts[0] || data.first_name,
    last_name: nameParts[1] || data.last_name,
  };
  const { new_password, old_password } = updatedData;

  try {
    if (new_password || old_password) {
      await changePassword(updatedData);
    }

    const response = await TokenCall.put(
      `${AUTH_API_ROOT}/demographics`,
      _.pick(updatedData, ['first_name', 'last_name', 'address', 'phone']),
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
