import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import SearchBar from 'components/common/search/SearchBar';
import PerPage from 'components/common/pagination/PerPage';
import SortableHeaderColumn from 'components/common/SortableHeaderColumn';
import TableLoadIndicator from 'components/common/TableLoadIndicator';
import PageSelector from 'components/common/pagination/PageSelector';
import QuarantineTableBody from '../QuarantineTableBody';

const QuarantineListPresenter = ({
  isProcessing,
  emptySearchMessage,
  quarantineList,
  searchChange,
  pagination,
  perPageChange,
  selectedPageChange,
  numberOfRecords,
  numberOfPages,
  sortChange,
  sort,
  style,
  searchFields,
  sortableFields,
  downloadFile,
  ...rest
}) => (
  <div>
    <SearchBar
      onSearch={searchChange}
      fields={searchFields}
    />
    <div
      style={style.paginationContainer}
    >
      <PerPage
        perPage={pagination.perPage}
        selectedPage={pagination.selectedPage}
        perPageChange={perPageChange}
        numberOfPages={numberOfPages}
        numberOfRecords={numberOfRecords}
        style={style.perPage}
      />
      <PageSelector
        selectedPage={pagination.selectedPage}
        selectedPageChange={selectedPageChange}
        numberOfPages={numberOfPages}
        position="topRight"
      />
    </div>
    <div style={style.tableWrapper}>
      <Table id="table--quarantine-list">
        <TableHead>
          <TableRow style={style.tableRow}>
            <TableCell />
            {sortableFields.map(field => (
              <SortableHeaderColumn
                id={`column--${field.key}`}
                title={field.title}
                field={field.key}
                key={field.key}
                activeField={sort.key}
                sortDirection={sort.direction}
                onSort={sortChange}
                notSortable={field.notSortable || quarantineList.length === 0}
              />
            ))}
          </TableRow>
        </TableHead>
        {
          !emptySearchMessage ?
            <TableBody>
              {(
                isProcessing &&
                <TableLoadIndicator
                  id="load-indicator"
                  size={50}
                  columnCount={6}
                />
              ) || quarantineList.map(quarantineItem => (
                <QuarantineTableBody
                  key={quarantineItem.id}
                  quarantineItem={quarantineItem}
                  downloadFile={downloadFile}
                  style={style}
                />
              ))}
            </TableBody>
            : <div style={style.searchMessage}>{emptySearchMessage}</div>
        }
      </Table>
      {
        !emptySearchMessage &&
        <PageSelector
          selectedPage={pagination.selectedPage}
          selectedPageChange={selectedPageChange}
          numberOfPages={numberOfPages}
        />
      }
    </div>
  </div>
);

QuarantineListPresenter.propTypes = {
  quarantineList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    serial_number: PropTypes.string,
    device_id: PropTypes.number,
    mac: PropTypes.string,
    request_date: PropTypes.string,
    request_type: PropTypes.string,
    file_link: PropTypes.string,
  })).isRequired,
  searchFields: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    key: PropTypes.string,
  })).isRequired,
  sortableFields: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    key: PropTypes.string,
    notSortable: PropTypes.bool,
  })).isRequired,
  searchChange: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  selectedPageChange: PropTypes.func.isRequired,
  sortChange: PropTypes.func.isRequired,
  perPageChange: PropTypes.func.isRequired,
  numberOfRecords: PropTypes.number.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  numberOfPages: PropTypes.number,
  emptySearchMessage: PropTypes.string,
  style: PropTypes.object.isRequired,
  downloadFile: PropTypes.func.isRequired,
};

export default QuarantineListPresenter;
