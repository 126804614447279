import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'recompose';

import { ASSIGN_DEVICE_FORM } from 'components/common/forms/names';
import { TextField, Datepicker, SelectField } from 'components/common/forms/elements';
import { required } from 'components/common/forms/validation';
import Form from 'components/common/forms/Form';

import { assignDevice } from '../../../actions/devicesActions';

const successMessage = { message: 'operations.form.assignDeviceSuccess' };
const errorMessage = { message: 'operations.form.assignDeviceError' };

class AssignForm extends Component {
  static propTypes = {
    deviceInventoryId: PropTypes.number.isRequired,
    prefixList: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      prefix: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    })).isRequired,
    prefixValue: PropTypes.string,
    _assignDevice: PropTypes.func.isRequired,
  };

  submit = values => new Promise((resolve, reject) => {
    const { _assignDevice, deviceInventoryId } = this.props;

    _assignDevice(values, deviceInventoryId, { resolve, reject });
  });

  render() {
    const { prefixList, prefixValue } = this.props;

    return (
      <Form
        id={ASSIGN_DEVICE_FORM}
        successMessage={successMessage}
        errorMessage={errorMessage}
        onSubmit={this.submit}
        {...this.props}
      >
        <TextField
          name="serial_number"
          label="operations.form.serialNumber"
          validate={required}
          grayBackground
          disabled
        />
        <SelectField
          name="prefix"
          label="operations.form.prefix"
          validate={required}
          otherValue={prefixValue}
          items={prefixList}
          itemValue="prefix"
          itemText="name"
          otherOption
        />
        <TextField
          style={{width: '18%'}}
          name="prefix"
          label="operations.form.prefix"
          validate={required}
          grayBackground
          disabled
        />
        <TextField
          style={{width: '78%', marginLeft: '20px'}}
          name="brighttree_id"
          label="operations.form.subjectId"
          validate={required}
        />
        <Datepicker name="assign_date" label="operations.form.assignDate" validate={required} />
      </Form>
    );
  }
}

const selector = formValueSelector(ASSIGN_DEVICE_FORM);

const mapStateToProps = state => ({
  prefixValue: selector(state, 'prefix'),
});

const mapDispatchToProps = dispatch => ({
  _assignDevice: (data, deviceInventoryId, promise) => {
    dispatch(assignDevice(data, deviceInventoryId, promise));
  },
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: ASSIGN_DEVICE_FORM,
    enableReinitialize: true,
  }),
);

export default enhance(AssignForm);
