import moment from 'moment';
import DOMPurify from 'dompurify';
// eslint-disable-next-line
import momentDurationFormatSetup from 'moment-duration-format';
import _ from 'lodash';
import * as en from 'intl/en';

export const getFullName = (firstName, lastName, anonymousName = 'Anonymous') => {
  const nameParts = [];

  if (!firstName && !lastName) {
    return anonymousName;
  }

  if (firstName) {
    nameParts.push(firstName);
  }
  if (lastName) {
    nameParts.push(lastName);
  }

  return nameParts.join(' ');
};

export const extractNameParts = (fullName = '') => {
  const nameParts = fullName.split(' ');
  const lastName = nameParts.pop();
  const firstName = nameParts.join(' ');

  return [firstName, lastName];
};

export const isStrInclusive = (host, keyword, caseInsensitive = true) => {
  const hostStr = host || '';

  if (caseInsensitive) {
    return hostStr.toLowerCase().includes(keyword.toLowerCase());
  }
  return hostStr.includes(keyword);
};

/**
 * Convert duration to selected time format. Uses the 'moment-duration-format' package
 * @function convertDuration
 * @param {number} duration the duration that needs to be converted
 * @param {string} timeFormat optional, defines how the output should be formatted, e.g. 'HH:mm:ss'
 * @param {string} units defaults to seconds, describes which unit is used for the provided duration, supports seconds, minutes, hours, etc.
 * @param {object} options additional options to format the output
 * @returns {string} the duration converted to the provided time format
*/
export const convertDuration = (
  duration,
  timeFormat = 'mm:ss',
  units = 'seconds',
  options = { trim: false },
) => moment.duration(duration, units).format(timeFormat, options);

/**
* Converts utc offset from minutes to hours:minutes and putting + or - sign in front
* @function convertUtcOffset
* @param {number} utcOffset UTC offset provided in minutes, can be positive or negative value
* @returns {string} UTC offset converter to, e.g. -07:00
*/
export const convertUtcOffset = utcOffset => {
  const plurOrMinus = utcOffset >= 0 ? '+' : '';

  return `${plurOrMinus}${convertDuration(utcOffset, 'HH:mm', 'minutes')}`;
};

/**
  Take an object with nested props and return an object with a single level of props
  @function flattenObject
  @param {object} ob {
    'PatientPage': {
      'title': 'Patients List'
    }
  }
  @returns {object} {
    'PatientPage.title': 'Patients List'
  }
*/
export const flattenObject = ob => {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) === 'object') {
      const flatObject = flattenObject(ob[i]);

      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[(`${i}.${x}`).toString()] = flatObject[x];
      }
    } else {
      toReturn[i.toString()] = ob[i];
    }
  }
  return toReturn;
};

/**
  Checking if event was a mouse click or keypress. If keypress then check if enter or space were clicked 508C requirement, clickable objects must have keypress events
  @function enterOrSpacePressed
  @param {object} e event
  @returns {bool} returns false if event is a keypress and the pressed key is neither space nor enter,
                    returns true if event is a mouse click or space or enter have been pressed
*/
export const enterOrSpacePressed = e => {
  const code = (e.keyCode ? e.keyCode : e.which);

  if (code && (code !== 13 && code !== 32)) {
    return false;
  }

  return true;
};

/**
 * Sanitizing HTML before injecting to the DOM to precent XSS attacks
 * @param {string} html HTML that needs to be escaped
 * @returns {string} sanitized HTML
 */
export const sanitizeHTML = html => {
  return DOMPurify.sanitize(html);
};

/**
 * Prints content of a specified HTML element
 * @param {string} id The id of the selected elemet
 * @returns {void}
 */
export const printElement = id => {
  const prtContent = document.getElementById(id);
  const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

  WinPrint.document.write(prtContent.innerHTML);
  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();
  WinPrint.close();
};

/**
 * Find object with same ID in two arrays and merge the content of those objects
 * @param {array} a1 Array of objects
 * @param {array} a2 Array of objects
 * @param {string} key Key by which to merge objects
 * @return {array} Array of objcets with merged content
 */
export const mergeObjectsById = (a1, a2, key = 'id') => {
  const merged = _.map(a1, item => {
    return _.assign(item, _.find(a2, [key, item[key]]));
  });

  return merged;
};

/**
 * Sorts the provided list alphatecally by the provided key
 * @param {array} list List of objects which need to be sorted alphabetically
 * @param {string} key Key by which to sort
 * @returns {void}
 */
export const sortAlphabetically = (list, key) => {
  list.sort((a, b) => a[key].localeCompare(b[key]));
};

/**
 * Verifies if item style should be highlighted, because it's either being edited or it was recentyl created
 * @param {object} itemInEdit Item that's being edited
 * @param {object} item Item in the list
 * @param {id} newItem New item that has just been created
 * @param {object} key Key by which to compare
 * @returns {bool} Whether the item should be highlighted or not
 */
export const shouldHighlightItem = (itemInEdit, item, newItem, key) => {
  return (_.get(itemInEdit, key) === item[key])
    || (item[key] === newItem);
};

/**
 * Returns translation for the provided key
 * @param {object} intl react-intl object with the translation function
 * @param {string} key What word has to be translated
 * @returns {string} Translated word
 */
export const intlWord = (intl, key) => {
  return intl.formatMessage({
    id: key,
    defaultMessage: _.get(en, key),
  });
};

/**
 * Puts real values at places marked with curly brackets
 * @param {string} string Text where values have to be replaced, e.g. 'Mr.{0}, welcome back. We missed you, said agent {1}'
 * @param {array} replacementArray Array with values, e.g. [Anderson, Smith]
 * @returns {string} Updated string which contains values instead of placeholders, for the example above: 'Mr. Anderson, welcome back. We missed you, said agent Smith'
 */
export const injectValuesToString = (string, replacementArray) => {
  return string.replace(/({\d})/g, j => {
    return replacementArray[j.replace(/{/, '').replace(/}/, '')];
  });
};

/**
 * Sorts any array of objects by selected key and direction
 * @param {array} list List that needs to be sorted
 * @param {string} key Name of field by which to sort
 * @param {number} direction Determines ascending or descending order of list
 * @returns {array} List of items sorted by key and direction
 */
export const orderBy = (list, key, direction) => {
  return _.orderBy(
    list,
    row => (_.get(row, key)),
    [(direction === 1) ? 'asc' : 'desc'],
  );
};
