import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import getStyles from './TableLoadIndicator.styles';

class TableLoadIndicator extends Component {
  render() {
    const { size, columnCount, id } = this.props;
    const styles = getStyles();

    return (
            <TableRow
              id={id}
              style={styles.row}
            >
                <TableCell
                  colSpan={columnCount}
                  style={styles.column}
                >
                    <CircularProgress
                      size={size}
                      color="inherit"
                    />
                </TableCell>
            </TableRow>
    );
  }
}

TableLoadIndicator.propTypes = {
  size: PropTypes.number.isRequired,
  columnCount: PropTypes.number.isRequired,
  id: PropTypes.string,
};

export default TableLoadIndicator;
