const getStyles = () => ({
  modal: {
    padding: 0,
    maxWidth: '100%',
    content: {
      width: '90%',
      maxWidth: 'none',
    },
  },
  container: {
    display: 'flex',
  },
});

export default getStyles;
