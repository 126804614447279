export const PUBLISH_FIRMWARE = 'PUBLISH_FIRMWARE';
export const LOAD_FIRMWARES = 'LOAD_FIRMWARES';
export const UPDATE_FIRMWARE = 'UPDATE_FIRMWARE';
export const UPDATE_FIRMWARE_ARCHIVE = 'UPDATE_FIRMWARE_ARCHIVE';
export const DEFAULT_FIRMWARE = 'DEFAULT_FIRMWARE';
export const LOAD_FIRMWARE_DEVICES = 'LOAD_FIRMWARE_DEVICES';
export const ASSIGN_FIRMWARE = 'ASSIGN_FIRMWARE';
export const UNASSIGN_FIRMWARE = 'UNASSIGN_FIRMWARE';
export const EXPORT_LIST = 'EXPORT_LIST';

export const publishFirmware = (firmware, promise) => {
  return {
    type: PUBLISH_FIRMWARE,
    firmware,
    promise,
  };
};

export const loadFirmwares = () => ({ type: LOAD_FIRMWARES });

export const updateFirmware = (firmware, promise) => ({ type: UPDATE_FIRMWARE, firmware, promise });

export const updateFirmwareArchive = firmware => ({
  type: UPDATE_FIRMWARE_ARCHIVE,
  firmware,
});

export const defaultFirmware = firmware => ({
  type: DEFAULT_FIRMWARE,
  firmware: {
    ...firmware,
    is_default: true,
  },
});

export const loadFirmwareDevices = () => ({ type: LOAD_FIRMWARE_DEVICES });

export const assignFirmware = (deviceFirmware, promise) => ({ type: ASSIGN_FIRMWARE, deviceFirmware, promise });

export const unassignFirmware = (deviceFirmware, promise) => ({ type: UNASSIGN_FIRMWARE, deviceFirmware, promise });

export const exportList = deviceType => ({ type: EXPORT_LIST, deviceType });
