import { createReducer } from 'components/utils/reduxHelpers';
import { LOAD_PROFILE, SAVE_PROFILE } from '../actions/profileActions';

export const DEFAULT_STATE = {};

const saveProfile = (state, action) => {
  return {
    ...state,
    ...action.result,
    pk: state.pk,
    role_id: state.role_id,
  };
};

const infoReducer = createReducer(DEFAULT_STATE, {
  [`${LOAD_PROFILE}_SUCCESS`]: (state, action) => action.result,
  [`${SAVE_PROFILE}_SUCCESS`]: saveProfile,
});

export default infoReducer;
