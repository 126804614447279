const getStyles = theme => ({
  content: {
    display: 'flex',
    padding: theme.padding.pageSmall,
    width: '100%',
    SMALL: {
      padding: theme.padding.pageSmall,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    left: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 500,
      paddingRight: 100,
      paddingBottom: 50,
    },
  },
});

export default getStyles;
