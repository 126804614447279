const palette = {
  red: '#DE4A58',
  blankColor: 'white',
  textColor: '#666',
  secondaryTextColor: 'white',
  highlightTextColor: '#189ACA',
  dangerTextColor: '#FE4042',
  defaultTextColor: 'rgb(158, 158, 158)',
  backgroundColor: '#189ACA',
  thickBackgroundColor: '#448CA5',
  secondaryBackgroundColor: '#EBEBEB',
  secondaryButtonColor: 'black',
  steel: '#D0D6D8',
  borderColor: 'rgb(158, 158, 158)',
  backgroundGradient: '#363636',
  warningColor: '#F05656',
  borderColor1: '#A0A0A0',
  borderColor2: '#C3C3C3',
  highlightColor: '#E8F7FC',
  mainTheme: '#189ACA',
  lightGrey: '#F2F2F2',
  grayBackgroundTextField: '#EDEDED',
  inputLabelColor: '#000000',
  backgroundColorFirmwareList: '#FAFAFA',
  grayBackgroundDefaultFirmware: 'rgba(0, 0, 0, 0.12)',
  controlColumnTitleColor: '#757575',
};
const spacing = factor =>
  [1, 5, '0.5em', '1em', '2em', '3em', '2.2em', '0.5em', '0.5em'][factor];
  // spacing [factor]:
  // titleLetters: 1 [0]
  // iconAndText: 5 [1]
  // titleAndBlock: '0.5em' [2]
  // siblingBlocks: '1em' [3]
  // titleAndContent: '2em' [4]
  // menuItems: '3em' [5]
  // sidebarItems: '2.2em' [6]
  // formElements: '0.5em' [7]
  // textLines: '0.5em' [8]

const size = {
  body: 12,
  header: 21,
  primaryLink: '1.3em',
  primaryInput: '0.9em',
};

const defaultTheme = {
  typography: {
    useNextVariants: true,
  },
  palette,
  spacing,
  padding: {
    pageLarge: '8em',
    pageMedium: '3em',
    pageSmall: '2em',
    textInput: '1em',
  },
  size,
  height: {
    pageHeader: 130,
  },
  font: {
    bold: 700,
    normal: 400,
    thin: 200,
  },
  appBar: {
    height: 70,
  },
  datePicker: {
    color: palette.highlightTextColor,
    selectColor: palette.highlightTextColor,
    selectTextColor: 'white',
    headerColor: palette.backgroundColor,
  },
  flatButton: {
    primaryTextColor: palette.highlightTextColor,
  },
  menuItem: {
    selectedTextColor: palette.highlightTextColor,
  },
  overrides: {
    MuiIconButton: {
      root: {
        color: palette.backgroundColor,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: palette.backgroundColor,
      },
    },
    MuiPickersDay: {
      day: {
        color: palette.defaultTextColor,
      },
      daySelected: {
        backgroundColor: palette.backgroundColor,
        '&:hover': {
          backgroundColor: palette.backgroundColor,
        },
      },
      current: {
        color: palette.defaultTextColor,
      },
    },
    MuiTableRow: {
      root: {
        color: 'inherit',
        height: '48px',
        display: 'table-row',
        outline: 'none',
        verticalAlign: 'middle',
      },
    },
    MuiTableCell: {
      root: {
        padding: '0 0 0 10px',
        color: 'unset',
        fontSize: '0.8125rem',
        fontWeight: '400',
      },
      body: {
        color: 'unset',
      },
    },
    MuiCheckbox: {
      root: {
        color: palette.backgroundColor,
        '&$checked': {
          color: palette.backgroundColor,
        },
      },
    },
    MuiRadio: {
      root: {
        '&$checked': {
          color: palette.mainTheme,
        },
      },
    },
    MuiButton: {
      textPrimary: {
        color: palette.highlightTextColor,
      },
      textSecondary: {
        color: 'red',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: palette.backgroundColor,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: palette.highlightTextColor,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.875rem',
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: '11px',
        paddingBottom: '11px',
      },
    },
    MuiTypography: {
      body1: {
        color: palette.defaultTextColor,
      },
    },
  },
};

export default defaultTheme;
