import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import Message from '../Message';

const ErrorSnackbarActions = ({ retryAction, disableRetry }) => (
  [
    <Button
      key="retry"
      color="secondary"
      size="small"
      onClick={retryAction}
      disabled={disableRetry}
    >
      <Message id="notifier.main.retry" />
    </Button>,
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      disableRipple
    >
      <Icon>close</Icon>
    </IconButton>,
  ]
);

ErrorSnackbarActions.propTypes = {
  retryAction: PropTypes.func,
  disableRetry: PropTypes.bool,
};

export default ErrorSnackbarActions;
