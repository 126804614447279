/* eslint max-len: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PatientListIcon = props => (
    <SvgIcon
      {...props}
      viewBox="0 0 22 28"
      fontSize="inherit"
    >
      <path d="M8.959,0C7.738,0,5.679,1.05,5.679,2.362v.613H-.29A1.591,1.591,0,0,0-2,4.638V26.25A1.712,1.712,0,0,0-.29,28H18.29A1.766,1.766,0,0,0,20,26.25V4.638a1.712,1.712,0,0,0-1.71-1.75H12.24V2.362C12.24,1.05,10.181,0,8.959,0Zm0,1.137c.57,0,2.059.525,2.059,1.137V4.55H6.9V2.362C6.9,1.663,8.389,1.137,8.959,1.137Zm0,.613a.578.578,0,0,0-.57.612.572.572,0,1,0,1.14,0A.578.578,0,0,0,8.959,1.75ZM-.29,4.112H5.76V5.25a.578.578,0,0,0,.57.612H11.67a.578.578,0,0,0,.57-.612V4.112h6.05a.578.578,0,0,1,.57.612V26.25a.578.578,0,0,1-.57.613H-.29a.578.578,0,0,1-.57-.613V4.638A.56.56,0,0,1-.29,4.112Zm13.647,7.7L7.33,18.462,4.48,15.4l-.733.787,3.584,3.85L14.09,12.6Z" />
    </SvgIcon>
);

export default PatientListIcon;
