import { ofType } from 'redux-observable';
import { from, of, merge } from 'rxjs';
import {
  map,
  switchMap,
  takeUntil,
  catchError,
} from 'rxjs/operators';
import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILURE,
  CANCEL_PENDING_REQUEST,
  loginRequested,
} from '../actions/authActions';
import { googleLogin } from '../services/auth';

export const loginEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_REQUEST),
    switchMap(action =>
      from(googleLogin(action.url)).pipe(takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST))))),

    map(response => loginRequested(response.data)),
    catchError((error, caught) => merge(
      of({
        type: LOGIN_REQUEST_FAILURE,
        payload: error,
        error: true,
      }),
      caught,
    )),
  );
