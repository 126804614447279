const getStyles = theme => ({
  fieldName: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  wrapper: {
    lineHeight: '25px',
  },
  itemContainer: {
    display: 'inline-block',
  },
});

export default getStyles;
