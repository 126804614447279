const getStyles = theme => ({
  modal: {
    paddingTop: '-200px',
    body: {
      padding: 0,
    },
    content: {
      width: '40%',
      maxWidth: 'none',
    },
  },
  header: {
    height: 'auto',
    maxHeight: '50px',
  },
  editorContainer: {
    margin: '25px',
    textAlign: 'center',
  },
  saveBtn: {
    marginTop: '20px',
    width: '50%',
    maxHeight: '40px',
    height: 'auto',
    lineHeight: 'auto',
  },
  saveBtnLbl: {
    fontWeight: 'bold',
  },
  containerDivider: {
    borderBottom: `1px solid ${theme.palette.borderColor1}`,
    borderTop: `1px solid ${theme.palette.borderColor1}`,
    background: theme.palette.secondaryBackgroundColor,
    color: theme.palette.textColor,
    padding: '10px 30px',
  },
  notesContainer: {
    margin: '25px 0',
    overflow: 'auto',
    maxHeight: '300px',
    wordWrap: 'break-word',
  },
  note: {
    margin: '0 30px',
    marginBottom: '10px',
    borderBottom: `1px solid ${theme.palette.borderColor1}`,
    position: 'relative',
  },
  noteType: {
    margin: '0 30px 10px 30px',
    position: 'relative',
    title: {
      fontWeight: theme.font.bold,
    },
  },
  noteText: {
    color: theme.palette.textColor,
    textAlign: 'justify',
  },
  noteFooter: {
    margin: '20px 0',
    fontSize: theme.size.body,
    display: 'flex',
    width: '100%',
  },
  footerAuthor: {
    display: 'inline-flex',
    marginRight: 'auto',
  },
  footerControls: {
    display: 'inline-flex',
    marginLeft: 'auto',
    color: theme.palette.backgroundColor,
  },
  footerBtn: {
    color: theme.palette.backgroundColor,
    lineHeight: 'auto',
    maxHeight: theme.size.body,
    margin: '0 10px',
    minWidth: '0px',
    label: {
      padding: '0px',
      fontSize: theme.size.body,
    },
  },
  editor: {
    minHeight: '150px',
    maxHeight: '150px',
    border: `1px solid #F1F1F1`,
    padding: '0 5px',
  },
  hashIcon: {
    height: 12,
    width: 12,
    position: 'absolute',
    left: -20,
    top: 1,
    warningIcon: {
      fill: 'red',
    },
    checkIcon: {
      fill: '#28B7F1',
    },
  },
  buttonWrapper: {
    marginLeft: 'auto',
    closeButton: {
      icon: {
        color: 'white',
        background: '#C2C2C2',
        borderRadius: '50px',
      },
    },
    printerButton: {
      marginRight: 30,
      icon: {
        fill: theme.palette.backgroundColor,
      },
    },
  },
});

export default getStyles;
