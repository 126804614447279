import { combineReducers } from 'redux';
import { asyncReducerHelper } from 'components/utils/reduxHelpers';
import {
  LOAD_USERS,
  ADD_USER,
  ADD_USER_SIGNUP,
  UPDATE_USER,
  FETCH_USER_DATA,
} from '../actions/usersActions';

export const loadInfo = asyncReducerHelper(LOAD_USERS);
// add and update do not change the list
// after add or update the list loaded again
export const addInfo = asyncReducerHelper(ADD_USER);
export const addInfoSignUp = asyncReducerHelper(ADD_USER_SIGNUP);
export const updateInfo = asyncReducerHelper(UPDATE_USER);
export const fetchInfo = asyncReducerHelper(FETCH_USER_DATA);

const asyncReducer = combineReducers({
  loadInfo,
  addInfo,
  addInfoSignUp,
  updateInfo,
  fetchInfo,
});

export default asyncReducer;
